// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../../context/router/Context_Router.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Orders_Modal$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Modal.bs.js";
import * as Flash_Search_Modal_Utils$DvmAdminFrontendFr from "./Flash_Search_Modal_Utils.bs.js";
import * as Flash_Search_Modal_Element$DvmAdminFrontendFr from "./Flash_Search_Modal_Element.bs.js";
import * as Flash_Search_Modal_Car_Filters$DvmAdminFrontendFr from "./filterPages/Flash_Search_Modal_Car_Filters.bs.js";
import * as Flash_Search_Modal_Date_Filters$DvmAdminFrontendFr from "./filterPages/Flash_Search_Modal_Date_Filters.bs.js";
import * as Flash_Search_Modal_Page_Buttons$DvmAdminFrontendFr from "./Flash_Search_Modal_Page_Buttons.bs.js";

function Flash_Search_Modal(Props) {
  var onClose = Props.onClose;
  var submit = Props.submit;
  var orderType = Props.orderType;
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var match$1 = React.useState(function () {
        return /* Date */0;
      });
  var filterPage = match$1[0];
  return React.createElement(Flash_Search_Modal_Element$DvmAdminFrontendFr.make, {
              open: true,
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                }),
              title: intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.title),
              children: React.createElement(Core.Container, {
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          justifyContent: "space-between",
                          wrap: "nowrap",
                          style: {
                            height: "100%"
                          }
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              spacing: 2
                            }, React.createElement(Flash_Search_Modal_Page_Buttons$DvmAdminFrontendFr.make, {
                                  filterPage: filterPage,
                                  setFilterPage: match$1[1],
                                  form: form,
                                  formAdditional: formAdditional
                                }), React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Collapse, {
                                            children: React.createElement(Flash_Search_Modal_Date_Filters$DvmAdminFrontendFr.make, {
                                                  orderType: orderType,
                                                  form: form
                                                }),
                                            in: filterPage === /* Date */0
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Collapse, {
                                            children: React.createElement(Flash_Search_Modal_Car_Filters$DvmAdminFrontendFr.make, {
                                                  form: formAdditional
                                                }),
                                            in: filterPage === /* Car */1
                                          }),
                                      item: true,
                                      style: {
                                        width: "100%"
                                      }
                                    }))), React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              justifyContent: "flex-end",
                              spacing: 2
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Curry._1(dispatch, {
                                                  TAG: /* StoreFlashModalFilter */9,
                                                  _0: undefined
                                                });
                                            Curry._1(dispatch, {
                                                  TAG: /* StoreFlashModalAdditionalFilter */10,
                                                  _0: undefined
                                                });
                                            Curry._1(form.reset, undefined);
                                            Curry._1(formAdditional.reset, undefined);
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.restart),
                                        color: "primaryBorder"
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Curry._1(onClose, undefined);
                                            Curry._1(submit, undefined);
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        disabled: Flash_Search_Modal_Utils$DvmAdminFrontendFr.datesIncorrect(form)
                                      }),
                                  item: true
                                }))),
                    style: {
                      height: "100%",
                      width: "800px"
                    }
                  })
            });
}

var ModalUtils;

var make = Flash_Search_Modal;

export {
  ModalUtils ,
  make ,
}
/* react Not a pure module */
