// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as Flash_Detail_Styles$DvmAdminFrontendFr from "./Flash_Detail_Styles.bs.js";

function Flash_Detail_Info_Row(Props) {
  var label = Props.label;
  var values = Props.values;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              className: Flash_Detail_Styles$DvmAdminFrontendFr.detailRow,
              container: true,
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "600"
                        },
                        children: intl.formatMessage(label)
                      }),
                  item: true,
                  md: Grid$Mui.Md[2],
                  sm: Grid$Mui.Sm[4]
                }), Belt_Array.mapWithIndex(values, (function (index, row) {
                    return React.createElement(Core.Grid, {
                                children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                      label: row.label,
                                      children: row.value
                                    }),
                                item: true,
                                md: Grid$Mui.Md[2],
                                sm: Grid$Mui.Sm[4],
                                key: String(index)
                              });
                  })));
}

var make = Flash_Detail_Info_Row;

export {
  make ,
}
/* react Not a pure module */
