// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactAlert from "react-alert";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as Upload_Page_Api$DvmAdminFrontendFr from "./Upload_Page_Api.bs.js";
import * as File_Upload_Button$DvmAdminFrontendFr from "../../common/fileUpload/File_Upload_Button.bs.js";
import * as Messages_Upload_Page$DvmAdminFrontendFr from "../../intl/messages/upload/Messages_Upload_Page.bs.js";

function Upload_Page(Props) {
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  React.useEffect((function () {
          Upload_Page_Api$DvmAdminFrontendFr.fetchPossibleUploads(alert, userManager, setResult, selectedRole);
        }), []);
  return React.createElement(File_Upload_Button$DvmAdminFrontendFr.make, {
              title: Messages_Upload_Page$DvmAdminFrontendFr.uploadAttachments,
              table: "SP_CONTRACT_ATTACHMENTS"
            });
}

var Api;

var make = Upload_Page;

export {
  Api ,
  make ,
}
/* react Not a pure module */
