// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

var Fields = {
  model: "model",
  modelName: "modelName",
  version: "version",
  versionName: "versionName",
  maxBonus: "maxBonus",
  startDate: "startDate",
  endDate: "endDate",
  category: "category",
  vehicleType: "versionVehicleType"
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "modelVersionID",
                Decco.stringToJson(v.modelVersionID)
              ],
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "modelName",
                Decco.optionToJson(Decco.stringToJson, v.modelName)
              ],
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "versionName",
                Decco.optionToJson(Decco.stringToJson, v.versionName)
              ],
              [
                "maxBonus",
                Decco.optionToJson(Decco.floatToJson, v.maxBonus)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "followingStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.followingStartDate)
              ],
              [
                "previousEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.previousEndDate)
              ],
              [
                "categoryID",
                Decco.optionToJson(Decco.stringToJson, v.categoryID)
              ],
              [
                "actionID",
                Decco.optionToJson(Decco.stringToJson, v.actionID)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var modelVersionID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersionID"), null));
  if (modelVersionID.TAG === /* Ok */0) {
    var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
    if (model.TAG === /* Ok */0) {
      var modelName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelName"), null));
      if (modelName.TAG === /* Ok */0) {
        var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
        if (version.TAG === /* Ok */0) {
          var versionName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionName"), null));
          if (versionName.TAG === /* Ok */0) {
            var maxBonus = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "maxBonus"), null));
            if (maxBonus.TAG === /* Ok */0) {
              var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
              if (startDate.TAG === /* Ok */0) {
                var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                if (endDate.TAG === /* Ok */0) {
                  var followingStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "followingStartDate"), null));
                  if (followingStartDate.TAG === /* Ok */0) {
                    var previousEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "previousEndDate"), null));
                    if (previousEndDate.TAG === /* Ok */0) {
                      var categoryID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "categoryID"), null));
                      if (categoryID.TAG === /* Ok */0) {
                        var actionID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionID"), null));
                        if (actionID.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: {
                                    modelVersionID: modelVersionID._0,
                                    model: model._0,
                                    modelName: modelName._0,
                                    version: version._0,
                                    versionName: versionName._0,
                                    maxBonus: maxBonus._0,
                                    startDate: startDate._0,
                                    endDate: endDate._0,
                                    followingStartDate: followingStartDate._0,
                                    previousEndDate: previousEndDate._0,
                                    categoryID: categoryID._0,
                                    actionID: actionID._0
                                  }
                                };
                        }
                        var e = actionID._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".actionID" + e.path,
                                  message: e.message,
                                  value: e.value
                                }
                              };
                      }
                      var e$1 = categoryID._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".categoryID" + e$1.path,
                                message: e$1.message,
                                value: e$1.value
                              }
                            };
                    }
                    var e$2 = previousEndDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".previousEndDate" + e$2.path,
                              message: e$2.message,
                              value: e$2.value
                            }
                          };
                  }
                  var e$3 = followingStartDate._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".followingStartDate" + e$3.path,
                            message: e$3.message,
                            value: e$3.value
                          }
                        };
                }
                var e$4 = endDate._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".endDate" + e$4.path,
                          message: e$4.message,
                          value: e$4.value
                        }
                      };
              }
              var e$5 = startDate._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".startDate" + e$5.path,
                        message: e$5.message,
                        value: e$5.value
                      }
                    };
            }
            var e$6 = maxBonus._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".maxBonus" + e$6.path,
                      message: e$6.message,
                      value: e$6.value
                    }
                  };
          }
          var e$7 = versionName._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".versionName" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
        }
        var e$8 = version._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".version" + e$8.path,
                  message: e$8.message,
                  value: e$8.value
                }
              };
      }
      var e$9 = modelName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".modelName" + e$9.path,
                message: e$9.message,
                value: e$9.value
              }
            };
    }
    var e$10 = model._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".model" + e$10.path,
              message: e$10.message,
              value: e$10.value
            }
          };
  }
  var e$11 = modelVersionID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".modelVersionID" + e$11.path,
            message: e$11.message,
            value: e$11.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var IncludedModelVersions = {
  row_encode: row_encode,
  row_decode: row_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

function row_encode$1(v) {
  return Js_dict.fromArray([
              [
                "modelVersionID",
                Decco.stringToJson(v.modelVersionID)
              ],
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "modelName",
                Decco.optionToJson(Decco.stringToJson, v.modelName)
              ],
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "versionName",
                Decco.optionToJson(Decco.stringToJson, v.versionName)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function row_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var modelVersionID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersionID"), null));
  if (modelVersionID.TAG === /* Ok */0) {
    var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
    if (model.TAG === /* Ok */0) {
      var modelName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelName"), null));
      if (modelName.TAG === /* Ok */0) {
        var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
        if (version.TAG === /* Ok */0) {
          var versionName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionName"), null));
          if (versionName.TAG === /* Ok */0) {
            var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
            if (startDate.TAG === /* Ok */0) {
              var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
              if (endDate.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          modelVersionID: modelVersionID._0,
                          model: model._0,
                          modelName: modelName._0,
                          version: version._0,
                          versionName: versionName._0,
                          startDate: startDate._0,
                          endDate: endDate._0
                        }
                      };
              }
              var e = endDate._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".endDate" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = startDate._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".startDate" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = versionName._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".versionName" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = version._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".version" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = modelName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".modelName" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = model._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".model" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = modelVersionID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".modelVersionID" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "excludedVersions",
                Decco.listToJson(row_encode$1, v.excludedVersions)
              ],
              [
                "includedModels",
                Decco.listToJson(Decco.stringToJson, v.includedModels)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var excludedVersions = Decco.listFromJson(row_decode$1, Belt_Option.getWithDefault(Js_dict.get(dict$1, "excludedVersions"), null));
  if (excludedVersions.TAG === /* Ok */0) {
    var includedModels = Decco.listFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "includedModels"), null));
    if (includedModels.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                excludedVersions: excludedVersions._0,
                includedModels: includedModels._0
              }
            };
    }
    var e = includedModels._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".includedModels" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = excludedVersions._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".excludedVersions" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var ExcludedModelVersions = {
  row_encode: row_encode$1,
  row_decode: row_decode$1,
  t_encode: t_encode,
  t_decode: t_decode
};

function row_encode$2(v) {
  return Js_dict.fromArray([
              [
                "modelVersionID",
                Decco.stringToJson(v.modelVersionID)
              ],
              [
                "model",
                Decco.optionToJson(Decco.stringToJson, v.model)
              ],
              [
                "modelName",
                Decco.optionToJson(Decco.stringToJson, v.modelName)
              ],
              [
                "version",
                Decco.optionToJson(Decco.stringToJson, v.version)
              ],
              [
                "versionName",
                Decco.optionToJson(Decco.stringToJson, v.versionName)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function row_decode$2(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var modelVersionID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersionID"), null));
  if (modelVersionID.TAG === /* Ok */0) {
    var model = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
    if (model.TAG === /* Ok */0) {
      var modelName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelName"), null));
      if (modelName.TAG === /* Ok */0) {
        var version = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
        if (version.TAG === /* Ok */0) {
          var versionName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionName"), null));
          if (versionName.TAG === /* Ok */0) {
            var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
            if (startDate.TAG === /* Ok */0) {
              var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
              if (endDate.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          modelVersionID: modelVersionID._0,
                          model: model._0,
                          modelName: modelName._0,
                          version: version._0,
                          versionName: versionName._0,
                          startDate: startDate._0,
                          endDate: endDate._0
                        }
                      };
              }
              var e = endDate._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".endDate" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = startDate._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".startDate" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = versionName._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".versionName" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = version._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".version" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = modelName._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".modelName" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = model._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".model" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = modelVersionID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".modelVersionID" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function t_encode$1(v) {
  return Js_dict.fromArray([
              [
                "excludedVersions",
                Decco.listToJson(row_encode$2, v.excludedVersions)
              ],
              [
                "includedModels",
                Decco.listToJson(Decco.stringToJson, v.includedModels)
              ]
            ]);
}

function t_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var excludedVersions = Decco.listFromJson(row_decode$2, Belt_Option.getWithDefault(Js_dict.get(dict$1, "excludedVersions"), null));
  if (excludedVersions.TAG === /* Ok */0) {
    var includedModels = Decco.listFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "includedModels"), null));
    if (includedModels.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                excludedVersions: excludedVersions._0,
                includedModels: includedModels._0
              }
            };
    }
    var e = includedModels._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".includedModels" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = excludedVersions._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".excludedVersions" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var SavingExcludedModelVersions = {
  row_encode: row_encode$2,
  row_decode: row_decode$2,
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function versionEdit_encode(v) {
  return Js_dict.fromArray([
              [
                "actionID",
                Decco.optionToJson(Decco.stringToJson, v.actionID)
              ],
              [
                "modelVersionID",
                Decco.stringToJson(v.modelVersionID)
              ],
              [
                "oldStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.oldStartDate)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "maxBonus",
                Decco.optionToJson(Decco.floatToJson, v.maxBonus)
              ]
            ]);
}

function versionEdit_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionID"), null));
  if (actionID.TAG === /* Ok */0) {
    var modelVersionID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelVersionID"), null));
    if (modelVersionID.TAG === /* Ok */0) {
      var oldStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "oldStartDate"), null));
      if (oldStartDate.TAG === /* Ok */0) {
        var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            var maxBonus = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "maxBonus"), null));
            if (maxBonus.TAG === /* Ok */0) {
              return {
                      TAG: /* Ok */0,
                      _0: {
                        actionID: actionID._0,
                        modelVersionID: modelVersionID._0,
                        oldStartDate: oldStartDate._0,
                        startDate: startDate._0,
                        endDate: endDate._0,
                        maxBonus: maxBonus._0
                      }
                    };
            }
            var e = maxBonus._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".maxBonus" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = oldStartDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".oldStartDate" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = modelVersionID._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".modelVersionID" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = actionID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionID" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function versionEditList_encode(v) {
  return Decco.listToJson(versionEdit_encode, v);
}

function versionEditList_decode(v) {
  return Decco.listFromJson(versionEdit_decode, v);
}

function editPair_encode(v) {
  return Js_dict.fromArray([
              [
                "original",
                versionEdit_encode(v.original)
              ],
              [
                "new",
                versionEdit_encode(v.new)
              ]
            ]);
}

function editPair_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var original = versionEdit_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "original"), null));
  if (original.TAG === /* Ok */0) {
    var $$new = versionEdit_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "new"), null));
    if ($$new.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                original: original._0,
                new: $$new._0
              }
            };
    }
    var e = $$new._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".new" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = original._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".original" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function editListPair_encode(v) {
  return Decco.listToJson(editPair_encode, v);
}

function editListPair_decode(v) {
  return Decco.listFromJson(editPair_decode, v);
}

var VersionEdit = {
  versionEdit_encode: versionEdit_encode,
  versionEdit_decode: versionEdit_decode,
  versionEditList_encode: versionEditList_encode,
  versionEditList_decode: versionEditList_decode,
  editPair_encode: editPair_encode,
  editPair_decode: editPair_decode,
  editListPair_encode: editListPair_encode,
  editListPair_decode: editListPair_decode
};

function model_encode(v) {
  return Js_dict.fromArray([[
                "parent",
                Decco.stringToJson(v.parent)
              ]]);
}

function model_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var parent = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "parent"), null));
  if (parent.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              parent: parent._0
            }
          };
  }
  var e = parent._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".parent" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function modelsVersions_encode(v) {
  return Decco.listToJson(model_encode, v);
}

function modelsVersions_decode(v) {
  return Decco.listFromJson(model_decode, v);
}

export {
  Fields ,
  IncludedModelVersions ,
  ExcludedModelVersions ,
  SavingExcludedModelVersions ,
  VersionEdit ,
  model_encode ,
  model_decode ,
  modelsVersions_encode ,
  modelsVersions_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
