// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../common/components/App_Checkbox.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as Flash_Detail_Models_Form_NonFleet$DvmAdminFrontendFr from "./Flash_Detail_Models_Form_NonFleet.bs.js";

function Flash_Detail_Models_Filter_NonFleet(Props) {
  var tableHandler = Props.tableHandler;
  var filterPagingAndSort = Props.filterPagingAndSort;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var result = Props.result;
  var intl = ReactIntl.useIntl();
  var form = Flash_Detail_Models_Form_NonFleet$DvmAdminFrontendFr.TableFilterForm.useForm(Flash_Detail_Models_Form_NonFleet$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          Curry._1(filterPagingAndSort, {
                page: 0,
                headers: tableHandler.headers,
                model: param.model,
                version: param.version,
                versionLabel: tableHandler.versionLabel
              });
        }));
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setOptions = match[1];
  React.useEffect((function () {
          if (typeof result !== "number" && result.TAG === /* Ok */0) {
            var modelVersions = result._0;
            var models = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(modelVersions, (function (x) {
                                return x.model;
                              })))));
            var newOptions = Belt_List.map(models, (function (model) {
                    return {
                            parent: model,
                            child: Belt_List.map(Belt_List.keep(modelVersions, (function (modelVersion) {
                                        return model === modelVersion.model;
                                      })), (function (x) {
                                    return x.version;
                                  }))
                          };
                  }));
            Curry._1(setOptions, (function (param) {
                    return newOptions;
                  }));
          }
          
        }), [result]);
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), [
        form.input.model,
        form.input.version,
        withClosedModels
      ]);
  return React.createElement("form", undefined, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        item: true,
                        style: {
                          width: "fit-content"
                        }
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                  labelParent: Messages_Common$DvmAdminFrontendFr.model,
                                  labelChild: Messages_Common$DvmAdminFrontendFr.version,
                                  valueParent: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.model,
                                      onChange: Curry._1(form.updateModel, (function (param, model) {
                                              return {
                                                      model: model,
                                                      version: ""
                                                    };
                                            }))
                                    }
                                  },
                                  valueChild: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.version,
                                      onChange: Curry._1(form.updateVersion, (function (input, version) {
                                              return {
                                                      model: input.model,
                                                      version: version
                                                    };
                                            }))
                                    }
                                  },
                                  options: match[0],
                                  classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                                  classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Core.FormControlLabel, {
                                  classes: {
                                    label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                  },
                                  control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                        checked: withClosedModels,
                                        onClick: (function ($$event) {
                                            Curry._1(setWithClosedModels, (function (param) {
                                                    return $$event.target.checked;
                                                  }));
                                          })
                                      }),
                                  label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                }),
                            item: true,
                            style: {
                              whiteSpace: "nowrap",
                              width: "1px"
                            }
                          })),
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }));
}

var InputStyles;

var Form;

var Api;

var make = Flash_Detail_Models_Filter_NonFleet;

export {
  InputStyles ,
  Form ,
  Api ,
  make ,
}
/* react Not a pure module */
