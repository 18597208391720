// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_ModelVersion$DvmAdminFrontendFr from "../../../intl/messages/modelVersion/Messages_ModelVersion.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as Quota_PARC_Search_Form$DvmAdminFrontendFr from "./Quota_PARC_Search_Form.bs.js";
import * as Quota_PARC_Search_Table$DvmAdminFrontendFr from "./Quota_PARC_Search_Table.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Quota_PARC_Search_Filters(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var pageType = Props.pageType;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAllModels = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAllEngines = match$5[1];
  var allEngines = match$5[0];
  var form = Quota_PARC_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match$2[0].quotaPARCFilter, Quota_PARC_Search_Form$DvmAdminFrontendFr.initialState), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: fetchRequest.page,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: Quota_PARC_Search_Table$DvmAdminFrontendFr.headersConfiguration(pageType),
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.dr,
                              tl: {
                                hd: param.dealerId,
                                tl: {
                                  hd: param.modelId,
                                  tl: {
                                    hd: param.year,
                                    tl: {
                                      hd: param.orderType,
                                      tl: {
                                        hd: param.phase,
                                        tl: {
                                          hd: param.engineType,
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, false, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setAllModels, userManager, selectedRole, undefined, undefined, undefined);
          App_Api$DvmAdminFrontendFr.fetchMotorisations(setAllEngines, userManager, selectedRole);
          Curry._1(form.submit, undefined);
        }), []);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.year,
    value: form.input.year,
    onChange: Curry._1(form.updateYear, (function (input, year) {
            return {
                    orderType: input.orderType,
                    dr: input.dr,
                    dealerId: input.dealerId,
                    modelId: input.modelId,
                    phase: input.phase,
                    engineType: input.engineType,
                    year: year
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    variant: /* YEAR */0,
    required: /* OnlyLabel */1
  };
  if (form.yearResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.yearResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreQuotaPARCFilter */27,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.orderType,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.orderType,
                                onChange: Curry._1(form.updateOrderType, (function (input, orderType) {
                                        return {
                                                orderType: orderType,
                                                dr: input.dr,
                                                dealerId: input.dealerId,
                                                modelId: input.modelId,
                                                phase: input.phase,
                                                engineType: input.engineType,
                                                year: input.year
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: {
                                hd: "VE",
                                tl: {
                                  hd: "RR",
                                  tl: {
                                    hd: "RC",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                            labelParent: Messages_Common$DvmAdminFrontendFr.territory,
                            labelChild: Messages_Common$DvmAdminFrontendFr.dealerID,
                            valueParent: {
                              TAG: /* Multiple */1,
                              _0: {
                                value: form.input.dr,
                                onChange: Curry._1(form.updateDr, (function (input, dr) {
                                        return {
                                                orderType: input.orderType,
                                                dr: dr,
                                                dealerId: input.dealerId,
                                                modelId: input.modelId,
                                                phase: input.phase,
                                                engineType: input.engineType,
                                                year: input.year
                                              };
                                      }))
                              }
                            },
                            valueChild: {
                              TAG: /* Multiple */1,
                              _0: {
                                value: form.input.dealerId,
                                onChange: Curry._1(form.updateDealerId, (function (input, dealerId) {
                                        return {
                                                orderType: input.orderType,
                                                dr: input.dr,
                                                dealerId: dealerId,
                                                modelId: input.modelId,
                                                phase: input.phase,
                                                engineType: input.engineType,
                                                year: input.year
                                              };
                                      }))
                              }
                            },
                            options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$3[0], /* [] */0),
                            classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                            classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                            limitTagsParent: 2,
                            limitTagsChild: 2,
                            selectParentIfOnlyOne: true,
                            selectChildIfOnlyOne: true
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.model,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.modelId,
                                onChange: Curry._1(form.updateModelId, (function (input, modelId) {
                                        return {
                                                orderType: input.orderType,
                                                dr: input.dr,
                                                dealerId: input.dealerId,
                                                modelId: modelId,
                                                phase: input.phase,
                                                engineType: input.engineType,
                                                year: input.year
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$4[0], /* [] */0), (function (x) {
                                      return x.parent;
                                    }))
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.phase,
                            options: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Common$DvmAdminFrontendFr.everything
                                },
                                value: ""
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* String */3,
                                    _0: "1"
                                  },
                                  value: "1"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* String */3,
                                      _0: "2"
                                    },
                                    value: "2"
                                  },
                                  tl: /* [] */0
                                }
                              }
                            },
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.phase,
                                onChange: Curry._1(form.updatePhase, (function (input, phase) {
                                        return {
                                                orderType: input.orderType,
                                                dr: input.dr,
                                                dealerId: input.dealerId,
                                                modelId: input.modelId,
                                                phase: phase,
                                                engineType: input.engineType,
                                                year: input.year
                                              };
                                      }))
                              }
                            },
                            id: "phase",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(allEngines),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                  label: Messages_ModelVersion$DvmAdminFrontendFr.motorization,
                                  value: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.engineType,
                                      onChange: Curry._1(form.updateEngineType, (function (input, engineType) {
                                              return {
                                                      orderType: input.orderType,
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      modelId: input.modelId,
                                                      phase: input.phase,
                                                      engineType: engineType,
                                                      year: input.year
                                                    };
                                            }))
                                    }
                                  },
                                  options: {
                                    TAG: /* Unlabeled */0,
                                    _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(allEngines, /* [] */0, (function (x) {
                                            return Belt_List.sort(Belt_List.keep(x, (function (y) {
                                                              return y.length > 0;
                                                            })), Caml.string_compare);
                                          }))
                                  },
                                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.ok),
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var Table;

var make = Quota_PARC_Search_Filters;

export {
  Form ,
  Api ,
  Table ,
  make ,
}
/* react Not a pure module */
