// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Messages_Contr_Man_Search$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contr_Man_Search.bs.js";
import * as Contract_Manager_Search_Api$DvmAdminFrontendFr from "./Contract_Manager_Search_Api.bs.js";
import * as Contract_Manager_Search_Table$DvmAdminFrontendFr from "./Contract_Manager_Search_Table.bs.js";
import * as Contract_Manager_Search_Filter$DvmAdminFrontendFr from "./Contract_Manager_Search_Filter.bs.js";

function Contract_Manager_Search_Page(Props) {
  var operation = Props.operation;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var showHistory = match$1[0];
  var match$2 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Contract_Manager_Search_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$2[1];
  var fetchRequest = match$2[0];
  var alert = ReactAlert.useAlert();
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Contract_Manager_Search_Api$DvmAdminFrontendFr.fetchManagersSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), showHistory, selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var tmp;
  var exit = 0;
  if (typeof tablePage === "number") {
    exit = 1;
  } else if (tablePage.TAG === /* Ok */0) {
    tmp = React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
          number: tablePage._0.total,
          message: Messages_Contr_Man_Search$DvmAdminFrontendFr.resultManagersLabel
        });
  } else {
    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
              message: {
                TAG: /* RestError */2,
                _0: tablePage._0
              }
            }));
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Contr_Man_Search$DvmAdminFrontendFr.title),
                  variant: "h3"
                }), React.createElement(Contract_Manager_Search_Filter$DvmAdminFrontendFr.make, {
                  fetchData: fetchData,
                  fetchRequest: fetchRequest,
                  setShowHistory: match$1[1]
                }), React.createElement(Core.Box, {
                  children: null
                }, tmp, React.createElement(Contract_Manager_Search_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      showHistory: showHistory,
                      operation: operation
                    })));
}

var Api;

var Table;

var Filter;

var make = Contract_Manager_Search_Page;

export {
  Api ,
  Table ,
  Filter ,
  make ,
}
/* react Not a pure module */
