// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Table$DvmAdminFrontendFr from "../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as Order_Detail_Router$DvmAdminFrontendFr from "../order/detail/Order_Detail_Router.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Messages_Bonuses_List$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_List.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../intl/messages/orders/Messages_Orders_Search.bs.js";
import * as Messages_Bonuses_Anomalies$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_Anomalies.bs.js";
import * as Bonus_Anomalies_Search_Types$DvmAdminFrontendFr from "./Bonus_Anomalies_Search_Types.bs.js";

function getEnglish(param) {
  return param[0];
}

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderDr), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.territory);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderDealerId), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.dealerID),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderCustomerOrderNumber, undefined, undefined, undefined, undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.customerOrderNum),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderBudgetType), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.actionType), undefined, undefined, undefined, undefined, undefined, Messages_Bonuses_List$DvmAdminFrontendFr.flashType),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.resultErrorDescriptionLocal), undefined, undefined, undefined, undefined, undefined, Messages_Bonuses_Anomalies$DvmAdminFrontendFr.errorDescription),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderSIRET, undefined, undefined, undefined, undefined, undefined, Messages_Bonuses_List$DvmAdminFrontendFr.clientSiret),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.siretName, undefined, undefined, undefined, undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.customerName),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderModelId), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderVersionId), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderVin, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.vin),
                    tl: {
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.resultDelivered), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.state),
                      tl: {
                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderOrderDate, /* Desc */1, undefined, undefined, undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.orderDate),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function Bonus_Anomalies_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var paramsList = Props.paramsList;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow((function (param) {
                                                Order_Detail_Router$DvmAdminFrontendFr.routeToPage(Belt_Option.getWithDefault(row.orderDealerId, "") + Belt_Option.getWithDefault(row.orderCustomerOrderNumber, ""), undefined);
                                              }), undefined, undefined, Belt_List.mapWithIndex({
                                                  hd: Belt_Option.getWithDefault(row.orderDr, ""),
                                                  tl: {
                                                    hd: Belt_Option.getWithDefault(row.orderDealerId, ""),
                                                    tl: {
                                                      hd: Belt_Option.getWithDefault(row.orderCustomerOrderNumber, ""),
                                                      tl: {
                                                        hd: Belt_Option.getWithDefault(row.orderBudgetType, ""),
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(row.actionType, ""),
                                                          tl: {
                                                            hd: Belt_Option.getWithDefault(row.resultErrorDescriptionLocal, ""),
                                                            tl: {
                                                              hd: Belt_Option.getWithDefault(row.orderSIRET, ""),
                                                              tl: {
                                                                hd: Belt_Option.getWithDefault(row.siretName, ""),
                                                                tl: {
                                                                  hd: Belt_Option.getWithDefault(row.orderModelId, ""),
                                                                  tl: {
                                                                    hd: Belt_Option.getWithDefault(row.orderVersionId, ""),
                                                                    tl: {
                                                                      hd: Belt_Option.getWithDefault(row.orderVin, ""),
                                                                      tl: {
                                                                        hd: Belt_Option.mapWithDefault(row.resultDelivered, "", (function (x) {
                                                                                switch (x) {
                                                                                  case "FALSE" :
                                                                                      return intl.formatMessage(Messages_Bonuses_Anomalies$DvmAdminFrontendFr.anomalieNonDelivered);
                                                                                  case "TRUE" :
                                                                                      return intl.formatMessage(Messages_Bonuses_Anomalies$DvmAdminFrontendFr.anomalieDelivered);
                                                                                  default:
                                                                                    return "";
                                                                                }
                                                                              })),
                                                                        tl: {
                                                                          hd: Belt_Option.mapWithDefault(row.orderOrderDate, "", (function (param) {
                                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                })),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }, (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp;
  tmp = typeof tablePage === "number" || tablePage.TAG !== /* Ok */0 ? true : tablePage._0.total > 30000;
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
                  exportMenu: React.createElement(Core.Tooltip, {
                        arrow: true,
                        children: React.createElement("div", undefined, React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                                  query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                                        page: 0,
                                        rowsPerPage: 30000,
                                        headers: fetchRequest.headers,
                                        filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(paramsList, (function (queryParam) {
                                                        return Belt_Option.map(queryParam, (function (x) {
                                                                      return x.fr;
                                                                    }));
                                                      }))), ";", (function (queryParam) {
                                                return queryParam;
                                              })),
                                        additionalQueryParams: fetchRequest.additionalQueryParams
                                      }),
                                  endpoint: "anomalies",
                                  disabled: tmp
                                })),
                        placement: "top",
                        title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.maxLinesExport, {
                              count: "30 000"
                            })
                      })
                }));
}

var Api;

var make = Bonus_Anomalies_Search_Table;

export {
  Api ,
  getEnglish ,
  headersConfiguration ,
  make ,
}
/* headersConfiguration Not a pure module */
