// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";

var validators_carSaleAvailibilityDateMADC = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.carSaleAvailibilityDateMADC, undefined);
    })
};

var validators_carSaleAvailibilityDateMADA = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.carSaleAvailibilityDateMADA, undefined);
    })
};

var validators_renaultInvoiceDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.renaultInvoiceDate, undefined);
    })
};

var validators_orderDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.orderDate, undefined);
    })
};

var validators_parcEndDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      if (param.orderType !== 2) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateDate(param.parcEndDate, undefined);
      }
    })
};

var validators_customerFirstName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.customerFirstName
            };
    })
};

var validators_shortCustomerName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.shortCustomerName
            };
    })
};

var validators_easyPack = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.easyPack
            };
    })
};

var validators_customerDiscount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if ((param.orderType - 1 >>> 0) > 5) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateFloatPositive(param.customerDiscount);
      }
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.orderType
            };
    })
};

var validators_comment = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var val = Utils_Form$DvmAdminFrontendFr.validateMaxCharacters(param.comment, 80);
      if (val.TAG === /* Ok */0) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(val._0);
      } else {
        return {
                TAG: /* Error */1,
                _0: val._0
              };
      }
    })
};

var validators_invoiceNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.invoiceNumber
            };
    })
};

var validators_invoiceClient = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.invoiceClient, undefined);
    })
};

var validators_registrationNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.registrationNumber
            };
    })
};

var validators_registrationDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationDate, undefined);
    })
};

var validators_budget = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.budget
            };
    })
};

var validators_acquisitionType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.acquisitionType
            };
    })
};

var validators_discount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if (param.orderType !== 0) {
        return {
                TAG: /* Ok */0,
                _0: 0.0
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateFloatPositiveRequired(param.discount);
      }
    })
};

var validators_finalCustomer = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.finalCustomer
            };
    })
};

var validators_siret = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.siret
            };
    })
};

var validators = {
  carSaleAvailibilityDateMADC: validators_carSaleAvailibilityDateMADC,
  carSaleAvailibilityDateMADA: validators_carSaleAvailibilityDateMADA,
  renaultInvoiceDate: validators_renaultInvoiceDate,
  orderDate: validators_orderDate,
  parcEndDate: validators_parcEndDate,
  customerFirstName: validators_customerFirstName,
  shortCustomerName: validators_shortCustomerName,
  easyPack: validators_easyPack,
  customerDiscount: validators_customerDiscount,
  orderType: validators_orderType,
  comment: validators_comment,
  invoiceNumber: validators_invoiceNumber,
  invoiceClient: validators_invoiceClient,
  registrationNumber: validators_registrationNumber,
  registrationDate: validators_registrationDate,
  budget: validators_budget,
  acquisitionType: validators_acquisitionType,
  discount: validators_discount,
  finalCustomer: validators_finalCustomer,
  siret: validators_siret
};

function initialFieldsStatuses(_input) {
  return {
          carSaleAvailibilityDateMADC: /* Pristine */0,
          carSaleAvailibilityDateMADA: /* Pristine */0,
          renaultInvoiceDate: /* Pristine */0,
          orderDate: /* Pristine */0,
          parcEndDate: /* Pristine */0,
          customerFirstName: /* Pristine */0,
          shortCustomerName: /* Pristine */0,
          easyPack: /* Pristine */0,
          customerDiscount: /* Pristine */0,
          orderType: /* Pristine */0,
          comment: /* Pristine */0,
          invoiceNumber: /* Pristine */0,
          invoiceClient: /* Pristine */0,
          registrationNumber: /* Pristine */0,
          registrationDate: /* Pristine */0,
          budget: /* Pristine */0,
          acquisitionType: /* Pristine */0,
          discount: /* Pristine */0,
          finalCustomer: /* Pristine */0,
          siret: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.carSaleAvailibilityDateMADC;
  var match_0 = match ? match._0 : Curry._1(validators.carSaleAvailibilityDateMADC.validate, input);
  var match$1 = fieldsStatuses.carSaleAvailibilityDateMADA;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.carSaleAvailibilityDateMADA.validate, input);
  var match$2 = fieldsStatuses.renaultInvoiceDate;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.renaultInvoiceDate.validate, input);
  var match$3 = fieldsStatuses.orderDate;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.orderDate.validate, input);
  var match$4 = fieldsStatuses.parcEndDate;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.parcEndDate.validate, input);
  var match$5 = fieldsStatuses.customerFirstName;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.customerFirstName.validate, input);
  var match$6 = fieldsStatuses.shortCustomerName;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.shortCustomerName.validate, input);
  var match$7 = fieldsStatuses.easyPack;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.easyPack.validate, input);
  var match$8 = fieldsStatuses.customerDiscount;
  var match_0$8 = match$8 ? match$8._0 : Curry._1(validators.customerDiscount.validate, input);
  var match$9 = fieldsStatuses.orderType;
  var match_0$9 = match$9 ? match$9._0 : Curry._1(validators.orderType.validate, input);
  var match$10 = fieldsStatuses.comment;
  var match_0$10 = match$10 ? match$10._0 : Curry._1(validators.comment.validate, input);
  var match$11 = fieldsStatuses.invoiceNumber;
  var match_0$11 = match$11 ? match$11._0 : Curry._1(validators.invoiceNumber.validate, input);
  var match$12 = fieldsStatuses.invoiceClient;
  var match_0$12 = match$12 ? match$12._0 : Curry._1(validators.invoiceClient.validate, input);
  var match$13 = fieldsStatuses.registrationNumber;
  var match_0$13 = match$13 ? match$13._0 : Curry._1(validators.registrationNumber.validate, input);
  var match$14 = fieldsStatuses.registrationDate;
  var match_0$14 = match$14 ? match$14._0 : Curry._1(validators.registrationDate.validate, input);
  var match$15 = fieldsStatuses.budget;
  var match_0$15 = match$15 ? match$15._0 : Curry._1(validators.budget.validate, input);
  var match$16 = fieldsStatuses.acquisitionType;
  var match_0$16 = match$16 ? match$16._0 : Curry._1(validators.acquisitionType.validate, input);
  var match$17 = fieldsStatuses.discount;
  var match_0$17 = match$17 ? match$17._0 : Curry._1(validators.discount.validate, input);
  var match$18 = fieldsStatuses.finalCustomer;
  var match_0$18 = match$18 ? match$18._0 : Curry._1(validators.finalCustomer.validate, input);
  var match$19 = fieldsStatuses.siret;
  var match_0$19 = match$19 ? match$19._0 : Curry._1(validators.siret.validate, input);
  var carSaleAvailibilityDateMADCResult = match_0;
  var carSaleAvailibilityDateMADCResult$1;
  if (carSaleAvailibilityDateMADCResult.TAG === /* Ok */0) {
    var carSaleAvailibilityDateMADAResult = match_0$1;
    if (carSaleAvailibilityDateMADAResult.TAG === /* Ok */0) {
      var renaultInvoiceDateResult = match_0$2;
      if (renaultInvoiceDateResult.TAG === /* Ok */0) {
        var orderDateResult = match_0$3;
        if (orderDateResult.TAG === /* Ok */0) {
          var parcEndDateResult = match_0$4;
          if (parcEndDateResult.TAG === /* Ok */0) {
            var customerFirstNameResult = match_0$5;
            if (customerFirstNameResult.TAG === /* Ok */0) {
              var shortCustomerNameResult = match_0$6;
              if (shortCustomerNameResult.TAG === /* Ok */0) {
                var easyPackResult = match_0$7;
                if (easyPackResult.TAG === /* Ok */0) {
                  var customerDiscountResult = match_0$8;
                  if (customerDiscountResult.TAG === /* Ok */0) {
                    var orderTypeResult = match_0$9;
                    if (orderTypeResult.TAG === /* Ok */0) {
                      var commentResult = match_0$10;
                      if (commentResult.TAG === /* Ok */0) {
                        var invoiceNumberResult = match_0$11;
                        if (invoiceNumberResult.TAG === /* Ok */0) {
                          var invoiceClientResult = match_0$12;
                          if (invoiceClientResult.TAG === /* Ok */0) {
                            var registrationNumberResult = match_0$13;
                            if (registrationNumberResult.TAG === /* Ok */0) {
                              var registrationDateResult = match_0$14;
                              if (registrationDateResult.TAG === /* Ok */0) {
                                var budgetResult = match_0$15;
                                if (budgetResult.TAG === /* Ok */0) {
                                  var acquisitionTypeResult = match_0$16;
                                  if (acquisitionTypeResult.TAG === /* Ok */0) {
                                    var discountResult = match_0$17;
                                    if (discountResult.TAG === /* Ok */0) {
                                      var finalCustomerResult = match_0$18;
                                      if (finalCustomerResult.TAG === /* Ok */0) {
                                        var siretResult = match_0$19;
                                        if (siretResult.TAG === /* Ok */0) {
                                          return {
                                                  TAG: /* Valid */0,
                                                  output: {
                                                    siret: siretResult._0,
                                                    finalCustomer: finalCustomerResult._0,
                                                    discount: discountResult._0,
                                                    acquisitionType: acquisitionTypeResult._0,
                                                    budget: budgetResult._0,
                                                    registrationDate: registrationDateResult._0,
                                                    registrationNumber: registrationNumberResult._0,
                                                    invoiceClient: invoiceClientResult._0,
                                                    invoiceNumber: invoiceNumberResult._0,
                                                    comment: commentResult._0,
                                                    orderType: orderTypeResult._0,
                                                    customerDiscount: customerDiscountResult._0,
                                                    easyPack: easyPackResult._0,
                                                    shortCustomerName: shortCustomerNameResult._0,
                                                    customerFirstName: customerFirstNameResult._0,
                                                    parcEndDate: parcEndDateResult._0,
                                                    orderDate: orderDateResult._0,
                                                    renaultInvoiceDate: renaultInvoiceDateResult._0,
                                                    carSaleAvailibilityDateMADA: carSaleAvailibilityDateMADAResult._0,
                                                    carSaleAvailibilityDateMADC: carSaleAvailibilityDateMADCResult._0
                                                  },
                                                  fieldsStatuses: {
                                                    carSaleAvailibilityDateMADC: /* Dirty */{
                                                      _0: carSaleAvailibilityDateMADCResult,
                                                      _1: /* Shown */0
                                                    },
                                                    carSaleAvailibilityDateMADA: /* Dirty */{
                                                      _0: carSaleAvailibilityDateMADAResult,
                                                      _1: /* Shown */0
                                                    },
                                                    renaultInvoiceDate: /* Dirty */{
                                                      _0: renaultInvoiceDateResult,
                                                      _1: /* Shown */0
                                                    },
                                                    orderDate: /* Dirty */{
                                                      _0: orderDateResult,
                                                      _1: /* Shown */0
                                                    },
                                                    parcEndDate: /* Dirty */{
                                                      _0: parcEndDateResult,
                                                      _1: /* Shown */0
                                                    },
                                                    customerFirstName: /* Dirty */{
                                                      _0: customerFirstNameResult,
                                                      _1: /* Shown */0
                                                    },
                                                    shortCustomerName: /* Dirty */{
                                                      _0: shortCustomerNameResult,
                                                      _1: /* Shown */0
                                                    },
                                                    easyPack: /* Dirty */{
                                                      _0: easyPackResult,
                                                      _1: /* Shown */0
                                                    },
                                                    customerDiscount: /* Dirty */{
                                                      _0: customerDiscountResult,
                                                      _1: /* Shown */0
                                                    },
                                                    orderType: /* Dirty */{
                                                      _0: orderTypeResult,
                                                      _1: /* Shown */0
                                                    },
                                                    comment: /* Dirty */{
                                                      _0: commentResult,
                                                      _1: /* Shown */0
                                                    },
                                                    invoiceNumber: /* Dirty */{
                                                      _0: invoiceNumberResult,
                                                      _1: /* Shown */0
                                                    },
                                                    invoiceClient: /* Dirty */{
                                                      _0: invoiceClientResult,
                                                      _1: /* Shown */0
                                                    },
                                                    registrationNumber: /* Dirty */{
                                                      _0: registrationNumberResult,
                                                      _1: /* Shown */0
                                                    },
                                                    registrationDate: /* Dirty */{
                                                      _0: registrationDateResult,
                                                      _1: /* Shown */0
                                                    },
                                                    budget: /* Dirty */{
                                                      _0: budgetResult,
                                                      _1: /* Shown */0
                                                    },
                                                    acquisitionType: /* Dirty */{
                                                      _0: acquisitionTypeResult,
                                                      _1: /* Shown */0
                                                    },
                                                    discount: /* Dirty */{
                                                      _0: discountResult,
                                                      _1: /* Shown */0
                                                    },
                                                    finalCustomer: /* Dirty */{
                                                      _0: finalCustomerResult,
                                                      _1: /* Shown */0
                                                    },
                                                    siret: /* Dirty */{
                                                      _0: siretResult,
                                                      _1: /* Shown */0
                                                    }
                                                  },
                                                  collectionsStatuses: undefined
                                                };
                                        }
                                        carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                                      } else {
                                        carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                                      }
                                    } else {
                                      carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                                    }
                                  } else {
                                    carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                                  }
                                } else {
                                  carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                                }
                              } else {
                                carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                              }
                            } else {
                              carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                            }
                          } else {
                            carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                          }
                        } else {
                          carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                        }
                      } else {
                        carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                      }
                    } else {
                      carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                    }
                  } else {
                    carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                  }
                } else {
                  carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
                }
              } else {
                carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
              }
            } else {
              carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
            }
          } else {
            carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
          }
        } else {
          carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
        }
      } else {
        carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
      }
    } else {
      carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
    }
  } else {
    carSaleAvailibilityDateMADCResult$1 = carSaleAvailibilityDateMADCResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            carSaleAvailibilityDateMADC: /* Dirty */{
              _0: carSaleAvailibilityDateMADCResult$1,
              _1: /* Shown */0
            },
            carSaleAvailibilityDateMADA: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            renaultInvoiceDate: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            orderDate: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            parcEndDate: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            customerFirstName: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            shortCustomerName: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            easyPack: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            customerDiscount: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            comment: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            invoiceNumber: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            invoiceClient: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            registrationNumber: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            registrationDate: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            budget: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            },
            acquisitionType: /* Dirty */{
              _0: match_0$16,
              _1: /* Shown */0
            },
            discount: /* Dirty */{
              _0: match_0$17,
              _1: /* Shown */0
            },
            finalCustomer: /* Dirty */{
              _0: match_0$18,
              _1: /* Shown */0
            },
            siret: /* Dirty */{
              _0: match_0$19,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurCarSaleAvailibilityDateMADCField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.carSaleAvailibilityDateMADC, validators_carSaleAvailibilityDateMADC, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.carSaleAvailibilityDateMADC = status;
                          return newrecord;
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCarSaleAvailibilityDateMADAField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.carSaleAvailibilityDateMADA, validators_carSaleAvailibilityDateMADA, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.carSaleAvailibilityDateMADA = status;
                          return newrecord;
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRenaultInvoiceDateField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.renaultInvoiceDate, validators_renaultInvoiceDate, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.renaultInvoiceDate = status;
                          return newrecord;
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderDateField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderDate, validators_orderDate, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.orderDate = status;
                          return newrecord;
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurParcEndDateField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.parcEndDate, validators_parcEndDate, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.parcEndDate = status;
                          return newrecord;
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerFirstNameField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.customerFirstName, validators_customerFirstName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.customerFirstName = status;
                          return newrecord;
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShortCustomerNameField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.shortCustomerName, validators_shortCustomerName, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.shortCustomerName = status;
                          return newrecord;
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEasyPackField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.easyPack, validators_easyPack, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.easyPack = status;
                          return newrecord;
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerDiscountField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.customerDiscount, validators_customerDiscount, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.customerDiscount = status;
                          return newrecord;
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.orderType = status;
                          return newrecord;
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCommentField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.comment, validators_comment, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.comment = status;
                          return newrecord;
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurInvoiceNumberField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.invoiceNumber, validators_invoiceNumber, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.invoiceNumber = status;
                          return newrecord;
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurInvoiceClientField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.invoiceClient, validators_invoiceClient, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.invoiceClient = status;
                          return newrecord;
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationNumberField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.registrationNumber, validators_registrationNumber, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.registrationNumber = status;
                          return newrecord;
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.registrationDate, validators_registrationDate, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.registrationDate = status;
                          return newrecord;
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budget, validators_budget, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.budget = status;
                          return newrecord;
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAcquisitionTypeField */16 :
                  var result$16 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.acquisitionType, validators_acquisitionType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.acquisitionType = status;
                          return newrecord;
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDiscountField */17 :
                  var result$17 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.discount, validators_discount, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.discount = status;
                          return newrecord;
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurFinalCustomerField */18 :
                  var result$18 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.finalCustomer, validators_finalCustomer, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.finalCustomer = status;
                          return newrecord;
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSiretField */19 :
                  var result$19 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siret, validators_siret, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.siret = status;
                          return newrecord;
                        }));
                  if (result$19 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$19,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */20 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */20,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */21,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */23);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */22);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */21 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */22 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */23 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateCarSaleAvailibilityDateMADCField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.carSaleAvailibilityDateMADC, state.submissionStatus, validators_carSaleAvailibilityDateMADC, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.carSaleAvailibilityDateMADC = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCarSaleAvailibilityDateMADAField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.carSaleAvailibilityDateMADA, state.submissionStatus, validators_carSaleAvailibilityDateMADA, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.carSaleAvailibilityDateMADA = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRenaultInvoiceDateField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.renaultInvoiceDate, state.submissionStatus, validators_renaultInvoiceDate, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.renaultInvoiceDate = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderDateField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.orderDate, state.submissionStatus, validators_orderDate, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.orderDate = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateParcEndDateField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.parcEndDate, state.submissionStatus, validators_parcEndDate, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.parcEndDate = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerFirstNameField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.customerFirstName, state.submissionStatus, validators_customerFirstName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.customerFirstName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShortCustomerNameField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.shortCustomerName, state.submissionStatus, validators_shortCustomerName, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.shortCustomerName = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEasyPackField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.easyPack, state.submissionStatus, validators_easyPack, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.easyPack = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerDiscountField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.customerDiscount, state.submissionStatus, validators_customerDiscount, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.customerDiscount = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$20 = Formality.validateDependentFieldOnChange(nextInput$9, nextFieldsStatuses.contents.discount, validators_discount, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.discount = status;
                          return newrecord;
                        }));
                  if (result$20 !== undefined) {
                    nextFieldsStatuses.contents = result$20;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, nextFieldsStatuses.contents.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                                    newrecord.orderType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCommentField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, state.fieldsStatuses.comment, state.submissionStatus, validators_comment, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.comment = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateInvoiceNumberField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$11, state.fieldsStatuses.invoiceNumber, state.submissionStatus, validators_invoiceNumber, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.invoiceNumber = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateInvoiceClientField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$12, state.fieldsStatuses.invoiceClient, state.submissionStatus, validators_invoiceClient, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.invoiceClient = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationNumberField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$13, state.fieldsStatuses.registrationNumber, state.submissionStatus, validators_registrationNumber, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.registrationNumber = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$14, state.fieldsStatuses.registrationDate, state.submissionStatus, validators_registrationDate, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.registrationDate = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$15, state.fieldsStatuses.budget, state.submissionStatus, validators_budget, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.budget = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAcquisitionTypeField */16 :
                  var nextInput$16 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$16, state.fieldsStatuses.acquisitionType, state.submissionStatus, validators_acquisitionType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.acquisitionType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDiscountField */17 :
                  var nextInput$17 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$21 = Formality.validateDependentFieldOnChange(nextInput$17, nextFieldsStatuses$1.contents.orderType, validators_orderType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$1.contents);
                          newrecord.orderType = status;
                          return newrecord;
                        }));
                  if (result$21 !== undefined) {
                    nextFieldsStatuses$1.contents = result$21;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$17, nextFieldsStatuses$1.contents.discount, state.submissionStatus, validators_discount, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$1.contents);
                                    newrecord.discount = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateFinalCustomerField */18 :
                  var nextInput$18 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$18, state.fieldsStatuses.finalCustomer, state.submissionStatus, validators_finalCustomer, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.finalCustomer = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSiretField */19 :
                  var nextInput$19 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$19, state.fieldsStatuses.siret, state.submissionStatus, validators_siret, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.siret = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */20 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */21 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */22 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateCarSaleAvailibilityDateMADC: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCarSaleAvailibilityDateMADCField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCarSaleAvailibilityDateMADA: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCarSaleAvailibilityDateMADAField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRenaultInvoiceDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRenaultInvoiceDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderDateField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateParcEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateParcEndDateField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerFirstName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerFirstNameField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateShortCustomerName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShortCustomerNameField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEasyPack: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEasyPackField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerDiscount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerDiscountField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateComment: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCommentField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateInvoiceNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateInvoiceNumberField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateInvoiceClient: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateInvoiceClientField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationNumberField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudget: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAcquisitionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAcquisitionTypeField */16,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDiscount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDiscountField */17,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateFinalCustomer: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateFinalCustomerField */18,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiret: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretField */19,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurCarSaleAvailibilityDateMADC: (function (param) {
              Curry._1(dispatch, /* BlurCarSaleAvailibilityDateMADCField */0);
            }),
          blurCarSaleAvailibilityDateMADA: (function (param) {
              Curry._1(dispatch, /* BlurCarSaleAvailibilityDateMADAField */1);
            }),
          blurRenaultInvoiceDate: (function (param) {
              Curry._1(dispatch, /* BlurRenaultInvoiceDateField */2);
            }),
          blurOrderDate: (function (param) {
              Curry._1(dispatch, /* BlurOrderDateField */3);
            }),
          blurParcEndDate: (function (param) {
              Curry._1(dispatch, /* BlurParcEndDateField */4);
            }),
          blurCustomerFirstName: (function (param) {
              Curry._1(dispatch, /* BlurCustomerFirstNameField */5);
            }),
          blurShortCustomerName: (function (param) {
              Curry._1(dispatch, /* BlurShortCustomerNameField */6);
            }),
          blurEasyPack: (function (param) {
              Curry._1(dispatch, /* BlurEasyPackField */7);
            }),
          blurCustomerDiscount: (function (param) {
              Curry._1(dispatch, /* BlurCustomerDiscountField */8);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */9);
            }),
          blurComment: (function (param) {
              Curry._1(dispatch, /* BlurCommentField */10);
            }),
          blurInvoiceNumber: (function (param) {
              Curry._1(dispatch, /* BlurInvoiceNumberField */11);
            }),
          blurInvoiceClient: (function (param) {
              Curry._1(dispatch, /* BlurInvoiceClientField */12);
            }),
          blurRegistrationNumber: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationNumberField */13);
            }),
          blurRegistrationDate: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateField */14);
            }),
          blurBudget: (function (param) {
              Curry._1(dispatch, /* BlurBudgetField */15);
            }),
          blurAcquisitionType: (function (param) {
              Curry._1(dispatch, /* BlurAcquisitionTypeField */16);
            }),
          blurDiscount: (function (param) {
              Curry._1(dispatch, /* BlurDiscountField */17);
            }),
          blurFinalCustomer: (function (param) {
              Curry._1(dispatch, /* BlurFinalCustomerField */18);
            }),
          blurSiret: (function (param) {
              Curry._1(dispatch, /* BlurSiretField */19);
            }),
          carSaleAvailibilityDateMADCResult: Formality.exposeFieldResult(state.fieldsStatuses.carSaleAvailibilityDateMADC),
          carSaleAvailibilityDateMADAResult: Formality.exposeFieldResult(state.fieldsStatuses.carSaleAvailibilityDateMADA),
          renaultInvoiceDateResult: Formality.exposeFieldResult(state.fieldsStatuses.renaultInvoiceDate),
          orderDateResult: Formality.exposeFieldResult(state.fieldsStatuses.orderDate),
          parcEndDateResult: Formality.exposeFieldResult(state.fieldsStatuses.parcEndDate),
          customerFirstNameResult: Formality.exposeFieldResult(state.fieldsStatuses.customerFirstName),
          shortCustomerNameResult: Formality.exposeFieldResult(state.fieldsStatuses.shortCustomerName),
          easyPackResult: Formality.exposeFieldResult(state.fieldsStatuses.easyPack),
          customerDiscountResult: Formality.exposeFieldResult(state.fieldsStatuses.customerDiscount),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          commentResult: Formality.exposeFieldResult(state.fieldsStatuses.comment),
          invoiceNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.invoiceNumber),
          invoiceClientResult: Formality.exposeFieldResult(state.fieldsStatuses.invoiceClient),
          registrationNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationNumber),
          registrationDateResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDate),
          budgetResult: Formality.exposeFieldResult(state.fieldsStatuses.budget),
          acquisitionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.acquisitionType),
          discountResult: Formality.exposeFieldResult(state.fieldsStatuses.discount),
          finalCustomerResult: Formality.exposeFieldResult(state.fieldsStatuses.finalCustomer),
          siretResult: Formality.exposeFieldResult(state.fieldsStatuses.siret),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.carSaleAvailibilityDateMADC || match.carSaleAvailibilityDateMADA || match.renaultInvoiceDate || match.orderDate || match.parcEndDate || match.customerFirstName || match.shortCustomerName || match.easyPack || match.customerDiscount || match.orderType || match.comment || match.invoiceNumber || match.invoiceClient || match.registrationNumber || match.registrationDate || match.budget || match.acquisitionType || match.discount || match.finalCustomer) {
                exit = 1;
              } else {
                if (!match.siret) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          var exit$6 = 0;
                          exit$6 = 7;
                          if (exit$6 === 7) {
                            var exit$7 = 0;
                            exit$7 = 8;
                            if (exit$7 === 8) {
                              var exit$8 = 0;
                              exit$8 = 9;
                              if (exit$8 === 9) {
                                return true;
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */20);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */21);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */22);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */22,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */23);
            })
        };
}

var CorrectionForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  siret: "",
  finalCustomer: "",
  discount: "",
  acquisitionType: "",
  budget: "",
  registrationDate: undefined,
  registrationNumber: "",
  invoiceClient: undefined,
  invoiceNumber: "",
  comment: "",
  orderType: /* NOTSET */7,
  customerDiscount: "",
  easyPack: "",
  shortCustomerName: "",
  customerFirstName: "",
  parcEndDate: undefined,
  orderDate: undefined,
  renaultInvoiceDate: undefined,
  carSaleAvailibilityDateMADA: undefined,
  carSaleAvailibilityDateMADC: undefined
};

export {
  Types ,
  CorrectionForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
