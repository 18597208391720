// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Contract_Manager_Search_Types$DvmAdminFrontendFr from "./Contract_Manager_Search_Types.bs.js";

function fetchManagersSearch(submissionCallbacks, userManager, setResult, queryParams, showHistory, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Contract_Manager_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "/contract/managers/" + (
          showHistory ? "true" : "false"
        ) + "" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchManagerSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchManagers(userManager, setResult, showHistory, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/dropdown/contractmanagers/" + (
          showHistory ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var managerId = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: managerId
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  fetchManagersSearch ,
  fetchManagers ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
