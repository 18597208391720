// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../common/utils/Utils_Form.bs.js";
import * as Bonus_Anomalies_Search_Types$DvmAdminFrontendFr from "./Bonus_Anomalies_Search_Types.bs.js";

var validators_showDealerHistory = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var tmp;
      if (param.showDealerHistory) {
        tmp = undefined;
      } else {
        var match = Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.showHistory;
        tmp = {
          en: match[0] + "=oui",
          fr: match[1] + "=oui"
        };
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_errorDescription = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.resultErrorDescriptionLocal, param.errorDescription, false)
            };
    })
};

var validators_delivered = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.resultDelivered, param.delivered, false)
            };
    })
};

var validators_customerName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.dealerKeyAccountManager, param.customerName, false)
            };
    })
};

var validators_orderStatus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.resultOrderStatus, "normal", param.orderStatus)
            };
    })
};

var validators_siren = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.siren, param.siren, false)
            };
    })
};

var validators_budgetYear = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var budgetYear = param.budgetYear;
      if (Belt_Option.isSome(budgetYear)) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(budgetYear, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.dateYearToQueryParamBilingual(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.resultBudgetYear, date);
                    }));
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_version = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderVersionId, param.version, false)
            };
    })
};

var validators_model = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderModelId, param.model, false)
            };
    })
};

var validators_actionType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var actionType = Utils_Form$DvmAdminFrontendFr.validateRequired(param.actionType);
      if (actionType.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.actionType, actionType._0, false)
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: actionType._0
              };
      }
    })
};

var validators_budgetType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var budgetType = Utils_Form$DvmAdminFrontendFr.validateListRequired(param.budgetType);
      if (budgetType.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderBudgetType, "normal", budgetType._0)
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: budgetType._0
              };
      }
    })
};

var validators_dealerId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderDealerId, "normal", param.dealerId)
            };
    })
};

var validators_dr = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Bonus_Anomalies_Search_Types$DvmAdminFrontendFr.Fields.orderDr, "normal", param.dr)
            };
    })
};

var validators = {
  showDealerHistory: validators_showDealerHistory,
  errorDescription: validators_errorDescription,
  delivered: validators_delivered,
  customerName: validators_customerName,
  orderStatus: validators_orderStatus,
  siren: validators_siren,
  budgetYear: validators_budgetYear,
  version: validators_version,
  model: validators_model,
  actionType: validators_actionType,
  budgetType: validators_budgetType,
  dealerId: validators_dealerId,
  dr: validators_dr
};

function initialFieldsStatuses(_input) {
  return {
          showDealerHistory: /* Pristine */0,
          errorDescription: /* Pristine */0,
          delivered: /* Pristine */0,
          customerName: /* Pristine */0,
          orderStatus: /* Pristine */0,
          siren: /* Pristine */0,
          budgetYear: /* Pristine */0,
          version: /* Pristine */0,
          model: /* Pristine */0,
          actionType: /* Pristine */0,
          budgetType: /* Pristine */0,
          dealerId: /* Pristine */0,
          dr: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.showDealerHistory;
  var match_0 = match ? match._0 : Curry._1(validators.showDealerHistory.validate, input);
  var match$1 = fieldsStatuses.errorDescription;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.errorDescription.validate, input);
  var match$2 = fieldsStatuses.delivered;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.delivered.validate, input);
  var match$3 = fieldsStatuses.customerName;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.customerName.validate, input);
  var match$4 = fieldsStatuses.orderStatus;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.orderStatus.validate, input);
  var match$5 = fieldsStatuses.siren;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.siren.validate, input);
  var match$6 = fieldsStatuses.budgetYear;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.budgetYear.validate, input);
  var match$7 = fieldsStatuses.version;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.version.validate, input);
  var match$8 = fieldsStatuses.model;
  var match_0$8 = match$8 ? match$8._0 : Curry._1(validators.model.validate, input);
  var match$9 = fieldsStatuses.actionType;
  var match_0$9 = match$9 ? match$9._0 : Curry._1(validators.actionType.validate, input);
  var match$10 = fieldsStatuses.budgetType;
  var match_0$10 = match$10 ? match$10._0 : Curry._1(validators.budgetType.validate, input);
  var match$11 = fieldsStatuses.dealerId;
  var match_0$11 = match$11 ? match$11._0 : Curry._1(validators.dealerId.validate, input);
  var match$12 = fieldsStatuses.dr;
  var match_0$12 = match$12 ? match$12._0 : Curry._1(validators.dr.validate, input);
  var showDealerHistoryResult = match_0;
  var showDealerHistoryResult$1;
  if (showDealerHistoryResult.TAG === /* Ok */0) {
    var errorDescriptionResult = match_0$1;
    if (errorDescriptionResult.TAG === /* Ok */0) {
      var deliveredResult = match_0$2;
      if (deliveredResult.TAG === /* Ok */0) {
        var customerNameResult = match_0$3;
        if (customerNameResult.TAG === /* Ok */0) {
          var orderStatusResult = match_0$4;
          if (orderStatusResult.TAG === /* Ok */0) {
            var sirenResult = match_0$5;
            if (sirenResult.TAG === /* Ok */0) {
              var budgetYearResult = match_0$6;
              if (budgetYearResult.TAG === /* Ok */0) {
                var versionResult = match_0$7;
                if (versionResult.TAG === /* Ok */0) {
                  var modelResult = match_0$8;
                  if (modelResult.TAG === /* Ok */0) {
                    var actionTypeResult = match_0$9;
                    if (actionTypeResult.TAG === /* Ok */0) {
                      var budgetTypeResult = match_0$10;
                      if (budgetTypeResult.TAG === /* Ok */0) {
                        var dealerIdResult = match_0$11;
                        if (dealerIdResult.TAG === /* Ok */0) {
                          var drResult = match_0$12;
                          if (drResult.TAG === /* Ok */0) {
                            return {
                                    TAG: /* Valid */0,
                                    output: {
                                      dr: drResult._0,
                                      dealerId: dealerIdResult._0,
                                      budgetType: budgetTypeResult._0,
                                      actionType: actionTypeResult._0,
                                      model: modelResult._0,
                                      version: versionResult._0,
                                      budgetYear: budgetYearResult._0,
                                      siren: sirenResult._0,
                                      orderStatus: orderStatusResult._0,
                                      customerName: customerNameResult._0,
                                      delivered: deliveredResult._0,
                                      errorDescription: errorDescriptionResult._0,
                                      showDealerHistory: showDealerHistoryResult._0
                                    },
                                    fieldsStatuses: {
                                      showDealerHistory: /* Dirty */{
                                        _0: showDealerHistoryResult,
                                        _1: /* Shown */0
                                      },
                                      errorDescription: /* Dirty */{
                                        _0: errorDescriptionResult,
                                        _1: /* Shown */0
                                      },
                                      delivered: /* Dirty */{
                                        _0: deliveredResult,
                                        _1: /* Shown */0
                                      },
                                      customerName: /* Dirty */{
                                        _0: customerNameResult,
                                        _1: /* Shown */0
                                      },
                                      orderStatus: /* Dirty */{
                                        _0: orderStatusResult,
                                        _1: /* Shown */0
                                      },
                                      siren: /* Dirty */{
                                        _0: sirenResult,
                                        _1: /* Shown */0
                                      },
                                      budgetYear: /* Dirty */{
                                        _0: budgetYearResult,
                                        _1: /* Shown */0
                                      },
                                      version: /* Dirty */{
                                        _0: versionResult,
                                        _1: /* Shown */0
                                      },
                                      model: /* Dirty */{
                                        _0: modelResult,
                                        _1: /* Shown */0
                                      },
                                      actionType: /* Dirty */{
                                        _0: actionTypeResult,
                                        _1: /* Shown */0
                                      },
                                      budgetType: /* Dirty */{
                                        _0: budgetTypeResult,
                                        _1: /* Shown */0
                                      },
                                      dealerId: /* Dirty */{
                                        _0: dealerIdResult,
                                        _1: /* Shown */0
                                      },
                                      dr: /* Dirty */{
                                        _0: drResult,
                                        _1: /* Shown */0
                                      }
                                    },
                                    collectionsStatuses: undefined
                                  };
                          }
                          showDealerHistoryResult$1 = showDealerHistoryResult;
                        } else {
                          showDealerHistoryResult$1 = showDealerHistoryResult;
                        }
                      } else {
                        showDealerHistoryResult$1 = showDealerHistoryResult;
                      }
                    } else {
                      showDealerHistoryResult$1 = showDealerHistoryResult;
                    }
                  } else {
                    showDealerHistoryResult$1 = showDealerHistoryResult;
                  }
                } else {
                  showDealerHistoryResult$1 = showDealerHistoryResult;
                }
              } else {
                showDealerHistoryResult$1 = showDealerHistoryResult;
              }
            } else {
              showDealerHistoryResult$1 = showDealerHistoryResult;
            }
          } else {
            showDealerHistoryResult$1 = showDealerHistoryResult;
          }
        } else {
          showDealerHistoryResult$1 = showDealerHistoryResult;
        }
      } else {
        showDealerHistoryResult$1 = showDealerHistoryResult;
      }
    } else {
      showDealerHistoryResult$1 = showDealerHistoryResult;
    }
  } else {
    showDealerHistoryResult$1 = showDealerHistoryResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            showDealerHistory: /* Dirty */{
              _0: showDealerHistoryResult$1,
              _1: /* Shown */0
            },
            errorDescription: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            delivered: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            customerName: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            orderStatus: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            siren: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            budgetYear: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            actionType: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            budgetType: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            dealerId: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            dr: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurShowDealerHistoryField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.showDealerHistory, validators_showDealerHistory, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: status,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurErrorDescriptionField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.errorDescription, validators_errorDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: status,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveredField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.delivered, validators_delivered, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: status,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerNameField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.customerName, validators_customerName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: status,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderStatusField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderStatus, validators_orderStatus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: status,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSirenField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.siren, validators_siren, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: status,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetYearField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budgetYear, validators_budgetYear, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: status,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: status,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: status,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionTypeField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.actionType, validators_actionType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: status,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetTypeField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budgetType, validators_budgetType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: status,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dealerId, validators_dealerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDrField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dr, validators_dr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */13 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */13,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */14,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */16);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */15);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */14 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */15 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */16 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateShowDealerHistoryField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.showDealerHistory, state.submissionStatus, validators_showDealerHistory, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: status,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateErrorDescriptionField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.errorDescription, state.submissionStatus, validators_errorDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: status,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveredField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.delivered, state.submissionStatus, validators_delivered, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: status,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerNameField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.customerName, state.submissionStatus, validators_customerName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: status,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderStatusField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.orderStatus, state.submissionStatus, validators_orderStatus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: status,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSirenField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.siren, state.submissionStatus, validators_siren, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: status,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetYearField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.budgetYear, state.submissionStatus, validators_budgetYear, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: status,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: status,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: status,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionTypeField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, state.fieldsStatuses.actionType, state.submissionStatus, validators_actionType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: status,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetTypeField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, state.fieldsStatuses.budgetType, state.submissionStatus, validators_budgetType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: status,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$13 = Formality.validateDependentFieldOnChange(nextInput$11, nextFieldsStatuses.contents.dr, validators_dr, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$13 !== undefined) {
                    nextFieldsStatuses.contents = result$13;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$11, nextFieldsStatuses.contents.dealerId, state.submissionStatus, validators_dealerId, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: status,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDrField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$14 = Formality.validateDependentFieldOnChange(nextInput$12, nextFieldsStatuses$1.contents.dealerId, validators_dealerId, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  errorDescription: init.errorDescription,
                                  delivered: init.delivered,
                                  customerName: init.customerName,
                                  orderStatus: init.orderStatus,
                                  siren: init.siren,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$14 !== undefined) {
                    nextFieldsStatuses$1.contents = result$14;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$12, nextFieldsStatuses$1.contents.dr, state.submissionStatus, validators_dr, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            errorDescription: init.errorDescription,
                                            delivered: init.delivered,
                                            customerName: init.customerName,
                                            orderStatus: init.orderStatus,
                                            siren: init.siren,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            dealerId: init.dealerId,
                                            dr: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */13 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */14 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */15 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateShowDealerHistory: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShowDealerHistoryField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateErrorDescription: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateErrorDescriptionField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDelivered: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveredField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerNameField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderStatusField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiren: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSirenField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetYearField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionTypeField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetTypeField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDrField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurShowDealerHistory: (function (param) {
              Curry._1(dispatch, /* BlurShowDealerHistoryField */0);
            }),
          blurErrorDescription: (function (param) {
              Curry._1(dispatch, /* BlurErrorDescriptionField */1);
            }),
          blurDelivered: (function (param) {
              Curry._1(dispatch, /* BlurDeliveredField */2);
            }),
          blurCustomerName: (function (param) {
              Curry._1(dispatch, /* BlurCustomerNameField */3);
            }),
          blurOrderStatus: (function (param) {
              Curry._1(dispatch, /* BlurOrderStatusField */4);
            }),
          blurSiren: (function (param) {
              Curry._1(dispatch, /* BlurSirenField */5);
            }),
          blurBudgetYear: (function (param) {
              Curry._1(dispatch, /* BlurBudgetYearField */6);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */7);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */8);
            }),
          blurActionType: (function (param) {
              Curry._1(dispatch, /* BlurActionTypeField */9);
            }),
          blurBudgetType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetTypeField */10);
            }),
          blurDealerId: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdField */11);
            }),
          blurDr: (function (param) {
              Curry._1(dispatch, /* BlurDrField */12);
            }),
          showDealerHistoryResult: Formality.exposeFieldResult(state.fieldsStatuses.showDealerHistory),
          errorDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.errorDescription),
          deliveredResult: Formality.exposeFieldResult(state.fieldsStatuses.delivered),
          customerNameResult: Formality.exposeFieldResult(state.fieldsStatuses.customerName),
          orderStatusResult: Formality.exposeFieldResult(state.fieldsStatuses.orderStatus),
          sirenResult: Formality.exposeFieldResult(state.fieldsStatuses.siren),
          budgetYearResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetYear),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          actionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.actionType),
          budgetTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetType),
          dealerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerId),
          drResult: Formality.exposeFieldResult(state.fieldsStatuses.dr),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.showDealerHistory || match.errorDescription || match.delivered || match.customerName || match.orderStatus || match.siren || match.budgetYear || match.version || match.model || match.actionType || match.budgetType || match.dealerId) {
                exit = 1;
              } else {
                if (!match.dr) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  return true;
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */13);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */14);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */15);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */15,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */16);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  dr: /* [] */0,
  dealerId: /* [] */0,
  budgetType: /* [] */0,
  actionType: "",
  model: "",
  version: "",
  budgetYear: undefined,
  siren: "",
  orderStatus: /* [] */0,
  customerName: "",
  delivered: "",
  errorDescription: "",
  showDealerHistory: false
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
