// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_UserTypeCheckBox$DvmAdminFrontendFr from "../../../../../../common/components/input/App_UserTypeCheckBox.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Modal_Api.bs.js";
import * as Flash_Edit_Version_Perf_Single_Form$DvmAdminFrontendFr from "./Flash_Edit_Version_Perf_Single_Form.bs.js";

function Flash_Edit_Version_Perf_Single_Modal(Props) {
  var flash = Props.flash;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var fetchUpdatedModelsForDropdownAndCumulables = Props.fetchUpdatedModelsForDropdownAndCumulables;
  var row = Props.row;
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match[1];
  var saveResult = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAdjacentDatesRow = match$1[1];
  var adjacentDatesRow = match$1[0];
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDeleteResult = match$2[1];
  var deleteResult = match$2[0];
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match$3 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$4 = match$3[0];
  var userManager = match$4.userManager;
  var selectedRole = match$4.selectedRole;
  React.useEffect((function () {
          Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr.queryForAdjacentDates(userManager, selectedRole, row, setAdjacentDatesRow);
        }), []);
  var form = Flash_Edit_Version_Perf_Single_Form$DvmAdminFrontendFr.VersionForm.useForm(Flash_Edit_Version_Perf_Single_Form$DvmAdminFrontendFr.initialInput, {
        flashStartDate: flash.startDate,
        flashEndDate: flash.endDate,
        followingStartDate: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(adjacentDatesRow, undefined, (function (x) {
                return x.followingStartDate;
              })),
        previousEndDate: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(adjacentDatesRow, undefined, (function (x) {
                return x.previousEndDate;
              }))
      }, (function (param, submissionCallbacks) {
          Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr.overwriteVersion(setSaveResult, userManager, submissionCallbacks, selectedRole, {
                hd: {
                  original: {
                    actionID: flash.actionID,
                    modelVersionID: row.modelVersionID,
                    oldStartDate: undefined,
                    startDate: row.startDate,
                    endDate: row.endDate,
                    maxBonus: row.maxBonus
                  },
                  new: {
                    actionID: flash.actionID,
                    modelVersionID: row.modelVersionID,
                    oldStartDate: undefined,
                    startDate: param.startDate,
                    endDate: param.endDate,
                    maxBonus: param.maxBonus
                  }
                },
                tl: /* [] */0
              });
        }));
  React.useEffect((function () {
          var __x = row.startDate;
          Curry._2(form.updateStartDate, (function (input, startDate) {
                  return {
                          startDate: startDate,
                          startDateAsFlash: input.startDateAsFlash,
                          endDate: input.endDate,
                          endDateAsFlash: input.endDateAsFlash,
                          maxBonus: input.maxBonus
                        };
                }), __x);
          if (Belt_Option.isNone(row.startDate)) {
            Curry._2(form.updateStartDateAsFlash, (function (input, startDateAsFlash) {
                    return {
                            startDate: input.startDate,
                            startDateAsFlash: startDateAsFlash,
                            endDate: input.endDate,
                            endDateAsFlash: input.endDateAsFlash,
                            maxBonus: input.maxBonus
                          };
                  }), true);
          }
          var __x$1 = row.endDate;
          Curry._2(form.updateEndDate, (function (input, endDate) {
                  return {
                          startDate: input.startDate,
                          startDateAsFlash: input.startDateAsFlash,
                          endDate: endDate,
                          endDateAsFlash: input.endDateAsFlash,
                          maxBonus: input.maxBonus
                        };
                }), __x$1);
          if (Belt_Option.isNone(row.endDate)) {
            Curry._2(form.updateEndDateAsFlash, (function (input, endDateAsFlash) {
                    return {
                            startDate: input.startDate,
                            startDateAsFlash: input.startDateAsFlash,
                            endDate: input.endDate,
                            endDateAsFlash: endDateAsFlash,
                            maxBonus: input.maxBonus
                          };
                  }), true);
          }
          var __x$2 = Belt_Option.mapWithDefault(row.maxBonus, "", (function (prim) {
                  return String(prim);
                }));
          Curry._2(form.updateMaxBonus, (function (input, maxBonus) {
                  return {
                          startDate: input.startDate,
                          startDateAsFlash: input.startDateAsFlash,
                          endDate: input.endDate,
                          endDateAsFlash: input.endDateAsFlash,
                          maxBonus: maxBonus
                        };
                }), __x$2);
        }), [
        row.startDate,
        row.endDate,
        row.maxBonus
      ]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  React.useEffect((function () {
          if (typeof deleteResult === "number") {
            deleteResult === /* NotStarted */0;
          } else if (deleteResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(fetchUpdatedModelsForDropdownAndCumulables, undefined);
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: deleteResult._0
                      }
                    }));
          }
        }), [deleteResult]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    startDate: startDate,
                    startDateAsFlash: input.startDateAsFlash,
                    endDate: input.endDate,
                    endDateAsFlash: input.endDateAsFlash,
                    maxBonus: input.maxBonus
                  };
          })),
    onBlur: (function (param) {
        Curry._1(form.blurStartDate, undefined);
      })
  };
  if (form.startDateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    startDate: input.startDate,
                    startDateAsFlash: input.startDateAsFlash,
                    endDate: endDate,
                    endDateAsFlash: input.endDateAsFlash,
                    maxBonus: input.maxBonus
                  };
          })),
    disabled: form.input.endDateAsFlash,
    onBlur: (function (param) {
        Curry._1(form.blurEndDate, undefined);
      })
  };
  if (form.endDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  var tmp$2 = {
    onChange: Curry._1(form.updateMaxBonus, (function (input, maxBonus) {
            return {
                    startDate: input.startDate,
                    startDateAsFlash: input.startDateAsFlash,
                    endDate: input.endDate,
                    endDateAsFlash: input.endDateAsFlash,
                    maxBonus: maxBonus
                  };
          })),
    value: form.input.maxBonus,
    label: Messages_Flash_Edit$DvmAdminFrontendFr.maxBonus,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.maxBonusResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.maxBonusResult);
  }
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.editDates),
              minWidth: "700px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(deleteResult),
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              color: "primary",
                              children: React.createElement(Core.Grid, {
                                    alignItems: "flex-start",
                                    children: null,
                                    container: true,
                                    direction: "column",
                                    spacing: 3
                                  }, React.createElement(Core.Grid, {
                                        alignItems: "flex-start",
                                        children: null,
                                        container: true,
                                        direction: "column",
                                        item: true,
                                        spacing: 1
                                      }, React.createElement(Core.Grid, {
                                            children: "" + intl.formatMessage(Messages_Common$DvmAdminFrontendFr.version) + " " + row.version + "",
                                            item: true
                                          }), React.createElement(Core.Grid, {
                                            children: Belt_Option.getWithDefault(row.versionName, ""),
                                            item: true
                                          })), React.createElement(Core.Grid, {
                                        children: "" + intl.formatMessage(Messages_Common$DvmAdminFrontendFr.previousEndDate) + ": " + Belt_Option.mapWithDefault(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(adjacentDatesRow, undefined, (function (x) {
                                                    return x.previousEndDate;
                                                  })), "-", (function (param) {
                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                              })) + "",
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(Core.Grid, {
                                              alignItems: "center",
                                              children: React.createElement(Core.Grid, {
                                                    children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                                                    item: true
                                                  }),
                                              container: true,
                                              spacing: 3
                                            }),
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(Core.Grid, {
                                              alignItems: "center",
                                              children: null,
                                              container: true,
                                              spacing: 3
                                            }, React.createElement(Core.Grid, {
                                                  children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                                                  item: true
                                                }), React.createElement(Core.Grid, {
                                                  children: React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                                                        label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.sameAsFlash),
                                                        checked: form.input.endDateAsFlash,
                                                        onClick: (function (param) {
                                                            Curry._2(form.updateEndDateAsFlash, (function (input, endDateAsFlash) {
                                                                    return {
                                                                            startDate: input.startDate,
                                                                            startDateAsFlash: input.startDateAsFlash,
                                                                            endDate: input.endDate,
                                                                            endDateAsFlash: endDateAsFlash,
                                                                            maxBonus: input.maxBonus
                                                                          };
                                                                  }), !form.input.endDateAsFlash);
                                                          }),
                                                        disabled: Belt_Option.isSome(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(adjacentDatesRow, undefined, (function (x) {
                                                                    return x.followingStartDate;
                                                                  })))
                                                      }),
                                                  item: true
                                                })),
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: "" + intl.formatMessage(Messages_Common$DvmAdminFrontendFr.followingStartDate) + ": " + Belt_Option.mapWithDefault(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(adjacentDatesRow, undefined, (function (x) {
                                                    return x.followingStartDate;
                                                  })), "-", (function (param) {
                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                              })) + "",
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$2),
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: null,
                                        container: true,
                                        item: true,
                                        justifyContent: "space-between"
                                      }, React.createElement(Core.Grid, {
                                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  onClick: (function (param) {
                                                      Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr.deleteVersion(userManager, setDeleteResult, selectedRole, {
                                                            hd: {
                                                              actionID: flash.actionID,
                                                              modelVersionID: row.modelVersionID,
                                                              oldStartDate: undefined,
                                                              startDate: row.startDate,
                                                              endDate: row.endDate,
                                                              maxBonus: row.maxBonus
                                                            },
                                                            tl: /* [] */0
                                                          });
                                                    }),
                                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                                                  color: "delete",
                                                  size: "medium",
                                                  type_: "button"
                                                }),
                                            item: true
                                          }), React.createElement(Core.Grid, {
                                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                  size: "medium",
                                                  type_: "submit"
                                                }),
                                            item: true
                                          }))),
                              variant: "bodyBig"
                            }))
                  })
            });
}

var Form;

var Api;

var Types;

var make = Flash_Edit_Version_Perf_Single_Modal;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
