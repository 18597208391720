// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as EnvVar$DvmAdminFrontendFr from "../../../../../bindings/global/EnvVar.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Flash_Detail_Table_Types$DvmAdminFrontendFr from "../Flash_Detail_Table_Types.bs.js";

var headersConfiguration = Belt_List.keepMap({
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
        tl: {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.versionLabel, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial),
          tl: {
            hd: EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? undefined : App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.customBonus, undefined, false, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.amount),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
                tl: /* [] */0
              }
            }
          }
        }
      }
    }, (function (x) {
        return x;
      }));

function displayCustomBonus(customBonus, flash, intl) {
  var amount = flash.amount;
  if (amount === undefined) {
    return "0";
  }
  var finalAmount = Belt_Option.mapWithDefault(customBonus, Belt_Option.getWithDefault(amount.value, 0.0), (function (x) {
          if (x > 0.0) {
            return x;
          } else {
            return Belt_Option.getWithDefault(amount.value, 0.0);
          }
        }));
  var match = amount.numberType;
  switch (match) {
    case /* PERCENT */0 :
        return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, finalAmount);
    case /* EURO */1 :
        return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, finalAmount);
    case /* NOTSET */2 :
        return String(finalAmount);
    
  }
}

function getDateString(date, flash, variant, intl) {
  return Belt_Option.mapWithDefault(date, Belt_Option.mapWithDefault(flash.orderPeriod, "", (function (x) {
                    return Belt_Option.mapWithDefault(variant === "start" ? x.startDate : x.endDate, "", (function (param) {
                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                }));
                  })), (function (param) {
                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
              }));
}

function Flash_Detail_Models_Table_NonFleet(Props) {
  var tablePage = Props.tablePage;
  var tableHandler = Props.tableHandler;
  var filterPagingAndSort = Props.filterPagingAndSort;
  var flash = Props.flash;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex(Belt_List.keepMap({
                                                      hd: param.model,
                                                      tl: {
                                                        hd: param.version,
                                                        tl: {
                                                          hd: Belt_Option.getWithDefault(param.versionLabel, ""),
                                                          tl: {
                                                            hd: EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? undefined : displayCustomBonus(param.customBonus, flash, intl),
                                                            tl: {
                                                              hd: getDateString(param.startDate, flash, "start", intl),
                                                              tl: {
                                                                hd: getDateString(param.endDate, flash, "end", intl),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }, (function (x) {
                                                        return x;
                                                      })), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: tableHandler.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._1(filterPagingAndSort, {
          page: tableHandler.page,
          headers: headers,
          model: tableHandler.model,
          version: tableHandler.version,
          versionLabel: tableHandler.versionLabel
        });
  };
  var handleChangePage = function (page) {
    Curry._1(filterPagingAndSort, {
          page: page,
          headers: tableHandler.headers,
          model: tableHandler.model,
          version: tableHandler.version,
          versionLabel: tableHandler.versionLabel
        });
  };
  var handleRowsPerPageChange = function (param) {
    Curry._1(filterPagingAndSort, {
          page: 0,
          headers: tableHandler.headers,
          model: tableHandler.model,
          version: tableHandler.version,
          versionLabel: tableHandler.versionLabel
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: tableHandler.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined)
                }));
}

var Api;

var make = Flash_Detail_Models_Table_NonFleet;

export {
  Api ,
  headersConfiguration ,
  displayCustomBonus ,
  getDateString ,
  make ,
}
/* headersConfiguration Not a pure module */
