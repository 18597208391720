// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Container$Mui from "rescript-material-ui/src/Container.bs.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Flash_Detail_Api$DvmAdminFrontendFr from "../Flash_Detail_Api.bs.js";
import * as Flash_Detail_AppBar$DvmAdminFrontendFr from "./Flash_Detail_AppBar.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as Flash_Detail_Main_Info$DvmAdminFrontendFr from "./Flash_Detail_Main_Info.bs.js";
import * as Flash_Detail_Conditions$DvmAdminFrontendFr from "./Flash_Detail_Conditions.bs.js";
import * as Flash_Detail_Non_Cumulables$DvmAdminFrontendFr from "./Flash_Detail_Non_Cumulables.bs.js";
import * as Flash_Detail_Models_Page_Fleet$DvmAdminFrontendFr from "../table/fleet/Flash_Detail_Models_Page_Fleet.bs.js";
import * as Flash_Detail_Models_Page_NonFleet$DvmAdminFrontendFr from "../table/nonFleet/Flash_Detail_Models_Page_NonFleet.bs.js";

function Flash_Detail_Page(Props) {
  var flashId = Props.flashId;
  var flashType = Props.flashType;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedFlash = match$2[1];
  var fetchedFlash = match$2[0];
  React.useEffect((function () {
          Flash_Detail_Api$DvmAdminFrontendFr.fetchFlash(flashId, userManager, setFetchedFlash, selectedRole, flashType);
        }), []);
  if (typeof fetchedFlash === "number") {
    if (fetchedFlash === /* NotStarted */0) {
      return null;
    } else {
      return React.createElement(Core.Grid, {
                  children: React.createElement(Core.CircularProgress, {
                        size: 100
                      }),
                  container: true,
                  justifyContent: "center"
                });
    }
  }
  if (fetchedFlash.TAG !== /* Ok */0) {
    return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                color: "error",
                children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.flashNotFound),
                variant: "h3"
              });
  }
  var flash = fetchedFlash._0;
  var match$3 = flash.actionType;
  var tmp;
  var exit = 0;
  switch (match$3) {
    case "PERF" :
    case "REPR" :
    case "STOC" :
        exit = 1;
        break;
    default:
      tmp = React.createElement(Flash_Detail_Models_Page_NonFleet$DvmAdminFrontendFr.make, {
            flashId: flashId,
            flash: flash
          });
  }
  if (exit === 1) {
    tmp = React.createElement(Flash_Detail_Models_Page_Fleet$DvmAdminFrontendFr.make, {
          flashId: flashId,
          flash: flash,
          flashType: flashType
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Flash_Detail_AppBar$DvmAdminFrontendFr.make, {
                  flash: flash,
                  flashType: flashType
                }), React.createElement(Core.Container, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        spacing: 5
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(Flash_Detail_Main_Info$DvmAdminFrontendFr.make, {
                                  flash: flash
                                }),
                            container: true,
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Core.Accordion, {
                                  children: null,
                                  square: true,
                                  style: {
                                    boxShadow: "none"
                                  }
                                }, React.createElement(Core.AccordionSummary, {
                                      children: React.createElement(Core.Grid, {
                                            children: null,
                                            container: true,
                                            spacing: 1
                                          }, React.createElement(Core.Grid, {
                                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                      size: /* Large */3,
                                                      type_: /* CarPark */7
                                                    }),
                                                item: true
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                      style: {
                                                        fontWeight: "600"
                                                      },
                                                      children: intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.modelVersionButtonTitle),
                                                      variant: "bodyBig"
                                                    }),
                                                item: true
                                              })),
                                      expandIcon: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                            size: /* Large */3,
                                            type_: /* PadDown */11
                                          }),
                                      style: {
                                        backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight2
                                      }
                                    }), tmp),
                            item: true
                          }), flash.actionType === "REPR" || flashType === /* NonFleet */1 ? React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Detail_Conditions$DvmAdminFrontendFr.make, {
                                    flash: flash,
                                    flashType: flashType
                                  }),
                              item: true
                            }) : null, flashType === /* NonFleet */1 ? React.createElement(Core.Grid, {
                              children: React.createElement(Flash_Detail_Non_Cumulables$DvmAdminFrontendFr.make, {
                                    flash: flash
                                  }),
                              item: true
                            }) : null),
                  maxWidth: Container$Mui.MaxWidth.md
                }));
}

var Api;

var make = Flash_Detail_Page;

export {
  Api ,
  make ,
}
/* react Not a pure module */
