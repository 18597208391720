// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_DateInput$DvmAdminFrontendFr from "./Flash_Edit_DateInput.bs.js";
import * as App_CheckBoxFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_CheckBoxFormInput.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";

function Flash_Edit_Details_Dates(Props) {
  var form = Props.form;
  var formGeneralDates = Props.formGeneralDates;
  var budgets = Props.budgets;
  var onOrderPeriodBlur = Props.onOrderPeriodBlur;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return false;
      });
  var setDeliveryDateSame = match[1];
  var deliveryDateSame = match[0];
  React.useEffect((function () {
          if (deliveryDateSame) {
            Curry._2(formGeneralDates.updateDeliveryStartDate, (function (input, deliveryStartDate) {
                    return {
                            startDate: input.startDate,
                            endDate: input.endDate,
                            deliveryStartDate: deliveryStartDate,
                            deliveryEndDate: input.deliveryEndDate,
                            registrationStartDate: input.registrationStartDate,
                            registrationEndDate: input.registrationEndDate
                          };
                  }), formGeneralDates.input.startDate);
          } else {
            var match = formGeneralDates.input.startDate;
            var match$1 = formGeneralDates.input.deliveryStartDate;
            var tmp;
            if (match !== undefined) {
              var start = Caml_option.valFromOption(match);
              if (match$1 !== undefined) {
                var deliveryStart = Caml_option.valFromOption(match$1);
                tmp = App_Types_Date$DvmAdminFrontendFr.isValid(start) && App_Types_Date$DvmAdminFrontendFr.isValid(deliveryStart) ? ReDate.isEqual(ReDate_utils.makeStartOfDayDate(deliveryStart), ReDate_utils.makeStartOfDayDate(start)) : false;
              } else {
                tmp = App_Types_Date$DvmAdminFrontendFr.isValid(start);
              }
            } else {
              tmp = false;
            }
            if (tmp) {
              Curry._1(setDeliveryDateSame, (function (param) {
                      return true;
                    }));
              Curry._2(formGeneralDates.updateDeliveryStartDate, (function (input, deliveryStartDate) {
                      return {
                              startDate: input.startDate,
                              endDate: input.endDate,
                              deliveryStartDate: deliveryStartDate,
                              deliveryEndDate: input.deliveryEndDate,
                              registrationStartDate: input.registrationStartDate,
                              registrationEndDate: input.registrationEndDate
                            };
                    }), formGeneralDates.input.startDate);
            } else {
              Curry._1(setDeliveryDateSame, (function (param) {
                      return false;
                    }));
            }
          }
        }), [formGeneralDates.input.startDate]);
  React.useEffect((function () {
          var match = formGeneralDates.input.startDate;
          var match$1 = formGeneralDates.input.deliveryStartDate;
          if (match !== undefined && match$1 !== undefined) {
            var deliveryStart = Caml_option.valFromOption(match$1);
            var start = Caml_option.valFromOption(match);
            if (App_Types_Date$DvmAdminFrontendFr.isValid(start) && App_Types_Date$DvmAdminFrontendFr.isValid(deliveryStart) && !ReDate.isEqual(ReDate_utils.makeStartOfDayDate(deliveryStart), ReDate_utils.makeStartOfDayDate(start))) {
              Curry._1(setDeliveryDateSame, (function (param) {
                      return false;
                    }));
            }
            
          }
          
        }), [formGeneralDates.input.deliveryStartDate]);
  React.useEffect((function () {
          if (Belt_Option.getWithDefault(form.input.isParcEntry, false)) {
            
          } else {
            if (form.input.detentionMin.length > 0) {
              
            } else {
              Curry._2(form.updateDetentionMin, (function (input, detentionMin) {
                      var newrecord = Caml_obj.obj_dup(input);
                      newrecord.detentionMin = detentionMin;
                      return newrecord;
                    }), "90");
            }
            if (form.input.detentionMax.length > 0) {
              
            } else {
              Curry._2(form.updateDetentionMax, (function (input, detentionMax) {
                      var newrecord = Caml_obj.obj_dup(input);
                      newrecord.detentionMax = detentionMax;
                      return newrecord;
                    }), "365");
            }
          }
        }), [form.input.isParcEntry]);
  var tmp;
  if (form.input.orderType === /* SO */0) {
    var tmp$1 = {
      label: Messages_Common$DvmAdminFrontendFr.budget,
      values: form.input.budgetType,
      options: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(budgets, /* [] */0), (function (x) {
              return {
                      label: {
                        TAG: /* String */3,
                        _0: x
                      },
                      value: x
                    };
            })),
      onChange: Curry._1(form.updateBudgetType, (function (input, budgetType) {
              var newrecord = Caml_obj.obj_dup(input);
              newrecord.budgetType = budgetType;
              return newrecord;
            })),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.extraLongInput
    };
    if (form.budgetTypeResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.budgetTypeResult);
    }
    tmp = React.createElement(Core.Grid, {
          children: React.createElement(App_CheckBoxFormInput$DvmAdminFrontendFr.make, tmp$1),
          item: true
        });
  } else {
    tmp = null;
  }
  var tmp$2;
  if (form.input.orderType === /* VE */2) {
    tmp$2 = null;
  } else {
    var tmp$3 = {};
    if (onOrderPeriodBlur !== undefined) {
      tmp$3.onBlur = Caml_option.valFromOption(onOrderPeriodBlur);
    }
    var tmp$4 = {
      icon: /* DeliveryCar */3,
      message: Messages_Flash_Detail$DvmAdminFrontendFr.deliveryPeriod,
      startDateValue: formGeneralDates.input.deliveryStartDate,
      startDateOnChange: Curry._1(formGeneralDates.updateDeliveryStartDate, (function (input, deliveryStartDate) {
              return {
                      startDate: input.startDate,
                      endDate: input.endDate,
                      deliveryStartDate: deliveryStartDate,
                      deliveryEndDate: input.deliveryEndDate,
                      registrationStartDate: input.registrationStartDate,
                      registrationEndDate: input.registrationEndDate
                    };
            })),
      startDateResult: formGeneralDates.deliveryStartDateResult,
      endDateValue: formGeneralDates.input.deliveryEndDate,
      endDateOnChange: Curry._1(formGeneralDates.updateDeliveryEndDate, (function (input, deliveryEndDate) {
              return {
                      startDate: input.startDate,
                      endDate: input.endDate,
                      deliveryStartDate: input.deliveryStartDate,
                      deliveryEndDate: deliveryEndDate,
                      registrationStartDate: input.registrationStartDate,
                      registrationEndDate: input.registrationEndDate
                    };
            })),
      endDateResult: formGeneralDates.deliveryEndDateResult
    };
    var tmp$5 = deliveryDateSame ? Messages_Flash_Edit$DvmAdminFrontendFr.startDateLinkedToMain : undefined;
    if (tmp$5 !== undefined) {
      tmp$4.customStartDateMessage = Caml_option.valFromOption(tmp$5);
    }
    tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement("div", tmp$3, React.createElement(Flash_Edit_DateInput$DvmAdminFrontendFr.make, {
                        icon: /* ShoppingCart */40,
                        message: Messages_Flash_Detail$DvmAdminFrontendFr.orderPeriod,
                        startDateValue: formGeneralDates.input.startDate,
                        startDateOnChange: (function (val) {
                            Curry._2(formGeneralDates.updateStartDate, (function (input, startDate) {
                                    return {
                                            startDate: startDate,
                                            endDate: input.endDate,
                                            deliveryStartDate: input.deliveryStartDate,
                                            deliveryEndDate: input.deliveryEndDate,
                                            registrationStartDate: input.registrationStartDate,
                                            registrationEndDate: input.registrationEndDate
                                          };
                                  }), val);
                          }),
                        startDateResult: formGeneralDates.startDateResult,
                        endDateValue: formGeneralDates.input.endDate,
                        endDateOnChange: Curry._1(formGeneralDates.updateEndDate, (function (input, endDate) {
                                return {
                                        startDate: input.startDate,
                                        endDate: endDate,
                                        deliveryStartDate: input.deliveryStartDate,
                                        deliveryEndDate: input.deliveryEndDate,
                                        registrationStartDate: input.registrationStartDate,
                                        registrationEndDate: input.registrationEndDate
                                      };
                              })),
                        endDateResult: formGeneralDates.endDateResult,
                        startDateRequired: !(form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5),
                        endDateRequired: !(form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5)
                      })),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(Flash_Edit_DateInput$DvmAdminFrontendFr.make, tmp$4),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(Flash_Edit_DateInput$DvmAdminFrontendFr.make, {
                    icon: /* CarHood */64,
                    message: Messages_Flash_Detail$DvmAdminFrontendFr.registrationPeriod,
                    startDateValue: formGeneralDates.input.registrationStartDate,
                    startDateOnChange: Curry._1(formGeneralDates.updateRegistrationStartDate, (function (input, registrationStartDate) {
                            return {
                                    startDate: input.startDate,
                                    endDate: input.endDate,
                                    deliveryStartDate: input.deliveryStartDate,
                                    deliveryEndDate: input.deliveryEndDate,
                                    registrationStartDate: registrationStartDate,
                                    registrationEndDate: input.registrationEndDate
                                  };
                          })),
                    startDateResult: formGeneralDates.registrationStartDateResult,
                    endDateValue: formGeneralDates.input.registrationEndDate,
                    endDateOnChange: Curry._1(formGeneralDates.updateRegistrationEndDate, (function (input, registrationEndDate) {
                            return {
                                    startDate: input.startDate,
                                    endDate: input.endDate,
                                    deliveryStartDate: input.deliveryStartDate,
                                    deliveryEndDate: input.deliveryEndDate,
                                    registrationStartDate: input.registrationStartDate,
                                    registrationEndDate: registrationEndDate
                                  };
                          })),
                    endDateResult: formGeneralDates.registrationEndDateResult
                  }),
              item: true
            }));
  }
  var tmp$6;
  if (form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5) {
    var tmp$7;
    if (Belt_Option.getWithDefault(form.input.isParcEntry, true)) {
      tmp$7 = null;
    } else {
      var tmp$8 = {
        onChange: Curry._1(form.updateDetentionMin, (function (input, detentionMin) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.detentionMin = detentionMin;
                return newrecord;
              })),
        value: form.input.detentionMin,
        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
        label: Messages_Flash_Edit$DvmAdminFrontendFr.dateMin,
        required: /* OnlyLabel */1,
        inputType: "number"
      };
      if (form.detentionMinResult !== undefined) {
        tmp$8.validationResult = Caml_option.valFromOption(form.detentionMinResult);
      }
      var tmp$9 = {
        onChange: Curry._1(form.updateDetentionMax, (function (input, detentionMax) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.detentionMax = detentionMax;
                return newrecord;
              })),
        value: form.input.detentionMax,
        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
        label: Messages_Flash_Edit$DvmAdminFrontendFr.dateMax,
        required: /* OnlyLabel */1,
        inputType: "number"
      };
      if (form.detentionMaxResult !== undefined) {
        tmp$9.validationResult = Caml_option.valFromOption(form.detentionMaxResult);
      }
      tmp$7 = React.createElement(Core.Grid, {
            children: React.createElement(Core.Box, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true
                      }, React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            spacing: 1
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                      size: /* Large */3,
                                      type_: /* DeliveryCar */3
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      style: {
                                        fontWeight: "600"
                                      },
                                      children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.detentionDuration),
                                      variant: "bodyBig"
                                    }),
                                item: true
                              })), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            spacing: 3
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$8),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$9),
                                item: true
                              }))),
                  borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                  borderLeft: 2,
                  paddingLeft: 1
                }),
            item: true
          });
    }
    tmp$6 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(Flash_Edit_DateInput$DvmAdminFrontendFr.make, {
                    icon: /* CarPark */7,
                    message: Messages_Flash_Edit$DvmAdminFrontendFr.parcEntryPeriod,
                    startDateValue: form.input.entryParcDatePeriodStart,
                    startDateOnChange: Curry._1(form.updateEntryParcDatePeriodStart, (function (input, entryParcDatePeriodStart) {
                            var newrecord = Caml_obj.obj_dup(input);
                            newrecord.entryParcDatePeriodStart = entryParcDatePeriodStart;
                            return newrecord;
                          })),
                    startDateResult: form.entryParcDatePeriodStartResult,
                    endDateValue: form.input.entryParcDatePeriodEnd,
                    endDateOnChange: Curry._1(form.updateEntryParcDatePeriodEnd, (function (input, entryParcDatePeriodEnd) {
                            var newrecord = Caml_obj.obj_dup(input);
                            newrecord.entryParcDatePeriodEnd = entryParcDatePeriodEnd;
                            return newrecord;
                          })),
                    endDateResult: form.entryParcDatePeriodEndResult
                  }),
              item: true
            }), tmp$7);
  } else {
    tmp$6 = null;
  }
  var tmp$10;
  if (form.input.orderType === /* VE */2) {
    if (Belt_Option.getWithDefault(form.input.isParcEntry, true)) {
      tmp$10 = React.createElement(Core.Grid, {
            children: React.createElement(Flash_Edit_DateInput$DvmAdminFrontendFr.make, {
                  icon: /* CarPark */7,
                  message: Messages_Flash_Edit$DvmAdminFrontendFr.parcEntryPeriod,
                  startDateValue: form.input.entryParcDatePeriodStart,
                  startDateOnChange: Curry._1(form.updateEntryParcDatePeriodStart, (function (input, entryParcDatePeriodStart) {
                          var newrecord = Caml_obj.obj_dup(input);
                          newrecord.entryParcDatePeriodStart = entryParcDatePeriodStart;
                          return newrecord;
                        })),
                  startDateResult: form.entryParcDatePeriodStartResult,
                  endDateValue: form.input.entryParcDatePeriodEnd,
                  endDateOnChange: Curry._1(form.updateEntryParcDatePeriodEnd, (function (input, entryParcDatePeriodEnd) {
                          var newrecord = Caml_obj.obj_dup(input);
                          newrecord.entryParcDatePeriodEnd = entryParcDatePeriodEnd;
                          return newrecord;
                        })),
                  endDateResult: form.entryParcDatePeriodEndResult,
                  startDateRequired: true,
                  endDateRequired: true
                }),
            item: true
          });
    } else {
      var tmp$11 = {
        onChange: Curry._1(form.updateDetentionMin, (function (input, detentionMin) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.detentionMin = detentionMin;
                return newrecord;
              })),
        value: form.input.detentionMin,
        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
        label: Messages_Flash_Edit$DvmAdminFrontendFr.dateMin,
        required: /* OnlyLabel */1,
        inputType: "number"
      };
      if (form.detentionMinResult !== undefined) {
        tmp$11.validationResult = Caml_option.valFromOption(form.detentionMinResult);
      }
      var tmp$12 = {
        onChange: Curry._1(form.updateDetentionMax, (function (input, detentionMax) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.detentionMax = detentionMax;
                return newrecord;
              })),
        value: form.input.detentionMax,
        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
        label: Messages_Flash_Edit$DvmAdminFrontendFr.dateMax,
        required: /* OnlyLabel */1,
        inputType: "number"
      };
      if (form.detentionMaxResult !== undefined) {
        tmp$12.validationResult = Caml_option.valFromOption(form.detentionMaxResult);
      }
      tmp$10 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                children: React.createElement(Flash_Edit_DateInput$DvmAdminFrontendFr.make, {
                      icon: /* CarPark */7,
                      message: Messages_Flash_Edit$DvmAdminFrontendFr.parcExitPeriod,
                      startDateValue: form.input.exitParcDatePeriodStart,
                      startDateOnChange: Curry._1(form.updateExitParcDatePeriodStart, (function (input, exitParcDatePeriodStart) {
                              var newrecord = Caml_obj.obj_dup(input);
                              newrecord.exitParcDatePeriodStart = exitParcDatePeriodStart;
                              return newrecord;
                            })),
                      startDateResult: form.exitParcDatePeriodStartResult,
                      endDateValue: form.input.exitParcDatePeriodEnd,
                      endDateOnChange: Curry._1(form.updateExitParcDatePeriodEnd, (function (input, exitParcDatePeriodEnd) {
                              var newrecord = Caml_obj.obj_dup(input);
                              newrecord.exitParcDatePeriodEnd = exitParcDatePeriodEnd;
                              return newrecord;
                            })),
                      endDateResult: form.exitParcDatePeriodEndResult,
                      startDateRequired: true,
                      endDateRequired: true
                    }),
                item: true
              }), React.createElement(Core.Grid, {
                children: React.createElement(Core.Box, {
                      children: React.createElement(Core.Grid, {
                            children: null,
                            container: true
                          }, React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                item: true,
                                spacing: 1
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                          size: /* Large */3,
                                          type_: /* DeliveryCar */3
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                          style: {
                                            fontWeight: "600"
                                          },
                                          children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.detentionDuration),
                                          variant: "bodyBig"
                                        }),
                                    item: true
                                  })), React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                item: true,
                                spacing: 3
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$11),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$12),
                                    item: true
                                  }))),
                      borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                      borderLeft: 2,
                      paddingLeft: 1
                    }),
                item: true
              }));
    }
  } else {
    tmp$10 = null;
  }
  return React.createElement(React.Fragment, undefined, tmp, tmp$2, tmp$6, tmp$10);
}

var Form;

var FormGeneralDates;

var InputStyles;

var make = Flash_Edit_Details_Dates;

export {
  Form ,
  FormGeneralDates ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
