// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../components/table/App_Table_Head.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../App_Typography.bs.js";
import * as App_Table_Styles$DvmAdminFrontendFr from "../../../components/table/App_Table_Styles.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../styles/App_Styles_Common.bs.js";
import * as File_Upload_Types$DvmAdminFrontendFr from "../../File_Upload_Types.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../../../intl/messages/Messages_File_Upload.bs.js";

function File_Upload_Excel_Check_Internal_Table(Props) {
  var validateResult = Props.validateResult;
  var excelInfo = Props.excelInfo;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              item: true,
              spacing: 1
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        color: "textPrimary",
                        gutterBottom: true,
                        children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.fileHasErrors),
                        variant: "bodyBig"
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: Belt_Option.mapWithDefault(validateResult.results, null, (function (results) {
                          var firstTen = Belt_List.length(results) > 10 ? Belt_Option.mapWithDefault(Belt_List.splitAt(results, 10), /* [] */0, (function (param) {
                                    return param[0];
                                  })) : results;
                          if (Belt_List.length(firstTen) === 0) {
                            return null;
                          } else {
                            return React.createElement(Core.TableContainer, {
                                        children: React.createElement(Core.Table, {
                                              children: null,
                                              stickyHeader: true
                                            }, React.createElement(Core.TableHead, {
                                                  children: React.createElement(Core.TableRow, {
                                                        children: Belt_List.toArray(Belt_List.map(Belt_List.concat({
                                                                      hd: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.rowNumber),
                                                                      tl: /* [] */0
                                                                    }, excelInfo.excelColumns), (function (column) {
                                                                    return React.createElement(Core.TableCell, {
                                                                                children: column,
                                                                                className: App_Table_Head$DvmAdminFrontendFr.headerText,
                                                                                key: column
                                                                              });
                                                                  }))),
                                                        className: App_Table_Head$DvmAdminFrontendFr.wholeRow
                                                      })
                                                }), React.createElement(Core.TableBody, {
                                                  children: Belt_List.toArray(Belt_List.mapWithIndex(firstTen, (function (rowIndex, row) {
                                                              return React.createElement(Core.TableRow, {
                                                                          children: null,
                                                                          key: "row" + String(rowIndex)
                                                                        }, React.createElement(Core.TableCell, {
                                                                              children: React.createElement("div", {
                                                                                    className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                                  }, React.createElement(Core.Grid, {
                                                                                        children: null,
                                                                                        container: true,
                                                                                        justifyContent: "space-between",
                                                                                        spacing: 2,
                                                                                        wrap: "nowrap"
                                                                                      }, React.createElement(Core.Grid, {
                                                                                            children: row.rowNumber,
                                                                                            item: true
                                                                                          }), Belt_Option.isSome(Belt_List.getBy(row.errors, (function (x) {
                                                                                                  return Belt_Option.getWithDefault(x.errorType, "") === "ERR_PK_DUPLICITY";
                                                                                                }))) ? React.createElement(Core.Grid, {
                                                                                              children: React.createElement(Core.Tooltip, {
                                                                                                    children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                                                              size: /* Large */3,
                                                                                                              type_: /* HelpWarning */0,
                                                                                                              color: "red"
                                                                                                            })),
                                                                                                    classes: {
                                                                                                      tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                                                    },
                                                                                                    placement: "bottom",
                                                                                                    title: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.primaryKeyDuplicate)
                                                                                                  }),
                                                                                              item: true
                                                                                            }) : null)),
                                                                              className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                              key: "rowNumber"
                                                                            }), Belt_List.toArray(Belt_List.mapWithIndex(Belt_Option.getWithDefault(row.rowValues, /* [] */0), (function (cellIndex, cell) {
                                                                                    var tmp = {
                                                                                      children: React.createElement(Core.Tooltip, {
                                                                                            arrow: true,
                                                                                            children: React.createElement("div", {
                                                                                                  className: App_Table_Styles$DvmAdminFrontendFr.tableCellContent
                                                                                                }, /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(cell) ? Intl_Utils$DvmAdminFrontendFr.formatDate(intl, new Date(cell)) : cell),
                                                                                            classes: {
                                                                                              tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                                            },
                                                                                            placement: "bottom",
                                                                                            title: Belt_Option.mapWithDefault(Belt_List.getBy(row.errors, (function (x) {
                                                                                                        return Belt_Option.getWithDefault(x.column, 0) === (cellIndex + 1 | 0);
                                                                                                      })), "", (function (x) {
                                                                                                    return File_Upload_Types$DvmAdminFrontendFr.errorToMessage(x.errorType, intl);
                                                                                                  }))
                                                                                          }),
                                                                                      className: App_Table_Styles$DvmAdminFrontendFr.tableCell(undefined, undefined),
                                                                                      key: "cell" + String(rowIndex) + String(cellIndex)
                                                                                    };
                                                                                    var tmp$1 = Belt_Option.isSome(Belt_List.getBy(row.errors, (function (x) {
                                                                                                return Belt_Option.getWithDefault(x.column, 0) === (cellIndex + 1 | 0);
                                                                                              }))) ? ({
                                                                                          backgroundColor: "indianred"
                                                                                        }) : undefined;
                                                                                    if (tmp$1 !== undefined) {
                                                                                      tmp.style = Caml_option.valFromOption(tmp$1);
                                                                                    }
                                                                                    return React.createElement(Core.TableCell, tmp);
                                                                                  }))));
                                                            })))
                                                })),
                                        className: App_Table_Styles$DvmAdminFrontendFr.table,
                                        style: {
                                          height: "50vh"
                                        }
                                      });
                          }
                        })),
                  item: true
                }));
}

var Types;

var Styles;

var make = File_Upload_Excel_Check_Internal_Table;

export {
  Types ,
  Styles ,
  make ,
}
/* react Not a pure module */
