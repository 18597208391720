// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Order_Detail_Correct_Api$DvmAdminFrontendFr from "./Order_Detail_Correct_Api.bs.js";

function saveCorrection(formOutput, formPerfOutput, formVehicleOutput, formAdditionalOutput, formSubmissionCallbacks, formPerfSubmissionCallbacks, formVehicleSubmissionCallbacks, formAdditionalSubmissionCallbacks, userManager, setResult, selectedRole, orderId) {
  if (formOutput === undefined) {
    return ;
  }
  if (formPerfOutput === undefined) {
    return ;
  }
  if (formVehicleOutput === undefined) {
    return ;
  }
  if (formAdditionalOutput === undefined) {
    return ;
  }
  if (formSubmissionCallbacks === undefined) {
    return ;
  }
  if (formPerfSubmissionCallbacks === undefined) {
    return ;
  }
  if (formVehicleSubmissionCallbacks === undefined) {
    return ;
  }
  if (formAdditionalSubmissionCallbacks === undefined) {
    return ;
  }
  var match = formOutput.orderType;
  var match$1 = formOutput.orderType;
  var match$2 = formOutput.orderType;
  var match$3 = formOutput.orderType;
  var match$4 = formOutput.orderType;
  var match$5 = formOutput.orderType;
  var match$6 = formOutput.orderType;
  var match$7 = formOutput.orderType;
  var match$8 = formOutput.orderType;
  var match$9 = formOutput.orderType;
  var match$10 = formOutput.orderType;
  var match$11 = formOutput.orderType;
  var match$12 = formOutput.orderType;
  Order_Detail_Correct_Api$DvmAdminFrontendFr.saveCorrection({
        orderID: orderId,
        orderType: formOutput.orderType,
        timeStamp: undefined,
        editor: undefined,
        siret: match !== 0 ? undefined : formOutput.siret,
        sirenFinalCustomerLLD: match$1 !== 0 ? undefined : formOutput.finalCustomer,
        companyDiscountPercentage: match$2 !== 0 ? undefined : formOutput.discount,
        acquisitionType: match$3 !== 0 ? undefined : formOutput.acquisitionType,
        budgetType: match$4 !== 0 ? undefined : formOutput.budget,
        newCarRegistrationID: formOutput.registrationNumber,
        newCarRegistrationDate: formOutput.registrationDate,
        customerInvoiceDate: formOutput.invoiceClient,
        usedCarPoliceNumber: formVehicleOutput.policeNumber,
        usedCarRegistrationID: formVehicleOutput.usedCarRegistrationID,
        usedCarLastRegistrationDate: formVehicleOutput.usedCarLastRegistrationDate,
        usedCarFirstReleaseDate: formVehicleOutput.usedCarFirstReleaseDate,
        brandUsedCar: formVehicleOutput.brand,
        modelUsedCar: formVehicleOutput.model,
        versionUsedCar: formVehicleOutput.version,
        vinUsedCar: formVehicleOutput.vin,
        realTradeAmount: match$5 !== 0 ? formVehicleOutput.valueUsedCar : undefined,
        estimatedTradeAmount: match$6 !== 0 ? formVehicleOutput.estimatedValueUsedCar : undefined,
        scrapyardIndicator: match$7 !== 0 ? formVehicleOutput.endOfLifeUsedCar : undefined,
        customerInvoiceNumber: formOutput.invoiceNumber,
        comment: formOutput.comment,
        bonusActionTypePERF: formPerfOutput.bonusActionTypePERF,
        bonusActionTypePERF2: formPerfOutput.bonusActionTypePERF2,
        bonusActionTypePERF3: formPerfOutput.bonusActionTypePERF3,
        planPerfAmount: Belt_Option.getWithDefault(formPerfOutput.planPerfAmount, 0.0),
        planPerfAmount2: Belt_Option.getWithDefault(formPerfOutput.planPerfAmount2, 0.0),
        planPerfAmount3: Belt_Option.getWithDefault(formPerfOutput.planPerfAmount3, 0.0),
        planPerfID: formPerfOutput.planPerfID,
        planPerfID2: formPerfOutput.planPerfID2,
        planPerfID3: formPerfOutput.planPerfID3,
        planPerfLabel1: undefined,
        planPerfLabel2: undefined,
        planPerfLabel3: undefined,
        customerDiscount: match$8 !== 0 ? formOutput.customerDiscount : undefined,
        easyPack: match$9 !== 0 ? formOutput.easyPack : undefined,
        shortCustomerName: match$10 !== 0 ? formOutput.shortCustomerName : undefined,
        customerFirstName: match$11 !== 0 ? formOutput.customerFirstName : undefined,
        parcEndDate: match$12 !== 2 ? undefined : formOutput.parcEndDate,
        orderDate: formOutput.orderDate,
        renaultInvoiceDate: formOutput.renaultInvoiceDate,
        carSaleAvailibilityDateMADA: formOutput.carSaleAvailibilityDateMADA,
        carSaleAvailibilityDateMADC: formOutput.carSaleAvailibilityDateMADC,
        modelID: formAdditionalOutput.modelID,
        versionID: formAdditionalOutput.versionID,
        phase: formAdditionalOutput.phase,
        salesmanName: formAdditionalOutput.salesmanName,
        agentCode: formAdditionalOutput.agentCode
      }, userManager, setResult, formSubmissionCallbacks, formPerfSubmissionCallbacks, formVehicleSubmissionCallbacks, formAdditionalSubmissionCallbacks, selectedRole, formOutput.orderType);
}

var Form;

var FormPerfs;

var FormVehicle;

var FormAdditional;

export {
  Form ,
  FormPerfs ,
  FormVehicle ,
  FormAdditional ,
  saveCorrection ,
}
/* Order_Detail_Correct_Api-DvmAdminFrontendFr Not a pure module */
