// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_TransferListFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TransferListFormInput.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Version_Excel$DvmAdminFrontendFr from "./VersionsExcelUpload/Flash_Edit_NonFleet_New_Vehicle_Version_Excel.bs.js";

function Flash_Edit_NonFleet_New_Vehicle_Versions(Props) {
  var form = Props.form;
  var versionsLabels = Props.versionsLabels;
  var newVehicleExpanded = Props.newVehicleExpanded;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setAvailableVersionsTyped = match[1];
  var availableVersionsTyped = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setVersionsForTransferList = match$1[1];
  var versionsForTransferList = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setFilterModels = match$2[1];
  var filterModels = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setFilterVersion = match$3[1];
  var filterVersion = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var setFilterLabel = match$4[1];
  var filterLabel = match$4[0];
  var match$5 = React.useState(function () {
        return "";
      });
  var setFilterEngineType = match$5[1];
  var filterEngineType = match$5[0];
  var match$6 = React.useState(function () {
        return "";
      });
  var setFilterPhase = match$6[1];
  var filterPhase = match$6[0];
  var match$7 = React.useState(function () {
        return "";
      });
  var setFilterCategory = match$7[1];
  var filterCategory = match$7[0];
  React.useEffect((function () {
          if (typeof versionsLabels !== "number" && versionsLabels.TAG === /* Ok */0) {
            var versionsLabels$1 = versionsLabels._0;
            Curry._1(setAvailableVersionsTyped, (function (param) {
                    return versionsLabels$1;
                  }));
          }
          
        }), [versionsLabels]);
  React.useEffect((function () {
          Curry._2(form.updateVersions, (function (input, versions) {
                  return {
                          models: input.models,
                          modelPhases: input.modelPhases,
                          mappingDirection: input.mappingDirection,
                          engineTypes: input.engineTypes,
                          versions: versions,
                          brandsNewCar: input.brandsNewCar,
                          vehicleType: input.vehicleType
                        };
                }), Belt_List.keep(form.input.versions, (function (version) {
                      return Belt_Option.isSome(Belt_List.getBy(form.input.models, (function (model) {
                                        return model === version.value.substring(0, 3);
                                      })));
                    })));
        }), [form.input.models]);
  React.useEffect((function () {
          Curry._1(setVersionsForTransferList, (function (param) {
                  return Belt_List.map(Belt_List.keep(Belt_List.keep(Belt_List.keep(Belt_List.keep(Belt_List.keep(Belt_List.keep(availableVersionsTyped, (function (x) {
                                                        if (filterModels.length > 0) {
                                                          return x.model === filterModels;
                                                        } else {
                                                          return true;
                                                        }
                                                      })), (function (x) {
                                                    if (filterVersion.length > 0) {
                                                      return x.modelVersion === filterVersion;
                                                    } else {
                                                      return true;
                                                    }
                                                  })), (function (x) {
                                                if (filterLabel.length > 0) {
                                                  return x.modelVersionName.toLowerCase().includes(filterLabel.toLowerCase());
                                                } else {
                                                  return true;
                                                }
                                              })), (function (x) {
                                            if (filterEngineType.length > 0) {
                                              return x.engineType === filterEngineType;
                                            } else {
                                              return true;
                                            }
                                          })), (function (x) {
                                        if (filterPhase.length > 0) {
                                          return x.modelPhase === filterPhase;
                                        } else {
                                          return true;
                                        }
                                      })), (function (x) {
                                    if (filterCategory.length > 0) {
                                      return Belt_Option.getWithDefault(x.categoryId, "") === filterCategory;
                                    } else {
                                      return true;
                                    }
                                  })), (function (x) {
                                return {
                                        label: {
                                          TAG: /* String */3,
                                          _0: x.modelVersion + (
                                            x.modelVersionName.length > 0 ? " (" + x.modelVersionName + ")" : ""
                                          )
                                        },
                                        value: x.modelVersion
                                      };
                              }));
                }));
        }), [
        availableVersionsTyped,
        filterModels,
        filterLabel,
        filterVersion,
        filterEngineType,
        filterPhase,
        filterCategory
      ]);
  var mapCategories = function (param) {
    return Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMap(availableVersionsTyped, (function (x) {
                                  return Belt_Option.mapWithDefault(x.categoryId, undefined, (function (cat) {
                                                if (cat.length > 0) {
                                                  return [
                                                          cat,
                                                          x.categoryName
                                                        ];
                                                }
                                                
                                              }));
                                }))))), (function (param) {
                  var key = param[0];
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: key + Belt_Option.mapWithDefault(param[1], "", (function (x) {
                                    return " (" + x + ")";
                                  }))
                          },
                          value: key
                        };
                }));
  };
  var tmp = {
    values: Belt_List.map(form.input.versions, (function (selected) {
            return Belt_Option.getWithDefault(Belt_List.getBy(versionsForTransferList, (function (options) {
                              return selected.value === options.value;
                            })), selected);
          })),
    options: versionsForTransferList,
    onChange: Curry._1(form.updateVersions, (function (input, versions) {
            return {
                    models: input.models,
                    modelPhases: input.modelPhases,
                    mappingDirection: input.mappingDirection,
                    engineTypes: input.engineTypes,
                    versions: versions,
                    brandsNewCar: input.brandsNewCar,
                    vehicleType: input.vehicleType
                  };
          })),
    boxHeight: 260,
    search: false
  };
  if (form.versionsResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.versionsResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Box, {
                        children: React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              item: true,
                              spacing: 1
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                        style: {
                                          fontWeight: "600"
                                        },
                                        children: intl.formatMessage(form.input.mappingDirection === "INCLUDE" ? Messages_Flash_Edit$DvmAdminFrontendFr.selectionForInclusion : Messages_Flash_Edit$DvmAdminFrontendFr.selectionForExclusion),
                                        variant: "bodyBig"
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(Flash_Edit_NonFleet_New_Vehicle_Version_Excel$DvmAdminFrontendFr.make, {
                                        availableVersionsTyped: availableVersionsTyped,
                                        form: form,
                                        newVehicleExpanded: newVehicleExpanded
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: null,
                                        container: true,
                                        direction: "column",
                                        item: true,
                                        sm: Grid$Mui.Sm[12]
                                      }, React.createElement(Core.Grid, {
                                            children: null,
                                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                                            container: true
                                          }, React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                      label: Messages_Common$DvmAdminFrontendFr.model,
                                                      value: {
                                                        TAG: /* Single */0,
                                                        _0: {
                                                          value: filterModels,
                                                          onChange: (function (val) {
                                                              Curry._1(setFilterModels, (function (param) {
                                                                      return val;
                                                                    }));
                                                            })
                                                        }
                                                      },
                                                      options: {
                                                        TAG: /* Unlabeled */0,
                                                        _0: form.input.models
                                                      },
                                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                                                    }),
                                                item: true
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                      label: Messages_Common$DvmAdminFrontendFr.version,
                                                      value: {
                                                        TAG: /* Single */0,
                                                        _0: {
                                                          value: filterVersion,
                                                          onChange: (function (val) {
                                                              Curry._1(setFilterVersion, (function (param) {
                                                                      return val;
                                                                    }));
                                                            })
                                                        }
                                                      },
                                                      options: {
                                                        TAG: /* Unlabeled */0,
                                                        _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(availableVersionsTyped, (function (x) {
                                                                            return x.modelVersion;
                                                                          })))))
                                                      },
                                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                                    }),
                                                item: true
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                                      onChange: (function (val) {
                                                          Curry._1(setFilterLabel, (function (param) {
                                                                  return val;
                                                                }));
                                                        }),
                                                      value: filterLabel,
                                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                                                      label: Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial
                                                    }),
                                                item: true
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                      label: Messages_Flash_Edit$DvmAdminFrontendFr.energyDropdown,
                                                      value: {
                                                        TAG: /* Single */0,
                                                        _0: {
                                                          value: filterEngineType,
                                                          onChange: (function (val) {
                                                              Curry._1(setFilterEngineType, (function (param) {
                                                                      return val;
                                                                    }));
                                                            })
                                                        }
                                                      },
                                                      options: {
                                                        TAG: /* Unlabeled */0,
                                                        _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(availableVersionsTyped, (function (x) {
                                                                            if (x.engineType.length > 0) {
                                                                              return x.engineType;
                                                                            }
                                                                            
                                                                          })))))
                                                      },
                                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                                    }),
                                                item: true
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                      label: Messages_Common$DvmAdminFrontendFr.phase,
                                                      value: {
                                                        TAG: /* Single */0,
                                                        _0: {
                                                          value: filterPhase,
                                                          onChange: (function (val) {
                                                              Curry._1(setFilterPhase, (function (param) {
                                                                      return val;
                                                                    }));
                                                            })
                                                        }
                                                      },
                                                      options: {
                                                        TAG: /* Unlabeled */0,
                                                        _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(availableVersionsTyped, (function (x) {
                                                                            if (x.modelPhase.length > 0) {
                                                                              return x.modelPhase;
                                                                            }
                                                                            
                                                                          })))))
                                                      },
                                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                                                    }),
                                                item: true
                                              }), React.createElement(Core.Grid, {
                                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                                      label: Messages_Common$DvmAdminFrontendFr.category,
                                                      value: {
                                                        TAG: /* Single */0,
                                                        _0: {
                                                          value: filterCategory,
                                                          onChange: (function (val) {
                                                              Curry._1(setFilterCategory, (function (param) {
                                                                      return val;
                                                                    }));
                                                            })
                                                        }
                                                      },
                                                      options: {
                                                        TAG: /* Labeled */1,
                                                        _0: mapCategories(undefined)
                                                      },
                                                      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                                    }),
                                                item: true
                                              })), React.createElement(Core.Grid, {
                                            children: React.createElement(App_TransferListFormInput$DvmAdminFrontendFr.make, tmp),
                                            item: true,
                                            sm: Grid$Mui.Sm[12]
                                          })),
                                  item: true,
                                  sm: Grid$Mui.Sm[12]
                                })),
                        borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                        borderLeft: 2,
                        paddingLeft: 1
                      }),
                  item: true
                }));
}

var Form;

var InputStyles;

var Types;

var Utils;

var make = Flash_Edit_NonFleet_New_Vehicle_Versions;

export {
  Form ,
  InputStyles ,
  Types ,
  Utils ,
  make ,
}
/* react Not a pure module */
