// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as FileSaver from "file-saver";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";
import * as Quota_PARC_Manage_Types$DvmAdminFrontendFr from "./Quota_PARC_Manage_Types.bs.js";

function savePARCQuota(operation, userManager, setResult, submissionCallbacks, selectedRole, id, quota) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var quotaFromOutput_orderType = quota.orderType;
  var quotaFromOutput_dealerId = quota.dealerId;
  var quotaFromOutput_modelId = quota.modelId;
  var quotaFromOutput_phase = quota.phase;
  var quotaFromOutput_engineType = quota.engineType;
  var quotaFromOutput_volumeInitial = operation ? undefined : quota.volume;
  var quotaFromOutput_volume = quota.volume;
  var quotaFromOutput_startDate = quota.startDate;
  var quotaFromOutput_endDate = quota.endDate;
  var quotaFromOutput = {
    id: id,
    orderType: quotaFromOutput_orderType,
    dealerId: quotaFromOutput_dealerId,
    modelId: quotaFromOutput_modelId,
    phase: quotaFromOutput_phase,
    engineType: quotaFromOutput_engineType,
    volumeInitial: quotaFromOutput_volumeInitial,
    volume: quotaFromOutput_volume,
    startDate: quotaFromOutput_startDate,
    endDate: quotaFromOutput_endDate
  };
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call(operation ? "PUT" : "POST", {
          TAG: /* Json */0,
          _0: Quota_PARC_Manage_Types$DvmAdminFrontendFr.quotaPARC_encode(quotaFromOutput)
        }, userManager, selectedRole, "/parc/quotas").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToSaveQuota */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchTemplate(userManager, setResult, selectedRole, alert) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithBlobResponse.call("GET", undefined, userManager, selectedRole, "/parc/quotas/excel/template").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          FileSaver.saveAs(result._0, "fichier_template.xlsx");
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchExcel(userManager, setResult, selectedRole, alert, filename, endpoint) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithBlobResponse.call("GET", undefined, userManager, selectedRole, "/" + endpoint + "/excel?limit=150000&offset=0").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          FileSaver.saveAs(result._0, filename + " ⚠️Restricted B.xlsx");
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteQuota(userManager, setResult, selectedRole, tableRow) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var quotaFromOutput_id = tableRow.id;
  var quotaFromOutput = {
    id: quotaFromOutput_id,
    orderType: undefined,
    dealerId: undefined,
    modelId: undefined,
    phase: undefined,
    engineType: undefined,
    volumeInitial: undefined,
    volume: undefined,
    startDate: undefined,
    endDate: undefined
  };
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", {
          TAG: /* Json */0,
          _0: Quota_PARC_Manage_Types$DvmAdminFrontendFr.quotaPARC_encode(quotaFromOutput)
        }, userManager, selectedRole, "/parc/quotas").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchDealerName(userManager, setResult, selectedRole, dealerId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/dealers/location/name?filter=dealerId=" + dealerId + ";agentCode=000").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var dealerName = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_Option.flatMap(Belt_List.head(dealerName), (function (x) {
                                        return x.paramTwo;
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

export {
  Form ,
  savePARCQuota ,
  fetchTemplate ,
  fetchExcel ,
  deleteQuota ,
  fetchDealerName ,
}
/* react Not a pure module */
