// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../common/components/App_Checkbox.bs.js";
import * as Category_Api$DvmAdminFrontendFr from "../Category_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../intl/messages/category/Messages_Category.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Category_Search_Form$DvmAdminFrontendFr from "./Category_Search_Form.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Category_Search_Filter(Props) {
  var setResult = Props.setResult;
  var setSelectedModel = Props.setSelectedModel;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersions = match[1];
  var modelVersions = match[0];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setWithClosedModels = match$4[1];
  var withClosedModels = match$4[0];
  var form = Category_Search_Form$DvmAdminFrontendFr.CategorySearchForm.useForm(Belt_Option.mapWithDefault(match$3[0].categoryFilter, Category_Search_Form$DvmAdminFrontendFr.initialInput, (function (filter) {
              return filter;
            })), (function (param, submissionCallbacks) {
          var model = param.model;
          Category_Api$DvmAdminFrontendFr.fetchCategories(submissionCallbacks, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                    page: 0,
                    rowsPerPage: 150000,
                    headers: /* [] */0,
                    filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                                  hd: model,
                                  tl: {
                                    hd: param.version,
                                    tl: /* [] */0
                                  }
                                }, (function (queryParam) {
                                    return queryParam;
                                  }))), ";", (function (queryParam) {
                            return queryParam;
                          })),
                    additionalQueryParams: /* [] */0
                  }), setResult, userManager, param.allVisible, selectedRole, withClosedModels);
          Curry._1(setSelectedModel, (function (param) {
                  return Belt_Array.get(model.split("="), 1);
                }));
        }));
  React.useEffect((function () {
          if (form.input.model.length > 0) {
            Curry._1(form.submit, undefined);
          }
          
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersions, userManager, selectedRole, withClosedModels, undefined, undefined);
        }), [withClosedModels]);
  var tmp = {
    labelParent: Messages_Common$DvmAdminFrontendFr.model,
    labelChild: Messages_Common$DvmAdminFrontendFr.version,
    valueParent: {
      TAG: /* Single */0,
      _0: {
        value: form.input.model,
        onChange: Curry._1(form.updateModel, (function (input, model) {
                return {
                        model: model,
                        version: "",
                        allVisible: input.allVisible
                      };
              }))
      }
    },
    valueChild: {
      TAG: /* Single */0,
      _0: {
        value: form.input.version,
        onChange: Curry._1(form.updateVersion, (function (input, version) {
                return {
                        model: input.model,
                        version: version,
                        allVisible: input.allVisible
                      };
              }))
      }
    },
    options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersions, /* [] */0),
    classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
    classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    requiredParent: /* OnlyLabel */1
  };
  if (form.modelResult !== undefined) {
    tmp.validationResultParent = Caml_option.valFromOption(form.modelResult);
  }
  if (form.versionResult !== undefined) {
    tmp.validationResultChild = Caml_option.valFromOption(form.versionResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreCategoryFilter */13,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions),
                            children: null
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, tmp),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.FormControlLabel, {
                                      classes: {
                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                      },
                                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                            checked: withClosedModels,
                                            onClick: (function ($$event) {
                                                Curry._1(setWithClosedModels, (function (param) {
                                                        return $$event.target.checked;
                                                      }));
                                              })
                                          }),
                                      label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                    }),
                                item: true,
                                style: {
                                  whiteSpace: "nowrap",
                                  width: "1px"
                                }
                              })),
                      container: true,
                      direction: "column",
                      item: true,
                      sm: Grid$Mui.Sm.auto,
                      style: {
                        width: "fit-content"
                      }
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.FormControlLabel, {
                            classes: {
                              label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                            },
                            control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                  checked: form.input.allVisible,
                                  onClick: (function ($$event) {
                                      Curry._2(form.updateAllVisible, (function (input, allVisible) {
                                              return {
                                                      model: input.model,
                                                      version: input.version,
                                                      allVisible: allVisible
                                                    };
                                            }), $$event.target.checked);
                                    })
                                }),
                            label: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.includeInactiveCategories)
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true,
                      sm: Grid$Mui.Sm.auto
                    })));
}

var Api;

var Form;

var InputStyles;

var make = Category_Search_Filter;

export {
  Api ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
