// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../../common/components/button/App_Clickable_Element.bs.js";
import * as Order_Detail_Dealer_Edits_ParcExit_Modal$DvmAdminFrontendFr from "./Order_Detail_Dealer_Edits_ParcExit_Modal.bs.js";

function isParcEditable(order, selectedRole, lastEndDateParc) {
  var match = Belt_Option.getWithDefault(order.detail.delivered, false);
  var match$1 = order.detail.parcStartDate;
  if (match && match$1 !== undefined && lastEndDateParc === undefined) {
    if (selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) {
      return true;
    } else {
      return User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole);
    }
  } else {
    return false;
  }
}

function Order_Detail_Dealer_Edits_ParcExit(Props) {
  var orderId = Props.orderId;
  var order = Props.order;
  var reloadPage = Props.reloadPage;
  var lastCorrectionValues = Props.lastCorrectionValues;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = React.useState(function () {
        return null;
      });
  var setModal = match$1[1];
  var lastEndDateParc = Belt_Option.mapWithDefault(lastCorrectionValues, order.detail.parcEndDate, (function (x) {
          return x.parcEndDate;
        }));
  var currentValueFormated = Belt_Option.getWithDefault(Belt_Option.mapWithDefault(lastEndDateParc, undefined, (function (x) {
              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x);
            })), "-");
  return React.createElement(React.Fragment, undefined, isParcEditable(order, match[0].selectedRole, lastEndDateParc) ? React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                    onClick: (function (param) {
                        Curry._1(setModal, (function (param) {
                                return React.createElement(Order_Detail_Dealer_Edits_ParcExit_Modal$DvmAdminFrontendFr.make, {
                                            onClose: (function (param) {
                                                Curry._1(setModal, (function (param) {
                                                        return null;
                                                      }));
                                              }),
                                            orderId: orderId,
                                            onOk: (function (param) {
                                                Curry._1(setModal, (function (param) {
                                                        Curry._1(reloadPage, undefined);
                                                        return null;
                                                      }));
                                              }),
                                            order: order
                                          });
                              }));
                      }),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          spacing: 1
                        }, React.createElement(Core.Grid, {
                              children: currentValueFormated,
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                    size: /* Regular */2,
                                    type_: /* Edit */60
                                  }),
                              item: true
                            }))
                  }) : currentValueFormated, match$1[0]);
}

var make = Order_Detail_Dealer_Edits_ParcExit;

export {
  isParcEditable ,
  make ,
}
/* react Not a pure module */
