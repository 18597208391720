// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Styles$DvmAdminFrontendFr from "../../../../common/App_Styles.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Flash_Detail_Styles$DvmAdminFrontendFr from "./Flash_Detail_Styles.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";

function Flash_Detail_AppBar(Props) {
  var flash = Props.flash;
  var flashType = Props.flashType;
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.AppBar, {
              children: React.createElement(Core.Grid, {
                    children: null,
                    className: Flash_Detail_Styles$DvmAdminFrontendFr.titleBox,
                    container: true,
                    justifyContent: "space-evenly",
                    spacing: 1
                  }, flashType ? null : React.createElement(Core.Grid, {
                          children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Messages_Common$DvmAdminFrontendFr.budget,
                                children: Belt_Option.mapWithDefault(flash.budget, "-", (function (x) {
                                        return x.join(",");
                                      }))
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Detail$DvmAdminFrontendFr.flashType,
                              children: flash.actionType
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Detail$DvmAdminFrontendFr.flashCode,
                              children: flash.actionId
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Detail$DvmAdminFrontendFr.flashLabel,
                              children: Belt_Option.getWithDefault(flash.actionLabel, "-")
                            }),
                        item: true
                      }), flash.actionType === "PERF" ? null : React.createElement(Core.Grid, {
                          children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Belt_Option.mapWithDefault(flash.amount, Messages_Common$DvmAdminFrontendFr.amount, (function (x) {
                                        var match = x.numberType;
                                        if (match !== 0) {
                                          return Messages_Common$DvmAdminFrontendFr.amountHT;
                                        } else {
                                          return Messages_Common$DvmAdminFrontendFr.amount;
                                        }
                                      })),
                                children: Belt_Option.mapWithDefault(flash.amount, "-", (function (x) {
                                        var valueDisplay = Belt_Option.flatMap(x.value, (function (x) {
                                                if (Belt_Option.getWithDefault(flash.hasCustomAmount, false)) {
                                                  return ;
                                                } else {
                                                  return x;
                                                }
                                              }));
                                        var match = x.numberType;
                                        switch (match) {
                                          case /* PERCENT */0 :
                                              return Belt_Option.mapWithDefault(valueDisplay, "-", (function (x) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x);
                                                          })) + (
                                                      Belt_Option.mapWithDefault(flash.orderType, false, (function (x) {
                                                              return Belt_Array.some(x, (function (x) {
                                                                            return x === "VE";
                                                                          }));
                                                            })) ? Belt_Option.mapWithDefault(flash.priceType, "", (function (x) {
                                                                var tmp;
                                                                switch (x) {
                                                                  case "NETPVR" :
                                                                      tmp = "PVR";
                                                                      break;
                                                                  case "PVC_HT" :
                                                                      tmp = "PVC";
                                                                      break;
                                                                  default:
                                                                    tmp = "";
                                                                }
                                                                return " (" + tmp + ")";
                                                              })) : ""
                                                    );
                                          case /* EURO */1 :
                                              return Belt_Option.mapWithDefault(valueDisplay, "-", (function (eta) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                                          }));
                                          case /* NOTSET */2 :
                                              return "";
                                          
                                        }
                                      }))
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Detail$DvmAdminFrontendFr.cumulable,
                              children: Belt_Option.mapWithDefault(flash.cumulative, "", (function (x) {
                                      if (x) {
                                        return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.yes);
                                      } else {
                                        return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.no);
                                      }
                                    }))
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.orderType,
                              children: Belt_Option.mapWithDefault(flash.orderType, "-", (function (x) {
                                      return x.join(",");
                                    }))
                            }),
                        item: true
                      })),
              className: App_Styles$DvmAdminFrontendFr.stickyBar
            });
}

var make = Flash_Detail_AppBar;

export {
  make ,
}
/* react Not a pure module */
