// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

var Fields_orderType = [
  "orderType",
  "Type_de_Commande"
];

var Fields_actionID = [
  "actionID",
  "ID_Action"
];

var Fields_actionLabel = [
  "actionLabel",
  "Libelle_action"
];

var Fields_bonusAmountEuro = [
  "bonusAmountEuro",
  ""
];

var Fields_bonusAmountPercent = [
  "bonusAmountPercent",
  ""
];

var Fields_actionType = [
  "actionType",
  "Type_Action"
];

var Fields_budgetType = [
  "budgetType",
  ""
];

var Fields_startDate = [
  "startDate",
  "Date_Debut"
];

var Fields_endDate = [
  "endDate",
  "Date_Fin"
];

var Fields_creationDate = [
  "creationDate",
  ""
];

var Fields_modificationDate = [
  "modificationDate",
  ""
];

var Fields_entryDateParcStart = [
  "PARCPeriodEntryStartDate",
  "Date_de_debut_de_periode_entree_PARC"
];

var Fields_entryDateParcEnd = [
  "PARCPeriodEntryEndDate",
  "Date_de_fin_de_periode_entree_PARC"
];

var Fields_exitDateParcStart = [
  "PARCPeriodExitStartDate",
  "Date_de_debut_de_periode_sortie_PARC"
];

var Fields_exitDateParcEnd = [
  "PARCPeriodExitEndDate",
  "Date_de_fin_de_periode_sortie_PARC"
];

var Fields_registrationDateStart = [
  "RegistrationStartDate",
  "Date_Debut_Immatriculation"
];

var Fields_registrationDateEnd = [
  "RegistrationEndDate",
  "Date_Fin_Immatriculation"
];

var Fields_deliveryDateStart = [
  "DeliveryStartDate",
  "Date_Debut_Livraison"
];

var Fields_deliveryDateEnd = [
  "DeliveryEndDate",
  "Date_Fin_Livraison"
];

var Fields_detentionMin = [
  "detentionMin",
  "Duree_detention_VE_MIN"
];

var Fields_detentionMax = [
  "detentionMax",
  "Duree_detention_VE_MAX"
];

var Fields_madaDateStart = [
  "MADAStartDate",
  "Date_Debut_MADA"
];

var Fields_madaDateEnd = [
  "MADAEndDate",
  "Date_Fin_MADA"
];

var Fields_madcDateStart = [
  "MADCStartDate",
  "Date_Debut_MADC"
];

var Fields_madcDateEnd = [
  "MADCEndDate",
  "Date_Fin_MADC"
];

var Fields = {
  orderType: Fields_orderType,
  actionID: Fields_actionID,
  actionLabel: Fields_actionLabel,
  bonusAmountEuro: Fields_bonusAmountEuro,
  bonusAmountPercent: Fields_bonusAmountPercent,
  actionType: Fields_actionType,
  budgetType: Fields_budgetType,
  startDate: Fields_startDate,
  endDate: Fields_endDate,
  creationDate: Fields_creationDate,
  modificationDate: Fields_modificationDate,
  entryDateParcStart: Fields_entryDateParcStart,
  entryDateParcEnd: Fields_entryDateParcEnd,
  exitDateParcStart: Fields_exitDateParcStart,
  exitDateParcEnd: Fields_exitDateParcEnd,
  registrationDateStart: Fields_registrationDateStart,
  registrationDateEnd: Fields_registrationDateEnd,
  deliveryDateStart: Fields_deliveryDateStart,
  deliveryDateEnd: Fields_deliveryDateEnd,
  detentionMin: Fields_detentionMin,
  detentionMax: Fields_detentionMax,
  madaDateStart: Fields_madaDateStart,
  madaDateEnd: Fields_madaDateEnd,
  madcDateStart: Fields_madcDateStart,
  madcDateEnd: Fields_madcDateEnd,
  brand: "brand",
  modelID: "modelID",
  phase: "phase"
};

function flashRow_encode(v) {
  return Js_dict.fromArray([
              [
                "actionID",
                Decco.stringToJson(v.actionID)
              ],
              [
                "actionLabel",
                Decco.stringToJson(v.actionLabel)
              ],
              [
                "budgetType",
                Decco.optionToJson(Decco.stringToJson, v.budgetType)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "bonusAmountEuro",
                Decco.optionToJson(Decco.floatToJson, v.bonusAmountEuro)
              ],
              [
                "bonusAmountPercent",
                Decco.optionToJson(Decco.floatToJson, v.bonusAmountPercent)
              ],
              [
                "actionType",
                Decco.optionToJson(Decco.stringToJson, v.actionType)
              ],
              [
                "calcMethod",
                Decco.optionToJson(Decco.stringToJson, v.calcMethod)
              ],
              [
                "bonusValue",
                Decco.optionToJson(Decco.floatToJson, v.bonusValue)
              ],
              [
                "creationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.creationDate)
              ],
              [
                "modificationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.modificationDate)
              ]
            ]);
}

function flashRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionID"), null));
  if (actionID.TAG === /* Ok */0) {
    var actionLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionLabel"), null));
    if (actionLabel.TAG === /* Ok */0) {
      var budgetType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetType"), null));
      if (budgetType.TAG === /* Ok */0) {
        var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            var bonusAmountEuro = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonusAmountEuro"), null));
            if (bonusAmountEuro.TAG === /* Ok */0) {
              var bonusAmountPercent = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonusAmountPercent"), null));
              if (bonusAmountPercent.TAG === /* Ok */0) {
                var actionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionType"), null));
                if (actionType.TAG === /* Ok */0) {
                  var calcMethod = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "calcMethod"), null));
                  if (calcMethod.TAG === /* Ok */0) {
                    var bonusValue = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonusValue"), null));
                    if (bonusValue.TAG === /* Ok */0) {
                      var creationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "creationDate"), null));
                      if (creationDate.TAG === /* Ok */0) {
                        var modificationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modificationDate"), null));
                        if (modificationDate.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Ok */0,
                                  _0: {
                                    actionID: actionID._0,
                                    actionLabel: actionLabel._0,
                                    budgetType: budgetType._0,
                                    startDate: startDate._0,
                                    endDate: endDate._0,
                                    bonusAmountEuro: bonusAmountEuro._0,
                                    bonusAmountPercent: bonusAmountPercent._0,
                                    actionType: actionType._0,
                                    calcMethod: calcMethod._0,
                                    bonusValue: bonusValue._0,
                                    creationDate: creationDate._0,
                                    modificationDate: modificationDate._0
                                  }
                                };
                        }
                        var e = modificationDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".modificationDate" + e.path,
                                  message: e.message,
                                  value: e.value
                                }
                              };
                      }
                      var e$1 = creationDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".creationDate" + e$1.path,
                                message: e$1.message,
                                value: e$1.value
                              }
                            };
                    }
                    var e$2 = bonusValue._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".bonusValue" + e$2.path,
                              message: e$2.message,
                              value: e$2.value
                            }
                          };
                  }
                  var e$3 = calcMethod._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".calcMethod" + e$3.path,
                            message: e$3.message,
                            value: e$3.value
                          }
                        };
                }
                var e$4 = actionType._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".actionType" + e$4.path,
                          message: e$4.message,
                          value: e$4.value
                        }
                      };
              }
              var e$5 = bonusAmountPercent._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".bonusAmountPercent" + e$5.path,
                        message: e$5.message,
                        value: e$5.value
                      }
                    };
            }
            var e$6 = bonusAmountEuro._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".bonusAmountEuro" + e$6.path,
                      message: e$6.message,
                      value: e$6.value
                    }
                  };
          }
          var e$7 = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
        }
        var e$8 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$8.path,
                  message: e$8.message,
                  value: e$8.value
                }
              };
      }
      var e$9 = budgetType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".budgetType" + e$9.path,
                message: e$9.message,
                value: e$9.value
              }
            };
    }
    var e$10 = actionLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionLabel" + e$10.path,
              message: e$10.message,
              value: e$10.value
            }
          };
  }
  var e$11 = actionID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionID" + e$11.path,
            message: e$11.message,
            value: e$11.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(flashRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(flashRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function flashRowList_encode(v) {
  return Decco.listToJson(flashRow_encode, v);
}

function flashRowList_decode(v) {
  return Decco.listFromJson(flashRow_decode, v);
}

export {
  Fields ,
  flashRow_encode ,
  flashRow_decode ,
  searchResult_encode ,
  searchResult_decode ,
  flashRowList_encode ,
  flashRowList_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
