// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_TransferListFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TransferListFormInput.bs.js";
import * as Flash_Edit_Fleet_Version_Add_Forms$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Add_Forms.bs.js";
import * as Flash_Edit_Fleet_Version_Modal_Api$DvmAdminFrontendFr from "./Flash_Edit_Fleet_Version_Modal_Api.bs.js";

function makeLabelValuePairList(list) {
  return Belt_List.map(list, (function (x) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: "" + x.model + " - " + x.version + "" + Belt_Option.mapWithDefault(x.versionName, "", (function (x) {
                                  if (x.length > 0) {
                                    return " (" + x + ")";
                                  } else {
                                    return "";
                                  }
                                })) + ""
                        },
                        value: x.modelVersionID
                      };
              }));
}

function Flash_Edit_Fleet_Version_Add_Selection(Props) {
  var filteredOptions = Props.filteredOptions;
  var flash = Props.flash;
  var setSaveResult = Props.setSaveResult;
  var duplicateVersions = Props.duplicateVersions;
  var actionDistinctVersions = Props.actionDistinctVersions;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var actionVersionsLabeled = makeLabelValuePairList(actionDistinctVersions);
  var form = Flash_Edit_Fleet_Version_Add_Forms$DvmAdminFrontendFr.VersionsForm.useForm(Flash_Edit_Fleet_Version_Add_Forms$DvmAdminFrontendFr.initialStateVersions, (function (output, submissionCallbacks) {
          Flash_Edit_Fleet_Version_Modal_Api$DvmAdminFrontendFr.editMultipleVersions(/* Create */0, userManager, setSaveResult, submissionCallbacks, flash.actionID, selectedRole, Belt_List.map(output.versions, (function (x) {
                      var someRow = Belt_List.getBy(actionDistinctVersions, (function (y) {
                              return y.modelVersionID === x.value;
                            }));
                      return {
                              actionID: flash.actionID,
                              modelVersionID: x.value,
                              oldStartDate: undefined,
                              startDate: Belt_Option.mapWithDefault(someRow, undefined, (function (x) {
                                      return x.startDate;
                                    })),
                              endDate: Belt_Option.mapWithDefault(someRow, undefined, (function (x) {
                                      return x.endDate;
                                    })),
                              maxBonus: undefined
                            };
                    })));
        }));
  React.useEffect((function () {
          Curry._2(form.updateVersions, (function (param, versions) {
                  return {
                          versions: versions
                        };
                }), Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_Array.map(Belt_List.toArray(Belt_List.concat(Belt_List.concat(form.input.versions, makeLabelValuePairList(duplicateVersions)), actionVersionsLabeled)), (function (x) {
                                  return [
                                          x.value,
                                          x.label
                                        ];
                                })))), (function (param) {
                      return {
                              label: param[1],
                              value: param[0]
                            };
                    })));
        }), [duplicateVersions]);
  var tmp = {
    values: form.input.versions,
    options: makeLabelValuePairList(filteredOptions),
    onChange: Curry._1(form.updateVersions, (function (param, versions) {
            return {
                    versions: versions
                  };
          })),
    boxHeight: 260,
    boxLabels: {
      left: {
        TAG: /* String */3,
        _0: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.transferListOptions, {
              count: Belt_List.length(Belt_List.keep(filteredOptions, (function (element) {
                          return !Belt_List.some(form.input.versions, (function (x) {
                                        return x.value === element.modelVersionID;
                                      }));
                        })))
            })
      },
      right: {
        TAG: /* String */3,
        _0: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.transferListSelected, {
              count: Belt_List.length(form.input.versions)
            })
      }
    },
    search: false
  };
  if (form.versionsResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.versionsResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      Curry._1(form.submit, undefined);
                    })
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_TransferListFormInput$DvmAdminFrontendFr.make, tmp),
                          item: true,
                          sm: Grid$Mui.Sm[12]
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                size: "medium",
                                type_: "submit"
                              }),
                          container: true,
                          item: true,
                          justifyContent: "flex-end"
                        }))));
}

var Form;

var Api;

var Types;

var make = Flash_Edit_Fleet_Version_Add_Selection;

export {
  Form ,
  Api ,
  Types ,
  makeLabelValuePairList ,
  make ,
}
/* react Not a pure module */
