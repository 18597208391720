// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../common/App_Typography.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../common/components/table/App_Table_Result.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as Bonus_Anomalies_Search_Api$DvmAdminFrontendFr from "./Bonus_Anomalies_Search_Api.bs.js";
import * as Messages_Bonuses_Anomalies$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_Anomalies.bs.js";
import * as Bonus_Anomalies_Search_Table$DvmAdminFrontendFr from "./Bonus_Anomalies_Search_Table.bs.js";
import * as Bonus_Anomalies_Search_Filter$DvmAdminFrontendFr from "./Bonus_Anomalies_Search_Filter.bs.js";

function Bonus_Anomalies_Search_Page(Props) {
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Bonus_Anomalies_Search_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$1[1];
  var fetchRequest = match$1[0];
  var alert = ReactAlert.useAlert();
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Bonus_Anomalies_Search_Api$DvmAdminFrontendFr.fetchCompanySearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var tmp;
  if (typeof tablePage === "number") {
    tmp = tablePage === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
  } else if (tablePage.TAG === /* Ok */0) {
    tmp = React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
          number: tablePage._0.total,
          message: Messages_Bonuses_Anomalies$DvmAdminFrontendFr.resultInfo
        });
  } else {
    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
              message: {
                TAG: /* RestError */2,
                _0: tablePage._0
              }
            }));
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Bonuses_Anomalies$DvmAdminFrontendFr.title),
                  variant: "h3"
                }), React.createElement(Bonus_Anomalies_Search_Filter$DvmAdminFrontendFr.make, {
                  fetchData: fetchData,
                  fetchRequest: fetchRequest,
                  setParamsList: match$4[1]
                }), React.createElement(Core.Box, {
                  children: null
                }, tmp, React.createElement(Bonus_Anomalies_Search_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      paramsList: match$4[0]
                    }), tablePage === 0 ? React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "600",
                          paddingTop: "100px",
                          textAlign: "center"
                        },
                        children: intl.formatMessage(Messages_Bonuses_Anomalies$DvmAdminFrontendFr.messageBeforeSearch),
                        variant: "bodyBig"
                      }) : null));
}

var Api;

var Table;

var Filter;

var make = Bonus_Anomalies_Search_Page;

export {
  Api ,
  Table ,
  Filter ,
  make ,
}
/* react Not a pure module */
