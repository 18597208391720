// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_TransferListFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TransferListFormInput.bs.js";
import * as Flash_Edit_NonCumulable_Api$DvmAdminFrontendFr from "./Flash_Edit_NonCumulable_Api.bs.js";
import * as Flash_Edit_NonCumulable_Form$DvmAdminFrontendFr from "./Flash_Edit_NonCumulable_Form.bs.js";

function Flash_Edit_NonCumulable(Props) {
  var flashId = Props.flashId;
  var flash = Props.flash;
  var modelVersions = Props.modelVersions;
  var includedModels = Props.includedModels;
  var flashType = Props.flashType;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setNonCumulablesList = match$3[1];
  var nonCumulablesList = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setActions = match$4[1];
  var form = Flash_Edit_NonCumulable_Form$DvmAdminFrontendFr.NonCumulableForm.useForm(Flash_Edit_NonCumulable_Form$DvmAdminFrontendFr.initialState, (function (output, submissionCallbacks) {
          Flash_Edit_NonCumulable_Api$DvmAdminFrontendFr.saveCumulables(/* Update */1, userManager, setSaveResult, submissionCallbacks, selectedRole, {
                parent: flashId,
                child: Belt_List.map(output.nonCumulables, (function (x) {
                        return x.value;
                      }))
              });
        }));
  React.useEffect((function () {
          var __x = Belt_List.map(nonCumulablesList, (function (x) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: "" + x.paramOne + " (" + Belt_Option.getWithDefault(x.paramTwo, "") + ")"
                          },
                          value: x.paramOne
                        };
                }));
          Curry._2(form.updateNonCumulables, (function (param, nonCumulables) {
                  return {
                          nonCumulables: nonCumulables
                        };
                }), __x);
        }), [nonCumulablesList]);
  React.useEffect((function () {
          Flash_Edit_NonCumulable_Api$DvmAdminFrontendFr.getCumulables(alert, userManager, setNonCumulablesList, flashId, selectedRole);
        }), []);
  React.useEffect((function () {
          if (Belt_Option.isSome(flash.startDate) || Belt_Option.isSome(flash.endDate)) {
            Belt_Option.mapWithDefault(flashType, undefined, (function (flashType) {
                    var models = flashType ? App_Types_Result$DvmAdminFrontendFr.mapWithDefault(includedModels, /* [] */0, (function (includedModels) {
                              return Belt_Option.mapWithDefault(includedModels.modelPhases, /* [] */0, (function (x) {
                                            return Belt_List.map(x, (function (x) {
                                                          return x.one;
                                                        }));
                                          }));
                            })) : App_Types_Result$DvmAdminFrontendFr.mapWithDefault(modelVersions, /* [] */0, (function (modelVersions) {
                              return Belt_List.map(modelVersions, (function (x) {
                                            return x.parent;
                                          }));
                            }));
                    if (Belt_List.length(models) > 0) {
                      return Flash_Edit_NonCumulable_Api$DvmAdminFrontendFr.getAvailableCumulablesOld(alert, userManager, setActions, models, Belt_Option.mapWithDefault(flash.startDate, "", (function (prim) {
                                        return prim.toISOString();
                                      })), Belt_Option.mapWithDefault(flash.endDate, "", (function (prim) {
                                        return prim.toISOString();
                                      })), selectedRole, flashType);
                    }
                    
                  }));
          }
          
        }), [
        modelVersions,
        includedModels
      ]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Flash_Edit_NonCumulable_Api$DvmAdminFrontendFr.getCumulables(alert, userManager, setNonCumulablesList, flashId, selectedRole);
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var tmp = {
    label: {
      TAG: /* Message */0,
      _0: Messages_Flash_Edit$DvmAdminFrontendFr.nonCumulableTitle
    },
    values: form.input.nonCumulables,
    options: Belt_List.map(Belt_List.keep(match$4[0], (function (x) {
                return x.paramOne !== flashId;
              })), (function (x) {
            return {
                    label: {
                      TAG: /* String */3,
                      _0: "" + x.paramOne + " (" + Belt_Option.getWithDefault(x.paramTwo, "") + ")"
                    },
                    value: x.paramOne
                  };
          })),
    onChange: Curry._1(form.updateNonCumulables, (function (param, nonCumulables) {
            return {
                    nonCumulables: nonCumulables
                  };
          })),
    boxHeight: 230,
    boxLabels: {
      left: {
        TAG: /* Message */0,
        _0: Messages_Common$DvmAdminFrontendFr.options
      },
      right: {
        TAG: /* Message */0,
        _0: Messages_Flash_Edit$DvmAdminFrontendFr.selectedActions
      }
    },
    checkAll: false
  };
  if (form.nonCumulablesResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.nonCumulablesResult);
  }
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(modelVersions) || App_Types_Result$DvmAdminFrontendFr.isPending(includedModels),
              children: React.createElement(Core.Paper, {
                    children: React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              Curry._1(form.submit, undefined);
                            })
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              spacing: 2,
                              style: {
                                padding: "40px"
                              }
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_TransferListFormInput$DvmAdminFrontendFr.make, tmp),
                                  item: true,
                                  sm: Grid$Mui.Sm[12]
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        color: "secondary",
                                        size: "medium",
                                        type_: "submit"
                                      }),
                                  container: true,
                                  item: true,
                                  justifyContent: "center"
                                }))),
                    elevation: 0,
                    square: true,
                    style: {
                      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1
                    }
                  })
            });
}

var Form;

var Api;

var Types;

var make = Flash_Edit_NonCumulable;

export {
  Form ,
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
