// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as RestApi$DvmAdminFrontendFr from "../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "./utils/Utils_Alert.bs.js";
import * as App_Types_Json$DvmAdminFrontendFr from "./types/App_Types_Json.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "./types/App_Types_Input.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../intl/messages/Messages_Error_Inputs.bs.js";

function getModelVersions(userManager, selectedRole, showClosed, filter, param) {
  return RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.parentChildList_decode, undefined, userManager, selectedRole, "/dropdown/modelversion/withClosedModels/" + (
              Belt_Option.getWithDefault(showClosed, true) ? "true" : "false"
            ) + Belt_Option.mapWithDefault(filter, "", (function (filter) {
                    return "?filter=" + filter + "";
                  })));
}

function getMotorisations(userManager, selectedRole) {
  return RestApi$DvmAdminFrontendFr.call("GET", App_Types_Json$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/motorisation");
}

var todayDate = new Date(Date.now()).toISOString();

var categoryTodayString = "startDate=%5B%5C%2C" + todayDate + "%5D;endDate=%5B" + todayDate + "%5C%2C%5D";

function getStringFromTo(dates) {
  var fromStr = Belt_Option.mapWithDefault(dates.from, undefined, (function (x) {
          return x.toISOString();
        }));
  var toStr = Belt_Option.mapWithDefault(dates.to, undefined, (function (x) {
          return x.toISOString();
        }));
  if (fromStr !== undefined) {
    if (toStr !== undefined) {
      return "startDate=%5B" + fromStr + "%5C%2C" + toStr + "%5D;endDate=%5B" + fromStr + "%5C%2C" + toStr + "%5D";
    } else {
      return "startDate=%5B" + fromStr + "%5C%2C%5D";
    }
  } else if (toStr !== undefined) {
    return "endDate=%5B%5C%2C" + toStr + "%5D";
  } else {
    return "";
  }
}

function getAllCategories(userManager, validWhen, selectedRole, model) {
  return RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.DropdownCategories.categoryList_decode, undefined, userManager, selectedRole, "/dropdown/category/all" + (
              typeof validWhen === "number" ? (
                  validWhen !== 0 ? "" + Belt_Option.mapWithDefault(model, "", (function (x) {
                            return "?filter=modelID=" + x + "";
                          })) : "?filter=" + categoryTodayString + "" + Belt_Option.mapWithDefault(model, "", (function (x) {
                            return ";modelID=" + x + "";
                          }))
                ) : "?filter=" + getStringFromTo(validWhen._0) + "" + Belt_Option.mapWithDefault(model, "", (function (x) {
                        return ";modelID=" + x + "";
                      }))
            ));
}

function fetchAllCategories(userManager, setResult, validWhen, selectedRole, model) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  getAllCategories(userManager, validWhen, selectedRole, model).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var categories = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: categories
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModelVersions(setResult, userManager, selectedRole, showClosed, filter, param) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  getModelVersions(userManager, selectedRole, showClosed, filter, undefined).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelVersions = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelVersions
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchMotorisations(setResult, userManager, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  getMotorisations(userManager, selectedRole).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var motorisations = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: motorisations
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModels(setResult, userManager, selectedRole, showClosed, filter, param) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  getModelVersions(userManager, selectedRole, showClosed, filter, undefined).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelVersions = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_List.map(modelVersions, (function (param) {
                                        return param.parent;
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchVersions(setResult, userManager, model, selectedRole, showClosed, filter, param) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  getModelVersions(userManager, selectedRole, showClosed, filter, undefined).then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelVersions = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map(Belt_List.keep(modelVersions, (function (x) {
                                                        return Belt_Option.mapWithDefault(model, true, (function (model) {
                                                                      return x.parent === model;
                                                                    }));
                                                      })), (function (param) {
                                                    return param.child;
                                                  })))))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchSirenSimple(userManager, setSirenName, selectedRole, siren) {
  Curry._1(setSirenName, (function (param) {
          return /* Pending */1;
        }));
  return RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.siren_decode, undefined, userManager, selectedRole, "/contract/siren?siren=" + siren + "").then(function (result) {
              if (result.TAG === /* OkResult */0) {
                var sirenName = result._0;
                Curry._1(setSirenName, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: sirenName.response
                              };
                      }));
                return {
                        TAG: /* Ok */0,
                        _0: siren
                      };
              }
              var error = result._0;
              Curry._1(setSirenName, (function (param) {
                      return {
                              TAG: /* Error */1,
                              _0: error
                            };
                    }));
              return {
                      TAG: /* Error */1,
                      _0: Messages_Error_Inputs$DvmAdminFrontendFr.sirenNotFound
                    };
            });
}

function queryForCompaniesSelect(userManager, setCompanies, filter, companyType, selectedRole) {
  Curry._1(setCompanies, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, companyType === "siret" ? "/dropdown/sirets?filter=SIRET%5E" + filter + "" : "/dropdown/sirens?filter=SIREN%5E" + filter + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var locations = result._0;
          return Curry._1(setCompanies, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: locations
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setCompanies, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForDRAndDealers(alert, userManager, setDrAndDealers, showHistory, selectedRole) {
  Curry._1(setDrAndDealers, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.parentChildList_decode, undefined, userManager, selectedRole, "/dropdown/drAndDealers/" + (
          showHistory ? "true" : "false"
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var dealers = result._0;
          return Curry._1(setDrAndDealers, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: dealers
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setDrAndDealers, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchBudgets(userManager, setBudgets, selectedRole) {
  Curry._1(setBudgets, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/budgets").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var budgets = result._0;
          return Curry._1(setBudgets, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: budgets
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setBudgets, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchActionTypes(userManager, setActionTypes, alert, selectedRole, whatType) {
  Curry._1(setActionTypes, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, whatType === "nonfleet" ? "/dropdown/actiontypes/modular" : "/dropdown/actiontypes").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var actionTypes = result._0;
          return Curry._1(setActionTypes, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: actionTypes
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setActionTypes, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchDealerGroups(userManager, setDealerGroups, alert, selectedRole) {
  Curry._1(setDealerGroups, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/dealer-groups").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var dealerGroups = result._0;
          return Curry._1(setDealerGroups, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: dealerGroups
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setDealerGroups, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchCustomerName(userManager, setCustomerName, alert, selectedRole, filter) {
  Curry._1(setCustomerName, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/customer-name?filter=" + filter + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var customerName = result._0;
          return Curry._1(setCustomerName, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: customerName
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setCustomerName, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchSalesmanNames(userManager, setSalesmanNames, alert, selectedRole) {
  Curry._1(setSalesmanNames, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/salesman-names").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var salesman = result._0;
          return Curry._1(setSalesmanNames, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: salesman
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setSalesmanNames, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchAgentCodes(userManager, setAgentCodes, alert, selectedRole) {
  Curry._1(setAgentCodes, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/agent-codes").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var agentCodes = result._0;
          return Curry._1(setAgentCodes, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: agentCodes
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setAgentCodes, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchFleetManagers(userManager, setFleetManagers, alert, selectedRole) {
  Curry._1(setFleetManagers, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/fleet-managers").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var fleetManagers = result._0;
          return Curry._1(setFleetManagers, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: fleetManagers
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setFleetManagers, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchLldManagers(userManager, setLldManagers, alert, selectedRole) {
  Curry._1(setLldManagers, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/lld-managers").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var lldManagers = result._0;
          return Curry._1(setLldManagers, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: lldManagers
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setLldManagers, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchContracts(userManager, setContracts, alert, selectedRole) {
  Curry._1(setContracts, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/dropdown/contracts").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var contracts = result._0;
          return Curry._1(setContracts, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: contracts
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setContracts, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForAcquisitions(alert, userManager, setAcquisitionOptions, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/acquisitiontypes").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var acquisitions = result._0;
          return Curry._1(setAcquisitionOptions, (function (param) {
                        return acquisitions;
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setAcquisitionOptions, (function (param) {
                      return /* [] */0;
                    }));
      });
}

function fetchAllModelPhases(userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/dropdown/model/phases").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var phases = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: phases
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForDealerNames(alert, userManager, setResult, selectedRole, onlyAgentZero, drs, localNames) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var filterAgent = onlyAgentZero ? "agentCode=000" : "";
  var filterDr = Belt_List.length(drs) > 0 ? "dr=" + encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(drs), "\\,", (function (x) {
                return x;
              }))) : "";
  var filterLocalNames = Belt_List.length(localNames) > 0 ? "locationCommonName=" + encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(localNames), "\\,", (function (x) {
                return x;
              }))) : "";
  var filters = Belt_Array.joinWith(Belt_Array.keep([
            filterAgent,
            filterDr,
            filterLocalNames
          ], (function (x) {
              return x.length > 0;
            })), ";", (function (x) {
          return x;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.twoParamList_decode, undefined, userManager, selectedRole, "/dropdown/dealers/names" + (
          filters.length > 0 ? "?filter=" + filters : ""
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var dealers = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: dealers
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForDrSelect(alert, userManager, setResult, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/drs").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var dealers = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: dealers
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForLocalDealerNames(alert, userManager, setResult, selectedRole, drs) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var filterDr = Belt_List.length(drs) > 0 ? "dr=" + encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(drs), "\\,", (function (x) {
                return x;
              }))) : "";
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/dropdown/dealers/local/names" + (
          filterDr.length > 0 ? "?filter=" + filterDr : ""
        ) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var dealers = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: dealers
                              };
                      }));
        }
        var error = result._0;
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: error
                  }
                }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  getModelVersions ,
  getMotorisations ,
  todayDate ,
  categoryTodayString ,
  getStringFromTo ,
  getAllCategories ,
  fetchAllCategories ,
  fetchModelVersions ,
  fetchMotorisations ,
  fetchModels ,
  fetchVersions ,
  fetchSirenSimple ,
  queryForCompaniesSelect ,
  queryForDRAndDealers ,
  fetchBudgets ,
  fetchActionTypes ,
  fetchDealerGroups ,
  fetchCustomerName ,
  fetchSalesmanNames ,
  fetchAgentCodes ,
  fetchFleetManagers ,
  fetchLldManagers ,
  fetchContracts ,
  queryForAcquisitions ,
  fetchAllModelPhases ,
  queryForDealerNames ,
  queryForDrSelect ,
  queryForLocalDealerNames ,
}
/* todayDate Not a pure module */
