// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as EnvVar$DvmAdminFrontendFr from "../../../bindings/global/EnvVar.bs.js";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_Status.bs.js";
import * as Order_Search_Api$DvmAdminFrontendFr from "./Order_Search_Api.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../../intl/messages/orders/Messages_Orders_Search.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_DoubleSelectFormInput.bs.js";

function Order_Search_Filters_Inputs(Props) {
  var setShowHistory = Props.setShowHistory;
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$2[1];
  var drAndDealers = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setStatuses = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$4[1];
  var match$5 = React.useState(function () {
        return "";
      });
  var setDealerSearchInput = match$5[1];
  var refreshDealers = function (param) {
    App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, form.input.showHistoryDealer, selectedRole);
  };
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(drAndDealers, undefined, (function (drAndDealers) {
                  var drKeep = Belt_List.keep(form.input.dr, (function (x) {
                          return Belt_List.some(drAndDealers, (function (dr) {
                                        return dr.parent === x;
                                      }));
                        }));
                  var dealerKeep = Belt_List.keep(form.input.dealerId, (function (x) {
                          return Belt_List.some(Belt_List.flatten(Belt_List.map(drAndDealers, (function (x) {
                                                return x.child;
                                              }))), (function (dr) {
                                        return dr === x;
                                      }));
                        }));
                  Curry._2(form.updateDealerId, (function (input, dealerId) {
                          return {
                                  dr: input.dr,
                                  dealerId: dealerId,
                                  customerOrderNumber: input.customerOrderNumber,
                                  orderNumberCar: input.orderNumberCar,
                                  vin: input.vin,
                                  newCarRegistrationID: input.newCarRegistrationID,
                                  budgetType: input.budgetType,
                                  budgetStatus: input.budgetStatus,
                                  showHistoryDealer: input.showHistoryDealer,
                                  showArchived: input.showArchived,
                                  orderType: input.orderType,
                                  orderStatus: input.orderStatus,
                                  usedCarStatus: input.usedCarStatus
                                };
                        }), dealerKeep);
                  Curry._2(form.updateDr, (function (input, dr) {
                          return {
                                  dr: dr,
                                  dealerId: input.dealerId,
                                  customerOrderNumber: input.customerOrderNumber,
                                  orderNumberCar: input.orderNumberCar,
                                  vin: input.vin,
                                  newCarRegistrationID: input.newCarRegistrationID,
                                  budgetType: input.budgetType,
                                  budgetStatus: input.budgetStatus,
                                  showHistoryDealer: input.showHistoryDealer,
                                  showArchived: input.showArchived,
                                  orderType: input.orderType,
                                  orderStatus: input.orderStatus,
                                  usedCarStatus: input.usedCarStatus
                                };
                        }), drKeep);
                }));
        }), [drAndDealers]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          refreshDealers(undefined);
          App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, false, selectedRole);
          Order_Search_Api$DvmAdminFrontendFr.queryForStatuses(alert, userManager, setStatuses, selectedRole);
        }), []);
  React.useEffect((function () {
          refreshDealers(undefined);
          Curry._1(setShowHistory, (function (param) {
                  return form.input.showHistoryDealer;
                }));
        }), [form.input.showHistoryDealer]);
  React.useEffect((function () {
          var match = form.input.orderType;
          var exit = 0;
          switch (match) {
            case "CL" :
            case "PG" :
            case "RC" :
            case "RR" :
            case "VD" :
            case "VE" :
                exit = 1;
                break;
            default:
              
          }
          if (exit === 1) {
            Curry._2(form.updateBudgetStatus, (function (input, budgetStatus) {
                    return {
                            dr: input.dr,
                            dealerId: input.dealerId,
                            customerOrderNumber: input.customerOrderNumber,
                            orderNumberCar: input.orderNumberCar,
                            vin: input.vin,
                            newCarRegistrationID: input.newCarRegistrationID,
                            budgetType: input.budgetType,
                            budgetStatus: budgetStatus,
                            showHistoryDealer: input.showHistoryDealer,
                            showArchived: input.showArchived,
                            orderType: input.orderType,
                            orderStatus: input.orderStatus,
                            usedCarStatus: input.usedCarStatus
                          };
                  }), /* [] */0);
            Curry._2(form.updateBudgetType, (function (input, budgetType) {
                    return {
                            dr: input.dr,
                            dealerId: input.dealerId,
                            customerOrderNumber: input.customerOrderNumber,
                            orderNumberCar: input.orderNumberCar,
                            vin: input.vin,
                            newCarRegistrationID: input.newCarRegistrationID,
                            budgetType: budgetType,
                            budgetStatus: input.budgetStatus,
                            showHistoryDealer: input.showHistoryDealer,
                            showArchived: input.showArchived,
                            orderType: input.orderType,
                            orderStatus: input.orderStatus,
                            usedCarStatus: input.usedCarStatus
                          };
                  }), "");
          }
          
        }), [form.input.orderType]);
  var tmp;
  if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
    var match$6 = form.input.orderType;
    var tmp$1;
    switch (match$6) {
      case "CL" :
      case "PG" :
      case "RC" :
      case "RR" :
      case "VD" :
      case "VE" :
          tmp$1 = true;
          break;
      default:
        tmp$1 = false;
    }
    tmp = React.createElement(Core.Grid, {
          children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                label: Messages_Common$DvmAdminFrontendFr.budget,
                options: Belt_List.add(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$4[0], /* [] */0), (function (x) {
                            return {
                                    label: {
                                      TAG: /* String */3,
                                      _0: x
                                    },
                                    value: x
                                  };
                          })), {
                      label: {
                        TAG: /* Message */0,
                        _0: Messages_Common$DvmAdminFrontendFr.nothing
                      },
                      value: ""
                    }),
                value: {
                  TAG: /* Single */0,
                  _0: {
                    value: form.input.budgetType,
                    onChange: Curry._1(form.updateBudgetType, (function (input, budgetType) {
                            return {
                                    dr: input.dr,
                                    dealerId: input.dealerId,
                                    customerOrderNumber: input.customerOrderNumber,
                                    orderNumberCar: input.orderNumberCar,
                                    vin: input.vin,
                                    newCarRegistrationID: input.newCarRegistrationID,
                                    budgetType: budgetType,
                                    budgetStatus: input.budgetStatus,
                                    showHistoryDealer: input.showHistoryDealer,
                                    showArchived: input.showArchived,
                                    orderType: input.orderType,
                                    orderStatus: input.orderStatus,
                                    usedCarStatus: input.usedCarStatus
                                  };
                          }))
                  }
                },
                id: "budget",
                className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                disabled: tmp$1
              }),
          item: true
        });
  } else {
    tmp = null;
  }
  var tmp$2 = {
    label: Belt_List.length(form.input.orderStatus) > 0 ? Messages_Orders_Search$DvmAdminFrontendFr.orderStatusLabel : Messages_Orders_Search$DvmAdminFrontendFr.orderStatusWithoutCanceledLabel,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: form.input.orderStatus,
        onChange: Curry._1(form.updateOrderStatus, (function (input, orderStatus) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        customerOrderNumber: input.customerOrderNumber,
                        orderNumberCar: input.orderNumberCar,
                        vin: input.vin,
                        newCarRegistrationID: input.newCarRegistrationID,
                        budgetType: input.budgetType,
                        budgetStatus: input.budgetStatus,
                        showHistoryDealer: input.showHistoryDealer,
                        showArchived: input.showArchived,
                        orderType: input.orderType,
                        orderStatus: orderStatus,
                        usedCarStatus: input.usedCarStatus
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: Belt_List.keepMap({
            hd: {
              label: {
                TAG: /* Message */0,
                _0: Messages_Orders_Search$DvmAdminFrontendFr.statusComplete
              },
              value: "Complete"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* Message */0,
                  _0: Messages_Orders_Search$DvmAdminFrontendFr.statusIncomplete
                },
                value: "Incomplete"
              },
              tl: {
                hd: {
                  label: {
                    TAG: /* Message */0,
                    _0: Messages_Orders_Search$DvmAdminFrontendFr.statusCanceled
                  },
                  value: "Annulee"
                },
                tl: {
                  hd: selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? undefined : ({
                        label: {
                          TAG: /* Message */0,
                          _0: Messages_Orders_Search$DvmAdminFrontendFr.statusReparceled
                        },
                        value: "Remembrement"
                      }),
                  tl: {
                    hd: EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? undefined : ({
                          label: {
                            TAG: /* Message */0,
                            _0: Messages_Orders_Search$DvmAdminFrontendFr.parcPending
                          },
                          value: "Attente entrée PARC"
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }, (function (x) {
              return x;
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputExtraLong,
    limitTags: 3,
    smallChips: true
  };
  if (form.orderStatusResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.orderStatusResult);
  }
  var tmp$3 = {
    label: Messages_Orders_Search$DvmAdminFrontendFr.usedCarStatusLabel,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: form.input.usedCarStatus,
        onChange: Curry._1(form.updateUsedCarStatus, (function (input, usedCarStatus) {
                return {
                        dr: input.dr,
                        dealerId: input.dealerId,
                        customerOrderNumber: input.customerOrderNumber,
                        orderNumberCar: input.orderNumberCar,
                        vin: input.vin,
                        newCarRegistrationID: input.newCarRegistrationID,
                        budgetType: input.budgetType,
                        budgetStatus: input.budgetStatus,
                        showHistoryDealer: input.showHistoryDealer,
                        showArchived: input.showArchived,
                        orderType: input.orderType,
                        orderStatus: input.orderStatus,
                        usedCarStatus: usedCarStatus
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: {
        hd: {
          label: {
            TAG: /* Message */0,
            _0: Messages_Orders_Search$DvmAdminFrontendFr.statusComplete
          },
          value: "Complete"
        },
        tl: {
          hd: {
            label: {
              TAG: /* Message */0,
              _0: Messages_Orders_Search$DvmAdminFrontendFr.statusIncomplete
            },
            value: "Incomplete"
          },
          tl: {
            hd: {
              label: {
                TAG: /* Message */0,
                _0: Messages_Orders_Search$DvmAdminFrontendFr.statusNoVO
              },
              value: "Pas de VO"
            },
            tl: /* [] */0
          }
        }
      }
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
    smallChips: true
  };
  if (form.usedCarStatusResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.usedCarStatusResult);
  }
  var tmp$4;
  if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
    var match$7 = form.input.orderType;
    var tmp$5;
    switch (match$7) {
      case "CL" :
      case "PG" :
      case "RC" :
      case "RR" :
      case "VD" :
      case "VE" :
          tmp$5 = true;
          break;
      default:
        tmp$5 = false;
    }
    var tmp$6 = {
      label: Messages_Common$DvmAdminFrontendFr.stateBudget,
      value: {
        TAG: /* Multiple */1,
        _0: {
          value: form.input.budgetStatus,
          onChange: Curry._1(form.updateBudgetStatus, (function (input, budgetStatus) {
                  return {
                          dr: input.dr,
                          dealerId: input.dealerId,
                          customerOrderNumber: input.customerOrderNumber,
                          orderNumberCar: input.orderNumberCar,
                          vin: input.vin,
                          newCarRegistrationID: input.newCarRegistrationID,
                          budgetType: input.budgetType,
                          budgetStatus: budgetStatus,
                          showHistoryDealer: input.showHistoryDealer,
                          showArchived: input.showArchived,
                          orderType: input.orderType,
                          orderStatus: input.orderStatus,
                          usedCarStatus: input.usedCarStatus
                        };
                }))
        }
      },
      options: {
        TAG: /* Labeled */1,
        _0: Belt_List.map(Belt_List.sort(match$3[0], (function (a, b) {
                    return Belt_Option.getWithDefault(Belt_Int.fromString(a.paramTwo), 0) - Belt_Option.getWithDefault(Belt_Int.fromString(b.paramTwo), 0) | 0;
                  })), (function (x) {
                return {
                        label: {
                          TAG: /* Message */0,
                          _0: App_Types_Status$DvmAdminFrontendFr.toMessage(x.paramOne)
                        },
                        value: App_Types_Status$DvmAdminFrontendFr.toString(x.paramOne)
                      };
              }))
      },
      disabled: tmp$5,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputExtraLong,
      limitTags: 4,
      smallChips: true
    };
    if (form.budgetStatusResult !== undefined) {
      tmp$6.validationResult = Caml_option.valFromOption(form.budgetStatusResult);
    }
    tmp$4 = React.createElement(Core.Grid, {
          children: null,
          container: true,
          direction: "column",
          item: true,
          style: {
            width: "fit-content"
          }
        }, React.createElement(Core.Grid, {
              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$6),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(Core.FormControlLabel, {
                    classes: {
                      label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                    },
                    control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                          checked: form.input.showArchived,
                          onClick: (function ($$event) {
                              Curry._2(form.updateShowArchived, (function (input, showArchived) {
                                      return {
                                              dr: input.dr,
                                              dealerId: input.dealerId,
                                              customerOrderNumber: input.customerOrderNumber,
                                              orderNumberCar: input.orderNumberCar,
                                              vin: input.vin,
                                              newCarRegistrationID: input.newCarRegistrationID,
                                              budgetType: input.budgetType,
                                              budgetStatus: input.budgetStatus,
                                              showHistoryDealer: input.showHistoryDealer,
                                              showArchived: showArchived,
                                              orderType: input.orderType,
                                              orderStatus: input.orderStatus,
                                              usedCarStatus: input.usedCarStatus
                                            };
                                    }), $$event.target.checked);
                            })
                        }),
                    label: intl.formatMessage(Messages_Orders_Search$DvmAdminFrontendFr.showArchived)
                  }),
              item: true
            }));
  } else {
    tmp$4 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                        label: Messages_Common$DvmAdminFrontendFr.orderType,
                        value: {
                          TAG: /* Single */0,
                          _0: {
                            value: form.input.orderType,
                            onChange: Curry._1(form.updateOrderType, (function (input, orderType) {
                                    return {
                                            dr: input.dr,
                                            dealerId: input.dealerId,
                                            customerOrderNumber: input.customerOrderNumber,
                                            orderNumberCar: input.orderNumberCar,
                                            vin: input.vin,
                                            newCarRegistrationID: input.newCarRegistrationID,
                                            budgetType: input.budgetType,
                                            budgetStatus: input.budgetStatus,
                                            showHistoryDealer: input.showHistoryDealer,
                                            showArchived: input.showArchived,
                                            orderType: orderType,
                                            orderStatus: input.orderStatus,
                                            usedCarStatus: input.usedCarStatus
                                          };
                                  }))
                          }
                        },
                        options: {
                          TAG: /* Unlabeled */0,
                          _0: EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? ({
                                hd: "SO",
                                tl: {
                                  hd: "CL",
                                  tl: {
                                    hd: "VD",
                                    tl: /* [] */0
                                  }
                                }
                              }) : (
                              User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
                                    hd: "SO",
                                    tl: {
                                      hd: "CL",
                                      tl: {
                                        hd: "VD",
                                        tl: {
                                          hd: "VE",
                                          tl: {
                                            hd: "RR",
                                            tl: {
                                              hd: "RC",
                                              tl: {
                                                hd: "PG",
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }) : ({
                                    hd: "CL",
                                    tl: {
                                      hd: "VD",
                                      tl: {
                                        hd: "VE",
                                        tl: {
                                          hd: "RR",
                                          tl: {
                                            hd: "RC",
                                            tl: {
                                              hd: "PG",
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                            )
                        },
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                      }),
                  item: true
                }), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(drAndDealers),
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        item: true,
                        style: {
                          width: "fit-content"
                        }
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                  labelParent: Messages_Common$DvmAdminFrontendFr.territory,
                                  labelChild: Messages_Common$DvmAdminFrontendFr.dealerID,
                                  valueParent: {
                                    TAG: /* Multiple */1,
                                    _0: {
                                      value: form.input.dr,
                                      onChange: Curry._1(form.updateDr, (function (input, dr) {
                                              return {
                                                      dr: dr,
                                                      dealerId: input.dealerId,
                                                      customerOrderNumber: input.customerOrderNumber,
                                                      orderNumberCar: input.orderNumberCar,
                                                      vin: input.vin,
                                                      newCarRegistrationID: input.newCarRegistrationID,
                                                      budgetType: input.budgetType,
                                                      budgetStatus: input.budgetStatus,
                                                      showHistoryDealer: input.showHistoryDealer,
                                                      showArchived: input.showArchived,
                                                      orderType: input.orderType,
                                                      orderStatus: input.orderStatus,
                                                      usedCarStatus: input.usedCarStatus
                                                    };
                                            }))
                                    }
                                  },
                                  valueChild: {
                                    TAG: /* Multiple */1,
                                    _0: {
                                      value: form.input.dealerId,
                                      onChange: (function (val) {
                                          Curry._1(setDealerSearchInput, (function (param) {
                                                  return "";
                                                }));
                                          Curry._2(form.updateDealerId, (function (input, dealerId) {
                                                  return {
                                                          dr: input.dr,
                                                          dealerId: dealerId,
                                                          customerOrderNumber: input.customerOrderNumber,
                                                          orderNumberCar: input.orderNumberCar,
                                                          vin: input.vin,
                                                          newCarRegistrationID: input.newCarRegistrationID,
                                                          budgetType: input.budgetType,
                                                          budgetStatus: input.budgetStatus,
                                                          showHistoryDealer: input.showHistoryDealer,
                                                          showArchived: input.showArchived,
                                                          orderType: input.orderType,
                                                          orderStatus: input.orderStatus,
                                                          usedCarStatus: input.usedCarStatus
                                                        };
                                                }), val);
                                        })
                                    }
                                  },
                                  options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0),
                                  classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                                  classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
                                  limitTagsParent: 2,
                                  limitTagsChild: 2,
                                  selectParentIfOnlyOne: true,
                                  selectChildIfOnlyOne: true,
                                  onSearchChild: (function (val) {
                                      Curry._1(setDealerSearchInput, (function (param) {
                                              return val.trim().slice(0, 6);
                                            }));
                                    }),
                                  inputValueChild: match$5[0]
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Core.FormControlLabel, {
                                  classes: {
                                    label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                  },
                                  control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                        checked: form.input.showHistoryDealer,
                                        onClick: (function ($$event) {
                                            Curry._2(form.updateShowHistoryDealer, (function (input, showHistoryDealer) {
                                                    return {
                                                            dr: input.dr,
                                                            dealerId: input.dealerId,
                                                            customerOrderNumber: input.customerOrderNumber,
                                                            orderNumberCar: input.orderNumberCar,
                                                            vin: input.vin,
                                                            newCarRegistrationID: input.newCarRegistrationID,
                                                            budgetType: input.budgetType,
                                                            budgetStatus: input.budgetStatus,
                                                            showHistoryDealer: showHistoryDealer,
                                                            showArchived: input.showArchived,
                                                            orderType: input.orderType,
                                                            orderStatus: input.orderStatus,
                                                            usedCarStatus: input.usedCarStatus
                                                          };
                                                  }), $$event.target.checked);
                                          })
                                      }),
                                  label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.showDealerHistory)
                                }),
                            item: true
                          }))
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: (function (val) {
                            Curry._2(form.updateCustomerOrderNumber, (function (input, customerOrderNumber) {
                                    return {
                                            dr: input.dr,
                                            dealerId: input.dealerId,
                                            customerOrderNumber: customerOrderNumber,
                                            orderNumberCar: input.orderNumberCar,
                                            vin: input.vin,
                                            newCarRegistrationID: input.newCarRegistrationID,
                                            budgetType: input.budgetType,
                                            budgetStatus: input.budgetStatus,
                                            showHistoryDealer: input.showHistoryDealer,
                                            showArchived: input.showArchived,
                                            orderType: input.orderType,
                                            orderStatus: input.orderStatus,
                                            usedCarStatus: input.usedCarStatus
                                          };
                                  }), val.trim().slice(0, 6));
                          }),
                        value: form.input.customerOrderNumber,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                        label: Messages_Orders_Search$DvmAdminFrontendFr.customerOrderNum
                      }),
                  item: true
                }), tmp, React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: (function (val) {
                            Curry._2(form.updateOrderNumberCar, (function (input, orderNumberCar) {
                                    return {
                                            dr: input.dr,
                                            dealerId: input.dealerId,
                                            customerOrderNumber: input.customerOrderNumber,
                                            orderNumberCar: orderNumberCar,
                                            vin: input.vin,
                                            newCarRegistrationID: input.newCarRegistrationID,
                                            budgetType: input.budgetType,
                                            budgetStatus: input.budgetStatus,
                                            showHistoryDealer: input.showHistoryDealer,
                                            showArchived: input.showArchived,
                                            orderType: input.orderType,
                                            orderStatus: input.orderStatus,
                                            usedCarStatus: input.usedCarStatus
                                          };
                                  }), val.trim().slice(0, 5));
                          }),
                        value: form.input.orderNumberCar,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                        label: Messages_Orders_Search$DvmAdminFrontendFr.carOrderNum
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: Curry._1(form.updateVin, (function (input, vin) {
                                return {
                                        dr: input.dr,
                                        dealerId: input.dealerId,
                                        customerOrderNumber: input.customerOrderNumber,
                                        orderNumberCar: input.orderNumberCar,
                                        vin: vin,
                                        newCarRegistrationID: input.newCarRegistrationID,
                                        budgetType: input.budgetType,
                                        budgetStatus: input.budgetStatus,
                                        showHistoryDealer: input.showHistoryDealer,
                                        showArchived: input.showArchived,
                                        orderType: input.orderType,
                                        orderStatus: input.orderStatus,
                                        usedCarStatus: input.usedCarStatus
                                      };
                              })),
                        value: form.input.vin,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                        label: Messages_Common$DvmAdminFrontendFr.vin
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                        onChange: Curry._1(form.updateNewCarRegistrationID, (function (input, newCarRegistrationID) {
                                return {
                                        dr: input.dr,
                                        dealerId: input.dealerId,
                                        customerOrderNumber: input.customerOrderNumber,
                                        orderNumberCar: input.orderNumberCar,
                                        vin: input.vin,
                                        newCarRegistrationID: newCarRegistrationID,
                                        budgetType: input.budgetType,
                                        budgetStatus: input.budgetStatus,
                                        showHistoryDealer: input.showHistoryDealer,
                                        showArchived: input.showArchived,
                                        orderType: input.orderType,
                                        orderStatus: input.orderStatus,
                                        usedCarStatus: input.usedCarStatus
                                      };
                              })),
                        value: form.input.newCarRegistrationID,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                        label: Messages_Orders_Search$DvmAdminFrontendFr.immatVn
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$2),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  style: {
                    width: "fit-content"
                  }
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$3),
                      item: true
                    }), User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1 ? React.createElement(Core.Grid, {
                        children: React.createElement(Core.FormControlLabel, {
                              classes: {
                                label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                              },
                              control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                    checked: form.input.showArchived,
                                    onClick: (function ($$event) {
                                        Curry._2(form.updateShowArchived, (function (input, showArchived) {
                                                return {
                                                        dr: input.dr,
                                                        dealerId: input.dealerId,
                                                        customerOrderNumber: input.customerOrderNumber,
                                                        orderNumberCar: input.orderNumberCar,
                                                        vin: input.vin,
                                                        newCarRegistrationID: input.newCarRegistrationID,
                                                        budgetType: input.budgetType,
                                                        budgetStatus: input.budgetStatus,
                                                        showHistoryDealer: input.showHistoryDealer,
                                                        showArchived: showArchived,
                                                        orderType: input.orderType,
                                                        orderStatus: input.orderStatus,
                                                        usedCarStatus: input.usedCarStatus
                                                      };
                                              }), $$event.target.checked);
                                      })
                                  }),
                              label: intl.formatMessage(Messages_Orders_Search$DvmAdminFrontendFr.showArchived)
                            }),
                        item: true
                      }) : null), tmp$4);
}

var InputStyles;

var Form;

var Api;

var make = Order_Search_Filters_Inputs;

export {
  InputStyles ,
  Form ,
  Api ,
  make ,
}
/* react Not a pure module */
