// Generated by ReScript, PLEASE EDIT WITH CARE


var Detail;

var title = {
  id: "7b6df001-0b2c-4592-9494-2e9d8257c620",
  defaultMessage: "Quotas primes PARC"
};

var dealerName = {
  id: "a8b9d4ee-b8ea-4bba-9325-9521e92fcb70",
  defaultMessage: "Nom Affaire"
};

var createQuotaButton = {
  id: "04ccb004-71ec-4812-b2b5-e6df388b2345",
  defaultMessage: "Créer / Importer des Quotas"
};

var modalTitle = {
  id: "d285b65d-a401-454b-b496-68eee0a505bf",
  defaultMessage: "Gestion de Quotas PARC"
};

var createQuota = {
  id: "063ec711-079b-4f9f-9bbe-3a5bd8a9c6e2",
  defaultMessage: "Saisir un Quota"
};

var importQuotas = {
  id: "0345accc-f9e7-45e0-9b33-e782a51a943f",
  defaultMessage: "Importer les quotas via Excel"
};

var documentTemplate = {
  id: "7c24d2f5-87c4-44ae-8eb1-439190bd0b39",
  defaultMessage: "Template du fichier à importer"
};

var uploadDocument = {
  id: "9caff019-b2dd-4eb3-a39c-d99cf5a2d270",
  defaultMessage: "Importer le fichier"
};

var quotaValidityTitle = {
  id: "ba1d7ae2-995d-4b73-a90c-aec6dd5d5ad0",
  defaultMessage: "Période de validité des quotas*"
};

var downloadDealers = {
  id: "4c40b110-5df4-4aa5-9e11-50217fdfcebd",
  defaultMessage: "Télécharger la liste des affaires"
};

var downloadModels = {
  id: "63ae9182-c357-4ad0-aeec-39abe5af8819",
  defaultMessage: "Télécharger la liste des modèles"
};

export {
  Detail ,
  title ,
  dealerName ,
  createQuotaButton ,
  modalTitle ,
  createQuota ,
  importQuotas ,
  documentTemplate ,
  uploadDocument ,
  quotaValidityTitle ,
  downloadDealers ,
  downloadModels ,
}
/* No side effect */
