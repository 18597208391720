// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

var Fields = {
  actionId: "actionId",
  model: "model",
  version: "version",
  startDate: "startDate",
  endDate: "endDate",
  maxBonus: "maxBonus",
  versionLabel: "versionLabel",
  customBonus: "customBonus"
};

function modelVersionRow_encode(v) {
  return Js_dict.fromArray([
              [
                "model",
                Decco.stringToJson(v.model)
              ],
              [
                "version",
                Decco.stringToJson(v.version)
              ],
              [
                "versionLabel",
                Decco.optionToJson(Decco.stringToJson, v.versionLabel)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ],
              [
                "maxBonus",
                Decco.optionToJson(Decco.floatToJson, v.maxBonus)
              ],
              [
                "customBonus",
                Decco.optionToJson(Decco.floatToJson, v.customBonus)
              ]
            ]);
}

function modelVersionRow_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var model = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
  if (model.TAG === /* Ok */0) {
    var version = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
    if (version.TAG === /* Ok */0) {
      var versionLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionLabel"), null));
      if (versionLabel.TAG === /* Ok */0) {
        var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
        if (startDate.TAG === /* Ok */0) {
          var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
          if (endDate.TAG === /* Ok */0) {
            var maxBonus = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "maxBonus"), null));
            if (maxBonus.TAG === /* Ok */0) {
              var customBonus = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customBonus"), null));
              if (customBonus.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          model: model._0,
                          version: version._0,
                          versionLabel: versionLabel._0,
                          startDate: startDate._0,
                          endDate: endDate._0,
                          maxBonus: maxBonus._0,
                          customBonus: customBonus._0
                        }
                      };
              }
              var e = customBonus._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".customBonus" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = maxBonus._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".maxBonus" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = endDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".endDate" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = startDate._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".startDate" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = versionLabel._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".versionLabel" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = version._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".version" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = model._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".model" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function modelVersionList_encode(v) {
  return Decco.listToJson(modelVersionRow_encode, v);
}

function modelVersionList_decode(v) {
  return Decco.listFromJson(modelVersionRow_decode, v);
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(modelVersionRow_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(modelVersionRow_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  Fields ,
  modelVersionRow_encode ,
  modelVersionRow_decode ,
  modelVersionList_encode ,
  modelVersionList_decode ,
  searchResult_encode ,
  searchResult_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
