// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../../../common/components/icons/App_Icon.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../../../../../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr from "../../Flash_Edit_Version_Perf_Modal_Api.bs.js";
import * as Flash_Edit_Fleet_Version_Table_Api$DvmAdminFrontendFr from "../../../Flash_Edit_Fleet_Version_Table_Api.bs.js";

function Flash_Edit_Version_Duplicate_Action_Perf(Props) {
  var flash = Props.flash;
  var setSelectedVersionsList = Props.setSelectedVersionsList;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return "";
      });
  var setSelectedAction = match$2[1];
  var selectedAction = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setActions = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setWithClosedModels = match$4[1];
  var withClosedModels = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setVersionsListResult = match$5[1];
  var versionsListResult = match$5[0];
  React.useEffect((function () {
          Flash_Edit_Version_Perf_Modal_Api$DvmAdminFrontendFr.getActions(alert, userManager, setActions, selectedRole);
        }), []);
  React.useEffect((function () {
          if (selectedAction === "") {
            Curry._1(setSelectedVersionsList, (function (param) {
                    return /* [] */0;
                  }));
          } else {
            Flash_Edit_Fleet_Version_Table_Api$DvmAdminFrontendFr.getFlashVersionsForDropdown(userManager, setVersionsListResult, selectedAction, /* PERF */1, selectedRole, withClosedModels);
          }
        }), [
        selectedAction,
        withClosedModels
      ]);
  React.useEffect((function () {
          if (typeof versionsListResult === "number") {
            versionsListResult === /* NotStarted */0;
          } else if (versionsListResult.TAG === /* Ok */0) {
            var table = versionsListResult._0;
            Curry._1(setSelectedVersionsList, (function (param) {
                    return Belt_List.map(Belt_List.map(Belt_MapString.toList(Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(table, (function (x) {
                                                      return [
                                                              x.modelVersionID,
                                                              x
                                                            ];
                                                    }))))), (function (x) {
                                      return x[1];
                                    })), (function (x) {
                                  return {
                                          modelVersionID: x.modelVersionID,
                                          model: x.model,
                                          modelName: x.modelName,
                                          version: x.version,
                                          versionName: x.versionName,
                                          maxBonus: undefined,
                                          startDate: undefined,
                                          endDate: x.endDate,
                                          followingStartDate: x.followingStartDate,
                                          previousEndDate: x.previousEndDate,
                                          categoryID: x.categoryID,
                                          actionID: x.actionID
                                        };
                                }));
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: versionsListResult._0
                      }
                    }));
          }
        }), [versionsListResult]);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  item: true,
                  spacing: 1
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                size: /* Large */3,
                                type_: /* Export */62
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "600"
                                },
                                children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.duplicateVersions),
                                variant: "bodyBig"
                              }),
                          item: true
                        })), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          item: true,
                          style: {
                            width: "fit-content"
                          }
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                    label: Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID,
                                    value: {
                                      TAG: /* Single */0,
                                      _0: {
                                        value: selectedAction,
                                        onChange: (function (value) {
                                            Curry._1(setSelectedAction, (function (param) {
                                                    return value;
                                                  }));
                                          })
                                      }
                                    },
                                    options: {
                                      TAG: /* Labeled */1,
                                      _0: Belt_List.map(Belt_List.keep(match$3[0], (function (x) {
                                                  return x.actionID !== flash.actionID;
                                                })), (function (x) {
                                              return {
                                                      label: {
                                                        TAG: /* String */3,
                                                        _0: "" + x.actionID + " (" + x.actionLabel + ")"
                                                      },
                                                      value: x.actionID
                                                    };
                                            }))
                                    },
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Core.FormControlLabel, {
                                    classes: {
                                      label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                    },
                                    control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                          checked: withClosedModels,
                                          onClick: (function ($$event) {
                                              Curry._1(setWithClosedModels, (function (param) {
                                                      return $$event.target.checked;
                                                    }));
                                            })
                                        }),
                                    label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                  }),
                              item: true,
                              style: {
                                whiteSpace: "nowrap",
                                width: "1px"
                              }
                            })), App_Types_Result$DvmAdminFrontendFr.isPending(versionsListResult) ? React.createElement(Core.Grid, {
                            children: React.createElement(App_Spinner$DvmAdminFrontendFr.make, {}),
                            item: true
                          }) : null)));
}

var InputStyles;

var Api;

var make = Flash_Edit_Version_Duplicate_Action_Perf;

export {
  InputStyles ,
  Api ,
  make ,
}
/* react Not a pure module */
