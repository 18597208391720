// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Flash_Edit_Api$DvmAdminFrontendFr from "./Flash_Edit_Api.bs.js";
import * as Flash_Edit_Form$DvmAdminFrontendFr from "./Flash_Edit_Form.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Flash_Edit_Steps$DvmAdminFrontendFr from "./steps/Flash_Edit_Steps.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "./Flash_Edit_Types.bs.js";
import * as Flash_Edit_Router$DvmAdminFrontendFr from "./Flash_Edit_Router.bs.js";
import * as Flash_Search_Form$DvmAdminFrontendFr from "../search/Flash_Search_Form.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Flash_Edit_Form_VO$DvmAdminFrontendFr from "./Flash_Edit_Form_VO.bs.js";
import * as Flash_Edit_Form_Set$DvmAdminFrontendFr from "./Flash_Edit_Form_Set.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Steps_Utils$DvmAdminFrontendFr from "./steps/Flash_Edit_Steps_Utils.bs.js";
import * as Flash_Edit_NonCumulable$DvmAdminFrontendFr from "./nonCumulables/Flash_Edit_NonCumulable.bs.js";
import * as Flash_Edit_NonFleet_Api$DvmAdminFrontendFr from "./steps/NonFleet/Flash_Edit_NonFleet_Api.bs.js";
import * as Flash_Edit_Versions_Page$DvmAdminFrontendFr from "./modelVersions/Flash_Edit_Versions_Page.bs.js";
import * as Flash_Edit_Form_General_Dates$DvmAdminFrontendFr from "./Flash_Edit_Form_General_Dates.bs.js";
import * as Flash_Edit_Form_Submit_Control$DvmAdminFrontendFr from "./Flash_Edit_Form_Submit_Control.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Form$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_New_Vehicle_Form.bs.js";

function Flash_Edit_Page(Props) {
  var flashIdOpt = Props.flashId;
  var flashTypeOpt = Props.flashType;
  var flashId = flashIdOpt !== undefined ? Caml_option.valFromOption(flashIdOpt) : undefined;
  var flashType = flashTypeOpt !== undefined ? Caml_option.valFromOption(flashTypeOpt) : undefined;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var flashFilter = match$2[0].flashFilter;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$3[1];
  var saveResult = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedFlash = match$4[1];
  var fetchedFlash = match$4[0];
  var match$5 = React.useState(function () {
        return /* NOTSET */3;
      });
  var setActionType = match$5[1];
  var actionType = match$5[0];
  var match$6 = React.useState(function () {
        return /* [] */0;
      });
  var setBrandsList = match$6[1];
  var match$7 = React.useState(function () {
        
      });
  var setFlash = match$7[1];
  var flash = match$7[0];
  var match$8 = React.useState(function () {
        return /* NotStarted */0;
      });
  var newVehicleResult = match$8[0];
  var match$9 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersionsForCumulables = match$9[1];
  var match$10 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setIncludedModelsForCumulables = match$10[1];
  var match$11 = React.useState(function () {
        
      });
  var match$12 = React.useState(function () {
        
      });
  var setFormOutput = match$12[1];
  var formOutput = match$12[0];
  var match$13 = React.useState(function () {
        
      });
  var setFormGeneralDatesOutput = match$13[1];
  var formGeneralDatesOutput = match$13[0];
  var match$14 = React.useState(function () {
        
      });
  var setFormNonFleetNewVehicleOutput = match$14[1];
  var formNonFleetNewVehicleOutput = match$14[0];
  var match$15 = React.useState(function () {
        
      });
  var setFormVOOutput = match$15[1];
  var formVOOutput = match$15[0];
  var match$16 = React.useState(function () {
        
      });
  var setSubmissionCallbacks = match$16[1];
  var submissionCallbacks = match$16[0];
  var match$17 = React.useState(function () {
        
      });
  var setSubmissionCallbacksGeneralDates = match$17[1];
  var submissionCallbacksGeneralDates = match$17[0];
  var match$18 = React.useState(function () {
        
      });
  var setSubmissionCallbacksNonFleetNewVehicle = match$18[1];
  var submissionCallbacksNonFleetNewVehicle = match$18[0];
  var match$19 = React.useState(function () {
        
      });
  var setSubmissionCallbacksVOForm = match$19[1];
  var submissionCallbacksVOForm = match$19[0];
  var form = Flash_Edit_Form$DvmAdminFrontendFr.FlashForm.useForm(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(fetchedFlash, Flash_Edit_Form$DvmAdminFrontendFr.initialState, Flash_Edit_Form_Set$DvmAdminFrontendFr.setForm), (function (output, submissionCallbacks) {
          Curry._1(setFormOutput, (function (param) {
                  return output;
                }));
          Curry._1(setSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  var formGeneralDates = Flash_Edit_Form_General_Dates$DvmAdminFrontendFr.FlashForm.useForm(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(fetchedFlash, Flash_Edit_Form_General_Dates$DvmAdminFrontendFr.initialState, Flash_Edit_Form_Set$DvmAdminFrontendFr.setFormGeneralDates), {
        orderType: form.input.orderType
      }, (function (output, submissionCallbacks) {
          Curry._1(setFormGeneralDatesOutput, (function (param) {
                  return output;
                }));
          Curry._1(setSubmissionCallbacksGeneralDates, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  var formNonFleetNewVehicle = Flash_Edit_NonFleet_New_Vehicle_Form$DvmAdminFrontendFr.NewVehicleForm.useForm(Belt_Option.mapWithDefault(match$11[0], App_Types_Result$DvmAdminFrontendFr.mapWithDefault(fetchedFlash, Flash_Edit_NonFleet_New_Vehicle_Form$DvmAdminFrontendFr.initialState, (function (flash) {
                  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(newVehicleResult, Flash_Edit_NonFleet_New_Vehicle_Form$DvmAdminFrontendFr.initialState, (function (newVehicle) {
                                return Flash_Edit_Form_Set$DvmAdminFrontendFr.setNonFleetNewVehicleForm(flash, newVehicle);
                              }));
                })), (function (param) {
              return Flash_Edit_Form_Set$DvmAdminFrontendFr.setNonFleetNewVehicleForm(param[1], param[0]);
            })), (function (output, submissionCallbacks) {
          Curry._1(setFormNonFleetNewVehicleOutput, (function (param) {
                  return output;
                }));
          Curry._1(setSubmissionCallbacksNonFleetNewVehicle, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  var formVO = Flash_Edit_Form_VO$DvmAdminFrontendFr.FlashForm.useForm(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(fetchedFlash, Flash_Edit_Form_VO$DvmAdminFrontendFr.initialState, Flash_Edit_Form_Set$DvmAdminFrontendFr.setVOForm), {
        actionType: actionType
      }, (function (output, submissionCallbacks) {
          Curry._1(setFormVOOutput, (function (param) {
                  return output;
                }));
          Curry._1(setSubmissionCallbacksVOForm, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  React.useEffect((function () {
          var match = form.input.actionType;
          if (typeof match === "number" && !(match >= 3 || !(formOutput !== undefined && formGeneralDatesOutput !== undefined && formVOOutput !== undefined && submissionCallbacks !== undefined && submissionCallbacksGeneralDates !== undefined && submissionCallbacksVOForm !== undefined))) {
            Flash_Edit_Api$DvmAdminFrontendFr.saveFlash(Belt_Option.isSome(flashId) ? /* Update */1 : /* Create */0, userManager, setSaveResult, submissionCallbacks, submissionCallbacksGeneralDates, submissionCallbacksVOForm, selectedRole, Flash_Edit_Form_Submit_Control$DvmAdminFrontendFr.submitFleet(formOutput, formVOOutput, formGeneralDatesOutput, actionType));
          }
          
        }), [formGeneralDatesOutput]);
  React.useEffect((function () {
          var match = form.input.actionType;
          if (typeof match === "number" || !(formOutput !== undefined && formGeneralDatesOutput !== undefined && formNonFleetNewVehicleOutput !== undefined && formVOOutput !== undefined && submissionCallbacks !== undefined && submissionCallbacksGeneralDates !== undefined && submissionCallbacksNonFleetNewVehicle !== undefined && submissionCallbacksVOForm !== undefined)) {
            
          } else {
            Flash_Edit_Api$DvmAdminFrontendFr.saveFlashNonFleet(Belt_Option.isSome(flashId) ? /* Update */1 : /* Create */0, userManager, setSaveResult, submissionCallbacks, submissionCallbacksGeneralDates, submissionCallbacksNonFleetNewVehicle, submissionCallbacksVOForm, selectedRole, Flash_Edit_Form_Submit_Control$DvmAdminFrontendFr.submitNonFleetNewVehicle(formOutput, formNonFleetNewVehicleOutput, formGeneralDatesOutput, newVehicleResult), Flash_Edit_Form_Submit_Control$DvmAdminFrontendFr.submitNonFleet(formOutput, formGeneralDatesOutput, formVOOutput, formNonFleetNewVehicleOutput));
          }
        }), [formNonFleetNewVehicleOutput]);
  var fetchFlash = function (flashId, flashType) {
    Flash_Edit_Api$DvmAdminFrontendFr.fetchFlash(userManager, setFetchedFlash, selectedRole, flashType, flashId);
  };
  var fetchFlashModelVersionsForCumulables = function (flashId) {
    var match = form.input.actionType;
    if (typeof match === "number") {
      if (match >= 3) {
        return ;
      } else {
        return Flash_Edit_Api$DvmAdminFrontendFr.fetchFlashModelVersions(alert, userManager, setModelVersionsForCumulables, selectedRole, true, flashId);
      }
    } else {
      return Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchNewVehicleInfo(userManager, setIncludedModelsForCumulables, flashId, selectedRole);
    }
  };
  React.useEffect((function () {
          if (flashId !== undefined && flashType !== undefined) {
            fetchFlash(flashId, flashType);
          }
          Flash_Edit_Api$DvmAdminFrontendFr.queryForBrands(alert, userManager, setBrandsList, selectedRole);
        }), []);
  React.useEffect((function () {
          Belt_Option.forEach(flashId, fetchFlashModelVersionsForCumulables);
        }), [form.input.actionType]);
  React.useEffect((function () {
          if (typeof fetchedFlash === "number") {
            fetchedFlash === /* NotStarted */0;
          } else if (fetchedFlash.TAG === /* Ok */0) {
            var flash = fetchedFlash._0;
            Curry._1(setFlash, (function (param) {
                    return flash;
                  }));
            Curry._1(form.reset, undefined);
            Curry._1(formVO.reset, undefined);
            Curry._1(formGeneralDates.reset, undefined);
          }
          
        }), [fetchedFlash]);
  React.useEffect((function () {
          if (typeof newVehicleResult === "number") {
            newVehicleResult === /* NotStarted */0;
          } else if (newVehicleResult.TAG === /* Ok */0) {
            Curry._1(formNonFleetNewVehicle.reset, undefined);
          }
          
        }), [newVehicleResult]);
  React.useEffect((function () {
          Curry._1(setActionType, (function (param) {
                  return form.input.actionType;
                }));
        }), [form.input.actionType]);
  React.useEffect((function () {
          if (form.input.orderType === /* VE */2) {
            Curry._1(formGeneralDates.reset, undefined);
          }
          
        }), [form.input.orderType]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            var match = saveResult._0;
            var message = match[1];
            if (match[0]) {
              fetchFlash(form.input.actionID, form.input.orderType === /* SO */0 ? /* Fleet */0 : /* NonFleet */1);
              Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            } else if (message !== undefined) {
              fetchFlash(message, form.input.orderType === /* SO */0 ? /* Fleet */0 : /* NonFleet */1);
              Flash_Edit_Router$DvmAdminFrontendFr.routeToPage(message, form.input.orderType === /* SO */0 ? "fleet" : "nonfleet", undefined);
              Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            } else {
              Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                        message: {
                          TAG: /* Message */0,
                          _0: Messages_Flash_Edit$DvmAdminFrontendFr.actionCodeCreationError
                        }
                      }));
            }
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var refreshPage = function (param) {
    fetchFlash(form.input.actionID, form.input.orderType === /* SO */0 ? /* Fleet */0 : /* NonFleet */1);
  };
  var tmp;
  var exit = 0;
  if (typeof fetchedFlash === "number") {
    exit = fetchedFlash === /* NotStarted */0 && flashId === undefined ? 2 : 1;
  } else if (fetchedFlash.TAG === /* Ok */0 || flashId === undefined) {
    exit = 2;
  } else {
    tmp = React.createElement(App_Typography$DvmAdminFrontendFr.make, {
          color: "secondary",
          children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.notFound),
          variant: "h3"
        });
  }
  switch (exit) {
    case 1 :
        tmp = React.createElement(Core.Grid, {
              children: React.createElement(Core.CircularProgress, {
                    size: 100
                  }),
              container: true,
              justifyContent: "center"
            });
        break;
    case 2 :
        var tmp$1;
        if (flash !== undefined && flashId !== undefined) {
          var match$20 = form.input.actionType;
          var tmp$2;
          var exit$1 = 0;
          if (typeof match$20 === "number" && match$20 >= 3) {
            tmp$2 = null;
          } else {
            exit$1 = 3;
          }
          if (exit$1 === 3) {
            tmp$2 = React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Edit_Versions_Page$DvmAdminFrontendFr.make, {
                        flashId: flashId,
                        flash: flash,
                        actionType: form.input.actionType,
                        fetchFlashModelVersionsForCumulables: fetchFlashModelVersionsForCumulables,
                        refreshPage: refreshPage
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[12]
                });
          }
          tmp$1 = React.createElement(React.Fragment, undefined, tmp$2, React.createElement(Core.Grid, {
                    children: React.createElement(Flash_Edit_NonCumulable$DvmAdminFrontendFr.make, {
                          flashId: flashId,
                          flash: flash,
                          modelVersions: match$9[0],
                          includedModels: match$10[0],
                          flashType: flashType
                        }),
                    item: true,
                    sm: Grid$Mui.Sm[12]
                  }));
        } else {
          tmp$1 = null;
        }
        tmp = React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
              children: React.createElement(Core.Container, {
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          justifyContent: "center",
                          spacing: 5
                        }, React.createElement(Core.Grid, {
                              children: React.createElement("form", {
                                    onSubmit: (function ($$event) {
                                        $$event.preventDefault();
                                        Belt_Option.mapWithDefault(submissionCallbacks, undefined, (function (x) {
                                                Curry._1(x.notifyOnSuccess, undefined);
                                              }));
                                        Belt_Option.mapWithDefault(submissionCallbacksGeneralDates, undefined, (function (x) {
                                                Curry._1(x.notifyOnSuccess, undefined);
                                              }));
                                        Belt_Option.mapWithDefault(submissionCallbacksNonFleetNewVehicle, undefined, (function (x) {
                                                Curry._1(x.notifyOnSuccess, undefined);
                                              }));
                                        Belt_Option.mapWithDefault(submissionCallbacksVOForm, undefined, (function (x) {
                                                Curry._1(x.notifyOnSuccess, undefined);
                                              }));
                                        Curry._1(setFormOutput, (function (param) {
                                                
                                              }));
                                        Curry._1(setFormNonFleetNewVehicleOutput, (function (param) {
                                                
                                              }));
                                        Curry._1(setFormVOOutput, (function (param) {
                                                
                                              }));
                                        Curry._1(setFormGeneralDatesOutput, (function (param) {
                                                
                                              }));
                                        if ((form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5) && !Flash_Edit_Steps_Utils$DvmAdminFrontendFr.isRRorRCSomeDate(form, formGeneralDates)) {
                                          return ;
                                        } else {
                                          Curry._1(form.submit, undefined);
                                          Curry._1(formVO.submit, undefined);
                                          Curry._1(formGeneralDates.submit, undefined);
                                          return Curry._1(formNonFleetNewVehicle.submit, undefined);
                                        }
                                      })
                                  }, React.createElement(Core.Grid, {
                                        children: React.createElement(Flash_Edit_Steps$DvmAdminFrontendFr.make, {
                                              form: form,
                                              formVO: formVO,
                                              formGeneralDates: formGeneralDates,
                                              formNonFleetNewVehicle: formNonFleetNewVehicle,
                                              actionType: actionType,
                                              brandsList: match$6[0],
                                              flashId: flashId,
                                              fetchFlashModelVersionsForCumulables: fetchFlashModelVersionsForCumulables,
                                              newVehicleResult: newVehicleResult,
                                              setNewVehicleResult: match$8[1],
                                              setDuplicateActionNewVehicle: match$11[1],
                                              flash: flash
                                            }),
                                        container: true,
                                        item: true,
                                        justifyContent: "center",
                                        spacing: 2
                                      })),
                              item: true,
                              sm: Grid$Mui.Sm[12]
                            }), tmp$1, React.createElement(Core.Grid, {
                              children: React.createElement(Core.Grid, {
                                    children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                          onClick: (function (param) {
                                              Curry._1(dispatch, {
                                                    TAG: /* StoreFlashFilter */8,
                                                    _0: Belt_Option.mapWithDefault(flashFilter, {
                                                          orderType: Flash_Edit_Types$DvmAdminFrontendFr.OrderType.toString(form.input.orderType),
                                                          actionID: Flash_Search_Form$DvmAdminFrontendFr.initialState.actionID,
                                                          actionLabel: Flash_Search_Form$DvmAdminFrontendFr.initialState.actionLabel,
                                                          actionType: Flash_Search_Form$DvmAdminFrontendFr.initialState.actionType,
                                                          budgetType: Flash_Search_Form$DvmAdminFrontendFr.initialState.budgetType,
                                                          startDate: Flash_Search_Form$DvmAdminFrontendFr.initialState.startDate,
                                                          endDate: Flash_Search_Form$DvmAdminFrontendFr.initialState.endDate
                                                        }, (function (x) {
                                                            return {
                                                                    orderType: Flash_Edit_Types$DvmAdminFrontendFr.OrderType.toString(form.input.orderType),
                                                                    actionID: x.actionID,
                                                                    actionLabel: x.actionLabel,
                                                                    actionType: x.actionType,
                                                                    budgetType: x.budgetType,
                                                                    startDate: x.startDate,
                                                                    endDate: x.endDate
                                                                  };
                                                          }))
                                                  });
                                              RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rFlash);
                                            }),
                                          label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.backToList),
                                          size: "medium"
                                        }),
                                    item: true
                                  }),
                              container: true,
                              item: true,
                              justifyContent: "flex-end"
                            }))
                  })
            });
        break;
    
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var Form;

var FormGeneralDates;

var FormNonFleetNewVehicle;

var FormVO;

var Api;

var Types;

var Router;

var make = Flash_Edit_Page;

export {
  Form ,
  FormGeneralDates ,
  FormNonFleetNewVehicle ,
  FormVO ,
  Api ,
  Types ,
  Router ,
  make ,
}
/* react Not a pure module */
