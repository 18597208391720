// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as Messages_Quotas_PARC$DvmAdminFrontendFr from "../../../intl/messages/quotas/Messages_Quotas_PARC.bs.js";
import * as Quota_PARC_Manage_Inputs$DvmAdminFrontendFr from "./Quota_PARC_Manage_Inputs.bs.js";
import * as Quota_PARC_Manage_Excel_Upload$DvmAdminFrontendFr from "./Quota_PARC_Manage_Excel_Upload.bs.js";

function Quota_PARC_Manage_Create_Options(Props) {
  var form = Props.form;
  var onClose = Props.onClose;
  var operation = Props.operation;
  var allModels = Props.allModels;
  var drAndDealers = Props.drAndDealers;
  var inputBoxRef = Props.inputBoxRef;
  var inputsHeight = Props.inputsHeight;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* Form */0;
      });
  var setFormOrUpload = match[1];
  var formOrUpload = match[0];
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              spacing: 2
            }, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(Core.Button, {
                            onClick: (function (param) {
                                Curry._1(setFormOrUpload, (function (param) {
                                        return /* Form */0;
                                      }));
                              }),
                            style: formOrUpload ? ({}) : ({
                                  borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
                                }),
                            children: intl.formatMessage(Messages_Quotas_PARC$DvmAdminFrontendFr.createQuota)
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Core.Button, {
                            onClick: (function (param) {
                                Curry._1(setFormOrUpload, (function (param) {
                                        return /* Upload */1;
                                      }));
                              }),
                            style: formOrUpload ? ({
                                  borderBottom: "solid 5px " + Theme_Colors$DvmAdminFrontendFr.primaryBrown + ""
                                }) : ({}),
                            children: intl.formatMessage(Messages_Quotas_PARC$DvmAdminFrontendFr.importQuotas)
                          }),
                      item: true
                    })), formOrUpload ? React.createElement(Quota_PARC_Manage_Excel_Upload$DvmAdminFrontendFr.make, {
                    inputsHeight: inputsHeight
                  }) : React.createElement(Quota_PARC_Manage_Inputs$DvmAdminFrontendFr.make, {
                    operation: operation,
                    form: form,
                    onClose: onClose,
                    allModels: allModels,
                    drAndDealers: drAndDealers,
                    inputBoxRef: inputBoxRef
                  }));
}

var Types;

var make = Quota_PARC_Manage_Create_Options;

export {
  Types ,
  make ,
}
/* react Not a pure module */
