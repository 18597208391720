// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as Quota_PARC_Search_Types$DvmAdminFrontendFr from "./Quota_PARC_Search_Types.bs.js";

var validators_year = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateRequiredDate(param.year, undefined), (function (date) {
                    return Utils_Api$DvmAdminFrontendFr.dateYearToQueryParam(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.year, Caml_option.some(date));
                  }));
    })
};

var validators_engineType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.engineType,
                    value: param.engineType
                  })
            };
    })
};

var validators_phase = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.phase,
                    value: param.phase
                  })
            };
    })
};

var validators_modelId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.modelId,
                    value: param.modelId
                  })
            };
    })
};

var validators_dealerId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParam(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.dealerId, param.dealerId)
            };
    })
};

var validators_dr = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParam(Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.dr, param.dr)
            };
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParam(true, {
                    fieldId: Quota_PARC_Search_Types$DvmAdminFrontendFr.FilterFields.orderType,
                    value: param.orderType
                  })
            };
    })
};

var validators = {
  year: validators_year,
  engineType: validators_engineType,
  phase: validators_phase,
  modelId: validators_modelId,
  dealerId: validators_dealerId,
  dr: validators_dr,
  orderType: validators_orderType
};

function initialFieldsStatuses(_input) {
  return {
          year: /* Pristine */0,
          engineType: /* Pristine */0,
          phase: /* Pristine */0,
          modelId: /* Pristine */0,
          dealerId: /* Pristine */0,
          dr: /* Pristine */0,
          orderType: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.year;
  var match_0 = match ? match._0 : Curry._1(validators.year.validate, input);
  var match$1 = fieldsStatuses.engineType;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.engineType.validate, input);
  var match$2 = fieldsStatuses.phase;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.phase.validate, input);
  var match$3 = fieldsStatuses.modelId;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.modelId.validate, input);
  var match$4 = fieldsStatuses.dealerId;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.dealerId.validate, input);
  var match$5 = fieldsStatuses.dr;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.dr.validate, input);
  var match$6 = fieldsStatuses.orderType;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.orderType.validate, input);
  var yearResult = match_0;
  var yearResult$1;
  if (yearResult.TAG === /* Ok */0) {
    var engineTypeResult = match_0$1;
    if (engineTypeResult.TAG === /* Ok */0) {
      var phaseResult = match_0$2;
      if (phaseResult.TAG === /* Ok */0) {
        var modelIdResult = match_0$3;
        if (modelIdResult.TAG === /* Ok */0) {
          var dealerIdResult = match_0$4;
          if (dealerIdResult.TAG === /* Ok */0) {
            var drResult = match_0$5;
            if (drResult.TAG === /* Ok */0) {
              var orderTypeResult = match_0$6;
              if (orderTypeResult.TAG === /* Ok */0) {
                return {
                        TAG: /* Valid */0,
                        output: {
                          orderType: orderTypeResult._0,
                          dr: drResult._0,
                          dealerId: dealerIdResult._0,
                          modelId: modelIdResult._0,
                          phase: phaseResult._0,
                          engineType: engineTypeResult._0,
                          year: yearResult._0
                        },
                        fieldsStatuses: {
                          year: /* Dirty */{
                            _0: yearResult,
                            _1: /* Shown */0
                          },
                          engineType: /* Dirty */{
                            _0: engineTypeResult,
                            _1: /* Shown */0
                          },
                          phase: /* Dirty */{
                            _0: phaseResult,
                            _1: /* Shown */0
                          },
                          modelId: /* Dirty */{
                            _0: modelIdResult,
                            _1: /* Shown */0
                          },
                          dealerId: /* Dirty */{
                            _0: dealerIdResult,
                            _1: /* Shown */0
                          },
                          dr: /* Dirty */{
                            _0: drResult,
                            _1: /* Shown */0
                          },
                          orderType: /* Dirty */{
                            _0: orderTypeResult,
                            _1: /* Shown */0
                          }
                        },
                        collectionsStatuses: undefined
                      };
              }
              yearResult$1 = yearResult;
            } else {
              yearResult$1 = yearResult;
            }
          } else {
            yearResult$1 = yearResult;
          }
        } else {
          yearResult$1 = yearResult;
        }
      } else {
        yearResult$1 = yearResult;
      }
    } else {
      yearResult$1 = yearResult;
    }
  } else {
    yearResult$1 = yearResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            year: /* Dirty */{
              _0: yearResult$1,
              _1: /* Shown */0
            },
            engineType: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            phase: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            modelId: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            dealerId: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            dr: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurYearField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.year, validators_year, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: status,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  dr: init.dr,
                                  orderType: init.orderType
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEngineTypeField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.engineType, validators_engineType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  engineType: status,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  dr: init.dr,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPhaseField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.phase, validators_phase, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  engineType: init.engineType,
                                  phase: status,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  dr: init.dr,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelIdField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelId, validators_modelId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: status,
                                  dealerId: init.dealerId,
                                  dr: init.dr,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dealerId, validators_dealerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: status,
                                  dr: init.dr,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDrField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dr, validators_dr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  dr: status,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  year: init.year,
                                  engineType: init.engineType,
                                  phase: init.phase,
                                  modelId: init.modelId,
                                  dealerId: init.dealerId,
                                  dr: init.dr,
                                  orderType: status
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */7 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */7,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */8,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */10);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */9);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */8 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */9 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */10 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateYearField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.year, state.submissionStatus, validators_year, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: status,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            dr: init.dr,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEngineTypeField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.engineType, state.submissionStatus, validators_engineType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            engineType: status,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            dr: init.dr,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePhaseField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.phase, state.submissionStatus, validators_phase, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            engineType: init.engineType,
                                            phase: status,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            dr: init.dr,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelIdField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.modelId, state.submissionStatus, validators_modelId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: status,
                                            dealerId: init.dealerId,
                                            dr: init.dr,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.dealerId, state.submissionStatus, validators_dealerId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: status,
                                            dr: init.dr,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDrField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.dr, state.submissionStatus, validators_dr, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            dr: status,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            year: init.year,
                                            engineType: init.engineType,
                                            phase: init.phase,
                                            modelId: init.modelId,
                                            dealerId: init.dealerId,
                                            dr: init.dr,
                                            orderType: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */7 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */8 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */9 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateYearField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEngineType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEngineTypeField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePhase: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePhaseField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelIdField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDrField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurYear: (function (param) {
              Curry._1(dispatch, /* BlurYearField */0);
            }),
          blurEngineType: (function (param) {
              Curry._1(dispatch, /* BlurEngineTypeField */1);
            }),
          blurPhase: (function (param) {
              Curry._1(dispatch, /* BlurPhaseField */2);
            }),
          blurModelId: (function (param) {
              Curry._1(dispatch, /* BlurModelIdField */3);
            }),
          blurDealerId: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdField */4);
            }),
          blurDr: (function (param) {
              Curry._1(dispatch, /* BlurDrField */5);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */6);
            }),
          yearResult: Formality.exposeFieldResult(state.fieldsStatuses.year),
          engineTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.engineType),
          phaseResult: Formality.exposeFieldResult(state.fieldsStatuses.phase),
          modelIdResult: Formality.exposeFieldResult(state.fieldsStatuses.modelId),
          dealerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerId),
          drResult: Formality.exposeFieldResult(state.fieldsStatuses.dr),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.year || match.engineType || match.phase || match.modelId || match.dealerId || match.dr || match.orderType) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */7);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */8);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */9);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */9,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */10);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState_year = Caml_option.some(new Date(Date.now()));

var initialState$1 = {
  orderType: "",
  dr: /* [] */0,
  dealerId: /* [] */0,
  modelId: "",
  phase: "",
  engineType: "",
  year: initialState_year
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* initialState Not a pure module */
