// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../common/types/App_Types_Date.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../common/types/App_Types_Status.bs.js";

var FieldsView_actionLabel = [
  "actionLabel",
  "Libellé_AC"
];

var FieldsView_actionType = [
  "actionType",
  "Type_d_action"
];

var FieldsView_siret = [
  "orderSiret",
  "SIRET"
];

var FieldsView_actionStatus = [
  "resultActionStatus",
  "Statut_Aide"
];

var FieldsView_actionBonus = [
  "resultBonus",
  "Montant_Euro"
];

var FieldsView_actualAmount = [
  "actualAmount",
  "Montant_Euro"
];

var FieldsView_actionId = [
  "actionId",
  "ID_Action"
];

var FieldsView_budgetYear = [
  "resultBudgetYear",
  "Année"
];

var FieldsView_showDealerHistory = [
  "ResultActiveDealerLocal",
  "Affaire_active"
];

var FieldsView_preinvoiceNumber = [
  "resultPreinvoiceNumber",
  "Numéro_Préfac"
];

var FieldsView_preinvoiceDate = [
  "resultPreinvoiceDate",
  "Date_Préfac"
];

var FieldsView_invoiceDate = [
  "resultInvoiceDate",
  "Date_BAP"
];

var FieldsView_deliveryDate = [
  "deliveryDate",
  "Date_de_livrasion"
];

var FieldsView_dr = [
  "OrderDR",
  "Territoire"
];

var FieldsView_dealerID = [
  "OrderDealerID",
  "Compte_Affaire"
];

var FieldsView_newCarRegistrationID = [
  "OrderRegistrationID",
  "Immatriculation_VN"
];

var FieldsView_customerOrderNumber = [
  "OrderCustomerOrderNumber",
  "Numero_de_commande"
];

var FieldsView_orderNumberCar = [
  "OrderCarOrderNumber",
  "Numero_d_ordre"
];

var FieldsView_budgetType = [
  "OrderBudgetType",
  "Type_de_Budget"
];

var FieldsView_siren = [
  "SIREN",
  "SIREN_Client"
];

var FieldsView_vin = [
  "OrderVIN",
  "VIN"
];

var FieldsView_orderDate = [
  "OrderOrderDate",
  "Date_de_commande"
];

var FieldsView_deliveryDateBoth = [
  "ResultDeliveryDate",
  "Date_Livraison_Client"
];

var FieldsView_modelID = [
  "OrderModelID",
  "Modèle_SC"
];

var FieldsView_versionID = [
  "OrderVersionID",
  "Version_SC"
];

var FieldsView_discount = [
  "OrderCompanyDiscount",
  "Discount"
];

var FieldsView_registrationDate = [
  "OrderRegistrationDate",
  "Date_Immatriculation_VN"
];

var FieldsView_customerInvoice = [
  "OrderCustomerInvoiceDate",
  "Date_Facture_Client"
];

var FieldsView_delivered = [
  "ResultDelivered",
  "Livree"
];

var FieldsView_customerName = [
  "SIRENName",
  "Raison_sociale_client"
];

var FieldsView_salesmanName = [
  "OrderSalesmanName",
  "Nom_de_Vendeur"
];

var FieldsView_agentCode = [
  "OrderAgentCode",
  "Code_de_l_agent"
];

var FieldsView_categoryLabel = [
  "CategoryID",
  "Categorie"
];

var FieldsView_archived = [
  "ResultOrderArchived",
  "Archivée"
];

var FieldsView_renaultInvoiceDate = [
  "OrderRenaultInvoiceDate",
  "Date_Facture_Renault"
];

var FieldsView_dealerName = [
  "DealerName",
  "Raison_sociale_de_l_affaire"
];

var FieldsView_dealerGroup = [
  "DealerGroup",
  "Dealer_Group"
];

var FieldsView_keyAccountManagerID = [
  "DealerKeyAccountManager",
  "Nom_ME_Local"
];

var FieldsView_sectorManagerID = [
  "ContractManagerFinalCustomer",
  "Nom_Intervenant"
];

var FieldsView_sirenClientFinalLLD = [
  "OrderSIRENFinalCustomer",
  "SIRET_Client_Final_LLD"
];

var FieldsView_sirenClientFinalLLDName = [
  "SIRENNameFinalCustomer",
  "Raison_Sociale_Client_Final_LLD"
];

var FieldsView_loueurID = [
  "ContractIDLeaser",
  "ID_Loueur"
];

var FieldsView_loueurName = [
  "ContractLabelLeaser",
  "Libelle_ID_Loueur"
];

var FieldsView_contractID = [
  "ContractIDFinalCustomer",
  "Code_Protocole_Client_Final"
];

var FieldsView_contractName = [
  "ContractLabelFinalCustomer",
  "Libelle_du_contrat"
];

var FieldsView_mainContractSIREN = [
  "ContractMainSIRENFinalCustomer",
  "SIREN_Signataire"
];

var FieldsView_codeAPE = [
  "SIRETAPECode",
  "CodeAPE"
];

var FieldsView_orderType = [
  "OrderOrderType",
  "Type_de_Commande"
];

var FieldsView_brandUsedCar = [
  "OrderUCBrand",
  "Marque_VO"
];

var FieldsView_modelUsedCar = [
  "OrderUCModel",
  "Modele_VO"
];

var FieldsView_vinUsedCar = [
  "OrderUCVIN",
  "VIN_VO"
];

var FieldsView_usedCarPoliceNumber = [
  "UCPoliceNumber",
  "Numero_de_police"
];

var FieldsView_usedCarLastRegistrationDate = [
  "OrderUCLastRegistrationDate",
  "Date_derniere_Immat_VO"
];

var FieldsView_usedCarFirstReleaseDate = [
  "OrderUCFirstReleaseDate",
  "Date_premiere_Immat_VO"
];

var FieldsView_usedCarRegistrationID = [
  "OrderUCRegistrationID",
  "Immat_VO"
];

var FieldsView_orderStatus = [
  "ResultOrderStatus",
  "StatutVN"
];

var FieldsView_usedCarStatus = [
  "ResultUCStatus",
  "StatutVO"
];

var FieldsView_phase = [
  "AOCPhase",
  "Phase"
];

var FieldsView_vehicleType = [
  "AOCVehicleType",
  "Type_de_vehicule"
];

var FieldsView_engineType = [
  "AOCEngineType",
  "Energie"
];

var FieldsView_brand = [
  "AOCBrand",
  "Marque"
];

var FieldsView_firstName = [
  "OrderCustomerFirstName",
  "Prenom_Client"
];

var FieldsView_entryDateParc = [
  "orderParcStartDate",
  "Date_Entree_Parc"
];

var FieldsView_exitDateParc = [
  "orderParcEndDate",
  "Date_Sortie_Parc"
];

var FieldsView = {
  actionLabel: FieldsView_actionLabel,
  actionType: FieldsView_actionType,
  siret: FieldsView_siret,
  actionStatus: FieldsView_actionStatus,
  actionBonus: FieldsView_actionBonus,
  actualAmount: FieldsView_actualAmount,
  actionId: FieldsView_actionId,
  budgetYear: FieldsView_budgetYear,
  showDealerHistory: FieldsView_showDealerHistory,
  preinvoiceNumber: FieldsView_preinvoiceNumber,
  preinvoiceDate: FieldsView_preinvoiceDate,
  invoiceDate: FieldsView_invoiceDate,
  deliveryDate: FieldsView_deliveryDate,
  dr: FieldsView_dr,
  dealerID: FieldsView_dealerID,
  newCarRegistrationID: FieldsView_newCarRegistrationID,
  customerOrderNumber: FieldsView_customerOrderNumber,
  orderNumberCar: FieldsView_orderNumberCar,
  budgetType: FieldsView_budgetType,
  siren: FieldsView_siren,
  vin: FieldsView_vin,
  orderDate: FieldsView_orderDate,
  deliveryDateBoth: FieldsView_deliveryDateBoth,
  modelID: FieldsView_modelID,
  versionID: FieldsView_versionID,
  discount: FieldsView_discount,
  registrationDate: FieldsView_registrationDate,
  customerInvoice: FieldsView_customerInvoice,
  delivered: FieldsView_delivered,
  customerName: FieldsView_customerName,
  salesmanName: FieldsView_salesmanName,
  agentCode: FieldsView_agentCode,
  categoryLabel: FieldsView_categoryLabel,
  archived: FieldsView_archived,
  renaultInvoiceDate: FieldsView_renaultInvoiceDate,
  dealerName: FieldsView_dealerName,
  dealerGroup: FieldsView_dealerGroup,
  keyAccountManagerID: FieldsView_keyAccountManagerID,
  sectorManagerID: FieldsView_sectorManagerID,
  sirenClientFinalLLD: FieldsView_sirenClientFinalLLD,
  sirenClientFinalLLDName: FieldsView_sirenClientFinalLLDName,
  loueurID: FieldsView_loueurID,
  loueurName: FieldsView_loueurName,
  contractID: FieldsView_contractID,
  contractName: FieldsView_contractName,
  mainContractSIREN: FieldsView_mainContractSIREN,
  codeAPE: FieldsView_codeAPE,
  orderType: FieldsView_orderType,
  brandUsedCar: FieldsView_brandUsedCar,
  modelUsedCar: FieldsView_modelUsedCar,
  vinUsedCar: FieldsView_vinUsedCar,
  usedCarPoliceNumber: FieldsView_usedCarPoliceNumber,
  usedCarLastRegistrationDate: FieldsView_usedCarLastRegistrationDate,
  usedCarFirstReleaseDate: FieldsView_usedCarFirstReleaseDate,
  usedCarRegistrationID: FieldsView_usedCarRegistrationID,
  orderStatus: FieldsView_orderStatus,
  usedCarStatus: FieldsView_usedCarStatus,
  phase: FieldsView_phase,
  vehicleType: FieldsView_vehicleType,
  engineType: FieldsView_engineType,
  brand: FieldsView_brand,
  firstName: FieldsView_firstName,
  entryDateParc: FieldsView_entryDateParc,
  exitDateParc: FieldsView_exitDateParc
};

var FieldsManage_dr = [
  "dr",
  "Code_DR"
];

var FieldsManage_dealerId = [
  "dealerId",
  "Compte_Affaire"
];

var FieldsManage_orderId = [
  "orderNumber",
  "Numero_de_commande"
];

var FieldsManage_budgetType = [
  "budgetType",
  "Type_de_Budget"
];

var FieldsManage_actionLabel = [
  "actionLabel",
  "Libellé_AC"
];

var FieldsManage_actionType = [
  "actionType",
  "Type_d_action"
];

var FieldsManage_siret = [
  "siret",
  "SIRET"
];

var FieldsManage_status = [
  "status",
  "Statut"
];

var FieldsManage_model = [
  "ModelID",
  "Modèle"
];

var FieldsManage_version = [
  "VersionID",
  "Version"
];

var FieldsManage_actionBonus = [
  "actionBonus",
  "Montant_Euro"
];

var FieldsManage_actualAmount = [
  "actualAmount",
  "Montant_Euro"
];

var FieldsManage_actionId = [
  "actionId",
  "ID_Action"
];

var FieldsManage_budgetYear = [
  "budgetYear",
  "Année"
];

var FieldsManage_preinvoiceNumber = [
  "PreinvoiceNumber",
  "Numéro_Préfac"
];

var FieldsManage_preinvoiceDate = [
  "PreinvoiceDate",
  "Date_Préfac"
];

var FieldsManage_invoiceDate = [
  "DateBAP",
  "Date_BAP"
];

var FieldsManage_siren = [
  "siren",
  "SIREN"
];

var FieldsManage_delivered = [
  "Delivered",
  "Livree"
];

var FieldsManage_deliveryDate = [
  "deliveryDate",
  "Date_de_livrasion"
];

var FieldsManage_orderDate = [
  "orderDate",
  "Date_de_commande"
];

var FieldsManage_maxAmount = [
  "maxAmount",
  ""
];

var FieldsManage_isOverMaximum = [
  "IsOverMaximum",
  ""
];

var FieldsManage_orderType = [
  "orderType",
  "Type_de_Commande"
];

var FieldsManage = {
  dr: FieldsManage_dr,
  dealerId: FieldsManage_dealerId,
  orderId: FieldsManage_orderId,
  budgetType: FieldsManage_budgetType,
  actionLabel: FieldsManage_actionLabel,
  actionType: FieldsManage_actionType,
  siret: FieldsManage_siret,
  status: FieldsManage_status,
  model: FieldsManage_model,
  version: FieldsManage_version,
  actionBonus: FieldsManage_actionBonus,
  actualAmount: FieldsManage_actualAmount,
  actionId: FieldsManage_actionId,
  budgetYear: FieldsManage_budgetYear,
  preinvoiceNumber: FieldsManage_preinvoiceNumber,
  preinvoiceDate: FieldsManage_preinvoiceDate,
  invoiceDate: FieldsManage_invoiceDate,
  siren: FieldsManage_siren,
  delivered: FieldsManage_delivered,
  deliveryDate: FieldsManage_deliveryDate,
  orderDate: FieldsManage_orderDate,
  maxAmount: FieldsManage_maxAmount,
  isOverMaximum: FieldsManage_isOverMaximum,
  orderType: FieldsManage_orderType
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "dr",
                Decco.stringToJson(v.dr)
              ],
              [
                "dealerId",
                Decco.stringToJson(v.dealerId)
              ],
              [
                "orderId",
                Decco.stringToJson(v.orderId)
              ],
              [
                "orderNumber",
                Decco.stringToJson(v.orderNumber)
              ],
              [
                "budgetType",
                Decco.optionToJson(Decco.stringToJson, v.budgetType)
              ],
              [
                "actionLabel",
                Decco.optionToJson(Decco.stringToJson, v.actionLabel)
              ],
              [
                "actionId",
                Decco.stringToJson(v.actionId)
              ],
              [
                "actionType",
                Decco.optionToJson(Decco.stringToJson, v.actionType)
              ],
              [
                "siret",
                Decco.optionToJson(Decco.stringToJson, v.siret)
              ],
              [
                "status",
                Decco.optionToJson(App_Types_Status$DvmAdminFrontendFr.Decco.t_encode, v.status)
              ],
              [
                "model",
                Decco.optionToJson(Decco.stringToJson, v.model)
              ],
              [
                "version",
                Decco.optionToJson(Decco.stringToJson, v.version)
              ],
              [
                "actionBonus",
                Decco.optionToJson(Decco.floatToJson, v.actionBonus)
              ],
              [
                "actualAmount",
                Decco.optionToJson(Decco.floatToJson, v.actualAmount)
              ],
              [
                "maxAmount",
                Decco.optionToJson(Decco.floatToJson, v.maxAmount)
              ],
              [
                "orderDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderDate)
              ],
              [
                "deliveryDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.deliveryDate)
              ],
              [
                "budgetYear",
                Decco.optionToJson(Decco.stringToJson, v.budgetYear)
              ],
              [
                "approved",
                Decco.optionToJson(Decco.stringToJson, v.approved)
              ],
              [
                "customerName",
                Decco.optionToJson(Decco.stringToJson, v.customerName)
              ],
              [
                "orderType",
                Decco.optionToJson(Decco.stringToJson, v.orderType)
              ],
              [
                "parcStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.parcStartDate)
              ],
              [
                "parcEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.parcEndDate)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var dr = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dr"), null));
  if (dr.TAG === /* Ok */0) {
    var dealerId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerId"), null));
    if (dealerId.TAG === /* Ok */0) {
      var orderId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderId"), null));
      if (orderId.TAG === /* Ok */0) {
        var orderNumber = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderNumber"), null));
        if (orderNumber.TAG === /* Ok */0) {
          var budgetType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetType"), null));
          if (budgetType.TAG === /* Ok */0) {
            var actionLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionLabel"), null));
            if (actionLabel.TAG === /* Ok */0) {
              var actionId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionId"), null));
              if (actionId.TAG === /* Ok */0) {
                var actionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionType"), null));
                if (actionType.TAG === /* Ok */0) {
                  var siret = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
                  if (siret.TAG === /* Ok */0) {
                    var status = Decco.optionFromJson(App_Types_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "status"), null));
                    if (status.TAG === /* Ok */0) {
                      var model = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
                      if (model.TAG === /* Ok */0) {
                        var version = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
                        if (version.TAG === /* Ok */0) {
                          var actionBonus = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionBonus"), null));
                          if (actionBonus.TAG === /* Ok */0) {
                            var actualAmount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actualAmount"), null));
                            if (actualAmount.TAG === /* Ok */0) {
                              var maxAmount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "maxAmount"), null));
                              if (maxAmount.TAG === /* Ok */0) {
                                var orderDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderDate"), null));
                                if (orderDate.TAG === /* Ok */0) {
                                  var deliveryDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deliveryDate"), null));
                                  if (deliveryDate.TAG === /* Ok */0) {
                                    var budgetYear = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetYear"), null));
                                    if (budgetYear.TAG === /* Ok */0) {
                                      var approved = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "approved"), null));
                                      if (approved.TAG === /* Ok */0) {
                                        var customerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerName"), null));
                                        if (customerName.TAG === /* Ok */0) {
                                          var orderType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
                                          if (orderType.TAG === /* Ok */0) {
                                            var parcStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "parcStartDate"), null));
                                            if (parcStartDate.TAG === /* Ok */0) {
                                              var parcEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "parcEndDate"), null));
                                              if (parcEndDate.TAG === /* Ok */0) {
                                                return {
                                                        TAG: /* Ok */0,
                                                        _0: {
                                                          dr: dr._0,
                                                          dealerId: dealerId._0,
                                                          orderId: orderId._0,
                                                          orderNumber: orderNumber._0,
                                                          budgetType: budgetType._0,
                                                          actionLabel: actionLabel._0,
                                                          actionId: actionId._0,
                                                          actionType: actionType._0,
                                                          siret: siret._0,
                                                          status: status._0,
                                                          model: model._0,
                                                          version: version._0,
                                                          actionBonus: actionBonus._0,
                                                          actualAmount: actualAmount._0,
                                                          maxAmount: maxAmount._0,
                                                          orderDate: orderDate._0,
                                                          deliveryDate: deliveryDate._0,
                                                          budgetYear: budgetYear._0,
                                                          approved: approved._0,
                                                          customerName: customerName._0,
                                                          orderType: orderType._0,
                                                          parcStartDate: parcStartDate._0,
                                                          parcEndDate: parcEndDate._0
                                                        }
                                                      };
                                              }
                                              var e = parcEndDate._0;
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: {
                                                        path: ".parcEndDate" + e.path,
                                                        message: e.message,
                                                        value: e.value
                                                      }
                                                    };
                                            }
                                            var e$1 = parcStartDate._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".parcStartDate" + e$1.path,
                                                      message: e$1.message,
                                                      value: e$1.value
                                                    }
                                                  };
                                          }
                                          var e$2 = orderType._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".orderType" + e$2.path,
                                                    message: e$2.message,
                                                    value: e$2.value
                                                  }
                                                };
                                        }
                                        var e$3 = customerName._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".customerName" + e$3.path,
                                                  message: e$3.message,
                                                  value: e$3.value
                                                }
                                              };
                                      }
                                      var e$4 = approved._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".approved" + e$4.path,
                                                message: e$4.message,
                                                value: e$4.value
                                              }
                                            };
                                    }
                                    var e$5 = budgetYear._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".budgetYear" + e$5.path,
                                              message: e$5.message,
                                              value: e$5.value
                                            }
                                          };
                                  }
                                  var e$6 = deliveryDate._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".deliveryDate" + e$6.path,
                                            message: e$6.message,
                                            value: e$6.value
                                          }
                                        };
                                }
                                var e$7 = orderDate._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".orderDate" + e$7.path,
                                          message: e$7.message,
                                          value: e$7.value
                                        }
                                      };
                              }
                              var e$8 = maxAmount._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".maxAmount" + e$8.path,
                                        message: e$8.message,
                                        value: e$8.value
                                      }
                                    };
                            }
                            var e$9 = actualAmount._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".actualAmount" + e$9.path,
                                      message: e$9.message,
                                      value: e$9.value
                                    }
                                  };
                          }
                          var e$10 = actionBonus._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".actionBonus" + e$10.path,
                                    message: e$10.message,
                                    value: e$10.value
                                  }
                                };
                        }
                        var e$11 = version._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".version" + e$11.path,
                                  message: e$11.message,
                                  value: e$11.value
                                }
                              };
                      }
                      var e$12 = model._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".model" + e$12.path,
                                message: e$12.message,
                                value: e$12.value
                              }
                            };
                    }
                    var e$13 = status._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".status" + e$13.path,
                              message: e$13.message,
                              value: e$13.value
                            }
                          };
                  }
                  var e$14 = siret._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".siret" + e$14.path,
                            message: e$14.message,
                            value: e$14.value
                          }
                        };
                }
                var e$15 = actionType._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".actionType" + e$15.path,
                          message: e$15.message,
                          value: e$15.value
                        }
                      };
              }
              var e$16 = actionId._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".actionId" + e$16.path,
                        message: e$16.message,
                        value: e$16.value
                      }
                    };
            }
            var e$17 = actionLabel._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".actionLabel" + e$17.path,
                      message: e$17.message,
                      value: e$17.value
                    }
                  };
          }
          var e$18 = budgetType._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".budgetType" + e$18.path,
                    message: e$18.message,
                    value: e$18.value
                  }
                };
        }
        var e$19 = orderNumber._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".orderNumber" + e$19.path,
                  message: e$19.message,
                  value: e$19.value
                }
              };
      }
      var e$20 = orderId._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".orderId" + e$20.path,
                message: e$20.message,
                value: e$20.value
              }
            };
    }
    var e$21 = dealerId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".dealerId" + e$21.path,
              message: e$21.message,
              value: e$21.value
            }
          };
  }
  var e$22 = dr._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".dr" + e$22.path,
            message: e$22.message,
            value: e$22.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var BonusRows = {
  row_encode: row_encode,
  row_decode: row_decode,
  searchResult_encode: searchResult_encode,
  searchResult_decode: searchResult_decode
};

var PerfRows = {};

function statusItem_encode(v) {
  return Js_dict.fromArray([
              [
                "paramOne",
                Curry._1(App_Types_Status$DvmAdminFrontendFr.Decco.t_encode, v.paramOne)
              ],
              [
                "paramTwo",
                Decco.stringToJson(v.paramTwo)
              ]
            ]);
}

function statusItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var paramOne = Curry._1(App_Types_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "paramOne"), null));
  if (paramOne.TAG === /* Ok */0) {
    var paramTwo = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "paramTwo"), null));
    if (paramTwo.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                paramOne: paramOne._0,
                paramTwo: paramTwo._0
              }
            };
    }
    var e = paramTwo._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".paramTwo" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = paramOne._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".paramOne" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function statusList_encode(v) {
  return Decco.listToJson(statusItem_encode, v);
}

function statusList_decode(v) {
  return Decco.listFromJson(statusItem_decode, v);
}

export {
  FieldsView ,
  FieldsManage ,
  BonusRows ,
  PerfRows ,
  statusItem_encode ,
  statusItem_decode ,
  statusList_encode ,
  statusList_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
