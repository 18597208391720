// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as App_Context$DvmAdminFrontendFr from "../../app/App_Context.bs.js";

var initialState = {
  modelVersionGridManage: undefined,
  modelVersionGridFilter: undefined,
  modelVersionFilter: undefined,
  ordersFilter: undefined,
  ordersModalFilter: undefined,
  ordersModalAdditionalFilter: undefined,
  ordersModalVOFilter: undefined,
  dealersFilter: undefined,
  flashFilter: undefined,
  flashModalFilter: undefined,
  flashAdditionalFilter: undefined,
  contractsFilter: undefined,
  managersFilter: undefined,
  categoryFilter: undefined,
  bonusesFilter: undefined,
  bonusesModalFilter: undefined,
  bonusesModalAdditionalFilter: undefined,
  bonusesModalAdditionalSecondFilter: undefined,
  bonusesModalVOFilter: undefined,
  bonusesPERFFilter: undefined,
  gemoFilter: undefined,
  submarketFilter: undefined,
  quotaViewFilter: undefined,
  quotaAdminFilter: undefined,
  quotaExclusionsFilter: undefined,
  brandsFilter: undefined,
  energyFilter: undefined,
  quotaPARCFilter: undefined,
  bonusAnomaliesFilter: undefined,
  pcdAgreementFilter: undefined
};

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* StoreModelVersionManageContract */0 :
        var newrecord = Caml_obj.obj_dup(state);
        newrecord.modelVersionGridManage = action._0;
        return newrecord;
    case /* StoreModelVersionGridFilter */1 :
        var newrecord$1 = Caml_obj.obj_dup(state);
        newrecord$1.modelVersionGridFilter = action._0;
        return newrecord$1;
    case /* StoreModelVersionFilter */2 :
        var newrecord$2 = Caml_obj.obj_dup(state);
        newrecord$2.modelVersionFilter = action._0;
        return newrecord$2;
    case /* StoreOrdersFilter */3 :
        var newrecord$3 = Caml_obj.obj_dup(state);
        newrecord$3.ordersFilter = action._0;
        return newrecord$3;
    case /* StoreOrdersModalFilter */4 :
        var newrecord$4 = Caml_obj.obj_dup(state);
        newrecord$4.ordersModalFilter = action._0;
        return newrecord$4;
    case /* StoreOrdersModalAdditionalFilter */5 :
        var newrecord$5 = Caml_obj.obj_dup(state);
        newrecord$5.ordersModalAdditionalFilter = action._0;
        return newrecord$5;
    case /* StoreOrdersModalVOFilter */6 :
        var newrecord$6 = Caml_obj.obj_dup(state);
        newrecord$6.ordersModalVOFilter = action._0;
        return newrecord$6;
    case /* StoreDealersFilter */7 :
        var newrecord$7 = Caml_obj.obj_dup(state);
        newrecord$7.dealersFilter = action._0;
        return newrecord$7;
    case /* StoreFlashFilter */8 :
        var newrecord$8 = Caml_obj.obj_dup(state);
        newrecord$8.flashFilter = action._0;
        return newrecord$8;
    case /* StoreFlashModalFilter */9 :
        var newrecord$9 = Caml_obj.obj_dup(state);
        newrecord$9.flashModalFilter = action._0;
        return newrecord$9;
    case /* StoreFlashModalAdditionalFilter */10 :
        var newrecord$10 = Caml_obj.obj_dup(state);
        newrecord$10.flashAdditionalFilter = action._0;
        return newrecord$10;
    case /* StoreContractsFilter */11 :
        var newrecord$11 = Caml_obj.obj_dup(state);
        newrecord$11.contractsFilter = action._0;
        return newrecord$11;
    case /* StoreManagersFilter */12 :
        var newrecord$12 = Caml_obj.obj_dup(state);
        newrecord$12.managersFilter = action._0;
        return newrecord$12;
    case /* StoreCategoryFilter */13 :
        var newrecord$13 = Caml_obj.obj_dup(state);
        newrecord$13.categoryFilter = action._0;
        return newrecord$13;
    case /* StoreBonusesFilter */14 :
        var newrecord$14 = Caml_obj.obj_dup(state);
        newrecord$14.bonusesFilter = action._0;
        return newrecord$14;
    case /* StoreBonusesModalFilter */15 :
        var newrecord$15 = Caml_obj.obj_dup(state);
        newrecord$15.bonusesModalFilter = action._0;
        return newrecord$15;
    case /* StoreBonusesModalAdditionalFilter */16 :
        var newrecord$16 = Caml_obj.obj_dup(state);
        newrecord$16.bonusesModalAdditionalFilter = action._0;
        return newrecord$16;
    case /* StoreBonusesModalAdditionalSecondFilter */17 :
        var newrecord$17 = Caml_obj.obj_dup(state);
        newrecord$17.bonusesModalAdditionalSecondFilter = action._0;
        return newrecord$17;
    case /* StoreBonusesModalVOFilter */18 :
        var newrecord$18 = Caml_obj.obj_dup(state);
        newrecord$18.bonusesModalVOFilter = action._0;
        return newrecord$18;
    case /* StoreBonusesPERFFilter */19 :
        var newrecord$19 = Caml_obj.obj_dup(state);
        newrecord$19.bonusesPERFFilter = action._0;
        return newrecord$19;
    case /* StoreGemoFilter */20 :
        var newrecord$20 = Caml_obj.obj_dup(state);
        newrecord$20.gemoFilter = action._0;
        return newrecord$20;
    case /* StoreSubmarketFilter */21 :
        var newrecord$21 = Caml_obj.obj_dup(state);
        newrecord$21.submarketFilter = action._0;
        return newrecord$21;
    case /* StoreQuotaViewFilter */22 :
        var newrecord$22 = Caml_obj.obj_dup(state);
        newrecord$22.quotaViewFilter = action._0;
        return newrecord$22;
    case /* StoreQuotaAdminFilter */23 :
        var newrecord$23 = Caml_obj.obj_dup(state);
        newrecord$23.quotaAdminFilter = action._0;
        return newrecord$23;
    case /* StoreQuotaExclusionsFilter */24 :
        var newrecord$24 = Caml_obj.obj_dup(state);
        newrecord$24.quotaExclusionsFilter = action._0;
        return newrecord$24;
    case /* StoreBrandsFilter */25 :
        var newrecord$25 = Caml_obj.obj_dup(state);
        newrecord$25.brandsFilter = action._0;
        return newrecord$25;
    case /* StoreEnergyFilter */26 :
        var newrecord$26 = Caml_obj.obj_dup(state);
        newrecord$26.energyFilter = action._0;
        return newrecord$26;
    case /* StoreQuotaPARCFilter */27 :
        var newrecord$27 = Caml_obj.obj_dup(state);
        newrecord$27.quotaPARCFilter = action._0;
        return newrecord$27;
    case /* StoreBonusAnomaliesFilter */28 :
        var newrecord$28 = Caml_obj.obj_dup(state);
        newrecord$28.bonusAnomaliesFilter = action._0;
        return newrecord$28;
    case /* StorePCDAgreementFilter */29 :
        var newrecord$29 = Caml_obj.obj_dup(state);
        newrecord$29.pcdAgreementFilter = action._0;
        return newrecord$29;
    
  }
}

function defaultValue_1(param) {
  
}

var defaultValue = [
  initialState,
  defaultValue_1
];

var include = App_Context$DvmAdminFrontendFr.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

var Context_t = include.t;

var Context_useAppContext = include.useAppContext;

var Context = {
  t: Context_t,
  Provider: Provider,
  useAppContext: Context_useAppContext
};

function Context_Router$Provider(Props) {
  var children = Props.children;
  var match = React.useReducer(reducer, initialState);
  return React.createElement(Provider.make, {
              value: [
                match[0],
                match[1]
              ],
              children: children
            });
}

var Provider$1 = {
  make: Context_Router$Provider
};

export {
  initialState ,
  reducer ,
  Context ,
  Provider$1 as Provider,
}
/* include Not a pure module */
