// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as RestApi$DvmAdminFrontendFr from "../../api/RestApi.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as Home_Types$DvmAdminFrontendFr from "./Home_Types.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as App_Plus_Button$DvmAdminFrontendFr from "../../common/components/button/App_Plus_Button.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../common/components/App_OverlaySpinner.bs.js";
import * as Home_QuickAccess_Modal$DvmAdminFrontendFr from "./modal/Home_QuickAccess_Modal.bs.js";
import * as Home_QuickAccess_Options$DvmAdminFrontendFr from "./Home_QuickAccess_Options.bs.js";
import * as Messages_Home_QuickAccess$DvmAdminFrontendFr from "../../intl/messages/home/Messages_Home_QuickAccess.bs.js";

var box = Css.css(Object.assign({}, {
          height: "45px",
          margin: "8px 15px 8px 0px",
          width: "max-content"
        }, {
          "&:hover": {
            cursor: "pointer"
          }
        }));

var empty = Css.css(Object.assign({}, {
          border: "1px dashed " + Theme_Colors$DvmAdminFrontendFr.grayLight3 + ""
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayHover2
          }
        }));

var item = Css.css(Object.assign({}, {
          backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
          fontWeight: "bold",
          padding: "0px 25px"
        }, {
          "&:hover": {
            backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayHover2
          }
        }));

var Styles = {
  box: box,
  empty: empty,
  item: item
};

function Home_QuickAccess(Props) {
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return [];
      });
  var setData = match$2[1];
  var data = match$2[0];
  var match$3 = React.useState(function () {
        return null;
      });
  var setModal = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setLoading = match$4[1];
  React.useEffect((function () {
          Curry._1(setLoading, (function (param) {
                  return true;
                }));
          RestApi$DvmAdminFrontendFr.call("GET", Home_Types$DvmAdminFrontendFr.quickAccessResponse_decode, undefined, userManager, selectedRole, "/dashboard/quickaccess").then(function (result) {
                if (result.TAG === /* OkResult */0) {
                  var flash = result._0;
                  Curry._1(setData, (function (param) {
                          return flash;
                        }));
                } else {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: result._0
                            }
                          }));
                }
                Curry._1(setLoading, (function (param) {
                        return false;
                      }));
              });
        }), []);
  var quickAccessFiltered = React.useMemo((function () {
          var ar = [];
          Belt_Array.forEach(data, (function (d) {
                  Belt_Array.forEach(Home_QuickAccess_Options$DvmAdminFrontendFr.options(intl, selectedRole), (function (o) {
                          if (o.uuid === d) {
                            ar.push(o);
                            return ;
                          }
                          
                        }));
                }));
          return ar;
        }), [data]);
  var openAddModal = function (param) {
    Curry._1(setModal, (function (param) {
            return React.createElement(Home_QuickAccess_Modal$DvmAdminFrontendFr.make, {
                        onClose: (function (param) {
                            Curry._1(setModal, (function (param) {
                                    return null;
                                  }));
                          }),
                        onOk: (function (values) {
                            Curry._1(setModal, (function (param) {
                                    return null;
                                  }));
                            Curry._1(setLoading, (function (param) {
                                    return true;
                                  }));
                            RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
                                    TAG: /* Json */0,
                                    _0: Home_Types$DvmAdminFrontendFr.quickAccessResponse_encode(values)
                                  }, userManager, selectedRole, "/dashboard/quickaccess").then(function (result) {
                                  if (result.TAG === /* OkResult */0) {
                                    Curry._1(setData, (function (param) {
                                            return values;
                                          }));
                                  } else {
                                    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                                              message: {
                                                TAG: /* RestError */2,
                                                _0: result._0
                                              }
                                            }));
                                  }
                                  Curry._1(setLoading, (function (param) {
                                          return false;
                                        }));
                                });
                          }),
                        initial: Belt_Array.map(quickAccessFiltered, (function (item) {
                                return item.uuid;
                              })),
                        options: Home_QuickAccess_Options$DvmAdminFrontendFr.options(intl, selectedRole)
                      });
          }));
  };
  var match$5 = quickAccessFiltered.length;
  return React.createElement("div", {
              style: {
                borderBottom: "1px solid " + Theme_Colors$DvmAdminFrontendFr.primaryBrown2 + "",
                paddingBottom: "20px"
              }
            }, match$3[0], React.createElement(Core.Box, {
                  children: null,
                  alignItems: "center",
                  display: "flex"
                }, React.createElement("h3", undefined, intl.formatMessage(Messages_Home_QuickAccess$DvmAdminFrontendFr.quickAccessTitle)), React.createElement(App_Plus_Button$DvmAdminFrontendFr.make, {
                      onClick: (function (param) {
                          openAddModal(undefined);
                        })
                    })), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: match$4[0],
                  children: React.createElement(Core.Box, {
                        children: match$5 !== 0 ? Belt_Array.map(quickAccessFiltered, (function (x) {
                                  var match = x.location;
                                  return React.createElement("div", {
                                              key: x.uuid,
                                              onClick: (function (param) {
                                                  RescriptReactRouter.push(x.redirect);
                                                })
                                            }, React.createElement(Core.Box, {
                                                  className: Css.cx([
                                                        box,
                                                        item
                                                      ]),
                                                  children: null,
                                                  alignItems: "center",
                                                  display: "flex",
                                                  gridColumnGap: "20px",
                                                  justifyContent: "space-between"
                                                }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                      size: /* Large */3,
                                                      type_: match ? /* Gear */39 : /* Show */59
                                                    }), React.createElement("div", undefined, x.label), React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                      size: /* Regular */2,
                                                      type_: /* ArrowRight */25
                                                    })));
                                })) : React.createElement("div", {
                                className: Css.cx([
                                      box,
                                      empty
                                    ]),
                                onClick: (function (param) {
                                    openAddModal(undefined);
                                  })
                              }, React.createElement(Core.Box, {
                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                          size: /* Large */3,
                                          type_: /* More */17
                                        }),
                                    alignItems: "center",
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center"
                                  })),
                        display: "flex",
                        flexWrap: "wrap"
                      })
                }));
}

var make = Home_QuickAccess;

export {
  Styles ,
  make ,
}
/* box Not a pure module */
