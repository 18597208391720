// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "@emotion/css";

var oval = Css.css({
      height: "10px",
      width: "10px",
      borderRadius: "5px"
    });

function stateColors(state) {
  switch (state) {
    case /* CALCULABLE */1 :
        return "#ffbd69";
    case /* CANCELED */2 :
        return "#60504a";
    case /* NOBONUS */3 :
        return "#978b7f";
    case /* PAID */4 :
        return "#5cba63";
    case /* PREINVOICED */5 :
        return "#5f5fff";
    case /* VALIDATED */6 :
        return "#66d8d0";
    case /* BLOCKED */0 :
    case /* REFUSED */7 :
        return "#cb4b32";
    case /* TOVALIDATE */8 :
        return "#4c9af6";
    case /* REPARCELED */9 :
        return "orange";
    case /* EMPTYSTATUS */10 :
    case /* MISSINGSTATUS */11 :
        return "black";
    case /* PENDING */12 :
        return "#FF5F1F";
    
  }
}

export {
  oval ,
  stateColors ,
}
/* oval Not a pure module */
