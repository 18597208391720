// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Core from "@material-ui/core";
import * as EnvVar$DvmAdminFrontendFr from "../../../../../bindings/global/EnvVar.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Flash_Search_Modal_Date_Box$DvmAdminFrontendFr from "../Flash_Search_Modal_Date_Box.bs.js";
import * as Flash_Search_Modal_Detention_Box$DvmAdminFrontendFr from "../Flash_Search_Modal_Detention_Box.bs.js";

function Flash_Search_Modal_Date_Filters(Props) {
  var orderType = Props.orderType;
  var form = Props.form;
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true,
              spacing: 7
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                        title: Messages_Flash_Detail$DvmAdminFrontendFr.deliveryPeriod,
                        onChangeFrom: Curry._1(form.updateDeliveryDateStart, (function (input, deliveryDateStart) {
                                return {
                                        registrationDateStart: input.registrationDateStart,
                                        registrationDateEnd: input.registrationDateEnd,
                                        deliveryDateStart: deliveryDateStart,
                                        deliveryDateEnd: input.deliveryDateEnd,
                                        madaDateStart: input.madaDateStart,
                                        madaDateEnd: input.madaDateEnd,
                                        madcDateStart: input.madcDateStart,
                                        madcDateEnd: input.madcDateEnd,
                                        entryDateParcStart: input.entryDateParcStart,
                                        entryDateParcEnd: input.entryDateParcEnd,
                                        exitDateParcStart: input.exitDateParcStart,
                                        exitDateParcEnd: input.exitDateParcEnd,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        onChangeTo: Curry._1(form.updateDeliveryDateEnd, (function (input, deliveryDateEnd) {
                                return {
                                        registrationDateStart: input.registrationDateStart,
                                        registrationDateEnd: input.registrationDateEnd,
                                        deliveryDateStart: input.deliveryDateStart,
                                        deliveryDateEnd: deliveryDateEnd,
                                        madaDateStart: input.madaDateStart,
                                        madaDateEnd: input.madaDateEnd,
                                        madcDateStart: input.madcDateStart,
                                        madcDateEnd: input.madcDateEnd,
                                        entryDateParcStart: input.entryDateParcStart,
                                        entryDateParcEnd: input.entryDateParcEnd,
                                        exitDateParcStart: input.exitDateParcStart,
                                        exitDateParcEnd: input.exitDateParcEnd,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        dateFrom: form.input.deliveryDateStart,
                        dateTo: form.input.deliveryDateEnd,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  item: true,
                  sm: Grid$Mui.Sm[6],
                  spacing: 1
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                        title: Messages_Flash_Detail$DvmAdminFrontendFr.registrationPeriod,
                        onChangeFrom: Curry._1(form.updateRegistrationDateStart, (function (input, registrationDateStart) {
                                return {
                                        registrationDateStart: registrationDateStart,
                                        registrationDateEnd: input.registrationDateEnd,
                                        deliveryDateStart: input.deliveryDateStart,
                                        deliveryDateEnd: input.deliveryDateEnd,
                                        madaDateStart: input.madaDateStart,
                                        madaDateEnd: input.madaDateEnd,
                                        madcDateStart: input.madcDateStart,
                                        madcDateEnd: input.madcDateEnd,
                                        entryDateParcStart: input.entryDateParcStart,
                                        entryDateParcEnd: input.entryDateParcEnd,
                                        exitDateParcStart: input.exitDateParcStart,
                                        exitDateParcEnd: input.exitDateParcEnd,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        onChangeTo: Curry._1(form.updateRegistrationDateEnd, (function (input, registrationDateEnd) {
                                return {
                                        registrationDateStart: input.registrationDateStart,
                                        registrationDateEnd: registrationDateEnd,
                                        deliveryDateStart: input.deliveryDateStart,
                                        deliveryDateEnd: input.deliveryDateEnd,
                                        madaDateStart: input.madaDateStart,
                                        madaDateEnd: input.madaDateEnd,
                                        madcDateStart: input.madcDateStart,
                                        madcDateEnd: input.madcDateEnd,
                                        entryDateParcStart: input.entryDateParcStart,
                                        entryDateParcEnd: input.entryDateParcEnd,
                                        exitDateParcStart: input.exitDateParcStart,
                                        exitDateParcEnd: input.exitDateParcEnd,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        dateFrom: form.input.registrationDateStart,
                        dateTo: form.input.registrationDateEnd,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                        title: Messages_Flash_Detail$DvmAdminFrontendFr.madaPeriod,
                        onChangeFrom: Curry._1(form.updateMadaDateStart, (function (input, madaDateStart) {
                                return {
                                        registrationDateStart: input.registrationDateStart,
                                        registrationDateEnd: input.registrationDateEnd,
                                        deliveryDateStart: input.deliveryDateStart,
                                        deliveryDateEnd: input.deliveryDateEnd,
                                        madaDateStart: madaDateStart,
                                        madaDateEnd: input.madaDateEnd,
                                        madcDateStart: input.madcDateStart,
                                        madcDateEnd: input.madcDateEnd,
                                        entryDateParcStart: input.entryDateParcStart,
                                        entryDateParcEnd: input.entryDateParcEnd,
                                        exitDateParcStart: input.exitDateParcStart,
                                        exitDateParcEnd: input.exitDateParcEnd,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        onChangeTo: Curry._1(form.updateMadaDateEnd, (function (input, madaDateEnd) {
                                return {
                                        registrationDateStart: input.registrationDateStart,
                                        registrationDateEnd: input.registrationDateEnd,
                                        deliveryDateStart: input.deliveryDateStart,
                                        deliveryDateEnd: input.deliveryDateEnd,
                                        madaDateStart: input.madaDateStart,
                                        madaDateEnd: madaDateEnd,
                                        madcDateStart: input.madcDateStart,
                                        madcDateEnd: input.madcDateEnd,
                                        entryDateParcStart: input.entryDateParcStart,
                                        entryDateParcEnd: input.entryDateParcEnd,
                                        exitDateParcStart: input.exitDateParcStart,
                                        exitDateParcEnd: input.exitDateParcEnd,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        dateFrom: form.input.madaDateStart,
                        dateTo: form.input.madaDateEnd,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                        title: Messages_Flash_Detail$DvmAdminFrontendFr.madcPeriod,
                        onChangeFrom: Curry._1(form.updateMadcDateStart, (function (input, madcDateStart) {
                                return {
                                        registrationDateStart: input.registrationDateStart,
                                        registrationDateEnd: input.registrationDateEnd,
                                        deliveryDateStart: input.deliveryDateStart,
                                        deliveryDateEnd: input.deliveryDateEnd,
                                        madaDateStart: input.madaDateStart,
                                        madaDateEnd: input.madaDateEnd,
                                        madcDateStart: madcDateStart,
                                        madcDateEnd: input.madcDateEnd,
                                        entryDateParcStart: input.entryDateParcStart,
                                        entryDateParcEnd: input.entryDateParcEnd,
                                        exitDateParcStart: input.exitDateParcStart,
                                        exitDateParcEnd: input.exitDateParcEnd,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        onChangeTo: Curry._1(form.updateMadcDateEnd, (function (input, madcDateEnd) {
                                return {
                                        registrationDateStart: input.registrationDateStart,
                                        registrationDateEnd: input.registrationDateEnd,
                                        deliveryDateStart: input.deliveryDateStart,
                                        deliveryDateEnd: input.deliveryDateEnd,
                                        madaDateStart: input.madaDateStart,
                                        madaDateEnd: input.madaDateEnd,
                                        madcDateStart: input.madcDateStart,
                                        madcDateEnd: madcDateEnd,
                                        entryDateParcStart: input.entryDateParcStart,
                                        entryDateParcEnd: input.entryDateParcEnd,
                                        exitDateParcStart: input.exitDateParcStart,
                                        exitDateParcEnd: input.exitDateParcEnd,
                                        detentionMinFrom: input.detentionMinFrom,
                                        detentionMinTo: input.detentionMinTo,
                                        detentionMaxFrom: input.detentionMaxFrom,
                                        detentionMaxTo: input.detentionMaxTo
                                      };
                              })),
                        dateFrom: form.input.madcDateStart,
                        dateTo: form.input.madcDateEnd,
                        className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                      }),
                  container: true,
                  item: true,
                  sm: Grid$Mui.Sm[6]
                }), EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? null : React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                        children: React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                              disabled: orderType !== "" && orderType !== "VE" && orderType !== "RR" && orderType !== "RC",
                              title: Messages_Orders_Detail$DvmAdminFrontendFr.entryDateParc,
                              onChangeFrom: Curry._1(form.updateEntryDateParcStart, (function (input, entryDateParcStart) {
                                      return {
                                              registrationDateStart: input.registrationDateStart,
                                              registrationDateEnd: input.registrationDateEnd,
                                              deliveryDateStart: input.deliveryDateStart,
                                              deliveryDateEnd: input.deliveryDateEnd,
                                              madaDateStart: input.madaDateStart,
                                              madaDateEnd: input.madaDateEnd,
                                              madcDateStart: input.madcDateStart,
                                              madcDateEnd: input.madcDateEnd,
                                              entryDateParcStart: entryDateParcStart,
                                              entryDateParcEnd: input.entryDateParcEnd,
                                              exitDateParcStart: input.exitDateParcStart,
                                              exitDateParcEnd: input.exitDateParcEnd,
                                              detentionMinFrom: input.detentionMinFrom,
                                              detentionMinTo: input.detentionMinTo,
                                              detentionMaxFrom: input.detentionMaxFrom,
                                              detentionMaxTo: input.detentionMaxTo
                                            };
                                    })),
                              onChangeTo: Curry._1(form.updateEntryDateParcEnd, (function (input, entryDateParcEnd) {
                                      return {
                                              registrationDateStart: input.registrationDateStart,
                                              registrationDateEnd: input.registrationDateEnd,
                                              deliveryDateStart: input.deliveryDateStart,
                                              deliveryDateEnd: input.deliveryDateEnd,
                                              madaDateStart: input.madaDateStart,
                                              madaDateEnd: input.madaDateEnd,
                                              madcDateStart: input.madcDateStart,
                                              madcDateEnd: input.madcDateEnd,
                                              entryDateParcStart: input.entryDateParcStart,
                                              entryDateParcEnd: entryDateParcEnd,
                                              exitDateParcStart: input.exitDateParcStart,
                                              exitDateParcEnd: input.exitDateParcEnd,
                                              detentionMinFrom: input.detentionMinFrom,
                                              detentionMinTo: input.detentionMinTo,
                                              detentionMaxFrom: input.detentionMaxFrom,
                                              detentionMaxTo: input.detentionMaxTo
                                            };
                                    })),
                              dateFrom: form.input.entryDateParcStart,
                              dateTo: form.input.entryDateParcEnd,
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                            }),
                        container: true,
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(Flash_Search_Modal_Date_Box$DvmAdminFrontendFr.make, {
                              disabled: orderType !== "" && orderType !== "VE" && orderType !== "RR" && orderType !== "RC",
                              title: Messages_Orders_Detail$DvmAdminFrontendFr.exitDateParc,
                              onChangeFrom: Curry._1(form.updateExitDateParcStart, (function (input, exitDateParcStart) {
                                      return {
                                              registrationDateStart: input.registrationDateStart,
                                              registrationDateEnd: input.registrationDateEnd,
                                              deliveryDateStart: input.deliveryDateStart,
                                              deliveryDateEnd: input.deliveryDateEnd,
                                              madaDateStart: input.madaDateStart,
                                              madaDateEnd: input.madaDateEnd,
                                              madcDateStart: input.madcDateStart,
                                              madcDateEnd: input.madcDateEnd,
                                              entryDateParcStart: input.entryDateParcStart,
                                              entryDateParcEnd: input.entryDateParcEnd,
                                              exitDateParcStart: exitDateParcStart,
                                              exitDateParcEnd: input.exitDateParcEnd,
                                              detentionMinFrom: input.detentionMinFrom,
                                              detentionMinTo: input.detentionMinTo,
                                              detentionMaxFrom: input.detentionMaxFrom,
                                              detentionMaxTo: input.detentionMaxTo
                                            };
                                    })),
                              onChangeTo: Curry._1(form.updateExitDateParcEnd, (function (input, exitDateParcEnd) {
                                      return {
                                              registrationDateStart: input.registrationDateStart,
                                              registrationDateEnd: input.registrationDateEnd,
                                              deliveryDateStart: input.deliveryDateStart,
                                              deliveryDateEnd: input.deliveryDateEnd,
                                              madaDateStart: input.madaDateStart,
                                              madaDateEnd: input.madaDateEnd,
                                              madcDateStart: input.madcDateStart,
                                              madcDateEnd: input.madcDateEnd,
                                              entryDateParcStart: input.entryDateParcStart,
                                              entryDateParcEnd: input.entryDateParcEnd,
                                              exitDateParcStart: input.exitDateParcStart,
                                              exitDateParcEnd: exitDateParcEnd,
                                              detentionMinFrom: input.detentionMinFrom,
                                              detentionMinTo: input.detentionMinTo,
                                              detentionMaxFrom: input.detentionMaxFrom,
                                              detentionMaxTo: input.detentionMaxTo
                                            };
                                    })),
                              dateFrom: form.input.exitDateParcStart,
                              dateTo: form.input.exitDateParcEnd,
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                            }),
                        container: true,
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(Flash_Search_Modal_Detention_Box$DvmAdminFrontendFr.make, {
                              disabled: orderType !== "" && orderType !== "VE" && orderType !== "RR" && orderType !== "RC",
                              title: Messages_Flash_Search$DvmAdminFrontendFr.detentionDurationMin,
                              onChangeFrom: Curry._1(form.updateDetentionMinFrom, (function (input, detentionMinFrom) {
                                      return {
                                              registrationDateStart: input.registrationDateStart,
                                              registrationDateEnd: input.registrationDateEnd,
                                              deliveryDateStart: input.deliveryDateStart,
                                              deliveryDateEnd: input.deliveryDateEnd,
                                              madaDateStart: input.madaDateStart,
                                              madaDateEnd: input.madaDateEnd,
                                              madcDateStart: input.madcDateStart,
                                              madcDateEnd: input.madcDateEnd,
                                              entryDateParcStart: input.entryDateParcStart,
                                              entryDateParcEnd: input.entryDateParcEnd,
                                              exitDateParcStart: input.exitDateParcStart,
                                              exitDateParcEnd: input.exitDateParcEnd,
                                              detentionMinFrom: detentionMinFrom,
                                              detentionMinTo: input.detentionMinTo,
                                              detentionMaxFrom: input.detentionMaxFrom,
                                              detentionMaxTo: input.detentionMaxTo
                                            };
                                    })),
                              onChangeTo: Curry._1(form.updateDetentionMinTo, (function (input, detentionMinTo) {
                                      return {
                                              registrationDateStart: input.registrationDateStart,
                                              registrationDateEnd: input.registrationDateEnd,
                                              deliveryDateStart: input.deliveryDateStart,
                                              deliveryDateEnd: input.deliveryDateEnd,
                                              madaDateStart: input.madaDateStart,
                                              madaDateEnd: input.madaDateEnd,
                                              madcDateStart: input.madcDateStart,
                                              madcDateEnd: input.madcDateEnd,
                                              entryDateParcStart: input.entryDateParcStart,
                                              entryDateParcEnd: input.entryDateParcEnd,
                                              exitDateParcStart: input.exitDateParcStart,
                                              exitDateParcEnd: input.exitDateParcEnd,
                                              detentionMinFrom: input.detentionMinFrom,
                                              detentionMinTo: detentionMinTo,
                                              detentionMaxFrom: input.detentionMaxFrom,
                                              detentionMaxTo: input.detentionMaxTo
                                            };
                                    })),
                              valFrom: form.input.detentionMinFrom,
                              valTo: form.input.detentionMinTo,
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                            }),
                        container: true,
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(Flash_Search_Modal_Detention_Box$DvmAdminFrontendFr.make, {
                              disabled: orderType !== "" && orderType !== "VE" && orderType !== "RR" && orderType !== "RC",
                              title: Messages_Flash_Search$DvmAdminFrontendFr.detentionDurationMax,
                              onChangeFrom: Curry._1(form.updateDetentionMaxFrom, (function (input, detentionMaxFrom) {
                                      return {
                                              registrationDateStart: input.registrationDateStart,
                                              registrationDateEnd: input.registrationDateEnd,
                                              deliveryDateStart: input.deliveryDateStart,
                                              deliveryDateEnd: input.deliveryDateEnd,
                                              madaDateStart: input.madaDateStart,
                                              madaDateEnd: input.madaDateEnd,
                                              madcDateStart: input.madcDateStart,
                                              madcDateEnd: input.madcDateEnd,
                                              entryDateParcStart: input.entryDateParcStart,
                                              entryDateParcEnd: input.entryDateParcEnd,
                                              exitDateParcStart: input.exitDateParcStart,
                                              exitDateParcEnd: input.exitDateParcEnd,
                                              detentionMinFrom: input.detentionMinFrom,
                                              detentionMinTo: input.detentionMinTo,
                                              detentionMaxFrom: detentionMaxFrom,
                                              detentionMaxTo: input.detentionMaxTo
                                            };
                                    })),
                              onChangeTo: Curry._1(form.updateDetentionMaxTo, (function (input, detentionMaxTo) {
                                      return {
                                              registrationDateStart: input.registrationDateStart,
                                              registrationDateEnd: input.registrationDateEnd,
                                              deliveryDateStart: input.deliveryDateStart,
                                              deliveryDateEnd: input.deliveryDateEnd,
                                              madaDateStart: input.madaDateStart,
                                              madaDateEnd: input.madaDateEnd,
                                              madcDateStart: input.madcDateStart,
                                              madcDateEnd: input.madcDateEnd,
                                              entryDateParcStart: input.entryDateParcStart,
                                              entryDateParcEnd: input.entryDateParcEnd,
                                              exitDateParcStart: input.exitDateParcStart,
                                              exitDateParcEnd: input.exitDateParcEnd,
                                              detentionMinFrom: input.detentionMinFrom,
                                              detentionMinTo: input.detentionMinTo,
                                              detentionMaxFrom: input.detentionMaxFrom,
                                              detentionMaxTo: detentionMaxTo
                                            };
                                    })),
                              valFrom: form.input.detentionMaxFrom,
                              valTo: form.input.detentionMaxTo,
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                            }),
                        container: true,
                        item: true,
                        sm: Grid$Mui.Sm[6]
                      })));
}

var InputStyles;

var make = Flash_Search_Modal_Date_Filters;

export {
  InputStyles ,
  make ,
}
/* react Not a pure module */
