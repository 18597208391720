// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Container$Mui from "rescript-material-ui/src/Container.bs.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../../common/components/button/App_Clickable_Element.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Correct_Utils$DvmAdminFrontendFr from "./Order_Detail_Correct_Utils.bs.js";
import * as Order_Detail_Correct_Perfs_New$DvmAdminFrontendFr from "./views/perfs/Order_Detail_Correct_Perfs_New.bs.js";
import * as Order_Detail_Correct_Vehicle_New$DvmAdminFrontendFr from "./views/vehicle/Order_Detail_Correct_Vehicle_New.bs.js";
import * as Order_Detail_Correct_Perfs_Content$DvmAdminFrontendFr from "./views/perfs/Order_Detail_Correct_Perfs_Content.bs.js";
import * as Order_Detail_Correct_Order_Separator$DvmAdminFrontendFr from "./views/order/Order_Detail_Correct_Order_Separator.bs.js";
import * as Order_Detail_Correct_Vehicle_Content$DvmAdminFrontendFr from "./views/vehicle/Order_Detail_Correct_Vehicle_Content.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Utils$DvmAdminFrontendFr from "../oldVehicle/edits/Order_Detail_Old_Vehicle_Edits_Utils.bs.js";

function Order_Detail_Correct_New(Props) {
  var order = Props.order;
  var form = Props.form;
  var formPerfs = Props.formPerfs;
  var formVehicle = Props.formVehicle;
  var formAdditional = Props.formAdditional;
  var newestUndeployedCorrection = Props.newestUndeployedCorrection;
  var submitForm = Props.submitForm;
  var result = Props.result;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return Order_Detail_Correct_Utils$DvmAdminFrontendFr.shouldShowVO(order, newestUndeployedCorrection);
      });
  var setShowVehicle = match[1];
  var vehicleEditDisabled = !Order_Detail_Old_Vehicle_Edits_Utils$DvmAdminFrontendFr.voActionsStatesEditable(order) || !Belt_Option.getWithDefault(order.detail.delivered, false) || Belt_Option.getWithDefault(order.archived, false);
  var tmp = {
    onChange: Curry._1(form.updateComment, (function (input, comment) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.comment = comment;
            return newrecord;
          })),
    value: form.input.comment,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Common$DvmAdminFrontendFr.comment,
    required: /* HtmlRequired */0,
    placeholder: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.maxCharactersAllowed, {
          max: 80
        })
  };
  if (form.commentResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.commentResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Container, {
                  children: React.createElement("form", {
                        onSubmit: (function ($$event) {
                            $$event.preventDefault();
                            Curry._1(submitForm, undefined);
                          })
                      }, React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            spacing: 5
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                      color: "primary",
                                      style: {
                                        fontWeight: "600"
                                      },
                                      children: "" + intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.titleCorrection) + " " + Belt_Option.getWithDefault(order.orderNumberCustomer, "-") + "",
                                      variant: "h3"
                                    }),
                                item: true
                              }), React.createElement(Core.Container, {
                                children: React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      direction: "column",
                                      spacing: 5
                                    }, React.createElement(Core.Grid, {
                                          children: React.createElement(Order_Detail_Correct_Order_Separator$DvmAdminFrontendFr.make, {
                                                form: form,
                                                formAdditional: formAdditional,
                                                order: order,
                                                newestUndeployedCorrection: newestUndeployedCorrection
                                              }),
                                          container: true,
                                          item: true,
                                          spacing: 2
                                        }), React.createElement(Core.Grid, {
                                          children: null,
                                          container: true,
                                          item: true,
                                          spacing: 2
                                        }, React.createElement(Order_Detail_Correct_Perfs_Content$DvmAdminFrontendFr.make, {
                                              order: order,
                                              color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                              title: React.createElement(Core.Grid, {
                                                    children: null,
                                                    container: true,
                                                    spacing: 1
                                                  }, React.createElement(Core.Grid, {
                                                        children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.initialValuesPerf),
                                                        item: true
                                                      }), Belt_Option.mapWithDefault(newestUndeployedCorrection, null, (function (correction) {
                                                          return React.createElement(Core.Grid, {
                                                                      children: React.createElement(Core.Tooltip, {
                                                                            arrow: true,
                                                                            children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                                      size: /* Large */3,
                                                                                      type_: /* Information */43
                                                                                    })),
                                                                            classes: {
                                                                              tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                            },
                                                                            placement: "bottom",
                                                                            title: "" + intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.lastUndeployedMod) + " " + Belt_Option.mapWithDefault(correction.timeStamp, "", (function (param) {
                                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                  })) + ""
                                                                          }),
                                                                      item: true
                                                                    });
                                                        }))),
                                              newestUndeployedCorrection: newestUndeployedCorrection
                                            }), React.createElement(Order_Detail_Correct_Perfs_New$DvmAdminFrontendFr.make, {
                                              form: formPerfs,
                                              order: order
                                            })), match[0] ? React.createElement(Core.Grid, {
                                            children: null,
                                            container: true,
                                            item: true,
                                            spacing: 2
                                          }, React.createElement(Order_Detail_Correct_Vehicle_Content$DvmAdminFrontendFr.make, {
                                                usedCarDetail: order.usedCarDetail,
                                                color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                                title: React.createElement(Core.Grid, {
                                                      children: null,
                                                      container: true,
                                                      spacing: 1
                                                    }, React.createElement(Core.Grid, {
                                                          children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.initialCarValues),
                                                          item: true
                                                        }), Belt_Option.mapWithDefault(newestUndeployedCorrection, null, (function (correction) {
                                                            return React.createElement(Core.Grid, {
                                                                        children: React.createElement(Core.Tooltip, {
                                                                              arrow: true,
                                                                              children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                                        size: /* Large */3,
                                                                                        type_: /* Information */43
                                                                                      })),
                                                                              classes: {
                                                                                tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                              },
                                                                              placement: "bottom",
                                                                              title: "" + intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.lastUndeployedMod) + " " + Belt_Option.mapWithDefault(correction.timeStamp, "", (function (param) {
                                                                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                    })) + ""
                                                                            }),
                                                                        item: true
                                                                      });
                                                          }))),
                                                newestUndeployedCorrection: newestUndeployedCorrection,
                                                form: form
                                              }), React.createElement(Order_Detail_Correct_Vehicle_New$DvmAdminFrontendFr.make, {
                                                form: form,
                                                formVehicle: formVehicle,
                                                vehicleEditDisabled: vehicleEditDisabled
                                              })) : React.createElement(Core.Grid, {
                                            children: React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                                                  onClick: (function (param) {
                                                      Curry._1(setShowVehicle, (function (param) {
                                                              return true;
                                                            }));
                                                    }),
                                                  disabled: vehicleEditDisabled,
                                                  children: React.createElement(Core.Grid, {
                                                        children: null,
                                                        container: true,
                                                        spacing: 1
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                  size: /* Large */3,
                                                                  type_: /* More */17
                                                                }),
                                                            item: true
                                                          }), React.createElement(Core.Grid, {
                                                            children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.appendCarInfo),
                                                            item: true
                                                          }))
                                                }),
                                            container: true,
                                            item: true,
                                            justifyContent: "flex-start"
                                          }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                          item: true
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                                show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                      label: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.validateCorrection),
                                                      size: "medium",
                                                      type_: "submit"
                                                    })
                                              }),
                                          container: true,
                                          item: true,
                                          justifyContent: "center"
                                        })),
                                style: {
                                  maxWidth: "650px"
                                }
                              }))),
                  maxWidth: Container$Mui.MaxWidth.md
                }));
}

var Form;

var FormPerfs;

var FormVehicle;

var FormAdditional;

var Types;

var UtilsCorrect;

var InputStyles;

var make = Order_Detail_Correct_New;

export {
  Form ,
  FormPerfs ,
  FormVehicle ,
  FormAdditional ,
  Types ,
  UtilsCorrect ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
