// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../common/App_Api.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Quotas$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../common/components/input/App_DateInputFormField.bs.js";
import * as Messages_Quotas_Detail$DvmAdminFrontendFr from "../../../../intl/messages/quotas/Messages_Quotas_Detail.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Quota_View_Exclusion_Filters(Props) {
  var form = Props.form;
  var tableType = Props.tableType;
  var contractOptions = Props.contractOptions;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModelVersionOptions = match$3[1];
  var modelVersionOptions = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setVersionOptions = match$4[1];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setModelVersionOptions, userManager, selectedRole, undefined, undefined, undefined);
        }), []);
  React.useEffect((function () {
          Curry._2(form.updateVersion, (function (input, param) {
                  return {
                          model: input.model,
                          version: "",
                          contractId: input.contractId,
                          vehicleType: input.vehicleType,
                          startDate: input.startDate,
                          endDate: input.endDate
                        };
                }), "");
          Curry._1(setVersionOptions, (function (param) {
                  return Belt_Option.mapWithDefault(Belt_List.getBy(App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersionOptions, /* [] */0), (function (x) {
                                    return x.parent === form.input.model;
                                  })), /* [] */0, (function (item) {
                                return item.child;
                              }));
                }));
        }), [form.input.model]);
  var tmp = {
    label: Messages_Quotas$DvmAdminFrontendFr.contractId,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.contractId,
        onChange: Curry._1(form.updateContractId, (function (input, contractId) {
                return {
                        model: input.model,
                        version: input.version,
                        contractId: contractId,
                        vehicleType: input.vehicleType,
                        startDate: input.startDate,
                        endDate: input.endDate
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(contractOptions, (function (item) {
                          return item.paramOne;
                        })))))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.contractIdResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.contractIdResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    model: input.model,
                    version: input.version,
                    contractId: input.contractId,
                    vehicleType: input.vehicleType,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.startDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    model: input.model,
                    version: input.version,
                    contractId: input.contractId,
                    vehicleType: input.vehicleType,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.endDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreQuotaExclusionsFilter */24,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      style: {
                        width: "fit-content"
                      }
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.FormControlLabel, {
                                classes: {
                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                },
                                control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                      checked: withClosedModels,
                                      onClick: (function ($$event) {
                                          Curry._1(setWithClosedModels, (function (param) {
                                                  return $$event.target.checked;
                                                }));
                                        })
                                    }),
                                label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                              }),
                          item: true,
                          style: {
                            whiteSpace: "nowrap",
                            width: "1px"
                          }
                        })), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Quotas_Detail$DvmAdminFrontendFr.vehicleType,
                            options: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Common$DvmAdminFrontendFr.nothing
                                },
                                value: ""
                              },
                              tl: {
                                hd: {
                                  label: {
                                    TAG: /* String */3,
                                    _0: "VP"
                                  },
                                  value: "VP"
                                },
                                tl: {
                                  hd: {
                                    label: {
                                      TAG: /* String */3,
                                      _0: "VU"
                                    },
                                    value: "VU"
                                  },
                                  tl: {
                                    hd: {
                                      label: {
                                        TAG: /* String */3,
                                        _0: "VPVU"
                                      },
                                      value: "VPVU"
                                    },
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.vehicleType,
                                onChange: Curry._1(form.updateVehicleType, (function (input, vehicleType) {
                                        return {
                                                model: input.model,
                                                version: input.version,
                                                contractId: input.contractId,
                                                vehicleType: vehicleType,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            id: "vehicleType",
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.model,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.model,
                                onChange: Curry._1(form.updateModel, (function (input, model) {
                                        return {
                                                model: model,
                                                version: input.version,
                                                contractId: input.contractId,
                                                vehicleType: input.vehicleType,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(modelVersionOptions, /* [] */0), (function (item) {
                                      return item.parent;
                                    }))
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), tableType === /* Version */1 ? React.createElement(Core.Grid, {
                        children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                              label: Messages_Common$DvmAdminFrontendFr.version,
                              value: {
                                TAG: /* Single */0,
                                _0: {
                                  value: form.input.version,
                                  onChange: Curry._1(form.updateVersion, (function (input, version) {
                                          return {
                                                  model: input.model,
                                                  version: version,
                                                  contractId: input.contractId,
                                                  vehicleType: input.vehicleType,
                                                  startDate: input.startDate,
                                                  endDate: input.endDate
                                                };
                                        }))
                                }
                              },
                              options: {
                                TAG: /* Unlabeled */0,
                                _0: match$4[0]
                              },
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                            }),
                        item: true
                      }) : null, React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var InputStyles;

var Form;

var make = Quota_View_Exclusion_Filters;

export {
  InputStyles ,
  Form ,
  make ,
}
/* react Not a pure module */
