// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Result.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Order_Search_Api$DvmAdminFrontendFr from "./Order_Search_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Order_Search_Table$DvmAdminFrontendFr from "./Order_Search_Table.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as Order_Search_Filters$DvmAdminFrontendFr from "./Order_Search_Filters.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../../intl/messages/orders/Messages_Orders_Search.bs.js";

function Order_Search_Page(Props) {
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$2[1];
  var tablePage = match$2[0];
  var match$3 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Order_Search_Table$DvmAdminFrontendFr.headersConfiguration(selectedRole),
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$3[1];
  var fetchRequest = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var match$5 = React.useState(function () {
        return false;
      });
  var alert = ReactAlert.useAlert();
  var match$6 = React.useState(function () {
        return /* NOTSET */7;
      });
  var fetchData = function (submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, submissionCallbacksModalVO, fetchRequest) {
    Order_Search_Api$DvmAdminFrontendFr.fetchOrderSearch(submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, submissionCallbacksModalVO, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams("normal", fetchRequest), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var tmp;
  if (typeof tablePage === "number") {
    tmp = null;
  } else if (tablePage.TAG === /* Ok */0) {
    tmp = React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
          number: tablePage._0.total,
          message: Messages_Orders_Search$DvmAdminFrontendFr.orders
        });
  } else {
    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
              message: {
                TAG: /* RestError */2,
                _0: tablePage._0
              }
            }));
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Orders_Search$DvmAdminFrontendFr.title),
                  variant: "h3"
                }), React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(tablePage),
                  children: React.createElement(Order_Search_Filters$DvmAdminFrontendFr.make, {
                        fetchData: fetchData,
                        fetchRequest: fetchRequest,
                        setParamsList: match$4[1],
                        setShowHistory: match$5[1],
                        setConfirmedOrderType: match$6[1]
                      })
                }), React.createElement(Core.Box, {
                  children: null
                }, tmp, React.createElement(Order_Search_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      paramsList: match$4[0],
                      showHistory: match$5[0],
                      confirmedOrderType: match$6[0]
                    }), tablePage === 0 ? React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        style: {
                          fontWeight: "600",
                          paddingTop: "100px",
                          textAlign: "center"
                        },
                        children: intl.formatMessage(Messages_Orders_Search$DvmAdminFrontendFr.searchForOrder),
                        variant: "bodyBig"
                      }) : null));
}

var Api;

var Table;

var Filter;

var make = Order_Search_Page;

export {
  Api ,
  Table ,
  Filter ,
  make ,
}
/* react Not a pure module */
