// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Order_Search_Types$DvmAdminFrontendFr from "./Order_Search_Types.bs.js";

function fetchOrderSearch(submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, submissionCallbacksModalVO, userManager, setResult, queryParams, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Order_Search_Types$DvmAdminFrontendFr.OrderResult.searchResult_decode, undefined, userManager, selectedRole, "/orders/page" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModal, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModalAdditional, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModalVO, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchOrderSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModal, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchOrderSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModalAdditional, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchOrderSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModalVO, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchOrderSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function queryForStatuses(alert, userManager, setStatuses, selectedRole) {
  RestApi$DvmAdminFrontendFr.call("GET", Order_Search_Types$DvmAdminFrontendFr.statusList_decode, undefined, userManager, selectedRole, "/dropdown/statuses/orders").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var statuses = result._0;
          return Curry._1(setStatuses, (function (param) {
                        if (selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) {
                          return Belt_List.keep(statuses, (function (x) {
                                        return x.paramOne !== /* REPARCELED */9;
                                      }));
                        } else {
                          return statuses;
                        }
                      }));
        }
        Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                  message: {
                    TAG: /* RestError */2,
                    _0: result._0
                  }
                }));
        return Curry._1(setStatuses, (function (param) {
                      return /* [] */0;
                    }));
      });
}

var Form;

var FormModal;

var FormModalAdditional;

var FormModalVO;

export {
  Form ,
  FormModal ,
  FormModalAdditional ,
  FormModalVO ,
  fetchOrderSearch ,
  queryForStatuses ,
}
/* react Not a pure module */
