// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../components/button/App_Button.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../types/App_Types_Result.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../intl/messages/Messages_File_Upload.bs.js";
import * as File_Upload_Excel_Check$DvmAdminFrontendFr from "./steps/excelCheck/File_Upload_Excel_Check.bs.js";
import * as File_Upload_Import_Mode$DvmAdminFrontendFr from "./steps/File_Upload_Import_Mode.bs.js";
import * as File_Upload_Stepper_Icon$DvmAdminFrontendFr from "./File_Upload_Stepper_Icon.bs.js";
import * as File_Upload_Preview_Table$DvmAdminFrontendFr from "./steps/File_Upload_Preview_Table.bs.js";
import * as File_Upload_Table_Mapping$DvmAdminFrontendFr from "./steps/File_Upload_Table_Mapping.bs.js";

function File_Upload_Stepper(Props) {
  var resultFile = Props.resultFile;
  var activeStep = Props.activeStep;
  var setActiveStep = Props.setActiveStep;
  var mapped = Props.mapped;
  var setMapped = Props.setMapped;
  var nextDisabled = Props.nextDisabled;
  var selectedTable = Props.selectedTable;
  var validateResult = Props.validateResult;
  var setValidateResult = Props.setValidateResult;
  var setNextDisabled = Props.setNextDisabled;
  var setCheckAttachmentErrors = Props.setCheckAttachmentErrors;
  var onClose = Props.onClose;
  var intl = ReactIntl.useIntl();
  var handleNext = function (param) {
    Curry._1(setActiveStep, activeStep + 1 | 0);
  };
  var handleBack = function (param) {
    Curry._1(setActiveStep, activeStep - 1 | 0);
  };
  var steps = Belt_Array.map([
        Messages_File_Upload$DvmAdminFrontendFr.stepPreview,
        Messages_File_Upload$DvmAdminFrontendFr.stepMapping,
        Messages_File_Upload$DvmAdminFrontendFr.stepExcelCheck,
        selectedTable === "SP_CONTRACT_ATTACHMENTS" || selectedTable === "SP_PARC_QUOTAS" ? Messages_File_Upload$DvmAdminFrontendFr.fileUploadTitle : Messages_File_Upload$DvmAdminFrontendFr.importMode
      ], (function (x) {
          return intl.formatMessage(x);
        }));
  var getStepContent = function (activeStep) {
    var stepContent = [
      App_Types_Result$DvmAdminFrontendFr.mapWithDefault(resultFile, null, (function (excelInfo) {
              return React.createElement(File_Upload_Preview_Table$DvmAdminFrontendFr.make, {
                          excelInfo: excelInfo,
                          setNextDisabled: setNextDisabled
                        });
            })),
      App_Types_Result$DvmAdminFrontendFr.mapWithDefault(resultFile, null, (function (excelInfo) {
              return React.createElement(File_Upload_Table_Mapping$DvmAdminFrontendFr.make, {
                          excelInfo: excelInfo,
                          mapped: mapped,
                          setMapped: setMapped,
                          setNextDisabled: setNextDisabled
                        });
            })),
      App_Types_Result$DvmAdminFrontendFr.mapWithDefault(resultFile, null, (function (excelInfo) {
              return React.createElement(File_Upload_Excel_Check$DvmAdminFrontendFr.make, {
                          mapped: mapped,
                          excelInfo: excelInfo,
                          selectedTable: selectedTable,
                          validateResult: validateResult,
                          setValidateResult: setValidateResult,
                          setNextDisabled: setNextDisabled
                        });
            })),
      App_Types_Result$DvmAdminFrontendFr.mapWithDefault(resultFile, null, (function (excelInfo) {
              return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(validateResult, null, (function (validateResult) {
                            return React.createElement(File_Upload_Import_Mode$DvmAdminFrontendFr.make, {
                                        mapped: mapped,
                                        excelInfo: excelInfo,
                                        validateResult: validateResult,
                                        selectedTable: selectedTable,
                                        setCheckAttachmentErrors: setCheckAttachmentErrors,
                                        onClose: onClose
                                      });
                          }));
            }))
    ];
    var content = Belt_Array.get(stepContent, activeStep);
    if (content !== undefined) {
      return Caml_option.valFromOption(content);
    } else {
      return "no step";
    }
  };
  return React.createElement(Core.Grid, {
              children: null,
              container: true,
              direction: "column",
              spacing: 1,
              wrap: "nowrap",
              style: {
                height: "80vh"
              }
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Stepper, {
                        activeStep: activeStep,
                        alternativeLabel: true,
                        children: Belt_Array.mapWithIndex(steps, (function (param, label) {
                                return React.createElement(Core.Step, {
                                            children: React.createElement(Core.StepLabel, {
                                                  children: label,
                                                  StepIconComponent: (function (props) {
                                                      return React.createElement(File_Upload_Stepper_Icon$DvmAdminFrontendFr.make, {
                                                                  props: props
                                                                });
                                                    })
                                                }),
                                            key: label
                                          });
                              }))
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: activeStep === steps.length ? null : getStepContent(activeStep),
                  item: true,
                  style: {
                    height: "70vh",
                    overflowX: "auto",
                    overflowY: "auto"
                  }
                }), activeStep === 3 ? null : React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    item: true,
                    justifyContent: "center",
                    spacing: 5
                  }, activeStep === 2 || activeStep === 0 ? null : React.createElement(Core.Grid, {
                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                onClick: handleBack,
                                label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.previous),
                                color: "primaryBorder",
                                size: "medium",
                                disabled: activeStep === 0
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                              onClick: handleNext,
                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.next),
                              color: "primary",
                              size: "medium",
                              disabled: nextDisabled
                            }),
                        item: true
                      })));
}

var Types;

var Msg;

var make = File_Upload_Stepper;

export {
  Types ,
  Msg ,
  make ,
}
/* react Not a pure module */
