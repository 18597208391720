// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Gemo$DvmAdminFrontendFr from "../../../intl/messages/gemo/Messages_Gemo.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Gemo_Search_Api$DvmAdminFrontendFr from "./Gemo_Search_Api.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Gemo_Search_Form$DvmAdminFrontendFr from "./Gemo_Search_Form.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Submarket$DvmAdminFrontendFr from "../../../intl/messages/gemo/Messages_Submarket.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Gemo_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var alert = ReactAlert.useAlert();
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setModels = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAllGemo = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$5[1];
  var budgets = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionTypesFleet = match$6[1];
  var actionTypesFleet = match$6[0];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionTypesNonFleet = match$7[1];
  var actionTypesNonFleet = match$7[0];
  var match$8 = React.useState(function () {
        return /* [] */0;
      });
  var setBonusActionOptions = match$8[1];
  var form = Gemo_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].gemoFilter, Gemo_Search_Form$DvmAdminFrontendFr.initialInput), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.gemoCode,
                              tl: {
                                hd: param.gemoLabel,
                                tl: {
                                  hd: param.budgetActionType,
                                  tl: {
                                    hd: param.modelId,
                                    tl: {
                                      hd: param.startDate,
                                      tl: {
                                        hd: param.endDate,
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchModels(setModels, userManager, selectedRole, undefined, undefined, undefined);
          Gemo_Search_Api$DvmAdminFrontendFr.fetchAllGemoCodes(userManager, setAllGemo, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchActionTypes(userManager, setActionTypesFleet, alert, selectedRole, "fleet");
          App_Api$DvmAdminFrontendFr.fetchActionTypes(userManager, setActionTypesNonFleet, alert, selectedRole, "nonfleet");
          Curry._1(form.submit, undefined);
        }), []);
  React.useEffect((function () {
          var actionsList = Belt_List.map(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keep(Belt_List.concat(App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesFleet, /* [] */0), App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0)), (function (x) {
                                  return x.length > 0;
                                }))))), (function (x) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: "Action " + x + ""
                          },
                          value: x
                        };
                }));
          var budgetList = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(budgets, /* [] */0, (function (x) {
                  return Belt_List.map(x, (function (x) {
                                return {
                                        label: {
                                          TAG: /* String */3,
                                          _0: "Budget " + x + ""
                                        },
                                        value: x
                                      };
                              }));
                }));
          Curry._1(setBonusActionOptions, (function (param) {
                  return Belt_List.concatMany([
                              actionsList,
                              budgetList
                            ]);
                }));
        }), [
        budgets,
        actionTypesFleet,
        actionTypesNonFleet
      ]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    gemoCode: input.gemoCode,
                    gemoLabel: input.gemoLabel,
                    budgetActionType: input.budgetActionType,
                    modelId: input.modelId,
                    startDate: startDate,
                    endDate: input.endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.startDateResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    gemoCode: input.gemoCode,
                    gemoLabel: input.gemoLabel,
                    budgetActionType: input.budgetActionType,
                    modelId: input.modelId,
                    startDate: input.startDate,
                    endDate: endDate
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.endDateResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreGemoFilter */20,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Gemo$DvmAdminFrontendFr.gemo,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.gemoCode,
                                onChange: Curry._1(form.updateGemoCode, (function (input, gemoCode) {
                                        return {
                                                gemoCode: gemoCode,
                                                gemoLabel: input.gemoLabel,
                                                budgetActionType: input.budgetActionType,
                                                modelId: input.modelId,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$4[0], /* [] */0)
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateGemoLabel, (function (input, gemoLabel) {
                                    return {
                                            gemoCode: input.gemoCode,
                                            gemoLabel: gemoLabel,
                                            budgetActionType: input.budgetActionType,
                                            modelId: input.modelId,
                                            startDate: input.startDate,
                                            endDate: input.endDate
                                          };
                                  })),
                            value: form.input.gemoLabel,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                            label: Messages_Gemo$DvmAdminFrontendFr.gemoLabel
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Submarket$DvmAdminFrontendFr.budgetActionType,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.budgetActionType,
                                onChange: Curry._1(form.updateBudgetActionType, (function (input, budgetActionType) {
                                        return {
                                                gemoCode: input.gemoCode,
                                                gemoLabel: input.gemoLabel,
                                                budgetActionType: budgetActionType,
                                                modelId: input.modelId,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Labeled */1,
                              _0: match$8[0]
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.model,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.modelId,
                                onChange: Curry._1(form.updateModelId, (function (input, modelId) {
                                        return {
                                                gemoCode: input.gemoCode,
                                                gemoLabel: input.gemoLabel,
                                                budgetActionType: input.budgetActionType,
                                                modelId: modelId,
                                                startDate: input.startDate,
                                                endDate: input.endDate
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$3[0], /* [] */0)
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var InputStyles;

var make = Gemo_Search_Filter;

export {
  Form ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
