// Generated by ReScript, PLEASE EDIT WITH CARE


var fileUploadTitle = {
  id: "0643428c-a561-4600-b9be-6c65229c3ee4",
  defaultMessage: "Importer"
};

var tablePreview = {
  id: "081aa0d9-1504-4078-973d-a65b6ba8fa89",
  defaultMessage: "Aperçu des 10 premières lignes du tableau"
};

var matchColumns = {
  id: "28a982dc-bf16-457f-a114-c422add099fa",
  defaultMessage: "Faire correspondre les colonnes dans Excel à la table DVME"
};

var importMode = {
  id: "16518231-7e8d-41bc-bb51-73624498b1b6",
  defaultMessage: "Mode d’import"
};

var excelOK = {
  id: "be88bf5b-a749-4cca-817e-80092d9d2b36",
  defaultMessage: "Le fichier Excel est conforme"
};

var fileHasErrors = {
  id: "bcfc22f5-e627-4c40-af40-218313185e26",
  defaultMessage: "Le fichier contient les erreurs suivantes"
};

var internalCheck = {
  id: "421e4b7c-e409-468d-a25f-74fb1579abdb",
  defaultMessage: "Vérification du type des données et des doublons"
};

var stageCheck = {
  id: "3d87177e-3cdd-436f-9fb8-5a151026572b",
  defaultMessage: "Vérification de la cohérence des clés primaires"
};

var unexpectedStageError = {
  id: "ca243058-a998-4ec3-8dcb-7b1553b5d908",
  defaultMessage: "Une erreur inattendue s’est produite lors de la vérification de la cohérence des clés primaires"
};

var unexpectedInternalError = {
  id: "c60aa523-6471-4fbf-a80e-7efc2aa9b6cf",
  defaultMessage: "Une erreur inattendue s’est produite lors de la vérification du type des données et des doublons"
};

var stepPreview = {
  id: "e543643a-46b7-4588-bd22-f36ff9dc61df",
  defaultMessage: "Aperçu"
};

var stepMapping = {
  id: "a56aa3b8-8f09-4215-9063-5a515515492b",
  defaultMessage: "Correspondance des tables"
};

var stepExcelCheck = {
  id: "7baba237-af0c-40d1-baf5-e329f70c420d",
  defaultMessage: "Vérification du fichier Excel"
};

var rowNumber = {
  id: "c99a6919-948f-457e-a35f-be49e3715545",
  defaultMessage: "Ligne"
};

var primaryKeyDuplicate = {
  id: "ec05ffb1-11d8-4373-9b6e-bb7ae5279c3c",
  defaultMessage: "Les clés primaires de cette ligne sont des doublons d’une ligne précédente"
};

var emptyFile = {
  id: "d3555de9-677e-447e-be1b-0e8ac678fa14",
  defaultMessage: "Le fichier est vide"
};

var invalidCellValue = {
  id: "2e46f919-3301-4345-ae7f-3595a0ccd0fa",
  defaultMessage: "Valeur de cellule non valide"
};

var emptyCell = {
  id: "2a8b7adf-5908-4775-a180-8d2ca616a927",
  defaultMessage: "Cellule vide"
};

var datesMismatch = {
  id: "1da83db7-fada-4083-8c0f-05a8541ba089",
  defaultMessage: "Les dates sont incorrectes"
};

var pkDuplicity = {
  id: "837f660e-69ef-4272-bee5-2605f96aeb7d",
  defaultMessage: "Doublon de la clé primaire"
};

var picklistMissing = {
  id: "c509403b-0bbc-483d-962e-a50eb0be86f0",
  defaultMessage: "Valeur manquante dans la table Varicent"
};

var unspecifiedUpload = {
  id: "d389b83d-de4e-47eb-8508-d93b01af6f9a",
  defaultMessage: "Import non spécifié"
};

var unknownError = {
  id: "945d02e0-321b-4674-b953-481b4290ef92",
  defaultMessage: "Erreur inconnue"
};

var importExcelToVaricent = {
  id: "755d5351-93a7-4add-8632-2a0229d1aefb",
  defaultMessage: "Import Excel dans la table Varicent"
};

var checkForOverlaps = {
  id: "efaa40d0-6db1-4e0e-8d29-a9114c052e76",
  defaultMessage: "Vérification des chevauchements de protocoles"
};

var rowRestrictionToDelete = {
  id: "7c3d642b-abc1-4015-b2dd-021bae79329b",
  defaultMessage: "Restriction pour les lignes à supprimer"
};

var addNewRowsAndUpdateExisting = {
  id: "a1883c75-a161-4eda-be6a-8f06659aae1f",
  defaultMessage: "Ajout de nouvelles lignes et mise à jour des lignes existantes par le fichier Excel"
};

var insertWithUpdate = {
  id: "9311f3fc-0c95-4275-8b16-82d1afb179b8",
  defaultMessage: "Insertion avec mise à jour"
};

var insertWithDeletion = {
  id: "628b15a6-dfdd-4a01-ab44-708b161f8f7f",
  defaultMessage: "Insertion avec suppression"
};

var picklistMissingError = {
  id: "e21cea09-826a-4a62-a81a-3b9d521bf97f",
  defaultMessage: "Ligne {rowNumber} : la valeur {value} n'existe pas dans la base pour le {table}"
};

var contractProtocol = {
  id: "88f33ab3-f2de-43b4-9c85-7a72864a5a3c",
  defaultMessage: "protocole"
};

var showingFirstTenOfRows = {
  id: "0ef4beb6-fd71-47d9-84c3-e34a16222017",
  defaultMessage: "Affichage des 10 premières/{number} erreurs"
};

var invalidDateField = {
  id: "2083df41-8277-4b26-bcca-fa04e5b3163a",
  defaultMessage: "Ligne {rowNumber}, champ {field}: Date invalide {date}"
};

var importantMessage = {
  id: "f15a60f0-d22d-41d0-a462-1dd3f1a2a001",
  defaultMessage: "Informations dates obligatoires"
};

var infoAboutDates = {
  id: "c9f83910-1aa4-4ead-ba61-92f8fe67791e",
  defaultMessage: "Les dates du fichier Excel doivent avoir un format date, pas du texte, du nombre, etc. \n \n Ils doivent être dans l’un des formats suivants : \n jj.MM.aaaa \n jj/MM/aaaa \n jj-MM-aaaa \n jj\\MM\\aaaa"
};

var recalculating = {
  id: "17e68ba8-871c-4688-978b-3b0923e4dd71",
  defaultMessage: "Recalcul en cours"
};

export {
  fileUploadTitle ,
  tablePreview ,
  matchColumns ,
  importMode ,
  excelOK ,
  fileHasErrors ,
  internalCheck ,
  stageCheck ,
  unexpectedStageError ,
  unexpectedInternalError ,
  stepPreview ,
  stepMapping ,
  stepExcelCheck ,
  rowNumber ,
  primaryKeyDuplicate ,
  emptyFile ,
  invalidCellValue ,
  emptyCell ,
  datesMismatch ,
  pkDuplicity ,
  picklistMissing ,
  unspecifiedUpload ,
  unknownError ,
  importExcelToVaricent ,
  checkForOverlaps ,
  rowRestrictionToDelete ,
  addNewRowsAndUpdateExisting ,
  insertWithUpdate ,
  insertWithDeletion ,
  picklistMissingError ,
  contractProtocol ,
  showingFirstTenOfRows ,
  invalidDateField ,
  importantMessage ,
  infoAboutDates ,
  recalculating ,
}
/* No side effect */
