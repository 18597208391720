// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as EnvVar$DvmAdminFrontendFr from "../../../../bindings/global/EnvVar.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "../Flash_Edit_Types.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_TextFieldFormInput.bs.js";

function Flash_Edit_Info(Props) {
  var form = Props.form;
  var formVO = Props.formVO;
  var flashId = Props.flashId;
  var children = Props.children;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  React.useEffect((function () {
          if (Belt_Option.isSome(flashId)) {
            
          } else {
            Curry._2(form.updateActionType, (function (input, actionType) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.actionType = actionType;
                    return newrecord;
                  }), /* NOTSET */3);
          }
          if (form.input.orderType === /* RR */4 || form.input.orderType === /* RC */5) {
            Curry._2(form.updateIsParcEntry, (function (input, isParcEntry) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.isParcEntry = isParcEntry;
                    return newrecord;
                  }), true);
          }
          
        }), [form.input.orderType]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.orderType,
    options: EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? ({
          hd: {
            label: {
              TAG: /* String */3,
              _0: "SO"
            },
            value: "SO"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "CL"
              },
              value: "CL"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* String */3,
                  _0: "VD"
                },
                value: "VD"
              },
              tl: /* [] */0
            }
          }
        }) : (
        EnvVar$DvmAdminFrontendFr.currentEnvironmentDev ? ({
              hd: {
                label: {
                  TAG: /* String */3,
                  _0: "SO"
                },
                value: "SO"
              },
              tl: {
                hd: {
                  label: {
                    TAG: /* String */3,
                    _0: "CL"
                  },
                  value: "CL"
                },
                tl: {
                  hd: {
                    label: {
                      TAG: /* String */3,
                      _0: "VD"
                    },
                    value: "VD"
                  },
                  tl: {
                    hd: {
                      label: {
                        TAG: /* String */3,
                        _0: "VE"
                      },
                      value: "VE"
                    },
                    tl: {
                      hd: {
                        label: {
                          TAG: /* String */3,
                          _0: "RR"
                        },
                        value: "RR"
                      },
                      tl: {
                        hd: {
                          label: {
                            TAG: /* String */3,
                            _0: "RC"
                          },
                          value: "RC"
                        },
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }) : (
            User_Types_Utils$DvmAdminFrontendFr.whatCountry(match[0].selectedRole) === /* France */0 ? ({
                  hd: {
                    label: {
                      TAG: /* String */3,
                      _0: "SO"
                    },
                    value: "SO"
                  },
                  tl: {
                    hd: {
                      label: {
                        TAG: /* String */3,
                        _0: "CL"
                      },
                      value: "CL"
                    },
                    tl: {
                      hd: {
                        label: {
                          TAG: /* String */3,
                          _0: "VD"
                        },
                        value: "VD"
                      },
                      tl: {
                        hd: {
                          label: {
                            TAG: /* String */3,
                            _0: "VE"
                          },
                          value: "VE"
                        },
                        tl: /* [] */0
                      }
                    }
                  }
                }) : ({
                  hd: {
                    label: {
                      TAG: /* String */3,
                      _0: "CL"
                    },
                    value: "CL"
                  },
                  tl: {
                    hd: {
                      label: {
                        TAG: /* String */3,
                        _0: "VD"
                      },
                      value: "VD"
                    },
                    tl: {
                      hd: {
                        label: {
                          TAG: /* String */3,
                          _0: "VE"
                        },
                        value: "VE"
                      },
                      tl: /* [] */0
                    }
                  }
                })
          )
      ),
    value: {
      TAG: /* Single */0,
      _0: {
        value: Flash_Edit_Types$DvmAdminFrontendFr.OrderType.toString(form.input.orderType),
        onChange: (function (val) {
            Curry._2(form.updateOrderType, (function (input, orderType) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.orderType = orderType;
                    return newrecord;
                  }), Belt_Option.getWithDefault(Flash_Edit_Types$DvmAdminFrontendFr.OrderType.fromString(val), /* NOTSET */6));
          })
      }
    },
    id: "orderType",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    disabled: Belt_Option.isSome(flashId)
  };
  if (form.orderTypeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.orderTypeResult);
  }
  var tmp$1;
  if (form.input.orderType === /* SO */0) {
    var tmp$2 = {
      label: Messages_Flash_Detail$DvmAdminFrontendFr.flashType,
      options: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "REPR"
          },
          value: "REPR"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "STOC"
            },
            value: "STOC"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "PERF"
              },
              value: "PERF"
            },
            tl: /* [] */0
          }
        }
      },
      value: {
        TAG: /* Single */0,
        _0: {
          value: Flash_Edit_Types$DvmAdminFrontendFr.ActionTypeEdit.toString(form.input.actionType),
          onChange: (function (val) {
              var tmp;
              switch (val) {
                case "PERF" :
                    tmp = /* PERF */1;
                    break;
                case "REPR" :
                    tmp = /* REPR */2;
                    break;
                case "STOC" :
                    tmp = /* STOC */0;
                    break;
                default:
                  tmp = /* NOTSET */3;
              }
              Curry._2(form.updateActionType, (function (input, actionType) {
                      var newrecord = Caml_obj.obj_dup(input);
                      newrecord.actionType = actionType;
                      return newrecord;
                    }), tmp);
            })
        }
      },
      id: "flashType",
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      disabled: Belt_Option.isSome(flashId)
    };
    if (form.actionTypeResult !== undefined) {
      tmp$2.validationResult = Caml_option.valFromOption(form.actionTypeResult);
    }
    tmp$1 = React.createElement(Core.Grid, {
          children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$2),
          item: true
        });
  } else {
    var tmp$3 = {
      onChange: (function (val) {
          Curry._2(form.updateActionType, (function (input, actionType) {
                  var newrecord = Caml_obj.obj_dup(input);
                  newrecord.actionType = actionType;
                  return newrecord;
                }), /* NonFleet */{
                _0: val.toUpperCase()
              });
        }),
      value: Flash_Edit_Types$DvmAdminFrontendFr.ActionTypeEdit.toString(form.input.actionType),
      disabled: Belt_Option.isSome(flashId),
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Flash_Detail$DvmAdminFrontendFr.flashType
    };
    if (form.actionTypeResult !== undefined) {
      tmp$3.validationResult = Caml_option.valFromOption(form.actionTypeResult);
    }
    tmp$1 = React.createElement(Core.Grid, {
          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
          item: true
        });
  }
  var tmp$4;
  if (form.input.actionType === /* PERF */1) {
    var tmp$5 = {
      label: Messages_Flash_Edit$DvmAdminFrontendFr.perfType,
      options: {
        hd: {
          label: {
            TAG: /* String */3,
            _0: "BOOSTER"
          },
          value: "BOOSTER"
        },
        tl: {
          hd: {
            label: {
              TAG: /* String */3,
              _0: "REPR"
            },
            value: "REPR"
          },
          tl: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "BUY-BACK"
              },
              value: "BUY-BACK"
            },
            tl: /* [] */0
          }
        }
      },
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.perfType,
          onChange: Curry._1(form.updatePerfType, (function (input, perfType) {
                  var newrecord = Caml_obj.obj_dup(input);
                  newrecord.perfType = perfType;
                  return newrecord;
                }))
        }
      },
      id: "flashType",
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      disabled: Belt_Option.isSome(flashId)
    };
    if (form.perfTypeResult !== undefined) {
      tmp$5.validationResult = Caml_option.valFromOption(form.perfTypeResult);
    }
    tmp$4 = React.createElement(Core.Grid, {
          children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$5),
          item: true
        });
  } else {
    tmp$4 = null;
  }
  var tmp$6 = {
    onChange: Curry._1(form.updateActionLabel, (function (input, actionLabel) {
            var newrecord = Caml_obj.obj_dup(input);
            newrecord.actionLabel = actionLabel;
            return newrecord;
          })),
    value: form.input.actionLabel,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Flash_Detail$DvmAdminFrontendFr.flashLabel
  };
  if (form.actionLabelResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.actionLabelResult);
  }
  var tmp$7;
  if (flashId !== undefined) {
    var tmp$8 = {
      onChange: Curry._1(form.updateActionID, (function (input, actionID) {
              var newrecord = Caml_obj.obj_dup(input);
              newrecord.actionID = actionID;
              return newrecord;
            })),
      value: form.input.actionID,
      disabled: true,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Flash_Detail$DvmAdminFrontendFr.flashCode
    };
    if (form.actionIDResult !== undefined) {
      tmp$8.validationResult = Caml_option.valFromOption(form.actionIDResult);
    }
    tmp$7 = React.createElement(Core.Grid, {
          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$8),
          item: true
        });
  } else {
    tmp$7 = null;
  }
  var match$1 = form.input.orderType;
  var tmp$9;
  var exit = 0;
  switch (match$1) {
    case /* VE */2 :
        var tmp$10 = {
          label: Messages_Flash_Edit$DvmAdminFrontendFr.parcType,
          options: {
            hd: {
              label: {
                TAG: /* String */3,
                _0: "Entrée"
              },
              value: "Entry"
            },
            tl: {
              hd: {
                label: {
                  TAG: /* String */3,
                  _0: "Sortie"
                },
                value: "Sortie"
              },
              tl: /* [] */0
            }
          },
          value: {
            TAG: /* Single */0,
            _0: {
              value: Belt_Option.mapWithDefault(form.input.isParcEntry, "", (function (x) {
                      if (x) {
                        return "Entry";
                      } else {
                        return "Sortie";
                      }
                    })),
              onChange: (function (val) {
                  Curry._2(form.updateIsParcEntry, (function (input, isParcEntry) {
                          var newrecord = Caml_obj.obj_dup(input);
                          newrecord.isParcEntry = isParcEntry;
                          return newrecord;
                        }), val === "Entry" ? true : false);
                })
            }
          },
          id: "isParcEntry",
          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
          disabled: Belt_Option.isSome(flashId)
        };
        if (form.isParcEntryResult !== undefined) {
          tmp$10.validationResult = Caml_option.valFromOption(form.isParcEntryResult);
        }
        tmp$9 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$10),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.FormControlLabel, {
                        classes: {
                          label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                        },
                        control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                              checked: form.input.includeFinancialCode,
                              onClick: (function ($$event) {
                                  Curry._2(form.updateIncludeFinancialCode, (function (input, includeFinancialCode) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.includeFinancialCode = includeFinancialCode;
                                          return newrecord;
                                        }), $$event.target.checked);
                                })
                            }),
                        label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.includeFinancialCode)
                      }),
                  item: true
                }));
        break;
    case /* CL */1 :
    case /* VD */3 :
        exit = 2;
        break;
    case /* RR */4 :
    case /* RC */5 :
        exit = 1;
        break;
    case /* SO */0 :
    case /* NOTSET */6 :
        tmp$9 = null;
        break;
    
  }
  switch (exit) {
    case 1 :
        tmp$9 = React.createElement(Core.Grid, {
              children: React.createElement(Core.FormControlLabel, {
                    classes: {
                      label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                    },
                    control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                          checked: !Belt_Option.getWithDefault(form.input.isParcEntry, true),
                          onClick: (function ($$event) {
                              Curry._2(form.updateIsParcEntry, (function (input, isParcEntry) {
                                      var newrecord = Caml_obj.obj_dup(input);
                                      newrecord.isParcEntry = isParcEntry;
                                      return newrecord;
                                    }), !$$event.target.checked);
                            })
                        }),
                    label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.bonusWithExitParc)
                  }),
              item: true
            });
        break;
    case 2 :
        tmp$9 = React.createElement(Core.Grid, {
              children: null,
              container: true,
              item: true
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.FormControlLabel, {
                        classes: {
                          label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                        },
                        control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                              checked: formVO.input.actionWithVO,
                              onClick: (function ($$event) {
                                  Curry._2(formVO.updateActionWithVO, (function (input, actionWithVO) {
                                          return {
                                                  carAge: input.carAge,
                                                  carAgeWhich: input.carAgeWhich,
                                                  possesionPeriod: input.possesionPeriod,
                                                  possesionPeriodWhich: input.possesionPeriodWhich,
                                                  brands: input.brands,
                                                  ie: input.ie,
                                                  actionWithVO: actionWithVO,
                                                  usedCarModels: input.usedCarModels
                                                };
                                        }), $$event.target.checked);
                                })
                            }),
                        label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.actionWithVO)
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.FormControlLabel, {
                        classes: {
                          label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                        },
                        control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                              checked: form.input.includeFinancialCode,
                              onClick: (function ($$event) {
                                  Curry._2(form.updateIncludeFinancialCode, (function (input, includeFinancialCode) {
                                          var newrecord = Caml_obj.obj_dup(input);
                                          newrecord.includeFinancialCode = includeFinancialCode;
                                          return newrecord;
                                        }), $$event.target.checked);
                                })
                            }),
                        label: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.includeFinancialCode)
                      }),
                  item: true
                }));
        break;
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  direction: "column",
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 2
                    }, tmp$1, tmp$4), React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      item: true,
                      spacing: 2
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$6),
                          item: true
                        }), tmp$7), tmp$9, React.createElement(Core.Grid, {
                      children: children,
                      container: true,
                      item: true,
                      justifyContent: "center"
                    })));
}

var Form;

var FormVO;

var Types;

var InputStyles;

var make = Flash_Edit_Info;

export {
  Form ,
  FormVO ,
  Types ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
