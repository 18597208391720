// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../../common/components/App_Checkbox.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as Flash_Edit_NonFleet_Version_Form$DvmAdminFrontendFr from "../Flash_Edit_NonFleet_Version_Form.bs.js";

function Flash_Edit_NonFleet_Modal_Filter(Props) {
  var tableHandler = Props.tableHandler;
  var filterPagingAndSort = Props.filterPagingAndSort;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var versions = Props.versions;
  var collectionForm = Props.collectionForm;
  var intl = ReactIntl.useIntl();
  var form = Flash_Edit_NonFleet_Version_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Flash_Edit_NonFleet_Version_Form$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          Curry._1(filterPagingAndSort, {
                page: 0,
                headers: tableHandler.headers,
                model: param.model,
                version: param.version,
                engineType: param.engineType,
                modelPhase: param.modelPhase
              });
        }));
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setModelVersionOptions = match[1];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setEnergyOptions = match$1[1];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setPhaseOptions = match$2[1];
  React.useEffect((function () {
          var models = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(versions, (function (x) {
                              return x.model;
                            })))));
          var newOptions = Belt_List.map(models, (function (model) {
                  return {
                          parent: model,
                          child: Belt_List.map(Belt_List.keep(versions, (function (modelVersion) {
                                      return model === modelVersion.model;
                                    })), (function (x) {
                                  return x.version;
                                }))
                        };
                }));
          Curry._1(setModelVersionOptions, (function (param) {
                  return newOptions;
                }));
          var engines = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(versions, (function (x) {
                                  return x.engineType;
                                })), (function (x) {
                              return x;
                            })))));
          Curry._1(setEnergyOptions, (function (param) {
                  return engines;
                }));
          var phases = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(versions, (function (x) {
                                  return x.modelPhase;
                                })), (function (x) {
                              return x;
                            })))));
          Curry._1(setPhaseOptions, (function (param) {
                  return phases;
                }));
        }), []);
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), [
        form.input.model,
        form.input.version,
        form.input.engineType,
        form.input.modelPhase,
        withClosedModels
      ]);
  return React.createElement("form", undefined, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      item: true,
                      style: {
                        width: "fit-content"
                      }
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                labelParent: Messages_Common$DvmAdminFrontendFr.model,
                                labelChild: Messages_Common$DvmAdminFrontendFr.version,
                                valueParent: {
                                  TAG: /* Single */0,
                                  _0: {
                                    value: form.input.model,
                                    onChange: Curry._1(form.updateModel, (function (input, model) {
                                            return {
                                                    model: model,
                                                    version: "",
                                                    engineType: input.engineType,
                                                    modelPhase: input.modelPhase
                                                  };
                                          }))
                                  }
                                },
                                valueChild: {
                                  TAG: /* Single */0,
                                  _0: {
                                    value: form.input.version,
                                    onChange: Curry._1(form.updateVersion, (function (input, version) {
                                            return {
                                                    model: input.model,
                                                    version: version,
                                                    engineType: input.engineType,
                                                    modelPhase: input.modelPhase
                                                  };
                                          }))
                                  }
                                },
                                options: match[0],
                                classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                                classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                                disableParent: !Curry._1(collectionForm.valid, undefined),
                                disableChild: !Curry._1(collectionForm.valid, undefined)
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                          children: React.createElement(Core.FormControlLabel, {
                                classes: {
                                  label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                },
                                control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                      checked: withClosedModels,
                                      onClick: (function ($$event) {
                                          Curry._1(setWithClosedModels, (function (param) {
                                                  return $$event.target.checked;
                                                }));
                                        })
                                    }),
                                disabled: !Curry._1(collectionForm.valid, undefined),
                                label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                              }),
                          item: true,
                          style: {
                            whiteSpace: "nowrap",
                            width: "1px"
                          }
                        })), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Flash_Edit$DvmAdminFrontendFr.energyDropdown,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.engineType,
                                onChange: Curry._1(form.updateEngineType, (function (input, engineType) {
                                        return {
                                                model: input.model,
                                                version: input.version,
                                                engineType: engineType,
                                                modelPhase: input.modelPhase
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: match$1[0]
                            },
                            disabled: !Curry._1(collectionForm.valid, undefined),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Common$DvmAdminFrontendFr.phase,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.modelPhase,
                                onChange: Curry._1(form.updateModelPhase, (function (input, modelPhase) {
                                        return {
                                                model: input.model,
                                                version: input.version,
                                                engineType: input.engineType,
                                                modelPhase: modelPhase
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: match$2[0]
                            },
                            disabled: !Curry._1(collectionForm.valid, undefined),
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
                          }),
                      item: true
                    })));
}

var InputStyles;

var Form;

var CollectionForm;

var make = Flash_Edit_NonFleet_Modal_Filter;

export {
  InputStyles ,
  Form ,
  CollectionForm ,
  make ,
}
/* react Not a pure module */
