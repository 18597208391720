// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../common/types/App_Types_Date.bs.js";

var Fields_actionType = [
  "actionType",
  "Type_d_action"
];

var Fields_dealerKeyAccountManager = [
  "dealerKeyAccountManager",
  "Nom_ME_Local"
];

var Fields_orderBudgetType = [
  "orderBudgetType",
  "Type_de_budget"
];

var Fields_orderDealerId = [
  "orderDealerId",
  "Compte_Affaire"
];

var Fields_orderDr = [
  "orderDr",
  "Territoire"
];

var Fields_orderModelId = [
  "orderModelId",
  "Modèle_SC"
];

var Fields_orderVersionId = [
  "orderVersionId",
  "Version_SC"
];

var Fields_resultBudgetYear = [
  "resultBudgetYear",
  "Année"
];

var Fields_resultDelivered = [
  "resultDelivered",
  "Livree"
];

var Fields_resultErrorDescriptionLocal = [
  "resultErrorDescriptionLocal",
  "Motif_d_anomalie"
];

var Fields_resultOrderStatus = [
  "resultOrderStatus",
  "StatutVN"
];

var Fields_siren = [
  "siren",
  "SIREN_Client"
];

var Fields_showHistory = [
  "ResultActiveDealerLocal",
  "Affaire_active"
];

var Fields = {
  actionType: Fields_actionType,
  dealerKeyAccountManager: Fields_dealerKeyAccountManager,
  orderBudgetType: Fields_orderBudgetType,
  orderCustomerOrderNumber: "orderCustomerOrderNumber",
  orderDealerId: Fields_orderDealerId,
  orderDr: Fields_orderDr,
  orderModelId: Fields_orderModelId,
  orderOrderDate: "orderOrderDate",
  orderSIRET: "orderSIRET",
  orderVersionId: Fields_orderVersionId,
  orderVin: "orderVin",
  resultBudgetYear: Fields_resultBudgetYear,
  resultDelivered: Fields_resultDelivered,
  resultErrorDescriptionLocal: Fields_resultErrorDescriptionLocal,
  resultOrderStatus: Fields_resultOrderStatus,
  siren: Fields_siren,
  siretName: "siretName",
  showHistory: Fields_showHistory
};

function row_encode(v) {
  return Js_dict.fromArray([
              [
                "actionId",
                Decco.optionToJson(Decco.stringToJson, v.actionId)
              ],
              [
                "actionType",
                Decco.optionToJson(Decco.stringToJson, v.actionType)
              ],
              [
                "dealerKeyAccountManager",
                Decco.optionToJson(Decco.stringToJson, v.dealerKeyAccountManager)
              ],
              [
                "orderBudgetType",
                Decco.optionToJson(Decco.stringToJson, v.orderBudgetType)
              ],
              [
                "orderCustomerOrderNumber",
                Decco.optionToJson(Decco.stringToJson, v.orderCustomerOrderNumber)
              ],
              [
                "orderDealerId",
                Decco.optionToJson(Decco.stringToJson, v.orderDealerId)
              ],
              [
                "orderDr",
                Decco.optionToJson(Decco.stringToJson, v.orderDr)
              ],
              [
                "orderId",
                Decco.optionToJson(Decco.stringToJson, v.orderId)
              ],
              [
                "orderModelId",
                Decco.optionToJson(Decco.stringToJson, v.orderModelId)
              ],
              [
                "orderOrderDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderOrderDate)
              ],
              [
                "orderSIRET",
                Decco.optionToJson(Decco.stringToJson, v.orderSIRET)
              ],
              [
                "orderVersionId",
                Decco.optionToJson(Decco.stringToJson, v.orderVersionId)
              ],
              [
                "orderVin",
                Decco.optionToJson(Decco.stringToJson, v.orderVin)
              ],
              [
                "resultBudgetYear",
                Decco.optionToJson(Decco.stringToJson, v.resultBudgetYear)
              ],
              [
                "resultDelivered",
                Decco.optionToJson(Decco.stringToJson, v.resultDelivered)
              ],
              [
                "resultErrorDescriptionLocal",
                Decco.optionToJson(Decco.stringToJson, v.resultErrorDescriptionLocal)
              ],
              [
                "resultOrderStatus",
                Decco.optionToJson(Decco.stringToJson, v.resultOrderStatus)
              ],
              [
                "siren",
                Decco.optionToJson(Decco.stringToJson, v.siren)
              ],
              [
                "siretName",
                Decco.optionToJson(Decco.stringToJson, v.siretName)
              ]
            ]);
}

function row_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionId"), null));
  if (actionId.TAG === /* Ok */0) {
    var actionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionType"), null));
    if (actionType.TAG === /* Ok */0) {
      var dealerKeyAccountManager = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerKeyAccountManager"), null));
      if (dealerKeyAccountManager.TAG === /* Ok */0) {
        var orderBudgetType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderBudgetType"), null));
        if (orderBudgetType.TAG === /* Ok */0) {
          var orderCustomerOrderNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderCustomerOrderNumber"), null));
          if (orderCustomerOrderNumber.TAG === /* Ok */0) {
            var orderDealerId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderDealerId"), null));
            if (orderDealerId.TAG === /* Ok */0) {
              var orderDr = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderDr"), null));
              if (orderDr.TAG === /* Ok */0) {
                var orderId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderId"), null));
                if (orderId.TAG === /* Ok */0) {
                  var orderModelId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderModelId"), null));
                  if (orderModelId.TAG === /* Ok */0) {
                    var orderOrderDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderOrderDate"), null));
                    if (orderOrderDate.TAG === /* Ok */0) {
                      var orderSIRET = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderSIRET"), null));
                      if (orderSIRET.TAG === /* Ok */0) {
                        var orderVersionId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderVersionId"), null));
                        if (orderVersionId.TAG === /* Ok */0) {
                          var orderVin = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderVin"), null));
                          if (orderVin.TAG === /* Ok */0) {
                            var resultBudgetYear = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "resultBudgetYear"), null));
                            if (resultBudgetYear.TAG === /* Ok */0) {
                              var resultDelivered = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "resultDelivered"), null));
                              if (resultDelivered.TAG === /* Ok */0) {
                                var resultErrorDescriptionLocal = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "resultErrorDescriptionLocal"), null));
                                if (resultErrorDescriptionLocal.TAG === /* Ok */0) {
                                  var resultOrderStatus = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "resultOrderStatus"), null));
                                  if (resultOrderStatus.TAG === /* Ok */0) {
                                    var siren = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siren"), null));
                                    if (siren.TAG === /* Ok */0) {
                                      var siretName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siretName"), null));
                                      if (siretName.TAG === /* Ok */0) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: {
                                                  actionId: actionId._0,
                                                  actionType: actionType._0,
                                                  dealerKeyAccountManager: dealerKeyAccountManager._0,
                                                  orderBudgetType: orderBudgetType._0,
                                                  orderCustomerOrderNumber: orderCustomerOrderNumber._0,
                                                  orderDealerId: orderDealerId._0,
                                                  orderDr: orderDr._0,
                                                  orderId: orderId._0,
                                                  orderModelId: orderModelId._0,
                                                  orderOrderDate: orderOrderDate._0,
                                                  orderSIRET: orderSIRET._0,
                                                  orderVersionId: orderVersionId._0,
                                                  orderVin: orderVin._0,
                                                  resultBudgetYear: resultBudgetYear._0,
                                                  resultDelivered: resultDelivered._0,
                                                  resultErrorDescriptionLocal: resultErrorDescriptionLocal._0,
                                                  resultOrderStatus: resultOrderStatus._0,
                                                  siren: siren._0,
                                                  siretName: siretName._0
                                                }
                                              };
                                      }
                                      var e = siretName._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".siretName" + e.path,
                                                message: e.message,
                                                value: e.value
                                              }
                                            };
                                    }
                                    var e$1 = siren._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".siren" + e$1.path,
                                              message: e$1.message,
                                              value: e$1.value
                                            }
                                          };
                                  }
                                  var e$2 = resultOrderStatus._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".resultOrderStatus" + e$2.path,
                                            message: e$2.message,
                                            value: e$2.value
                                          }
                                        };
                                }
                                var e$3 = resultErrorDescriptionLocal._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".resultErrorDescriptionLocal" + e$3.path,
                                          message: e$3.message,
                                          value: e$3.value
                                        }
                                      };
                              }
                              var e$4 = resultDelivered._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".resultDelivered" + e$4.path,
                                        message: e$4.message,
                                        value: e$4.value
                                      }
                                    };
                            }
                            var e$5 = resultBudgetYear._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".resultBudgetYear" + e$5.path,
                                      message: e$5.message,
                                      value: e$5.value
                                    }
                                  };
                          }
                          var e$6 = orderVin._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".orderVin" + e$6.path,
                                    message: e$6.message,
                                    value: e$6.value
                                  }
                                };
                        }
                        var e$7 = orderVersionId._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".orderVersionId" + e$7.path,
                                  message: e$7.message,
                                  value: e$7.value
                                }
                              };
                      }
                      var e$8 = orderSIRET._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".orderSIRET" + e$8.path,
                                message: e$8.message,
                                value: e$8.value
                              }
                            };
                    }
                    var e$9 = orderOrderDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".orderOrderDate" + e$9.path,
                              message: e$9.message,
                              value: e$9.value
                            }
                          };
                  }
                  var e$10 = orderModelId._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".orderModelId" + e$10.path,
                            message: e$10.message,
                            value: e$10.value
                          }
                        };
                }
                var e$11 = orderId._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".orderId" + e$11.path,
                          message: e$11.message,
                          value: e$11.value
                        }
                      };
              }
              var e$12 = orderDr._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".orderDr" + e$12.path,
                        message: e$12.message,
                        value: e$12.value
                      }
                    };
            }
            var e$13 = orderDealerId._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".orderDealerId" + e$13.path,
                      message: e$13.message,
                      value: e$13.value
                    }
                  };
          }
          var e$14 = orderCustomerOrderNumber._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".orderCustomerOrderNumber" + e$14.path,
                    message: e$14.message,
                    value: e$14.value
                  }
                };
        }
        var e$15 = orderBudgetType._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".orderBudgetType" + e$15.path,
                  message: e$15.message,
                  value: e$15.value
                }
              };
      }
      var e$16 = dealerKeyAccountManager._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dealerKeyAccountManager" + e$16.path,
                message: e$16.message,
                value: e$16.value
              }
            };
    }
    var e$17 = actionType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionType" + e$17.path,
              message: e$17.message,
              value: e$17.value
            }
          };
  }
  var e$18 = actionId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionId" + e$18.path,
            message: e$18.message,
            value: e$18.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(row_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(row_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

export {
  Fields ,
  row_encode ,
  row_decode ,
  searchResult_encode ,
  searchResult_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
