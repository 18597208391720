// Generated by ReScript, PLEASE EDIT WITH CARE


var title = {
  id: "302a28de-559e-40a0-807b-94b41a217bb3",
  defaultMessage: "Recherche avancée"
};

var orderPeriod = {
  id: "80322300-1bd3-4f2d-9279-e54cfec66fd4",
  defaultMessage: "Période commande"
};

var registrationPeriod = {
  id: "4dd7f8d5-8c1a-453f-a95e-51b4a2a95095",
  defaultMessage: "Période immatriculation"
};

var custInvoicePeriod = {
  id: "1031cea2-95d9-4733-b3c8-14f8b7e3a262",
  defaultMessage: "Période facturation client"
};

var delivered = {
  id: "f2b28686-98b9-4c8b-b8a2-7de7217b31a3",
  defaultMessage: "Livrées"
};

var protID = {
  id: "5823fa90-9ac1-476d-bfd5-c569ce7f4cc7",
  defaultMessage: "Code Prot/Loueur"
};

var actionID = {
  id: "d1d437b0-ad3d-4f01-a8b9-3f3f5429d79d",
  defaultMessage: "Code Action Commerciale"
};

var deliveryPeriod = {
  id: "6e899365-c384-4fa4-b30c-54d972e16bc8",
  defaultMessage: "Période livraison"
};

var deliveryPeriodBoth = {
  id: "39db6898-2e56-4e59-ab11-7c6b4f32eb1f",
  defaultMessage: "Période livraison / livraison souhaitée"
};

var desiredDeliveryPeriod = {
  id: "4f118a42-6fea-441b-a11e-b5aa2297c1a2",
  defaultMessage: "Période livraison souhaitée"
};

var renaultInvoiceDate = {
  id: "2311b1ec-dbf7-4295-ba56-7be3d0956cda",
  defaultMessage: "Date de facture Renault"
};

var dealerName = {
  id: "7a651273-5400-4e2a-8d7a-cccc338ec0e2",
  defaultMessage: "Raison sociale de l’affaire"
};

var dealerGroup = {
  id: "89fac1ed-c586-4c69-913a-8aea02b32c18",
  defaultMessage: "Groupe National"
};

var keyAccountManagerID = {
  id: "03488636-b69d-4c12-b3d9-2b79a1442b3c",
  defaultMessage: "Manager Entreprises"
};

var sectorManagerID = {
  id: "244253ba-7169-40d1-bc88-63e6ba1d31c1",
  defaultMessage: "Nom du MGC / LLD Manager"
};

var sirenClientFinalLLD = {
  id: "cb9334a6-f2d4-442e-89be-75658e9fea16",
  defaultMessage: "SIREN Client Final LLD"
};

var sirenClientFinalLLDName = {
  id: "546bee42-1827-4592-81f2-eb1c2f762ebd",
  defaultMessage: "Raison Sociale Client Final LLD"
};

var sirenClientFinal = {
  id: "0b33f493-3c77-48b3-ab12-a5483529106e",
  defaultMessage: "SIREN Client Final"
};

var sirenClientFinalName = {
  id: "3806dbf9-ea76-4129-bb14-73ff60059838",
  defaultMessage: "Raison Sociale Client Final"
};

var loueurID = {
  id: "31911448-efbb-472c-9cff-7d302730a548",
  defaultMessage: "Code loueur"
};

var loueurName = {
  id: "01407b64-5ec1-4eaa-935b-7c81b3bb7ab4",
  defaultMessage: "Libellé Loueur"
};

var contractID = {
  id: "97b5678d-269e-4ec1-b8dd-9e9ab43dd3ec",
  defaultMessage: "Code protocole"
};

var contractName = {
  id: "cfe2bfb9-61fb-4bb6-93ac-0e884685a315",
  defaultMessage: "Libellé du contrat"
};

var mainContractSIREN = {
  id: "7473e308-6336-4807-8668-28cae22468c0",
  defaultMessage: "SIREN signataire"
};

var preinvoiceDate = {
  id: "5e29dbc7-5c67-4e43-a2ba-99eddeb1c342",
  defaultMessage: "Date Préfac"
};

var preinvoiceNumber = {
  id: "5204ef61-47fd-4605-8fcd-0040b3b2f1e2",
  defaultMessage: "Numéro Préfac"
};

var dateBAP = {
  id: "dbfbcdbe-e671-4d9f-8f2e-74796673acf6",
  defaultMessage: "Date BAP"
};

var dateFilterPage = {
  id: "cce6575c-ad2a-4680-b3fb-a897571d2654",
  defaultMessage: "Date"
};

var codeAPE = {
  id: "09bc9fe7-ff97-4994-b42c-a95489f913fc",
  defaultMessage: "Code activité (APE)"
};

var codeAPEhint = {
  id: "87a86e56-2e79-4cf2-ae4c-d74982ecc96c",
  defaultMessage: "Appuyez sur ENTER après chaque code"
};

var customerFilterPage = {
  id: "e8c09042-0863-4503-9f2e-c06de8562454",
  defaultMessage: "Client"
};

var dealerFilterPage = {
  id: "9ae625dd-7ae4-4ffc-86e4-5789eab9a431",
  defaultMessage: "Affaire"
};

var carFilterPage = {
  id: "3ed06b7e-260a-4a7d-b810-88ffdbf7ca01",
  defaultMessage: "VN"
};

var voFilterPage = {
  id: "f79b4a7e-641a-4c69-adda-67ae9e6dde44",
  defaultMessage: "VO"
};

var registeredDateCheckbox = {
  id: "ab8a7ec6-a940-428d-90d4-50cfc301efcf",
  defaultMessage: "Immatriculées"
};

var invoicedCheckbox = {
  id: "72776ab5-be6f-4143-afb3-4e40f383e551",
  defaultMessage: "Facturées"
};

export {
  title ,
  orderPeriod ,
  registrationPeriod ,
  custInvoicePeriod ,
  delivered ,
  protID ,
  actionID ,
  deliveryPeriod ,
  deliveryPeriodBoth ,
  desiredDeliveryPeriod ,
  renaultInvoiceDate ,
  dealerName ,
  dealerGroup ,
  keyAccountManagerID ,
  sectorManagerID ,
  sirenClientFinalLLD ,
  sirenClientFinalLLDName ,
  sirenClientFinal ,
  sirenClientFinalName ,
  loueurID ,
  loueurName ,
  contractID ,
  contractName ,
  mainContractSIREN ,
  preinvoiceDate ,
  preinvoiceNumber ,
  dateBAP ,
  dateFilterPage ,
  codeAPE ,
  codeAPEhint ,
  customerFilterPage ,
  dealerFilterPage ,
  carFilterPage ,
  voFilterPage ,
  registeredDateCheckbox ,
  invoicedCheckbox ,
}
/* No side effect */
