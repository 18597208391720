// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../components/App_Modal.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../components/App_OverlaySpinner.bs.js";
import * as File_Upload_Stepper$DvmAdminFrontendFr from "./File_Upload_Stepper.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../intl/messages/Messages_File_Upload.bs.js";
import * as File_Upload_Contract_Errors_Check$DvmAdminFrontendFr from "./contractErrors/File_Upload_Contract_Errors_Check.bs.js";

function File_Upload_Modal(Props) {
  var resultFile = Props.resultFile;
  var onClose = Props.onClose;
  var selectedTable = Props.selectedTable;
  var intl = ReactIntl.useIntl();
  var match = React.useReducer((function (param, step) {
          return step;
        }), 0);
  var match$1 = React.useState(function () {
        return false;
      });
  var match$2 = React.useState(function () {
        return false;
      });
  var match$3 = React.useState(function () {
        return Belt_MapString.fromArray([]);
      });
  var setMapped = match$3[1];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(resultFile, undefined, (function (x) {
                  Curry._1(setMapped, (function (param) {
                          return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(x.excelColumns, (function (x) {
                                                return [
                                                        x,
                                                        ""
                                                      ];
                                              }))));
                        }));
                }));
        }), [resultFile]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.fileUploadTitle),
              minWidth: "80vw",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(resultFile),
                    children: React.createElement(Core.Container, {
                          children: match$2[0] ? React.createElement(File_Upload_Contract_Errors_Check$DvmAdminFrontendFr.make, {
                                  onClose: onClose
                                }) : React.createElement(File_Upload_Stepper$DvmAdminFrontendFr.make, {
                                  resultFile: resultFile,
                                  activeStep: match[0],
                                  setActiveStep: match[1],
                                  mapped: match$3[0],
                                  setMapped: setMapped,
                                  nextDisabled: match$1[0],
                                  selectedTable: selectedTable,
                                  validateResult: match$4[0],
                                  setValidateResult: match$4[1],
                                  setNextDisabled: match$1[1],
                                  setCheckAttachmentErrors: match$2[1],
                                  onClose: onClose
                                })
                        })
                  })
            });
}

var Types;

var make = File_Upload_Modal;

export {
  Types ,
  make ,
}
/* react Not a pure module */
