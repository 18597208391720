// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Table$DvmAdminFrontendFr from "../../../../../../common/components/table/App_Table.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Category$DvmAdminFrontendFr from "../../../../../../intl/messages/category/Messages_Category.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Flash_Edit_NonFleet_Modal_Row$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Row.bs.js";
import * as Flash_Edit_NonFleet_Modal_Utils$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Modal_Utils.bs.js";
import * as Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr from "../Flash_Edit_NonFleet_Version_Types.bs.js";

function headersConfiguration(flash) {
  var amountLabel = Belt_Option.mapWithDefault(flash.amount, Messages_Common$DvmAdminFrontendFr.amount, (function (flashamount) {
          return Belt_Option.mapWithDefault(flashamount.numberType, Messages_Common$DvmAdminFrontendFr.amount, (function (amountType) {
                        switch (amountType) {
                          case /* PERCENT */0 :
                              return Messages_Common$DvmAdminFrontendFr.amountPercent;
                          case /* EURO */1 :
                              return Messages_Common$DvmAdminFrontendFr.amountEuro;
                          case /* NOTSET */2 :
                              return Messages_Common$DvmAdminFrontendFr.amount;
                          
                        }
                      }));
        }));
  return {
          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
          tl: {
            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
            tl: {
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.versionLabel, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.customBonus, undefined, undefined, undefined, undefined, undefined, amountLabel),
                tl: {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.Fields.isCustomFEValue, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.nonFleetCustomAmount),
                  tl: /* [] */0
                }
              }
            }
          }
        };
}

function isAmountCustomLabel(customBonus, intl) {
  var message = Belt_Option.mapWithDefault(customBonus, false, (function (x) {
          return x > 0.0;
        })) ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no;
  return intl.formatMessage(message);
}

function Flash_Edit_NonFleet_Modal_Table(Props) {
  var tablePage = Props.tablePage;
  var tableHandler = Props.tableHandler;
  var filterPagingAndSort = Props.filterPagingAndSort;
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  var convertTable = function (param) {
    return {
            TAG: /* Ok */0,
            _0: {
              content: Belt_List.map(Belt_List.keep(Belt_List.fromArray(form.input.versions), (function (x) {
                          return Belt_Option.isSome(Belt_List.getBy(tablePage.content, (function (tablePageItem) {
                                            return x.index === tablePageItem.index;
                                          })));
                        })), (function (version) {
                      return Flash_Edit_NonFleet_Modal_Row$DvmAdminFrontendFr.makeRow(form, version, intl);
                    })),
              currentPage: tableHandler.page,
              totalElements: tablePage.total
            }
          };
  };
  var handleSorting = function (headers) {
    Curry._1(filterPagingAndSort, {
          page: tableHandler.page,
          headers: headers,
          model: tableHandler.model,
          version: tableHandler.version,
          engineType: tableHandler.engineType,
          modelPhase: tableHandler.modelPhase
        });
  };
  var handleChangePage = function (page) {
    Curry._1(filterPagingAndSort, {
          page: page,
          headers: tableHandler.headers,
          model: tableHandler.model,
          version: tableHandler.version,
          engineType: tableHandler.engineType,
          modelPhase: tableHandler.modelPhase
        });
  };
  var handleRowsPerPageChange = function (param) {
    Curry._1(filterPagingAndSort, {
          page: 0,
          headers: tableHandler.headers,
          model: tableHandler.model,
          version: tableHandler.version,
          engineType: tableHandler.engineType,
          modelPhase: tableHandler.modelPhase
        });
  };
  var tmp = {
    onClick: (function (param) {
        Curry._1(form.submit, undefined);
      }),
    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
    size: "medium"
  };
  var tmp$1 = !Curry._1(form.valid, undefined) ? ({
        border: "2px red solid"
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: tableHandler.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(undefined),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined),
                  disablePaginator: !Curry._1(form.valid, undefined)
                }), React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  item: true,
                  justifyContent: "flex-end",
                  spacing: 2,
                  wrap: "nowrap"
                }, React.createElement(Core.Grid, {
                      alignItems: "flex-end",
                      children: null,
                      container: true,
                      direction: "column",
                      item: true
                    }, React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                style: {
                                  fontWeight: "600"
                                },
                                children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.versionsToSave, {
                                      count: Flash_Edit_NonFleet_Modal_Utils$DvmAdminFrontendFr.getChangedRowsAmount(form)
                                    }),
                                variant: "body"
                              }),
                          item: true
                        }), Curry._1(form.valid, undefined) ? null : React.createElement(Core.Grid, {
                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  color: "error",
                                  style: {
                                    fontWeight: "600"
                                  },
                                  children: intl.formatMessage(Messages_Category$DvmAdminFrontendFr.pageHasErrors),
                                  variant: "body"
                                }),
                            item: true
                          })), React.createElement(Core.Grid, {
                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, tmp),
                      item: true
                    })));
}

var Row;

var Form;

var Types;

var Utils;

var Api;

var make = Flash_Edit_NonFleet_Modal_Table;

export {
  Row ,
  Form ,
  Types ,
  Utils ,
  Api ,
  headersConfiguration ,
  isAmountCustomLabel ,
  make ,
}
/* react Not a pure module */
