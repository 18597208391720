// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../common/utils/Utils_Form.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../common/types/App_Types_Status.bs.js";
import * as Bonuses_Search_Types$DvmAdminFrontendFr from "./Bonuses_Search_Types.bs.js";

function getLastFourYearsToFilter(param) {
  var thisYear = ReDate.getYear(new Date()) | 0;
  return Belt_Array.joinWith(Belt_List.toArray({
                  hd: thisYear,
                  tl: {
                    hd: thisYear - 1 | 0,
                    tl: {
                      hd: thisYear - 2 | 0,
                      tl: {
                        hd: thisYear - 3 | 0,
                        tl: /* [] */0
                      }
                    }
                  }
                }), "%5C,", (function (x) {
                return String(x);
              }));
}

var validators_showDealerHistory = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var tmp;
      if (param.showDealerHistory) {
        tmp = undefined;
      } else {
        var match = Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.showDealerHistory;
        tmp = {
          en: match[0] + "=oui",
          fr: match[1] + "=oui"
        };
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_siretPERF = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.siret, param.siretPERF, false)
            };
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.orderType, param.orderType, false)
            };
    })
};

var validators_isOverMaximum = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var match = Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.isOverMaximum;
      return {
              TAG: /* Ok */0,
              _0: param.isOverMaximum ? ({
                    en: match[0] + "=true",
                    fr: match[1] + "=true"
                  }) : undefined
            };
    })
};

var validators_maxAmount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.maxAmount, param.maxAmount, false)
            };
    })
};

var validators_budgetYear = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var operation = param$1.operation;
      var budgetYear = param.budgetYear;
      if (Belt_Option.isSome(budgetYear)) {
        return Belt_Result.map(Utils_Form$DvmAdminFrontendFr.validateDate(budgetYear, undefined), (function (date) {
                      return Utils_Api$DvmAdminFrontendFr.dateYearToQueryParamBilingual(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.budgetYear : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.budgetYear, date);
                    }));
      }
      if (param.showBonusesPlusHistory) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var match = operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.budgetYear : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.budgetYear;
      return {
              TAG: /* Ok */0,
              _0: {
                en: match[0] + "=" + getLastFourYearsToFilter(undefined),
                fr: match[1] + "=" + getLastFourYearsToFilter(undefined)
              }
            };
    })
};

var validators_version = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, param$1.operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.versionID : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.version, param.version, false)
            };
    })
};

var validators_model = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, param$1.operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.modelID : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.model, param.model, false)
            };
    })
};

var validators_status = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var operation = param$1.operation;
      var status = param.status;
      var tmp;
      if (Belt_List.length(status) > 0) {
        tmp = Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.actionStatus : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.status, "normal", status);
      } else {
        var match = operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.actionStatus : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.status;
        tmp = {
          en: match[0] + "<>" + App_Types_Status$DvmAdminFrontendFr.toString(/* CANCELED */2),
          fr: match[1] + "<>" + App_Types_Status$DvmAdminFrontendFr.toString(/* CANCELED */2)
        };
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_actionId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, param$1.operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.actionId : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.actionId, param.actionId, false)
            };
    })
};

var validators_actionType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, param$1.operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.actionType : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.actionType, param.actionType, false)
            };
    })
};

var validators_budgetType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var tmp;
      switch (param.orderType) {
        case "CL" :
        case "PG" :
        case "RC" :
        case "RR" :
        case "VD" :
        case "VE" :
            tmp = undefined;
            break;
        default:
          tmp = Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, param$1.operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.budgetType : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.budgetType, param.budgetType, false);
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_orderId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, param$1.operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.customerOrderNumber : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.orderId, param.orderId, false)
            };
    })
};

var validators_dealerId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(param$1.operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.dealerID : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.dealerId, "normal", param.dealerId)
            };
    })
};

var validators_dr = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(param$1.operation ? Bonuses_Search_Types$DvmAdminFrontendFr.FieldsView.dr : Bonuses_Search_Types$DvmAdminFrontendFr.FieldsManage.dr, "normal", param.dr)
            };
    })
};

var validators = {
  showDealerHistory: validators_showDealerHistory,
  siretPERF: validators_siretPERF,
  orderType: validators_orderType,
  isOverMaximum: validators_isOverMaximum,
  maxAmount: validators_maxAmount,
  showBonusesPlusHistory: undefined,
  budgetYear: validators_budgetYear,
  version: validators_version,
  model: validators_model,
  status: validators_status,
  actionId: validators_actionId,
  actionType: validators_actionType,
  budgetType: validators_budgetType,
  orderId: validators_orderId,
  dealerId: validators_dealerId,
  dr: validators_dr
};

function initialFieldsStatuses(_input) {
  return {
          showDealerHistory: /* Pristine */0,
          siretPERF: /* Pristine */0,
          orderType: /* Pristine */0,
          isOverMaximum: /* Pristine */0,
          maxAmount: /* Pristine */0,
          showBonusesPlusHistory: /* Pristine */0,
          budgetYear: /* Pristine */0,
          version: /* Pristine */0,
          model: /* Pristine */0,
          status: /* Pristine */0,
          actionId: /* Pristine */0,
          actionType: /* Pristine */0,
          budgetType: /* Pristine */0,
          orderId: /* Pristine */0,
          dealerId: /* Pristine */0,
          dr: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.showDealerHistory;
  var match_0 = match ? match._0 : Curry._2(validators.showDealerHistory.validate, input, metadata);
  var match$1 = fieldsStatuses.siretPERF;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.siretPERF.validate, input, metadata);
  var match$2 = fieldsStatuses.orderType;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.orderType.validate, input, metadata);
  var match$3 = fieldsStatuses.isOverMaximum;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.isOverMaximum.validate, input, metadata);
  var match$4 = fieldsStatuses.maxAmount;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.maxAmount.validate, input, metadata);
  var match_0$5 = {
    TAG: /* Ok */0,
    _0: input.showBonusesPlusHistory
  };
  var match$5 = fieldsStatuses.budgetYear;
  var match_0$6 = match$5 ? match$5._0 : Curry._2(validators.budgetYear.validate, input, metadata);
  var match$6 = fieldsStatuses.version;
  var match_0$7 = match$6 ? match$6._0 : Curry._2(validators.version.validate, input, metadata);
  var match$7 = fieldsStatuses.model;
  var match_0$8 = match$7 ? match$7._0 : Curry._2(validators.model.validate, input, metadata);
  var match$8 = fieldsStatuses.status;
  var match_0$9 = match$8 ? match$8._0 : Curry._2(validators.status.validate, input, metadata);
  var match$9 = fieldsStatuses.actionId;
  var match_0$10 = match$9 ? match$9._0 : Curry._2(validators.actionId.validate, input, metadata);
  var match$10 = fieldsStatuses.actionType;
  var match_0$11 = match$10 ? match$10._0 : Curry._2(validators.actionType.validate, input, metadata);
  var match$11 = fieldsStatuses.budgetType;
  var match_0$12 = match$11 ? match$11._0 : Curry._2(validators.budgetType.validate, input, metadata);
  var match$12 = fieldsStatuses.orderId;
  var match_0$13 = match$12 ? match$12._0 : Curry._2(validators.orderId.validate, input, metadata);
  var match$13 = fieldsStatuses.dealerId;
  var match_0$14 = match$13 ? match$13._0 : Curry._2(validators.dealerId.validate, input, metadata);
  var match$14 = fieldsStatuses.dr;
  var match_0$15 = match$14 ? match$14._0 : Curry._2(validators.dr.validate, input, metadata);
  var showDealerHistoryResult = match_0;
  var showDealerHistoryResult$1;
  if (showDealerHistoryResult.TAG === /* Ok */0) {
    var siretPERFResult = match_0$1;
    if (siretPERFResult.TAG === /* Ok */0) {
      var orderTypeResult = match_0$2;
      if (orderTypeResult.TAG === /* Ok */0) {
        var isOverMaximumResult = match_0$3;
        if (isOverMaximumResult.TAG === /* Ok */0) {
          var maxAmountResult = match_0$4;
          if (maxAmountResult.TAG === /* Ok */0) {
            var showBonusesPlusHistoryResult = match_0$5;
            if (showBonusesPlusHistoryResult.TAG === /* Ok */0) {
              var budgetYearResult = match_0$6;
              if (budgetYearResult.TAG === /* Ok */0) {
                var versionResult = match_0$7;
                if (versionResult.TAG === /* Ok */0) {
                  var modelResult = match_0$8;
                  if (modelResult.TAG === /* Ok */0) {
                    var statusResult = match_0$9;
                    if (statusResult.TAG === /* Ok */0) {
                      var actionIdResult = match_0$10;
                      if (actionIdResult.TAG === /* Ok */0) {
                        var actionTypeResult = match_0$11;
                        if (actionTypeResult.TAG === /* Ok */0) {
                          var budgetTypeResult = match_0$12;
                          if (budgetTypeResult.TAG === /* Ok */0) {
                            var orderIdResult = match_0$13;
                            if (orderIdResult.TAG === /* Ok */0) {
                              var dealerIdResult = match_0$14;
                              if (dealerIdResult.TAG === /* Ok */0) {
                                var drResult = match_0$15;
                                if (drResult.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Valid */0,
                                          output: {
                                            dr: drResult._0,
                                            dealerId: dealerIdResult._0,
                                            orderId: orderIdResult._0,
                                            budgetType: budgetTypeResult._0,
                                            actionType: actionTypeResult._0,
                                            actionId: actionIdResult._0,
                                            status: statusResult._0,
                                            model: modelResult._0,
                                            version: versionResult._0,
                                            budgetYear: budgetYearResult._0,
                                            showBonusesPlusHistory: showBonusesPlusHistoryResult._0,
                                            maxAmount: maxAmountResult._0,
                                            isOverMaximum: isOverMaximumResult._0,
                                            orderType: orderTypeResult._0,
                                            siretPERF: siretPERFResult._0,
                                            showDealerHistory: showDealerHistoryResult._0
                                          },
                                          fieldsStatuses: {
                                            showDealerHistory: /* Dirty */{
                                              _0: showDealerHistoryResult,
                                              _1: /* Shown */0
                                            },
                                            siretPERF: /* Dirty */{
                                              _0: siretPERFResult,
                                              _1: /* Shown */0
                                            },
                                            orderType: /* Dirty */{
                                              _0: orderTypeResult,
                                              _1: /* Shown */0
                                            },
                                            isOverMaximum: /* Dirty */{
                                              _0: isOverMaximumResult,
                                              _1: /* Shown */0
                                            },
                                            maxAmount: /* Dirty */{
                                              _0: maxAmountResult,
                                              _1: /* Shown */0
                                            },
                                            showBonusesPlusHistory: /* Dirty */{
                                              _0: showBonusesPlusHistoryResult,
                                              _1: /* Hidden */1
                                            },
                                            budgetYear: /* Dirty */{
                                              _0: budgetYearResult,
                                              _1: /* Shown */0
                                            },
                                            version: /* Dirty */{
                                              _0: versionResult,
                                              _1: /* Shown */0
                                            },
                                            model: /* Dirty */{
                                              _0: modelResult,
                                              _1: /* Shown */0
                                            },
                                            status: /* Dirty */{
                                              _0: statusResult,
                                              _1: /* Shown */0
                                            },
                                            actionId: /* Dirty */{
                                              _0: actionIdResult,
                                              _1: /* Shown */0
                                            },
                                            actionType: /* Dirty */{
                                              _0: actionTypeResult,
                                              _1: /* Shown */0
                                            },
                                            budgetType: /* Dirty */{
                                              _0: budgetTypeResult,
                                              _1: /* Shown */0
                                            },
                                            orderId: /* Dirty */{
                                              _0: orderIdResult,
                                              _1: /* Shown */0
                                            },
                                            dealerId: /* Dirty */{
                                              _0: dealerIdResult,
                                              _1: /* Shown */0
                                            },
                                            dr: /* Dirty */{
                                              _0: drResult,
                                              _1: /* Shown */0
                                            }
                                          },
                                          collectionsStatuses: undefined
                                        };
                                }
                                showDealerHistoryResult$1 = showDealerHistoryResult;
                              } else {
                                showDealerHistoryResult$1 = showDealerHistoryResult;
                              }
                            } else {
                              showDealerHistoryResult$1 = showDealerHistoryResult;
                            }
                          } else {
                            showDealerHistoryResult$1 = showDealerHistoryResult;
                          }
                        } else {
                          showDealerHistoryResult$1 = showDealerHistoryResult;
                        }
                      } else {
                        showDealerHistoryResult$1 = showDealerHistoryResult;
                      }
                    } else {
                      showDealerHistoryResult$1 = showDealerHistoryResult;
                    }
                  } else {
                    showDealerHistoryResult$1 = showDealerHistoryResult;
                  }
                } else {
                  showDealerHistoryResult$1 = showDealerHistoryResult;
                }
              } else {
                showDealerHistoryResult$1 = showDealerHistoryResult;
              }
            } else {
              showDealerHistoryResult$1 = showDealerHistoryResult;
            }
          } else {
            showDealerHistoryResult$1 = showDealerHistoryResult;
          }
        } else {
          showDealerHistoryResult$1 = showDealerHistoryResult;
        }
      } else {
        showDealerHistoryResult$1 = showDealerHistoryResult;
      }
    } else {
      showDealerHistoryResult$1 = showDealerHistoryResult;
    }
  } else {
    showDealerHistoryResult$1 = showDealerHistoryResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            showDealerHistory: /* Dirty */{
              _0: showDealerHistoryResult$1,
              _1: /* Shown */0
            },
            siretPERF: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            isOverMaximum: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            maxAmount: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            showBonusesPlusHistory: /* Dirty */{
              _0: match_0$5,
              _1: /* Hidden */1
            },
            budgetYear: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            status: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            actionId: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            actionType: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            budgetType: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            orderId: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            dealerId: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            dr: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurShowDealerHistoryField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.showDealerHistory, validators_showDealerHistory, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: status,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSiretPERFField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.siretPERF, validators_siretPERF, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: status,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: status,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurIsOverMaximumField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.isOverMaximum, validators_isOverMaximum, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: status,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMaxAmountField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.maxAmount, validators_maxAmount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: status,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShowBonusesPlusHistoryField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.showBonusesPlusHistory, state.fieldsStatuses.showBonusesPlusHistory, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: status,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetYearField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.budgetYear, validators_budgetYear, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: status,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: status,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: status,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStatusField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.status, validators_status, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionIdField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.actionId, validators_actionId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: status,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionTypeField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.actionType, validators_actionType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: status,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetTypeField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.budgetType, validators_budgetType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: status,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderIdField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderId, validators_orderId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: status,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.dealerId, validators_dealerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDrField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.dr, validators_dr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  showDealerHistory: init.showDealerHistory,
                                  siretPERF: init.siretPERF,
                                  orderType: init.orderType,
                                  isOverMaximum: init.isOverMaximum,
                                  maxAmount: init.maxAmount,
                                  showBonusesPlusHistory: init.showBonusesPlusHistory,
                                  budgetYear: init.budgetYear,
                                  version: init.version,
                                  model: init.model,
                                  status: init.status,
                                  actionId: init.actionId,
                                  actionType: init.actionType,
                                  budgetType: init.budgetType,
                                  orderId: init.orderId,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */16 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */16,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */17,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */19);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */18);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */17 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */18 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */19 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateShowDealerHistoryField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.showDealerHistory, state.submissionStatus, validators_showDealerHistory, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: status,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSiretPERFField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.siretPERF, state.submissionStatus, validators_siretPERF, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: status,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, state.fieldsStatuses.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: status,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateIsOverMaximumField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, state.fieldsStatuses.isOverMaximum, state.submissionStatus, validators_isOverMaximum, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: status,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMaxAmountField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.maxAmount, state.submissionStatus, validators_maxAmount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: status,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShowBonusesPlusHistoryField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.showBonusesPlusHistory, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: status,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetYearField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.budgetYear, state.submissionStatus, validators_budgetYear, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: status,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: status,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: status,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStatusField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, state.fieldsStatuses.status, state.submissionStatus, validators_status, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionIdField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, state.fieldsStatuses.actionId, state.submissionStatus, validators_actionId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: status,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionTypeField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, state.fieldsStatuses.actionType, state.submissionStatus, validators_actionType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: status,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetTypeField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, state.fieldsStatuses.budgetType, state.submissionStatus, validators_budgetType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: status,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderIdField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$13, metadata, state.fieldsStatuses.orderId, state.submissionStatus, validators_orderId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: status,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, state.fieldsStatuses.dealerId, state.submissionStatus, validators_dealerId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: status,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDrField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$15, metadata, state.fieldsStatuses.dr, state.submissionStatus, validators_dr, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            showDealerHistory: init.showDealerHistory,
                                            siretPERF: init.siretPERF,
                                            orderType: init.orderType,
                                            isOverMaximum: init.isOverMaximum,
                                            maxAmount: init.maxAmount,
                                            showBonusesPlusHistory: init.showBonusesPlusHistory,
                                            budgetYear: init.budgetYear,
                                            version: init.version,
                                            model: init.model,
                                            status: init.status,
                                            actionId: init.actionId,
                                            actionType: init.actionType,
                                            budgetType: init.budgetType,
                                            orderId: init.orderId,
                                            dealerId: init.dealerId,
                                            dr: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */16 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */17 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */18 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateShowDealerHistory: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShowDealerHistoryField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiretPERF: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretPERFField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateIsOverMaximum: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateIsOverMaximumField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMaxAmount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMaxAmountField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateShowBonusesPlusHistory: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShowBonusesPlusHistoryField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetYear: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetYearField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStatusField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionIdField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionTypeField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetTypeField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderIdField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDrField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurShowDealerHistory: (function (param) {
              Curry._1(dispatch, /* BlurShowDealerHistoryField */0);
            }),
          blurSiretPERF: (function (param) {
              Curry._1(dispatch, /* BlurSiretPERFField */1);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */2);
            }),
          blurIsOverMaximum: (function (param) {
              Curry._1(dispatch, /* BlurIsOverMaximumField */3);
            }),
          blurMaxAmount: (function (param) {
              Curry._1(dispatch, /* BlurMaxAmountField */4);
            }),
          blurShowBonusesPlusHistory: (function (param) {
              Curry._1(dispatch, /* BlurShowBonusesPlusHistoryField */5);
            }),
          blurBudgetYear: (function (param) {
              Curry._1(dispatch, /* BlurBudgetYearField */6);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */7);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */8);
            }),
          blurStatus: (function (param) {
              Curry._1(dispatch, /* BlurStatusField */9);
            }),
          blurActionId: (function (param) {
              Curry._1(dispatch, /* BlurActionIdField */10);
            }),
          blurActionType: (function (param) {
              Curry._1(dispatch, /* BlurActionTypeField */11);
            }),
          blurBudgetType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetTypeField */12);
            }),
          blurOrderId: (function (param) {
              Curry._1(dispatch, /* BlurOrderIdField */13);
            }),
          blurDealerId: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdField */14);
            }),
          blurDr: (function (param) {
              Curry._1(dispatch, /* BlurDrField */15);
            }),
          showDealerHistoryResult: Formality.exposeFieldResult(state.fieldsStatuses.showDealerHistory),
          siretPERFResult: Formality.exposeFieldResult(state.fieldsStatuses.siretPERF),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          isOverMaximumResult: Formality.exposeFieldResult(state.fieldsStatuses.isOverMaximum),
          maxAmountResult: Formality.exposeFieldResult(state.fieldsStatuses.maxAmount),
          showBonusesPlusHistoryResult: Formality.exposeFieldResult(state.fieldsStatuses.showBonusesPlusHistory),
          budgetYearResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetYear),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          statusResult: Formality.exposeFieldResult(state.fieldsStatuses.status),
          actionIdResult: Formality.exposeFieldResult(state.fieldsStatuses.actionId),
          actionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.actionType),
          budgetTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetType),
          orderIdResult: Formality.exposeFieldResult(state.fieldsStatuses.orderId),
          dealerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerId),
          drResult: Formality.exposeFieldResult(state.fieldsStatuses.dr),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.showDealerHistory || match.siretPERF || match.orderType || match.isOverMaximum || match.maxAmount || match.showBonusesPlusHistory || match.budgetYear || match.version || match.model || match.status || match.actionId || match.actionType || match.budgetType || match.orderId || match.dealerId) {
                exit = 1;
              } else {
                if (!match.dr) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        return true;
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */16);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */17);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */18);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */18,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */19);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  dr: /* [] */0,
  dealerId: /* [] */0,
  orderId: "",
  budgetType: "",
  actionType: "",
  actionId: "",
  status: /* [] */0,
  model: "",
  version: "",
  budgetYear: undefined,
  showBonusesPlusHistory: false,
  maxAmount: "",
  isOverMaximum: false,
  orderType: "",
  siretPERF: "",
  showDealerHistory: false
};

export {
  getLastFourYearsToFilter ,
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
