// Generated by ReScript, PLEASE EDIT WITH CARE


var url = /^([^?#]*)\??([^#]*)#?(.*)$/;

var oidcAuthCodeUrl = /^.*[?&]?code=.+$/;

var oidcAuthorities = /[\[\]\s+]/ig;

var whitespaces = /\s/g;

var Regex = {
  url: url,
  oidcAuthCodeUrl: oidcAuthCodeUrl,
  oidcAuthorities: oidcAuthorities,
  whitespaces: whitespaces
};

var defaultAlertTimeout = 0;

var defaultSuccessAlertTimeout = 2000;

var quickAccessMaxCount = 6;

var topMenuHeight = "7rem";

var requiredLabelSuffix = "*";

var redirectUrlSessionStorageKey = "dvmeRedirectUrl";

var dvmeRoleSessionStorageKey = "dvmeRole";

var dvmeLocaleLocalStorageKey = "dvmeLocale";

export {
  defaultAlertTimeout ,
  defaultSuccessAlertTimeout ,
  quickAccessMaxCount ,
  topMenuHeight ,
  requiredLabelSuffix ,
  redirectUrlSessionStorageKey ,
  dvmeRoleSessionStorageKey ,
  dvmeLocaleLocalStorageKey ,
  Regex ,
}
/* No side effect */
