// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function makeOrder(order) {
  if (order.TAG === /* Fleet */0) {
    var order$1 = order._0;
    var orderDetail_lastModificationDate = order$1.detail.lastModificationDate;
    var orderDetail_siret = order$1.detail.siret;
    var orderDetail_customerName = order$1.detail.customerName;
    var orderDetail_shortCustomerName = order$1.detail.shortCustomerName;
    var orderDetail_model = order$1.detail.model;
    var orderDetail_version = order$1.detail.version;
    var orderDetail_acquisitionType = order$1.detail.acquisitionType;
    var orderDetail_budget = order$1.detail.budget;
    var orderDetail_orderDate = order$1.detail.orderDate;
    var orderDetail_registrationDate = order$1.detail.registrationDate;
    var orderDetail_deliveryDate = order$1.detail.deliveryDate;
    var orderDetail_renaultInvoiceDate = order$1.detail.renaultInvoiceDate;
    var orderDetail_pvcHt = order$1.detail.pvcHt;
    var orderDetail_tariffNumber = order$1.detail.tariffNumber;
    var orderDetail_madcDate = order$1.detail.madcDate;
    var orderDetail_madaDate = order$1.detail.madaDate;
    var orderDetail_customerInvoiceDate = order$1.detail.customerInvoiceDate;
    var orderDetail_customerInvoiceNumber = order$1.detail.customerInvoiceNumber;
    var orderDetail_agentCode = order$1.detail.agentCode;
    var orderDetail_salesmanName = order$1.detail.salesmanName;
    var orderDetail_delivered = order$1.detail.delivered;
    var orderDetail_finalCustomerLLD = order$1.detail.finalCustomerLLD;
    var orderDetail_finalCustomerNameLLD = order$1.detail.finalCustomerNameLLD;
    var orderDetail_registrationId = order$1.detail.newCarRegistrationID;
    var orderDetail_preinvoiceDate = order$1.detail.preinvoiceDate;
    var orderDetail_preinvoiceNumber = order$1.detail.preinvoiceNumber;
    var orderDetail_bapInvoiceDate = order$1.detail.bapInvoiceDate;
    var orderDetail_cancellationDate = order$1.detail.cancellationDate;
    var orderDetail_perfDetails = order$1.detail.perfDetails;
    var orderDetail_renaultInvoiceNumber = order$1.detail.renaultInvoiceNumber;
    var orderDetail_vin = order$1.detail.vin;
    var orderDetail_contractFinal = order$1.detail.contractFinal;
    var orderDetail_orderStatus = order$1.detail.orderStatus;
    var orderDetail_usedCarStatus = order$1.detail.usedCarStatus;
    var orderDetail_phase = order$1.detail.phase;
    var orderDetail = {
      lastModificationDate: orderDetail_lastModificationDate,
      siret: orderDetail_siret,
      customerName: orderDetail_customerName,
      clientFirstName: undefined,
      shortCustomerName: orderDetail_shortCustomerName,
      model: orderDetail_model,
      version: orderDetail_version,
      acquisitionType: orderDetail_acquisitionType,
      budget: orderDetail_budget,
      orderDate: orderDetail_orderDate,
      registrationDate: orderDetail_registrationDate,
      deliveryDate: orderDetail_deliveryDate,
      renaultInvoiceDate: orderDetail_renaultInvoiceDate,
      pvcHt: orderDetail_pvcHt,
      tariffNumber: orderDetail_tariffNumber,
      madcDate: orderDetail_madcDate,
      madaDate: orderDetail_madaDate,
      customerInvoiceDate: orderDetail_customerInvoiceDate,
      customerInvoiceNumber: orderDetail_customerInvoiceNumber,
      agentCode: orderDetail_agentCode,
      salesmanName: orderDetail_salesmanName,
      delivered: orderDetail_delivered,
      finalCustomerLLD: orderDetail_finalCustomerLLD,
      finalCustomerNameLLD: orderDetail_finalCustomerNameLLD,
      registrationId: orderDetail_registrationId,
      preinvoiceDate: orderDetail_preinvoiceDate,
      preinvoiceNumber: orderDetail_preinvoiceNumber,
      bapInvoiceDate: orderDetail_bapInvoiceDate,
      cancellationDate: orderDetail_cancellationDate,
      perfDetails: orderDetail_perfDetails,
      parcStartDate: undefined,
      parcEndDate: undefined,
      renaultInvoiceNumber: orderDetail_renaultInvoiceNumber,
      pvcTtc: undefined,
      pvrHt: undefined,
      vin: orderDetail_vin,
      contractFinal: orderDetail_contractFinal,
      financialCode: undefined,
      caht: undefined,
      cattc: undefined,
      discount: undefined,
      orderStatus: orderDetail_orderStatus,
      usedCarStatus: orderDetail_usedCarStatus,
      phase: orderDetail_phase
    };
    var budget = Belt_Option.mapWithDefault(order$1.budget, undefined, (function (budget) {
            return {
                    actionType: undefined,
                    stringActionID: budget.stringActionID,
                    actionLabel: budget.actionLabel,
                    state: budget.state,
                    comment: budget.comment,
                    bonus: budget.bonus,
                    discount: budget.discount,
                    year: budget.year,
                    deros: budget.deros,
                    preinvoiceDate: budget.preinvoiceDate,
                    preinvoiceNumber: budget.preinvoiceNumber,
                    invoiceDate: budget.invoiceDate,
                    budgetType: budget.budgetType,
                    amountPercent: budget.amountPercent,
                    amountEuro: budget.amountEuro,
                    gridDiscount: budget.gridDiscount,
                    bypassDetail: budget.bypassDetail,
                    perfValidationDetail: undefined,
                    bonusPaid: undefined,
                    bonusTtc: undefined,
                    withUsedCar: undefined
                  };
          }));
    var stoc = Belt_Option.mapWithDefault(order$1.stoc, undefined, (function (stoc) {
            return Belt_Array.map(stoc, (function (item) {
                          return {
                                  actionType: item.actionType,
                                  stringActionID: item.stringActionID,
                                  actionLabel: item.actionLabel,
                                  state: item.state,
                                  comment: item.comment,
                                  bonus: item.bonus,
                                  discount: item.discount,
                                  year: item.year,
                                  deros: item.deros,
                                  preinvoiceDate: item.preinvoiceDate,
                                  preinvoiceNumber: item.preinvoiceNumber,
                                  invoiceDate: item.invoiceDate,
                                  budgetType: item.budgetType,
                                  amountPercent: item.amountPercent,
                                  amountEuro: item.amountEuro,
                                  gridDiscount: item.gridDiscount,
                                  bypassDetail: undefined,
                                  perfValidationDetail: undefined,
                                  bonusPaid: undefined,
                                  bonusTtc: undefined,
                                  withUsedCar: undefined
                                };
                        }));
          }));
    var perf = Belt_Option.mapWithDefault(order$1.perf, undefined, (function (perf) {
            return Belt_Array.map(perf, (function (item) {
                          return {
                                  actionType: item.actionType,
                                  stringActionID: item.stringActionID,
                                  actionLabel: item.actionLabel,
                                  state: item.state,
                                  comment: item.comment,
                                  bonus: item.bonus,
                                  discount: item.discount,
                                  year: item.year,
                                  deros: item.deros,
                                  preinvoiceDate: item.preinvoiceDate,
                                  preinvoiceNumber: item.preinvoiceNumber,
                                  invoiceDate: item.invoiceDate,
                                  budgetType: item.budgetType,
                                  amountPercent: item.amountPercent,
                                  amountEuro: item.amountEuro,
                                  gridDiscount: item.gridDiscount,
                                  bypassDetail: undefined,
                                  perfValidationDetail: item.perfValidationDetail,
                                  bonusPaid: undefined,
                                  bonusTtc: undefined,
                                  withUsedCar: undefined
                                };
                        }));
          }));
    var repr = Belt_Option.mapWithDefault(order$1.repr, undefined, (function (repr) {
            return Belt_Array.map(repr, (function (item) {
                          return {
                                  actionType: item.actionType,
                                  stringActionID: item.stringActionID,
                                  actionLabel: item.actionLabel,
                                  state: item.state,
                                  comment: item.comment,
                                  bonus: item.bonus,
                                  discount: item.discount,
                                  year: item.year,
                                  deros: item.deros,
                                  preinvoiceDate: item.preinvoiceDate,
                                  preinvoiceNumber: item.preinvoiceNumber,
                                  invoiceDate: item.invoiceDate,
                                  budgetType: item.budgetType,
                                  amountPercent: item.amountPercent,
                                  amountEuro: item.amountEuro,
                                  gridDiscount: item.gridDiscount,
                                  bypassDetail: undefined,
                                  perfValidationDetail: undefined,
                                  bonusPaid: undefined,
                                  bonusTtc: undefined,
                                  withUsedCar: undefined
                                };
                        }));
          }));
    return {
            dr: order$1.dr,
            dealerID: order$1.dealerID,
            dealerName: order$1.dealerName,
            agentName: order$1.agentName,
            orderNumberCustomer: order$1.orderNumberCustomer,
            orderNumberCar: order$1.orderNumberCar,
            detail: orderDetail,
            budget: budget,
            stoc: stoc,
            perf: perf,
            repr: repr,
            dero: order$1.deros,
            contractID: order$1.contractID,
            usedCarDetail: order$1.usedCarDetail,
            archived: order$1.archived,
            orderType: order$1.orderType,
            locationCommonName: order$1.locationCommonName,
            actions: undefined
          };
  }
  var order$2 = order._0;
  var orderDetail_lastModificationDate$1 = order$2.detail.lastModificationDate;
  var orderDetail_customerName$1 = order$2.detail.customerName;
  var orderDetail_clientFirstName = order$2.detail.clientFirstName;
  var orderDetail_shortCustomerName$1 = order$2.detail.shortCustomerName;
  var orderDetail_model$1 = order$2.detail.model;
  var orderDetail_version$1 = order$2.detail.version;
  var orderDetail_orderDate$1 = order$2.detail.orderDate;
  var orderDetail_registrationDate$1 = order$2.detail.registrationDate;
  var orderDetail_deliveryDate$1 = order$2.detail.deliveryDate;
  var orderDetail_renaultInvoiceDate$1 = order$2.detail.renaultInvoiceDate;
  var orderDetail_pvcHt$1 = order$2.detail.pvcHt;
  var orderDetail_tariffNumber$1 = order$2.detail.tariffNumber;
  var orderDetail_madcDate$1 = order$2.detail.madcDate;
  var orderDetail_madaDate$1 = order$2.detail.madaDate;
  var orderDetail_customerInvoiceDate$1 = order$2.detail.customerInvoiceDate;
  var orderDetail_customerInvoiceNumber$1 = order$2.detail.customerInvoiceNumber;
  var orderDetail_agentCode$1 = order$2.detail.agentCode;
  var orderDetail_salesmanName$1 = order$2.detail.salesmanName;
  var orderDetail_delivered$1 = order$2.detail.delivered;
  var orderDetail_registrationId$1 = order$2.detail.registrationId;
  var orderDetail_cancellationDate$1 = order$2.detail.cancellationDate;
  var orderDetail_parcStartDate = order$2.detail.parcStartDate;
  var orderDetail_parcEndDate = order$2.detail.parcEndDate;
  var orderDetail_renaultInvoiceNumber$1 = order$2.detail.renaultInvoiceNumber;
  var orderDetail_pvcTtc = order$2.detail.pvcTtc;
  var orderDetail_pvrHt = order$2.detail.pvrHt;
  var orderDetail_vin$1 = order$2.detail.vin;
  var orderDetail_financialCode = order$2.detail.financialCode;
  var orderDetail_caht = order$2.detail.caht;
  var orderDetail_cattc = order$2.detail.cattc;
  var orderDetail_discount = order$2.detail.customerDiscount;
  var orderDetail_orderStatus$1 = order$2.detail.orderStatus;
  var orderDetail_usedCarStatus$1 = order$2.detail.usedCarStatus;
  var orderDetail_phase$1 = order$2.detail.phase;
  var orderDetail$1 = {
    lastModificationDate: orderDetail_lastModificationDate$1,
    siret: undefined,
    customerName: orderDetail_customerName$1,
    clientFirstName: orderDetail_clientFirstName,
    shortCustomerName: orderDetail_shortCustomerName$1,
    model: orderDetail_model$1,
    version: orderDetail_version$1,
    acquisitionType: undefined,
    budget: undefined,
    orderDate: orderDetail_orderDate$1,
    registrationDate: orderDetail_registrationDate$1,
    deliveryDate: orderDetail_deliveryDate$1,
    renaultInvoiceDate: orderDetail_renaultInvoiceDate$1,
    pvcHt: orderDetail_pvcHt$1,
    tariffNumber: orderDetail_tariffNumber$1,
    madcDate: orderDetail_madcDate$1,
    madaDate: orderDetail_madaDate$1,
    customerInvoiceDate: orderDetail_customerInvoiceDate$1,
    customerInvoiceNumber: orderDetail_customerInvoiceNumber$1,
    agentCode: orderDetail_agentCode$1,
    salesmanName: orderDetail_salesmanName$1,
    delivered: orderDetail_delivered$1,
    finalCustomerLLD: undefined,
    finalCustomerNameLLD: undefined,
    registrationId: orderDetail_registrationId$1,
    preinvoiceDate: undefined,
    preinvoiceNumber: undefined,
    bapInvoiceDate: undefined,
    cancellationDate: orderDetail_cancellationDate$1,
    perfDetails: undefined,
    parcStartDate: orderDetail_parcStartDate,
    parcEndDate: orderDetail_parcEndDate,
    renaultInvoiceNumber: orderDetail_renaultInvoiceNumber$1,
    pvcTtc: orderDetail_pvcTtc,
    pvrHt: orderDetail_pvrHt,
    vin: orderDetail_vin$1,
    contractFinal: undefined,
    financialCode: orderDetail_financialCode,
    caht: orderDetail_caht,
    cattc: orderDetail_cattc,
    discount: orderDetail_discount,
    orderStatus: orderDetail_orderStatus$1,
    usedCarStatus: orderDetail_usedCarStatus$1,
    phase: orderDetail_phase$1
  };
  var actions = Belt_Option.mapWithDefault(order$2.actions, undefined, (function (action) {
          return Belt_Array.map(action, (function (item) {
                        return {
                                actionType: item.actionType,
                                stringActionID: item.stringActionID,
                                actionLabel: item.actionLabel,
                                state: item.state,
                                comment: item.comment,
                                bonus: item.bonus,
                                discount: item.discount,
                                year: item.year,
                                deros: item.deros,
                                preinvoiceDate: item.preinvoiceDate,
                                preinvoiceNumber: item.preinvoiceNumber,
                                invoiceDate: item.invoiceDate,
                                budgetType: item.budgetType,
                                amountPercent: item.amountPercent,
                                amountEuro: item.amountEuro,
                                gridDiscount: item.gridDiscount,
                                bypassDetail: undefined,
                                perfValidationDetail: undefined,
                                bonusPaid: item.bonusPaid,
                                bonusTtc: item.bonusTtc,
                                withUsedCar: item.withUsedCar
                              };
                      }));
        }));
  return {
          dr: order$2.dr,
          dealerID: order$2.dealerID,
          dealerName: order$2.dealerName,
          agentName: order$2.agentName,
          orderNumberCustomer: order$2.orderNumberCustomer,
          orderNumberCar: order$2.orderNumberCar,
          detail: orderDetail$1,
          budget: undefined,
          stoc: undefined,
          perf: undefined,
          repr: undefined,
          dero: order$2.deros,
          contractID: order$2.contractID,
          usedCarDetail: order$2.usedCarDetail,
          archived: order$2.archived,
          orderType: order$2.orderType,
          locationCommonName: order$2.locationCommonName,
          actions: actions
        };
}

export {
  makeOrder ,
}
/* No side effect */
