// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../common/components/icons/App_Icon.bs.js";
import * as App_TopMenu$DvmAdminFrontendFr from "../../common/components/topMenu/App_TopMenu.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../intl/messages/Messages_Common.bs.js";

function Varicent_Migration_Info(Props) {
  var intl = ReactIntl.useIntl();
  return React.createElement("div", undefined, React.createElement(App_TopMenu$DvmAdminFrontendFr.make, {
                  hideFeatures: true
                }), React.createElement(Core.Grid, {
                  alignItems: "center",
                  children: null,
                  container: true,
                  direction: "column",
                  justifyContent: "center",
                  style: {
                    minHeight: "80vh"
                  }
                }, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                      type_: /* Factory */54,
                      style: {
                        fontSize: "20rem"
                      }
                    }), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.varicentMigrationInfo),
                      variant: "h2"
                    })));
}

var make = Varicent_Migration_Info;

export {
  make ,
}
/* react Not a pure module */
