// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../api/RestApi.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "./Flash_Search_Types.bs.js";

function fetchFlashSearch(submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, userManager, setResult, queryParams, selectedRole, tableType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var whichEndpoint = tableType ? "/flash/actions/modular" : "/flash/actions";
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Search_Types$DvmAdminFrontendFr.searchResult_decode, undefined, userManager, selectedRole, "" + whichEndpoint + "" + queryParams + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          Belt_Option.forEach(submissionCallbacks, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModal, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          Belt_Option.forEach(submissionCallbacksModalAdditional, (function (param) {
                  Curry._1(param.notifyOnSuccess, undefined);
                }));
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Belt_Option.forEach(submissionCallbacks, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchFlashSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModal, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchFlashSearch */0);
              }));
        Belt_Option.forEach(submissionCallbacksModalAdditional, (function (param) {
                Curry._1(param.notifyOnFailure, /* FailedToFetchFlashSearch */0);
              }));
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function deleteFlash(userManager, setResult, selectedRole, actionID) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/flash/actions/" + actionID + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Form;

var FormModal;

var FormModalAdditional;

export {
  Form ,
  FormModal ,
  FormModalAdditional ,
  fetchFlashSearch ,
  deleteFlash ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
