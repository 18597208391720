// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Flash_Edit_Form$DvmAdminFrontendFr from "./Flash_Edit_Form.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "./Flash_Edit_Types.bs.js";
import * as Flash_Edit_Form_VO$DvmAdminFrontendFr from "./Flash_Edit_Form_VO.bs.js";
import * as Flash_Edit_NonFleet_Types$DvmAdminFrontendFr from "./steps/NonFleet/Flash_Edit_NonFleet_Types.bs.js";
import * as Flash_Edit_NonFleet_New_Vehicle_Form$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_New_Vehicle_Form.bs.js";

function actionHasVO(flash) {
  if (Belt_Option.getWithDefault(flash.carAgeAtOrderSelected, false) || Belt_Option.getWithDefault(flash.carAgeAtDeliverySelected, false) || Belt_Option.getWithDefault(flash.possessionPeriodAtOrderSelected, false) || Belt_Option.getWithDefault(flash.possessionPeriodAtDeliverySelected, false) || Belt_Option.isSome(flash.carAgeAtOrderDate) || Belt_Option.isSome(flash.carAgeAtDeliveryDate) || Belt_Option.isSome(flash.possesionPeriodAtOrderDate) || Belt_Option.isSome(flash.possesionPeriodAtDeliveryDate)) {
    return true;
  } else {
    return Belt_Option.isSome(flash.brands);
  }
}

function setForm(flash) {
  var match = flash.orderType;
  var match$1 = flash.actionType;
  return {
          actionID: flash.actionID,
          actionLabel: flash.actionLabel,
          actionType: match !== undefined && match$1 !== undefined ? Flash_Edit_Types$DvmAdminFrontendFr.ActionTypeEdit.fromString(match$1, match) : Flash_Edit_Form$DvmAdminFrontendFr.initialState.actionType,
          budgetType: Belt_Option.getWithDefault(flash.budgetType, Flash_Edit_Form$DvmAdminFrontendFr.initialState.budgetType),
          orderType: Belt_Option.getWithDefault(flash.orderType, Flash_Edit_Form$DvmAdminFrontendFr.initialState.orderType),
          stocDateStart: flash.stocDateStart,
          stocDateEnd: flash.stocDateEnd,
          stocDateType: Belt_Option.getWithDefault(flash.stocDateType, Flash_Edit_Form$DvmAdminFrontendFr.initialState.stocDateType),
          value: Belt_Option.mapWithDefault(flash.amount, Flash_Edit_Form$DvmAdminFrontendFr.initialState.value, (function (x) {
                  return Belt_Option.mapWithDefault(x.value, Flash_Edit_Form$DvmAdminFrontendFr.initialState.value, (function (x) {
                                return String(x);
                              }));
                })),
          numberType: Belt_Option.mapWithDefault(flash.amount, Flash_Edit_Form$DvmAdminFrontendFr.initialState.numberType, (function (x) {
                  return x.numberType;
                })),
          perfType: Belt_Option.getWithDefault(flash.perfType, Flash_Edit_Form$DvmAdminFrontendFr.initialState.perfType),
          includeFinancialCode: Belt_List.length(Belt_Option.getWithDefault(flash.financialCodes, /* [] */0)) > 0 || Belt_Option.getWithDefault(flash.financialCodeOrientation, /* NOTSET */2) !== /* NOTSET */2,
          financialCodes: Belt_Option.getWithDefault(flash.financialCodes, /* [] */0),
          financialCodeOrientation: Belt_Option.getWithDefault(flash.financialCodeOrientation, /* NOTSET */2),
          entryParcDatePeriodStart: flash.entryParcDatePeriodStart,
          entryParcDatePeriodEnd: flash.entryParcDatePeriodEnd,
          exitParcDatePeriodStart: flash.exitParcDatePeriodStart,
          exitParcDatePeriodEnd: flash.exitParcDatePeriodEnd,
          detentionMin: Belt_Option.mapWithDefault(flash.detentionMin, "", (function (prim) {
                  return String(prim);
                })),
          detentionMax: Belt_Option.mapWithDefault(flash.detentionMax, "", (function (prim) {
                  return String(prim);
                })),
          priceType: Belt_Option.getWithDefault(flash.priceType, ""),
          isParcEntry: flash.isParcEntry,
          dealerIds: Belt_Option.getWithDefault(flash.dealerIds, Flash_Edit_Form$DvmAdminFrontendFr.initialState.dealerIds)
        };
}

function setVOForm(flash) {
  return {
          carAge: Belt_Option.getWithDefault(flash.carAgeAtOrderDate, 0) > Belt_Option.getWithDefault(flash.carAgeAtDeliveryDate, 0) ? Belt_Option.mapWithDefault(flash.carAgeAtOrderDate, Flash_Edit_Form_VO$DvmAdminFrontendFr.initialState.carAge, (function (prim) {
                    return String(prim);
                  })) : Belt_Option.mapWithDefault(flash.carAgeAtDeliveryDate, Flash_Edit_Form_VO$DvmAdminFrontendFr.initialState.carAge, (function (prim) {
                    return String(prim);
                  })),
          carAgeWhich: Belt_Option.getWithDefault(flash.carAgeAtOrderDate, 0) === 0 && Belt_Option.getWithDefault(flash.carAgeAtDeliveryDate, 0) === 0 ? "none" : (
              Belt_Option.getWithDefault(flash.carAgeAtOrderDate, 0) > Belt_Option.getWithDefault(flash.carAgeAtDeliveryDate, 0) ? "order" : "delivery"
            ),
          possesionPeriod: Belt_Option.getWithDefault(flash.possesionPeriodAtOrderDate, 0) > Belt_Option.getWithDefault(flash.possesionPeriodAtDeliveryDate, 0) ? Belt_Option.mapWithDefault(flash.possesionPeriodAtOrderDate, Flash_Edit_Form_VO$DvmAdminFrontendFr.initialState.possesionPeriod, (function (prim) {
                    return String(prim);
                  })) : Belt_Option.mapWithDefault(flash.possesionPeriodAtDeliveryDate, Flash_Edit_Form_VO$DvmAdminFrontendFr.initialState.possesionPeriod, (function (prim) {
                    return String(prim);
                  })),
          possesionPeriodWhich: Belt_Option.getWithDefault(flash.possesionPeriodAtOrderDate, 0) === 0 && Belt_Option.getWithDefault(flash.possesionPeriodAtDeliveryDate, 0) === 0 ? "none" : (
              Belt_Option.getWithDefault(flash.possesionPeriodAtOrderDate, 0) > Belt_Option.getWithDefault(flash.possesionPeriodAtDeliveryDate, 0) ? "order" : "delivery"
            ),
          brands: Belt_Option.mapWithDefault(flash.brands, Flash_Edit_Form_VO$DvmAdminFrontendFr.initialState.brands, (function (x) {
                  return Belt_Option.getWithDefault(x.brands, Flash_Edit_Form_VO$DvmAdminFrontendFr.initialState.brands);
                })),
          ie: Belt_Option.mapWithDefault(flash.brands, /* ALL */0, (function (x) {
                  return Belt_Option.getWithDefault(x.ie, /* ALL */0);
                })),
          actionWithVO: actionHasVO(flash),
          usedCarModels: Belt_Option.getWithDefault(flash.usedCarModels, /* [] */0)
        };
}

function setFormGeneralDates(flash) {
  return {
          startDate: flash.startDate,
          endDate: flash.endDate,
          deliveryStartDate: flash.deliveryStartDate,
          deliveryEndDate: flash.deliveryEndDate,
          registrationStartDate: flash.registrationStartDate,
          registrationEndDate: flash.registrationEndDate
        };
}

function setNonFleetNewVehicleForm(flash, newVehicle) {
  return {
          models: Belt_Option.mapWithDefault(newVehicle.modelPhases, /* [] */0, (function (x) {
                  return Belt_List.map(x, (function (model) {
                                return model.one;
                              }));
                })),
          modelPhases: Belt_Option.mapWithDefault(newVehicle.modelPhases, /* [] */0, (function (x) {
                  return Belt_List.map(x, (function (model) {
                                return model.one + Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.delimiter + model.two;
                              }));
                })),
          mappingDirection: Belt_Option.getWithDefault(newVehicle.mappingDirection, Flash_Edit_NonFleet_New_Vehicle_Form$DvmAdminFrontendFr.initialState.mappingDirection),
          engineTypes: Belt_Option.getWithDefault(newVehicle.engineTypes, Flash_Edit_NonFleet_New_Vehicle_Form$DvmAdminFrontendFr.initialState.engineTypes),
          versions: Belt_List.map(Belt_Option.getWithDefault(newVehicle.modelVersions, /* [] */0), (function (x) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: x
                          },
                          value: x
                        };
                })),
          brandsNewCar: Belt_Option.getWithDefault(newVehicle.brandsNewCar, Flash_Edit_NonFleet_New_Vehicle_Form$DvmAdminFrontendFr.initialState.brandsNewCar),
          vehicleType: Belt_Option.getWithDefault(flash.vehicleType, /* NOTSET */3)
        };
}

export {
  actionHasVO ,
  setForm ,
  setVOForm ,
  setFormGeneralDates ,
  setNonFleetNewVehicleForm ,
}
/* Flash_Edit_Form-DvmAdminFrontendFr Not a pure module */
