// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../../../common/App_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Accordion$DvmAdminFrontendFr from "../Flash_Edit_Accordion.bs.js";
import * as Messages_Dealer_List$DvmAdminFrontendFr from "../../../../../intl/messages/dealer/Messages_Dealer_List.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Flash_Edit_Details_NonFleet_Dealer(Props) {
  var form = Props.form;
  var setDetailREPRExpanded = Props.setDetailREPRExpanded;
  var nonFleetDealerExpanded = Props.nonFleetDealerExpanded;
  var setNonFleetDealerExpanded = Props.setNonFleetDealerExpanded;
  var step = Props.step;
  var setStep = Props.setStep;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setDrs = match$2[1];
  var drs = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setLocalNames = match$3[1];
  var localNames = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealers = match$4[1];
  var dealers = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrOptions = match$5[1];
  var drOptions = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDealerLocalNames = match$6[1];
  var dealerLocalNames = match$6[0];
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDrSelect(alert, userManager, setDrOptions, selectedRole);
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForLocalDealerNames(alert, userManager, setDealerLocalNames, selectedRole, drs);
        }), [drs]);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDealerNames(alert, userManager, setDealers, selectedRole, true, drs, localNames);
        }), [
        drs,
        localNames
      ]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(dealerLocalNames, undefined, (function (names) {
                  Curry._1(setLocalNames, (function (param) {
                          return Belt_List.keep(localNames, (function (x) {
                                        return Belt_List.has(names, x, (function (a, b) {
                                                      return a === b;
                                                    }));
                                      }));
                        }));
                }));
        }), [dealerLocalNames]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(dealers, undefined, (function (availableDealers) {
                  Curry._2(form.updateDealerIds, (function (input, dealerIds) {
                          var newrecord = Caml_obj.obj_dup(input);
                          newrecord.dealerIds = dealerIds;
                          return newrecord;
                        }), Belt_List.keep(form.input.dealerIds, (function (x) {
                              return Belt_List.has(availableDealers, x, (function (a, b) {
                                            return a.paramOne === b;
                                          }));
                            })));
                }));
        }), [dealers]);
  var tmp = {
    label: Messages_Common$DvmAdminFrontendFr.dealerID,
    value: {
      TAG: /* Multiple */1,
      _0: {
        value: form.input.dealerIds,
        onChange: Curry._1(form.updateDealerIds, (function (input, dealerIds) {
                var newrecord = Caml_obj.obj_dup(input);
                newrecord.dealerIds = dealerIds;
                return newrecord;
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(dealers, /* [] */0, (function (dealers) {
              return Belt_List.map(dealers, (function (x) {
                            return {
                                    label: {
                                      TAG: /* String */3,
                                      _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (paramTwo) {
                                              return " - " + paramTwo + "";
                                            }))
                                    },
                                    value: x.paramOne
                                  };
                          }));
            }))
    }
  };
  if (form.dealerIdsResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.dealerIdsResult);
  }
  return React.createElement(Core.Grid, {
              children: React.createElement(Flash_Edit_Accordion$DvmAdminFrontendFr.make, {
                    title: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.dealerAccordionTitle),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          spacing: 4
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(drOptions),
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                          label: Messages_Common$DvmAdminFrontendFr.territory,
                                          value: {
                                            TAG: /* Multiple */1,
                                            _0: {
                                              value: drs,
                                              onChange: (function (val) {
                                                  Curry._1(setDrs, (function (param) {
                                                          return val;
                                                        }));
                                                })
                                            }
                                          },
                                          options: {
                                            TAG: /* Unlabeled */0,
                                            _0: App_Types_Result$DvmAdminFrontendFr.getWithDefault(drOptions, /* [] */0)
                                          }
                                        })
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(dealerLocalNames),
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                          label: Messages_Dealer_List$DvmAdminFrontendFr.dealerName,
                                          value: {
                                            TAG: /* Multiple */1,
                                            _0: {
                                              value: localNames,
                                              onChange: (function (val) {
                                                  Curry._1(setLocalNames, (function (param) {
                                                          return val;
                                                        }));
                                                })
                                            }
                                          },
                                          options: {
                                            TAG: /* Unlabeled */0,
                                            _0: Belt_List.keep(App_Types_Result$DvmAdminFrontendFr.getWithDefault(dealerLocalNames, /* [] */0), (function (x) {
                                                    return x.length > 0;
                                                  }))
                                          }
                                        })
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(dealers),
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp)
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: step === 1 ? React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      onClick: (function (param) {
                                          Curry._1(setStep, (function (step) {
                                                  return step + 1 | 0;
                                                }));
                                          Curry._1(setNonFleetDealerExpanded, (function (param) {
                                                  return false;
                                                }));
                                          Curry._1(setDetailREPRExpanded, (function (param) {
                                                  return true;
                                                }));
                                        }),
                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.next),
                                      size: "medium"
                                    }) : null,
                              container: true,
                              item: true,
                              justifyContent: "center"
                            })),
                    expanded: nonFleetDealerExpanded,
                    setter: setNonFleetDealerExpanded,
                    disabled: step < 1
                  }),
              item: true,
              sm: Grid$Mui.Sm[12]
            });
}

var Form;

var FormVO;

var FormGeneralDates;

var FormNewVehicle;

var Types;

var InputStyles;

var make = Flash_Edit_Details_NonFleet_Dealer;

export {
  Form ,
  FormVO ,
  FormGeneralDates ,
  FormNewVehicle ,
  Types ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
