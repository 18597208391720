// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Edit_Fleet_Version_Page$DvmAdminFrontendFr from "./fleet/Flash_Edit_Fleet_Version_Page.bs.js";
import * as Flash_Edit_NonFleet_Version_Page$DvmAdminFrontendFr from "./nonFleet/Flash_Edit_NonFleet_Version_Page.bs.js";

function Flash_Edit_Versions_Page(Props) {
  var flashId = Props.flashId;
  var flash = Props.flash;
  var actionType = Props.actionType;
  var fetchFlashModelVersionsForCumulables = Props.fetchFlashModelVersionsForCumulables;
  var refreshPage = Props.refreshPage;
  var intl = ReactIntl.useIntl();
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Paper, {
                  children: null,
                  elevation: 0,
                  square: true,
                  style: {
                    backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
                    padding: "40px"
                  }
                }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                      color: "primary",
                      gutterBottom: true,
                      children: intl.formatMessage(typeof actionType === "number" && actionType < 3 ? Messages_Flash_Edit$DvmAdminFrontendFr.modelVersionFleetTitle : Messages_Flash_Edit$DvmAdminFrontendFr.modelVersionNonFleetTitle),
                      variant: "h3"
                    }), typeof actionType === "number" ? (
                    actionType >= 3 ? null : React.createElement(Flash_Edit_Fleet_Version_Page$DvmAdminFrontendFr.make, {
                            flashId: flashId,
                            flash: flash,
                            actionType: actionType,
                            fetchFlashModelVersionsForCumulables: fetchFlashModelVersionsForCumulables
                          })
                  ) : React.createElement(Flash_Edit_NonFleet_Version_Page$DvmAdminFrontendFr.make, {
                        flashId: flashId,
                        flash: flash,
                        refreshPage: refreshPage
                      })));
}

var make = Flash_Edit_Versions_Page;

export {
  make ,
}
/* react Not a pure module */
