// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Contr_Man_Search$DvmAdminFrontendFr from "../../../intl/messages/contractManagers/Messages_Contr_Man_Search.bs.js";
import * as Contract_Manager_Search_Api$DvmAdminFrontendFr from "./Contract_Manager_Search_Api.bs.js";
import * as Contract_Manager_Search_Form$DvmAdminFrontendFr from "./Contract_Manager_Search_Form.bs.js";

function Contract_Manager_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var setShowHistory = Props.setShowHistory;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var alert = ReactAlert.useAlert();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedManagerIds = match$3[1];
  var fetchedManagerIds = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match$4[1];
  var form = Contract_Manager_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].managersFilter, Contract_Manager_Search_Form$DvmAdminFrontendFr.initialState), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.managerID,
                              tl: {
                                hd: param.managerName,
                                tl: {
                                  hd: param.budget,
                                  tl: {
                                    hd: param.startDate,
                                    tl: {
                                      hd: param.endDate,
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          if (typeof fetchedManagerIds !== "number" && fetchedManagerIds.TAG === /* Error */1) {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: fetchedManagerIds._0
                      }
                    }));
          }
          
        }), [fetchedManagerIds]);
  var refreshManagers = function (param) {
    Contract_Manager_Search_Api$DvmAdminFrontendFr.fetchManagers(userManager, setFetchedManagerIds, form.input.showHistory, selectedRole);
  };
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          refreshManagers(undefined);
          Curry._1(form.submit, undefined);
        }), []);
  React.useEffect((function () {
          refreshManagers(undefined);
          Curry._1(setShowHistory, (function (param) {
                  return form.input.showHistory;
                }));
        }), [form.input.showHistory]);
  var tmp = {
    label: Messages_Contr_Man_Search$DvmAdminFrontendFr.managerIPN,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.managerID,
        onChange: Curry._1(form.updateManagerID, (function (input, managerID) {
                return {
                        managerID: managerID,
                        managerName: input.managerName,
                        budget: input.budget,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        showHistory: input.showHistory
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(fetchedManagerIds, /* [] */0, (function (x) {
              return Belt_List.map(x, (function (x) {
                            return x.paramOne;
                          }));
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.managerIDResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.managerIDResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateManagerName, (function (input, managerName) {
            return {
                    managerID: input.managerID,
                    managerName: managerName,
                    budget: input.budget,
                    startDate: input.startDate,
                    endDate: input.endDate,
                    showHistory: input.showHistory
                  };
          })),
    value: form.input.managerName,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
    label: Messages_Contr_Man_Search$DvmAdminFrontendFr.managerName
  };
  if (form.managerNameResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.managerNameResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.budget,
    options: Belt_List.add(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(match$4[0], /* [] */0), (function (x) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: x
                        },
                        value: x
                      };
              })), {
          label: {
            TAG: /* Message */0,
            _0: Messages_Common$DvmAdminFrontendFr.nothing
          },
          value: ""
        }),
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.budget,
        onChange: Curry._1(form.updateBudget, (function (input, budget) {
                return {
                        managerID: input.managerID,
                        managerName: input.managerName,
                        budget: budget,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        showHistory: input.showHistory
                      };
              }))
      }
    },
    id: "budget",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput
  };
  if (form.budgetResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.budgetResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.creationDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    managerID: input.managerID,
                    managerName: input.managerName,
                    budget: input.budget,
                    startDate: startDate,
                    endDate: input.endDate,
                    showHistory: input.showHistory
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.startDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$4 = {
    label: Messages_Contr_Man_Search$DvmAdminFrontendFr.endingDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    managerID: input.managerID,
                    managerName: input.managerName,
                    budget: input.budget,
                    startDate: input.startDate,
                    endDate: endDate,
                    showHistory: input.showHistory
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
  };
  if (form.endDateResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreManagersFilter */12,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                      show: App_Types_Result$DvmAdminFrontendFr.isPending(fetchedManagerIds),
                      children: React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            item: true,
                            style: {
                              width: "fit-content"
                            }
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.FormControlLabel, {
                                      classes: {
                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                      },
                                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                            checked: form.input.showHistory,
                                            onClick: (function ($$event) {
                                                Curry._2(form.updateShowHistory, (function (input, showHistory) {
                                                        return {
                                                                managerID: input.managerID,
                                                                managerName: input.managerName,
                                                                budget: input.budget,
                                                                startDate: input.startDate,
                                                                endDate: input.endDate,
                                                                showHistory: showHistory
                                                              };
                                                      }), $$event.target.checked);
                                              })
                                          }),
                                      label: intl.formatMessage(Messages_Contr_Man_Search$DvmAdminFrontendFr.showManagerHistory)
                                    }),
                                item: true,
                                style: {
                                  whiteSpace: "nowrap",
                                  width: "1px"
                                }
                              }))
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$2),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$4),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var InputStyles;

var make = Contract_Manager_Search_Filter;

export {
  Form ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
