// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../../../common/components/button/App_Clickable_Element.bs.js";
import * as Flash_Edit_NonFleet_Modal$DvmAdminFrontendFr from "./editModal/Flash_Edit_NonFleet_Modal.bs.js";
import * as Flash_Edit_NonFleet_Version_Table$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Version_Table.bs.js";
import * as Flash_Edit_NonFleet_Version_Filter$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Version_Filter.bs.js";
import * as Flash_Edit_NonFleet_Version_Table_Api$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Version_Table_Api.bs.js";
import * as Flash_Edit_NonFleet_Version_Sort_Table$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Version_Sort_Table.bs.js";

function Flash_Edit_NonFleet_Version_Page(Props) {
  var flashId = Props.flashId;
  var flash = Props.flash;
  var refreshPage = Props.refreshPage;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$3[1];
  var result = match$3[0];
  var match$4 = React.useState(function () {
        return {
                page: 0,
                headers: Flash_Edit_NonFleet_Version_Table$DvmAdminFrontendFr.headersConfiguration,
                model: undefined,
                version: undefined,
                engineType: undefined,
                modelPhase: undefined
              };
      });
  var setTableHandler = match$4[1];
  var tableHandler = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var match$6 = React.useState(function () {
        return false;
      });
  var setModal = match$6[1];
  var modal = match$6[0];
  var filterPagingAndSort = function (tableHandler) {
    Curry._1(setTablePage, (function (param) {
            return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(result, /* Pending */1, (function (list) {
                          var filteredList = Belt_List.keep(list, (function (x) {
                                  if (Belt_Option.mapWithDefault(tableHandler.model, true, (function (model) {
                                            return x.model === model;
                                          })) && Belt_Option.mapWithDefault(tableHandler.version, true, (function (version) {
                                            return x.version === version;
                                          })) && Belt_Option.mapWithDefault(tableHandler.engineType, true, (function (engine) {
                                            return Belt_Option.getWithDefault(x.engineType, "") === engine;
                                          }))) {
                                    return Belt_Option.mapWithDefault(tableHandler.modelPhase, true, (function (phase) {
                                                  return Belt_Option.getWithDefault(x.modelPhase, "") === phase;
                                                }));
                                  } else {
                                    return false;
                                  }
                                }));
                          var sortedList = Flash_Edit_NonFleet_Version_Sort_Table$DvmAdminFrontendFr.sortList(filteredList, tableHandler);
                          var paged = Belt_List.keepWithIndex(sortedList, (function (param, index) {
                                  if (index >= Math.imul(tableHandler.page, 10)) {
                                    return index < Math.imul(tableHandler.page + 1 | 0, 10);
                                  } else {
                                    return false;
                                  }
                                }));
                          return {
                                  TAG: /* Ok */0,
                                  _0: {
                                    content: paged,
                                    total: Belt_List.length(filteredList)
                                  }
                                };
                        }));
          }));
    Curry._1(setTableHandler, (function (param) {
            return tableHandler;
          }));
  };
  React.useEffect((function () {
          if (typeof result !== "number" && result.TAG === /* Ok */0) {
            filterPagingAndSort(tableHandler);
          }
          
        }), [result]);
  React.useEffect((function () {
          Flash_Edit_NonFleet_Version_Table_Api$DvmAdminFrontendFr.fetchNonFleetValidVersions(userManager, setResult, flashId, selectedRole);
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(Flash_Edit_NonFleet_Version_Filter$DvmAdminFrontendFr.make, {
                  tableHandler: tableHandler,
                  filterPagingAndSort: filterPagingAndSort,
                  withClosedModels: match$5[0],
                  setWithClosedModels: match$5[1],
                  result: result
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                        children: React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                              onClick: (function (param) {
                                  Curry._1(setModal, (function (param) {
                                          return true;
                                        }));
                                }),
                              children: React.createElement(Core.Grid, {
                                    alignItems: "center",
                                    children: null,
                                    container: true,
                                    spacing: 1
                                  }, React.createElement(Core.Grid, {
                                        children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                              size: /* Regular */2,
                                              type_: /* Edit */60
                                            }),
                                        item: true
                                      }), React.createElement(Core.Grid, {
                                        children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                              style: {
                                                fontWeight: "600"
                                              },
                                              children: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.edit).toLocaleUpperCase(),
                                              variant: "bodyBig"
                                            }),
                                        item: true
                                      }))
                            })
                      }),
                  container: true,
                  justifyContent: "flex-end"
                }), React.createElement(Flash_Edit_NonFleet_Version_Table$DvmAdminFrontendFr.make, {
                  tablePage: match$2[0],
                  tableHandler: tableHandler,
                  filterPagingAndSort: filterPagingAndSort,
                  flash: flash
                }), App_Types_Result$DvmAdminFrontendFr.mapWithDefault(result, null, (function (versions) {
                    if (modal) {
                      return React.createElement(Flash_Edit_NonFleet_Modal$DvmAdminFrontendFr.make, {
                                  onClose: (function (param) {
                                      Curry._1(setModal, (function (param) {
                                              return false;
                                            }));
                                    }),
                                  versions: versions,
                                  flash: flash,
                                  refreshPage: refreshPage
                                });
                    } else {
                      return null;
                    }
                  })));
}

var Api;

var Table;

var Filter;

var Types;

var SortTable;

var make = Flash_Edit_NonFleet_Version_Page;

export {
  Api ,
  Table ,
  Filter ,
  Types ,
  SortTable ,
  make ,
}
/* react Not a pure module */
