// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

function dateOptionToStr(date) {
  return Belt_Option.mapWithDefault(date, "", (function (x) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid(x)) {
                  return x.toISOString();
                } else {
                  return "";
                }
              }));
}

function handleDateNumber(form) {
  return Belt_List.length(Belt_List.keep({
                  hd: dateOptionToStr(form.input.registrationDateStart),
                  tl: {
                    hd: dateOptionToStr(form.input.registrationDateEnd),
                    tl: {
                      hd: dateOptionToStr(form.input.deliveryDateStart),
                      tl: {
                        hd: dateOptionToStr(form.input.deliveryDateEnd),
                        tl: {
                          hd: dateOptionToStr(form.input.entryDateParcStart),
                          tl: {
                            hd: dateOptionToStr(form.input.entryDateParcEnd),
                            tl: {
                              hd: dateOptionToStr(form.input.exitDateParcStart),
                              tl: {
                                hd: dateOptionToStr(form.input.exitDateParcEnd),
                                tl: {
                                  hd: dateOptionToStr(form.input.madaDateStart),
                                  tl: {
                                    hd: dateOptionToStr(form.input.madaDateEnd),
                                    tl: {
                                      hd: dateOptionToStr(form.input.madcDateStart),
                                      tl: {
                                        hd: dateOptionToStr(form.input.madcDateEnd),
                                        tl: {
                                          hd: form.input.detentionMinFrom,
                                          tl: {
                                            hd: form.input.detentionMinFrom,
                                            tl: {
                                              hd: form.input.detentionMaxFrom,
                                              tl: {
                                                hd: form.input.detentionMaxTo,
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleCarNumber(formAdditional) {
  return Belt_List.length(Belt_List.keep({
                  hd: formAdditional.input.brand,
                  tl: {
                    hd: Belt_List.reduce(formAdditional.input.modelID, "", (function (a, b) {
                            return a + b;
                          })),
                    tl: {
                      hd: formAdditional.input.phase,
                      tl: /* [] */0
                    }
                  }
                }, (function (result) {
                    return result.length > 0;
                  })));
}

function handleModalNumber(form, formAdditional) {
  return handleDateNumber(form) + handleCarNumber(formAdditional) | 0;
}

function dateOptionToBool(date) {
  return Belt_Option.mapWithDefault(date, false, (function (x) {
                if (x.TAG === /* Ok */0) {
                  return false;
                } else {
                  return true;
                }
              }));
}

function datesIncorrect(form) {
  if (dateOptionToBool(form.registrationDateStartResult) || dateOptionToBool(form.registrationDateEndResult) || dateOptionToBool(form.deliveryDateStartResult) || dateOptionToBool(form.deliveryDateEndResult) || dateOptionToBool(form.madaDateStartResult) || dateOptionToBool(form.madaDateEndResult) || dateOptionToBool(form.madcDateStartResult) || dateOptionToBool(form.madcDateEndResult) || dateOptionToBool(form.entryDateParcStartResult) || dateOptionToBool(form.entryDateParcEndResult) || dateOptionToBool(form.exitDateParcStartResult)) {
    return true;
  } else {
    return dateOptionToBool(form.exitDateParcEndResult);
  }
}

export {
  dateOptionToStr ,
  handleDateNumber ,
  handleCarNumber ,
  handleModalNumber ,
  dateOptionToBool ,
  datesIncorrect ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
