// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as Brands_Api$DvmAdminFrontendFr from "./Brands_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../common/App_Typography.bs.js";
import * as Messages_Brands$DvmAdminFrontendFr from "../../intl/messages/brands/Messages_Brands.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../common/components/table/App_Table_Result.bs.js";
import * as Brands_Search_Table$DvmAdminFrontendFr from "./Brands_Search_Table.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as Brands_AddEdit_Modal$DvmAdminFrontendFr from "./edit/Brands_AddEdit_Modal.bs.js";
import * as Brands_Search_Filter$DvmAdminFrontendFr from "./Brands_Search_Filter.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../common/components/table/App_Table_Create_Button.bs.js";

function Brands_Search_Page(Props) {
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match[1];
  var tablePage = match[0];
  var match$1 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Brands_Search_Table$DvmAdminFrontendFr.headersConfiguration,
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$1[1];
  var fetchRequest = match$1[0];
  var alert = ReactAlert.useAlert();
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var userManager = match$3.userManager;
  var selectedRole = match$3.selectedRole;
  var match$4 = React.useState(function () {
        return null;
      });
  var setModal = match$4[1];
  var fetchData = function (submissionCallbacks, fetchRequest) {
    Brands_Api$DvmAdminFrontendFr.fetchBrandsSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole);
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
  };
  var tmp;
  var exit = 0;
  if (typeof tablePage === "number") {
    exit = 1;
  } else if (tablePage.TAG === /* Ok */0) {
    tmp = React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
          number: tablePage._0.total,
          message: Messages_Brands$DvmAdminFrontendFr.resultInfo
        });
  } else {
    Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
              message: {
                TAG: /* RestError */2,
                _0: tablePage._0
              }
            }));
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(Messages_Brands$DvmAdminFrontendFr.title),
                  variant: "h3"
                }), React.createElement(Brands_Search_Filter$DvmAdminFrontendFr.make, {
                  fetchData: fetchData,
                  fetchRequest: fetchRequest
                }), React.createElement(Core.Box, {
                  children: null
                }, tmp, React.createElement(Brands_Search_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      createBrandsElement: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                            onClick: (function (param) {
                                Curry._1(setModal, (function (param) {
                                        return React.createElement(Brands_AddEdit_Modal$DvmAdminFrontendFr.make, {
                                                    onClose: (function (param) {
                                                        Curry._1(setModal, (function (param) {
                                                                return null;
                                                              }));
                                                      }),
                                                    operation: /* Create */1,
                                                    fetchRequest: fetchRequest,
                                                    fetchData: fetchData
                                                  });
                                      }));
                              }),
                            message: Messages_Brands$DvmAdminFrontendFr.createBrandCode
                          })
                    })), match$4[0]);
}

var Api;

var Table;

var Filter;

var make = Brands_Search_Page;

export {
  Api ,
  Table ,
  Filter ,
  make ,
}
/* react Not a pure module */
