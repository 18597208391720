// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "./Flash_Search_Types.bs.js";

function makeDateOverlap(start, end, vehicleFilter, fieldsStart, fieldsEnd, language, alwaysShow) {
  if (!alwaysShow && Belt_Option.isNone(start) && Belt_Option.isNone(end)) {
    return "";
  }
  var startStr = encodeURIComponent(Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(start), "1900-01-01"));
  var endStr = encodeURIComponent(Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(end), "3000-12-31"));
  var endDateEn = fieldsEnd[0];
  var startDateEn = fieldsStart[0];
  var match = vehicleFilter || language !== "fr" ? [
      startDateEn,
      endDateEn
    ] : [
      fieldsStart[1],
      fieldsEnd[1]
    ];
  var endDateString = match[1];
  var startDateString = match[0];
  return Belt_Array.joinWith([
              encodeURIComponent("" + startDateString + "=[\\," + startStr + "]&&" + endDateString + "=[" + startStr + "\\,]"),
              encodeURIComponent("" + startDateString + "=[\\," + endStr + "]&&" + endDateString + "=[" + endStr + "\\,]"),
              encodeURIComponent("" + startDateString + "=[" + startStr + "\\,]&&" + endDateString + "=[\\," + endStr + "]"),
              encodeURIComponent("" + startDateString + "=[\\," + startStr + "]&&" + endDateString + "=[" + endStr + "\\,]")
            ], encodeURIComponent("||"), (function (x) {
                return x;
              }));
}

function getQueryParams(form, formModal, formAdditional, tableType, language) {
  var match = Belt_Option.mapWithDefault(form, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ], (function (x) {
          return [
                  x.actionID,
                  x.actionLabel,
                  x.actionType,
                  x.budgetType,
                  x.startDate,
                  x.endDate,
                  x.orderType
                ];
        }));
  var match$1 = Belt_Option.mapWithDefault(formModal, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ], (function (x) {
          return [
                  x.registrationDateStart,
                  x.registrationDateEnd,
                  x.deliveryDateStart,
                  x.deliveryDateEnd,
                  x.entryDateParcStart,
                  x.entryDateParcEnd,
                  x.exitDateParcStart,
                  x.exitDateParcEnd,
                  x.madaDateStart,
                  x.madaDateEnd,
                  x.madcDateStart,
                  x.madcDateEnd,
                  x.detentionMinFrom,
                  x.detentionMaxFrom
                ];
        }));
  var match$2 = Belt_Option.mapWithDefault(formAdditional, [
        undefined,
        undefined,
        undefined
      ], (function (x) {
          return [
                  x.brand,
                  x.modelID,
                  x.phase
                ];
        }));
  var phase = match$2[2];
  var modelID = match$2[1];
  var brand = match$2[0];
  var vehicleFilter = Belt_Option.isSome(brand) || Belt_Option.isSome(modelID) || Belt_Option.isSome(phase);
  var firstStr = Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(Belt_List.map({
                    hd: match[0],
                    tl: {
                      hd: match[1],
                      tl: {
                        hd: match[2],
                        tl: {
                          hd: match[3],
                          tl: {
                            hd: tableType === /* NonFleet */1 ? match[6] : undefined,
                            tl: {
                              hd: match$1[12],
                              tl: {
                                hd: match$1[13],
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }, (function (x) {
                      return Belt_Option.flatMap(x, (function (x) {
                                    return vehicleFilter || language !== "fr" ? x.en : x.fr;
                                  }));
                    })), (function (queryParam) {
                  return queryParam;
                }))), encodeURIComponent("&&"), (function (x) {
          return x;
        }));
  var str = firstStr.length > 0 ? firstStr + encodeURIComponent(";") : "";
  return str + Belt_Array.joinWith(Belt_Array.keep([
                  makeDateOverlap(match[4], match[5], vehicleFilter, Flash_Search_Types$DvmAdminFrontendFr.Fields.startDate, Flash_Search_Types$DvmAdminFrontendFr.Fields.endDate, language, true),
                  makeDateOverlap(match$1[0], match$1[1], vehicleFilter, Flash_Search_Types$DvmAdminFrontendFr.Fields.registrationDateStart, Flash_Search_Types$DvmAdminFrontendFr.Fields.registrationDateEnd, language, false),
                  makeDateOverlap(match$1[2], match$1[3], vehicleFilter, Flash_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateStart, Flash_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateEnd, language, false),
                  makeDateOverlap(match$1[4], match$1[5], vehicleFilter, Flash_Search_Types$DvmAdminFrontendFr.Fields.entryDateParcStart, Flash_Search_Types$DvmAdminFrontendFr.Fields.entryDateParcEnd, language, false),
                  makeDateOverlap(match$1[6], match$1[7], vehicleFilter, Flash_Search_Types$DvmAdminFrontendFr.Fields.exitDateParcStart, Flash_Search_Types$DvmAdminFrontendFr.Fields.exitDateParcEnd, language, false),
                  makeDateOverlap(match$1[8], match$1[9], vehicleFilter, Flash_Search_Types$DvmAdminFrontendFr.Fields.madaDateStart, Flash_Search_Types$DvmAdminFrontendFr.Fields.madaDateEnd, language, false),
                  makeDateOverlap(match$1[10], match$1[11], vehicleFilter, Flash_Search_Types$DvmAdminFrontendFr.Fields.madcDateStart, Flash_Search_Types$DvmAdminFrontendFr.Fields.madcDateEnd, language, false)
                ], (function (x) {
                    return x.length > 0;
                  })), encodeURIComponent(";"), (function (x) {
                return x;
              })) + Belt_Option.mapWithDefault(brand, "", (function (x) {
                return "&brandsFilter=value=" + x + "";
              })) + (
          modelID !== undefined ? (
              phase !== undefined ? "&modelPhaseFilter=" + modelID + ";" + phase + "" : "&modelPhaseFilter=" + modelID + ""
            ) : (
              phase !== undefined ? "&modelPhaseFilter=" + phase + "" : ""
            )
        );
}

export {
  makeDateOverlap ,
  getQueryParams ,
}
/* Utils_Date-DvmAdminFrontendFr Not a pure module */
