// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as Flash_Detail_Api$DvmAdminFrontendFr from "../../Flash_Detail_Api.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as Flash_Detail_Models_Form_Fleet$DvmAdminFrontendFr from "./Flash_Detail_Models_Form_Fleet.bs.js";

function Flash_Detail_Models_Filter_Fleet(Props) {
  var flash = Props.flash;
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var withClosedModels = Props.withClosedModels;
  var setWithClosedModels = Props.setWithClosedModels;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setPerfVersions = match$3[1];
  var perfVersions = match$3[0];
  var form = Flash_Detail_Models_Form_Fleet$DvmAdminFrontendFr.TableFilterForm.useForm(Flash_Detail_Models_Form_Fleet$DvmAdminFrontendFr.initialState, (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.model,
                              tl: {
                                hd: param.version,
                                tl: /* [] */0
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Curry._1(form.submit, undefined);
        }), [
        form.input.model,
        form.input.version,
        withClosedModels
      ]);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            var versions = result._0;
            Curry._1(setPerfVersions, (function (param) {
                    return versions.content;
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  React.useEffect((function () {
          if (flash.actionType === "PERF") {
            Flash_Detail_Api$DvmAdminFrontendFr.fetchModelsTablePerfAll(userManager, setResult, flash.actionId, selectedRole, withClosedModels);
          }
          
        }), [withClosedModels]);
  var perfVersionsMapped = Belt_List.map(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(perfVersions, (function (x) {
                          return x.model;
                        }))))), (function (x) {
          return {
                  parent: x,
                  child: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.keep(perfVersions, (function (y) {
                                          return y.model === x;
                                        })), (function (x) {
                                      return x.version;
                                    })))))
                };
        }));
  return React.createElement("form", undefined, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        item: true,
                        style: {
                          width: "fit-content"
                        }
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                  labelParent: Messages_Common$DvmAdminFrontendFr.model,
                                  labelChild: Messages_Common$DvmAdminFrontendFr.version,
                                  valueParent: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.model,
                                      onChange: Curry._1(form.updateModel, (function (param, model) {
                                              return {
                                                      model: model,
                                                      version: ""
                                                    };
                                            }))
                                    }
                                  },
                                  valueChild: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.version,
                                      onChange: Curry._1(form.updateVersion, (function (input, version) {
                                              return {
                                                      model: input.model,
                                                      version: version
                                                    };
                                            }))
                                    }
                                  },
                                  options: flash.actionType === "PERF" ? perfVersionsMapped : flash.modelVersions,
                                  classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.shortInput,
                                  classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(Core.FormControlLabel, {
                                  classes: {
                                    label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                  },
                                  control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                        checked: withClosedModels,
                                        onClick: (function ($$event) {
                                            Curry._1(setWithClosedModels, (function (param) {
                                                    return $$event.target.checked;
                                                  }));
                                          })
                                      }),
                                  label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.includeClosedCars)
                                }),
                            item: true,
                            style: {
                              whiteSpace: "nowrap",
                              width: "1px"
                            }
                          })),
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }));
}

var InputStyles;

var Form;

var Api;

var make = Flash_Detail_Models_Filter_Fleet;

export {
  InputStyles ,
  Form ,
  Api ,
  make ,
}
/* react Not a pure module */
