// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Order_Detail_Styles$DvmAdminFrontendFr from "../Order_Detail_Styles.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Dealer_Edits_Api$DvmAdminFrontendFr from "./Order_Detail_Dealer_Edits_Api.bs.js";

function Order_Detail_Dealer_Edits_ParcExit_Modal(Props) {
  var onClose = Props.onClose;
  var orderId = Props.orderId;
  var onOk = Props.onOk;
  var order = Props.order;
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match[1];
  var saveResult = match[0];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  var intl = ReactIntl.useIntl();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return false;
      });
  var setConfirmModal = match$3[1];
  var postChange = function (param) {
    Order_Detail_Dealer_Edits_Api$DvmAdminFrontendFr.postParcEndDateCorrection(userManager, setSaveResult, orderId, selectedRole);
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                  open: true,
                  onClose: onClose,
                  title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.dateOutputParc),
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                        children: React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              direction: "column",
                              spacing: 4
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  item: true
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDateV2,
                                            children: Belt_Option.mapWithDefault(order.detail.registrationDate, "-", (function (param) {
                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                  }))
                                          }),
                                      className: Order_Detail_Styles$DvmAdminFrontendFr.detailItem,
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Common$DvmAdminFrontendFr.vin,
                                            children: Belt_Option.getWithDefault(order.detail.vin, "")
                                          }),
                                      className: Order_Detail_Styles$DvmAdminFrontendFr.detailItem,
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                            label: Messages_Orders_Detail$DvmAdminFrontendFr.dateInputParc,
                                            children: Belt_Option.mapWithDefault(order.detail.parcStartDate, "-", (function (param) {
                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                  }))
                                          }),
                                      className: Order_Detail_Styles$DvmAdminFrontendFr.detailItem,
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  alignItems: "flex-end",
                                  children: null,
                                  container: true,
                                  item: true,
                                  justifyContent: "center",
                                  spacing: 1
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            style: {
                                              color: Theme_Colors$DvmAdminFrontendFr.grayDark2,
                                              fontWeight: "600"
                                            },
                                            children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.dateOutputParc),
                                            variant: "subtitle"
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            style: {
                                              textDecoration: "underline"
                                            },
                                            children: Intl_Utils$DvmAdminFrontendFr.formatDate(intl, new Date()),
                                            variant: "h5"
                                          }),
                                      item: true
                                    })), React.createElement(Core.Grid, {
                                  children: React.createElement(Core.Grid, {
                                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                              onClick: (function (param) {
                                                  Curry._1(setConfirmModal, (function (param) {
                                                          return true;
                                                        }));
                                                }),
                                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                              color: "primary",
                                              size: "medium",
                                              style: {
                                                width: "100%"
                                              }
                                            }),
                                        item: true
                                      }),
                                  container: true,
                                  direction: "column",
                                  item: true
                                }))
                      })
                }), match$3[0] ? React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                    open: true,
                    onClose: (function (param) {
                        Curry._1(setConfirmModal, (function (param) {
                                return false;
                              }));
                      }),
                    title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.confirmEditExitParc),
                    minWidth: "600px",
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          justifyContent: "space-between"
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(setConfirmModal, (function (param) {
                                                return false;
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                    color: "primaryBorder",
                                    size: "medium"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        postChange(undefined);
                                        Curry._1(setConfirmModal, (function (param) {
                                                return false;
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                    size: "medium"
                                  }),
                              item: true
                            }))
                  }) : null);
}

var Api;

var make = Order_Detail_Dealer_Edits_ParcExit_Modal;

export {
  Api ,
  make ,
}
/* react Not a pure module */
