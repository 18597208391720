// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../../../common/components/App_Checkbox.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Flash_Edit_Api$DvmAdminFrontendFr from "../../Flash_Edit_Api.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as Flash_Search_Api$DvmAdminFrontendFr from "../../../search/Flash_Search_Api.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Bonuses_List$DvmAdminFrontendFr from "../../../../../intl/messages/bonuses/Messages_Bonuses_List.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as Flash_Edit_NonFleet_Api$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";

function getLastFourYearsToFilter(param) {
  var thisYear = ReDate.getYear(new Date()) | 0;
  return Belt_Array.joinWith(Belt_List.toArray({
                  hd: thisYear,
                  tl: {
                    hd: thisYear - 1 | 0,
                    tl: {
                      hd: thisYear - 2 | 0,
                      tl: {
                        hd: thisYear - 3 | 0,
                        tl: /* [] */0
                      }
                    }
                  }
                }), "%5C,", (function (x) {
                return String(x);
              }));
}

function Flash_Edit_NonFleet_New_Vehicle_Duplication(Props) {
  var formNewVehicle = Props.formNewVehicle;
  var flashId = Props.flashId;
  var setDuplicationFetchPending = Props.setDuplicationFetchPending;
  var setDuplicateActionNewVehicle = Props.setDuplicateActionNewVehicle;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var intl = ReactIntl.useIntl();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionsResult = match$2[1];
  var actionsResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setFetchedDuplicateFlash = match$3[1];
  var fetchedDuplicateFlash = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setNewVehicleDuplicateResult = match$4[1];
  var newVehicleDuplicateResult = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setIncludeOldActions = match$5[1];
  var includeOldActions = match$5[0];
  var match$6 = React.useState(function () {
        return "";
      });
  var setSelectedAction = match$6[1];
  var selectedAction = match$6[0];
  var fetchFlashForDuplicate = function (duplicateFlashId) {
    Flash_Edit_Api$DvmAdminFrontendFr.fetchFlash(userManager, setFetchedDuplicateFlash, selectedRole, /* NonFleet */1, duplicateFlashId);
    Flash_Edit_NonFleet_Api$DvmAdminFrontendFr.fetchNewVehicleInfo(userManager, setNewVehicleDuplicateResult, duplicateFlashId, selectedRole);
  };
  var fetchActionSelection = function (param) {
    var filter = includeOldActions ? "" : "year=" + getLastFourYearsToFilter(undefined);
    Flash_Search_Api$DvmAdminFrontendFr.fetchFlashSearch(undefined, undefined, undefined, userManager, setActionsResult, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
              page: 0,
              rowsPerPage: 5000,
              headers: /* [] */0,
              filterQueryParams: filter,
              additionalQueryParams: /* [] */0
            }), selectedRole, /* NonFleet */1);
  };
  React.useEffect((function () {
          fetchActionSelection(undefined);
        }), [includeOldActions]);
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isPending(fetchedDuplicateFlash) || App_Types_Result$DvmAdminFrontendFr.isPending(newVehicleDuplicateResult)) {
            Curry._1(setDuplicationFetchPending, (function (param) {
                    return true;
                  }));
          } else {
            Curry._1(setDuplicationFetchPending, (function (param) {
                    return false;
                  }));
          }
          if (typeof fetchedDuplicateFlash !== "number" && fetchedDuplicateFlash.TAG === /* Ok */0) {
            var flashDuplicate = fetchedDuplicateFlash._0;
            if (typeof newVehicleDuplicateResult !== "number" && newVehicleDuplicateResult.TAG === /* Ok */0) {
              var newVehicleDuplicate = newVehicleDuplicateResult._0;
              Curry._1(setDuplicateActionNewVehicle, (function (param) {
                      return [
                              newVehicleDuplicate,
                              flashDuplicate
                            ];
                    }));
              Curry._1(formNewVehicle.reset, undefined);
            }
            
          }
          
        }), [
        fetchedDuplicateFlash,
        newVehicleDuplicateResult
      ]);
  React.useEffect((function () {
          if (selectedAction.length > 0) {
            fetchFlashForDuplicate(selectedAction);
          }
          
        }), [selectedAction]);
  return React.createElement(Core.Box, {
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(actionsResult),
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          direction: "column",
                          item: true
                        }, React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              spacing: 1
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                        size: /* Large */3,
                                        type_: /* Export */62
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                        style: {
                                          fontWeight: "600"
                                        },
                                        children: intl.formatMessage(Messages_Flash_Edit$DvmAdminFrontendFr.duplicateVersions),
                                        variant: "bodyBig"
                                      }),
                                  item: true
                                })), React.createElement(Core.Grid, {
                              children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                    label: Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID,
                                    value: {
                                      TAG: /* Single */0,
                                      _0: {
                                        value: selectedAction,
                                        onChange: (function (val) {
                                            Curry._1(setSelectedAction, (function (param) {
                                                    return val;
                                                  }));
                                          })
                                      }
                                    },
                                    options: {
                                      TAG: /* Labeled */1,
                                      _0: Belt_List.reverse(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(actionsResult, /* [] */0, (function (actions) {
                                                  return Belt_List.keepMap(actions.content, (function (action) {
                                                                if (action.actionID === Belt_Option.getWithDefault(flashId, "")) {
                                                                  return ;
                                                                } else {
                                                                  return {
                                                                          label: {
                                                                            TAG: /* String */3,
                                                                            _0: "" + action.actionID + " (" + action.actionLabel + ")"
                                                                          },
                                                                          value: action.actionID
                                                                        };
                                                                }
                                                              }));
                                                })))
                                    },
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(Core.FormControlLabel, {
                                    classes: {
                                      label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                    },
                                    control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                          checked: includeOldActions,
                                          onClick: (function ($$event) {
                                              Curry._1(setIncludeOldActions, (function (param) {
                                                      return $$event.target.checked;
                                                    }));
                                            })
                                        }),
                                    label: intl.formatMessage(Messages_Bonuses_List$DvmAdminFrontendFr.showBonusesPlusHistory)
                                  }),
                              item: true
                            }))
                  }),
              borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
              borderLeft: 2,
              paddingLeft: 1
            });
}

var Api;

var InputStyles;

var Form;

var FormNewVehicle;

var make = Flash_Edit_NonFleet_New_Vehicle_Duplication;

export {
  Api ,
  InputStyles ,
  Form ,
  FormNewVehicle ,
  getLastFourYearsToFilter ,
  make ,
}
/* react Not a pure module */
