// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../api/RestApi.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Input.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "../../Flash_Edit_Types.bs.js";
import * as Flash_Edit_NonFleet_Types$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Types.bs.js";

function getEngines(userManager, selectedRole, setResult, models) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.parentChildList_decode, undefined, userManager, selectedRole, "/dropdown/engines?models=" + Belt_Array.joinWith(Belt_List.toArray(models), ",", (function (x) {
                return x;
              })) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var engines = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: engines
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function getPhases(userManager, selectedRole, setResult, models) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.parentChildList_decode, undefined, userManager, selectedRole, "/modelversion/model/phases?models=" + Belt_Array.joinWith(Belt_List.toArray(models), ",", (function (x) {
                return x;
              })) + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var phases = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: phases
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchModelsByBrand(setResult, userManager, selectedRole, brands, vehicleType) {
  var joinedBrands = Belt_List.length(brands) > 0 ? "brandDescription=" + Belt_Array.joinWith(Belt_List.toArray(brands), "%5C%2C", (function (x) {
            return x;
          })) : "";
  var vehicleTypeString = "ModelVehicleType=" + (
    vehicleType !== 1 ? (
        vehicleType !== 0 ? "VP%5C%2CVU" : "VP"
      ) : "VU"
  );
  var filters = "?filter=" + vehicleTypeString + ";" + joinedBrands + "";
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", App_Types_Input$DvmAdminFrontendFr.stringList_decode, undefined, userManager, selectedRole, "/modelversion/models/by/brands" + filters + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var modelVersions = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: modelVersions
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchNewVehicleInfo(userManager, setResult, flashId, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.NewVehicles.t_decode, undefined, userManager, selectedRole, "/flash/map/modular/" + flashId + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var searchTable = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: searchTable
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function fetchAllVersions(userManager, setResult, formNewVehicle, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  var models = Belt_Array.joinWith(Belt_List.toArray(formNewVehicle.input.models), "%5C%2C", (function (x) {
          return x;
        }));
  var joined = Belt_Array.joinWith(Belt_List.toArray(formNewVehicle.input.engineTypes), "%5C%2C", (function (x) {
          return encodeURIComponent(x);
        }));
  var engineType = joined.length > 0 ? ";IdentificationEngineType=" + joined + "" : "";
  var match = formNewVehicle.input.vehicleType;
  var vehicleType = match >= 2 ? "" : ";versionVehicleType=" + Flash_Edit_Types$DvmAdminFrontendFr.VehicleType.toString(formNewVehicle.input.vehicleType) + "";
  var phases = Belt_List.keepMap(formNewVehicle.input.modelPhases, (function (x) {
          var splitArray = x.split(Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.delimiter);
          var paramOne = Belt_Array.get(splitArray, 0);
          var paramTwo = Belt_Array.get(splitArray, 1);
          if (paramOne !== undefined && paramTwo !== undefined) {
            return [
                    paramOne,
                    paramTwo
                  ];
          }
          
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_NonFleet_Types$DvmAdminFrontendFr.versionTypeList_decode, undefined, userManager, selectedRole, "/flash/map/modular/modelversions/filtered?filter=model=" + models + "" + engineType + "" + vehicleType + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var versionsList = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_List.keep(versionsList, (function (x) {
                                        return Belt_Option.isSome(Belt_List.getBy(phases, (function (param) {
                                                          if (param[0] === x.model) {
                                                            return param[1] === x.modelPhase;
                                                          } else {
                                                            return false;
                                                          }
                                                        })));
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var Types;

var NonFleetTypes;

var VersionsTypes;

export {
  Types ,
  NonFleetTypes ,
  VersionsTypes ,
  getEngines ,
  getPhases ,
  fetchModelsByBrand ,
  fetchNewVehicleInfo ,
  fetchAllVersions ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
