// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as App_Table$DvmAdminFrontendFr from "../../../../../../common/components/table/App_Table.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../../common/components/input/App_TextFieldFormInput.bs.js";

var standardInputRow = Css.css(Object.assign({}, {
          width: "120px"
        }, {
          "& > div *": {
            paddingTop: "0px"
          }
        }));

function isAmountCustomLabel(isCustomFEValue, intl) {
  var message = isCustomFEValue ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no;
  return intl.formatMessage(message);
}

function isAmountCustom(originalBonus, customBonus) {
  return originalBonus !== Belt_Option.getWithDefault(Belt_Float.fromString(customBonus), 0.0);
}

function makeRow(form, version, intl) {
  var tmp = {
    onChange: (function (val) {
        Curry._3(form.updateVersionCustomBonus, version.index, (function (param, customBonus) {
                return {
                        versions: Belt_Array.mapWithIndex(param.versions, (function (eachindex, arrayversion) {
                                if (eachindex === version.index) {
                                  return {
                                          index: arrayversion.index,
                                          model: arrayversion.model,
                                          version: arrayversion.version,
                                          versionLabel: arrayversion.versionLabel,
                                          customBonus: customBonus,
                                          originalBonus: arrayversion.originalBonus,
                                          actionBonus: arrayversion.actionBonus,
                                          isCustomFEValue: arrayversion.isCustomFEValue,
                                          engineType: arrayversion.engineType,
                                          modelPhase: arrayversion.modelPhase
                                        };
                                } else {
                                  return arrayversion;
                                }
                              }))
                      };
              }), val);
        Curry._3(form.updateVersionIsCustomFEValue, version.index, (function (param, isCustomFEValue) {
                return {
                        versions: Belt_Array.mapWithIndex(param.versions, (function (eachindex, arrayversion) {
                                if (eachindex === version.index) {
                                  return {
                                          index: arrayversion.index,
                                          model: arrayversion.model,
                                          version: arrayversion.version,
                                          versionLabel: arrayversion.versionLabel,
                                          customBonus: arrayversion.customBonus,
                                          originalBonus: arrayversion.originalBonus,
                                          actionBonus: arrayversion.actionBonus,
                                          isCustomFEValue: isCustomFEValue,
                                          engineType: arrayversion.engineType,
                                          modelPhase: arrayversion.modelPhase
                                        };
                                } else {
                                  return arrayversion;
                                }
                              }))
                      };
              }), isAmountCustom(version.actionBonus, val));
      }),
    value: version.customBonus,
    className: standardInputRow,
    inputType: "number"
  };
  var tmp$1 = Curry._1(form.versionCustomBonusResult, version.index);
  if (tmp$1 !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(tmp$1);
  }
  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, false, Belt_List.mapWithIndex({
                  hd: version.model,
                  tl: {
                    hd: version.version,
                    tl: {
                      hd: version.versionLabel,
                      tl: {
                        hd: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                        tl: {
                          hd: isAmountCustomLabel(version.isCustomFEValue, intl),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }, (function (param, content) {
                    return App_Table$DvmAdminFrontendFr.makeCell(undefined, undefined, undefined, content);
                  })));
}

var Form;

export {
  Form ,
  standardInputRow ,
  isAmountCustomLabel ,
  isAmountCustom ,
  makeRow ,
}
/* standardInputRow Not a pure module */
