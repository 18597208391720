// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";

function quotaPARC_encode(v) {
  return Js_dict.fromArray([
              [
                "id",
                Decco.optionToJson(Decco.stringToJson, v.id)
              ],
              [
                "orderType",
                Decco.optionToJson(Decco.stringToJson, v.orderType)
              ],
              [
                "dealerId",
                Decco.optionToJson(Decco.stringToJson, v.dealerId)
              ],
              [
                "modelId",
                Decco.optionToJson(Decco.stringToJson, v.modelId)
              ],
              [
                "phase",
                Decco.optionToJson(Decco.stringToJson, v.phase)
              ],
              [
                "engineType",
                Decco.optionToJson(Decco.stringToJson, v.engineType)
              ],
              [
                "volumeInitial",
                Decco.optionToJson(Decco.intToJson, v.volumeInitial)
              ],
              [
                "volume",
                Decco.optionToJson(Decco.intToJson, v.volume)
              ],
              [
                "startDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.startDate)
              ],
              [
                "endDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.endDate)
              ]
            ]);
}

function quotaPARC_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var id = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "id"), null));
  if (id.TAG === /* Ok */0) {
    var orderType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
    if (orderType.TAG === /* Ok */0) {
      var dealerId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerId"), null));
      if (dealerId.TAG === /* Ok */0) {
        var modelId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelId"), null));
        if (modelId.TAG === /* Ok */0) {
          var phase = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "phase"), null));
          if (phase.TAG === /* Ok */0) {
            var engineType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "engineType"), null));
            if (engineType.TAG === /* Ok */0) {
              var volumeInitial = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "volumeInitial"), null));
              if (volumeInitial.TAG === /* Ok */0) {
                var volume = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "volume"), null));
                if (volume.TAG === /* Ok */0) {
                  var startDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "startDate"), null));
                  if (startDate.TAG === /* Ok */0) {
                    var endDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endDate"), null));
                    if (endDate.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                id: id._0,
                                orderType: orderType._0,
                                dealerId: dealerId._0,
                                modelId: modelId._0,
                                phase: phase._0,
                                engineType: engineType._0,
                                volumeInitial: volumeInitial._0,
                                volume: volume._0,
                                startDate: startDate._0,
                                endDate: endDate._0
                              }
                            };
                    }
                    var e = endDate._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".endDate" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = startDate._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".startDate" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = volume._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".volume" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = volumeInitial._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".volumeInitial" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = engineType._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".engineType" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = phase._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".phase" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = modelId._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".modelId" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = dealerId._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".dealerId" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = orderType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".orderType" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = id._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".id" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

export {
  quotaPARC_encode ,
  quotaPARC_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
