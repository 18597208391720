// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as File_Upload_Api$DvmAdminFrontendFr from "../File_Upload_Api.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../components/input/App_RadioFormInput.bs.js";
import * as App_UserTypeCheckBox$DvmAdminFrontendFr from "../../components/input/App_UserTypeCheckBox.bs.js";
import * as Messages_File_Upload$DvmAdminFrontendFr from "../../../intl/messages/Messages_File_Upload.bs.js";

function File_Upload_Import_Mode(Props) {
  var mapped = Props.mapped;
  var excelInfo = Props.excelInfo;
  var validateResult = Props.validateResult;
  var selectedTable = Props.selectedTable;
  var setCheckAttachmentErrors = Props.setCheckAttachmentErrors;
  var onClose = Props.onClose;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return "update";
      });
  var setMode = match[1];
  var mode = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setPrimaryKeys = match$1[1];
  var primaryKeys = match$1[0];
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCheckResult = match$3[1];
  var checkResult = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAttachmentsCheckResult = match$4[1];
  var attachmentsCheckResult = match$4[0];
  var match$5 = React.useState(function () {
        return /* Nothing */0;
      });
  var setUploadStage = match$5[1];
  var uploadStage = match$5[0];
  var alert = ReactAlert.useAlert();
  var match$6 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$7 = match$6[0];
  var userManager = match$7.userManager;
  var selectedRole = match$7.selectedRole;
  var finalUpload = function (param) {
    Curry._1(setUploadStage, (function (param) {
            return /* Upload */1;
          }));
    File_Upload_Api$DvmAdminFrontendFr.excelConfirm(userManager, setResult, {
          mapping: Belt_List.keepMap(Belt_MapString.toList(mapped), (function (item) {
                  var table = item[1];
                  var excel = item[0];
                  if (selectedTable === "SP_PARC_QUOTAS") {
                    if (table.length > 0) {
                      if (table === "CreationDate") {
                        return {
                                tableHeader: table,
                                excelHeader: ""
                              };
                      } else {
                        return {
                                tableHeader: table,
                                excelHeader: excel
                              };
                      }
                    } else {
                      return ;
                    }
                  } else if (table.length > 0) {
                    return {
                            tableHeader: table,
                            excelHeader: excel
                          };
                  } else {
                    return ;
                  }
                })),
          primaryKeys: Belt_List.keepMap(Belt_List.map(Belt_SetString.toList(primaryKeys), (function (key) {
                      return Belt_List.getBy(Belt_Option.getWithDefault(validateResult.primaryKeys, /* [] */0), (function (x) {
                                    return x.primaryKeyName === key;
                                  }));
                    })), (function (x) {
                  return x;
                }))
        }, selectedTable, Belt_Option.getWithDefault(validateResult.expirationToken, ""), Belt_Option.getWithDefault(validateResult.cellRange, ""), mode === "delete", excelInfo.sheet, selectedRole);
  };
  React.useEffect((function () {
          if (selectedTable === "SP_CONTRACT_ATTACHMENTS" || selectedTable === "SP_PARC_QUOTAS") {
            finalUpload(undefined);
          }
          
        }), []);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            if (selectedTable === "SP_PARC_QUOTAS") {
              Curry._1(setUploadStage, (function (param) {
                      return /* Nothing */0;
                    }));
              Curry._1(onClose, undefined);
              Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            } else {
              File_Upload_Api$DvmAdminFrontendFr.checkImportStatus(userManager, setCheckResult, selectedRole, String(result._0));
            }
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  React.useEffect((function () {
          if (typeof checkResult === "number") {
            checkResult === /* NotStarted */0;
          } else if (checkResult.TAG === /* Ok */0) {
            var response = checkResult._0;
            var match = response.status;
            if (match === "Running") {
              Belt_Option.mapWithDefault(response.progressId, undefined, (function (x) {
                      Belt_Option.mapWithDefault(Belt_Int.fromString(x), undefined, (function (progressId) {
                              setTimeout((function (param) {
                                      File_Upload_Api$DvmAdminFrontendFr.checkImportStatus(userManager, setCheckResult, selectedRole, String(progressId));
                                    }), 1000);
                            }));
                    }));
            } else if (selectedTable === "SP_CONTRACT_ATTACHMENTS") {
              if (uploadStage === /* Checking */2) {
                Curry._1(setUploadStage, (function (param) {
                        return /* Nothing */0;
                      }));
                Curry._1(setCheckAttachmentErrors, (function (param) {
                        return true;
                      }));
              } else if (selectedTable === "SP_CONTRACT_ATTACHMENTS") {
                File_Upload_Api$DvmAdminFrontendFr.runContractAttachmentCalculations(userManager, setAttachmentsCheckResult, selectedRole);
                Curry._1(setUploadStage, (function (param) {
                        return /* Checking */2;
                      }));
              }
              
            } else {
              Curry._1(setUploadStage, (function (param) {
                      return /* Nothing */0;
                    }));
              Curry._1(onClose, undefined);
            }
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: checkResult._0
                      }
                    }));
          }
        }), [checkResult]);
  React.useEffect((function () {
          if (typeof attachmentsCheckResult === "number") {
            attachmentsCheckResult === /* NotStarted */0;
          } else if (attachmentsCheckResult.TAG === /* Ok */0) {
            File_Upload_Api$DvmAdminFrontendFr.checkImportStatus(userManager, setCheckResult, selectedRole, String(attachmentsCheckResult._0));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: attachmentsCheckResult._0
                      }
                    }));
          }
        }), [attachmentsCheckResult]);
  var tmp;
  switch (uploadStage) {
    case /* Nothing */0 :
        tmp = selectedTable === "SP_CONTRACT_ATTACHMENTS" || selectedTable === "SP_PARC_QUOTAS" ? null : React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    direction: "column",
                    justifyContent: "space-between",
                    style: {
                      height: "100%"
                    }
                  }, React.createElement(Core.Grid, {
                        children: null,
                        item: true
                      }, React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_File_Upload$DvmAdminFrontendFr.importMode,
                            value: mode === "update" ? "update" : "delete",
                            options: Belt_List.map(selectedTable === "SP_CONTRACT_ATTACHMENTS" ? ({
                                      hd: [
                                        "update",
                                        intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.insertWithUpdate)
                                      ],
                                      tl: /* [] */0
                                    }) : ({
                                      hd: [
                                        "update",
                                        intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.insertWithUpdate)
                                      ],
                                      tl: {
                                        hd: [
                                          "delete",
                                          intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.insertWithDeletion)
                                        ],
                                        tl: /* [] */0
                                      }
                                    }), (function (param) {
                                    return {
                                            label: {
                                              TAG: /* String */3,
                                              _0: param[1]
                                            },
                                            value: param[0]
                                          };
                                  })),
                            onChange: (function (val) {
                                Curry._1(setMode, (function (param) {
                                        return val;
                                      }));
                              })
                          }), mode === "delete" ? React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  children: React.createElement(Core.Grid, {
                                        children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.rowRestrictionToDelete),
                                        item: true
                                      }),
                                  variant: "bodyBig"
                                }), React.createElement(Core.Grid, {
                                  children: Belt_List.toArray(Belt_List.mapWithIndex(Belt_Option.getWithDefault(validateResult.primaryKeys, /* [] */0), (function (rowIndex, row) {
                                              return React.createElement(Core.Grid, {
                                                          children: null,
                                                          container: true,
                                                          direction: "column",
                                                          item: true,
                                                          style: {
                                                            width: "fit-content"
                                                          },
                                                          key: "row" + String(rowIndex)
                                                        }, React.createElement(Core.Grid, {
                                                              children: React.createElement(App_UserTypeCheckBox$DvmAdminFrontendFr.make, {
                                                                    kind: /* Button */0,
                                                                    label: row.primaryKeyName,
                                                                    checked: Belt_SetString.has(primaryKeys, row.primaryKeyName),
                                                                    onClick: (function ($$event) {
                                                                        if ($$event.target.checked) {
                                                                          return Curry._1(setPrimaryKeys, (function (primaryKeys) {
                                                                                        return Belt_SetString.add(primaryKeys, row.primaryKeyName);
                                                                                      }));
                                                                        } else {
                                                                          return Curry._1(setPrimaryKeys, (function (primaryKeys) {
                                                                                        return Belt_SetString.remove(primaryKeys, row.primaryKeyName);
                                                                                      }));
                                                                        }
                                                                      }),
                                                                    controlStyle: {
                                                                      width: "100%"
                                                                    }
                                                                  }),
                                                              item: true
                                                            }), React.createElement(Core.Grid, {
                                                              children: Belt_List.toArray(Belt_List.mapWithIndex(row.values, (function (param, cell) {
                                                                          return React.createElement(Core.Grid, {
                                                                                      children: cell,
                                                                                      item: true
                                                                                    });
                                                                        }))),
                                                              container: true,
                                                              direction: "column",
                                                              item: true,
                                                              wrap: "nowrap",
                                                              style: {
                                                                height: "150px",
                                                                overflowX: "hidden",
                                                                overflowY: "auto",
                                                                padding: "0px 10px"
                                                              }
                                                            }));
                                            }))),
                                  container: true,
                                  direction: "row",
                                  justifyContent: "flex-start",
                                  wrap: "nowrap"
                                })) : React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              color: "error",
                              children: React.createElement(Core.Grid, {
                                    children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.addNewRowsAndUpdateExisting),
                                    item: true
                                  }),
                              variant: "bodyBig"
                            })), React.createElement(Core.Grid, {
                        children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                              onClick: finalUpload,
                              label: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.fileUploadTitle),
                              color: "primary",
                              size: "medium"
                            }),
                        container: true,
                        item: true,
                        justifyContent: "center"
                      })));
        break;
    case /* Upload */1 :
        tmp = React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              direction: "column"
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        color: "primary",
                        children: intl.formatMessage(Messages_File_Upload$DvmAdminFrontendFr.importExcelToVaricent),
                        variant: "bodyBig"
                      }),
                  container: true,
                  item: true,
                  justifyContent: "center",
                  style: {
                    marginBottom: "20px"
                  }
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.CircularProgress, {
                        size: 100
                      }),
                  item: true
                }));
        break;
    case /* Checking */2 :
        var tmp$1;
        switch (selectedTable) {
          case "SP_CONTRACT_ATTACHMENTS" :
              tmp$1 = Messages_File_Upload$DvmAdminFrontendFr.checkForOverlaps;
              break;
          case "SP_PARC_QUOTAS" :
              tmp$1 = Messages_File_Upload$DvmAdminFrontendFr.recalculating;
              break;
          default:
            tmp$1 = Messages_File_Upload$DvmAdminFrontendFr.checkForOverlaps;
        }
        tmp = React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              direction: "column"
            }, React.createElement(Core.Grid, {
                  children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        color: "primary",
                        children: intl.formatMessage(tmp$1),
                        variant: "bodyBig"
                      }),
                  container: true,
                  item: true,
                  justifyContent: "center",
                  style: {
                    marginBottom: "20px"
                  }
                }), React.createElement(Core.Grid, {
                  children: React.createElement(Core.CircularProgress, {
                        size: 100
                      }),
                  item: true
                }));
        break;
    
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var Api;

var Types;

var make = File_Upload_Import_Mode;

export {
  Api ,
  Types ,
  make ,
}
/* react Not a pure module */
