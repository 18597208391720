// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Api.bs.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Date.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Form.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "../../Flash_Search_Types.bs.js";

var todayStr = Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(Caml_option.some(new Date())), "");

function boolFieldToEmpty(fieldId) {
  return {
          en: fieldId[0] + "=",
          fr: fieldId[1] + "="
        };
}

function datePairToQuery(from, to, fieldId) {
  return Utils_Api$DvmAdminFrontendFr.datePairToQueryParamBilingual(Caml_option.some(from), Caml_option.some(to), fieldId, "normal");
}

var validators_detentionMaxTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    })
};

var validators_detentionMaxFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      return {
              TAG: /* Ok */0,
              _0: orderType === "VE" || orderType === "RR" || orderType === "RC" || orderType === "" ? Utils_Api$DvmAdminFrontendFr.stringPairToQueryParamBilingual(param.detentionMaxFrom, param.detentionMaxTo, Flash_Search_Types$DvmAdminFrontendFr.Fields.detentionMax) : undefined
            };
    })
};

var validators_detentionMinTo = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    })
};

var validators_detentionMinFrom = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      return {
              TAG: /* Ok */0,
              _0: orderType === "VE" || orderType === "RR" || orderType === "RC" || orderType === "" ? Utils_Api$DvmAdminFrontendFr.stringPairToQueryParamBilingual(param.detentionMinFrom, param.detentionMinTo, Flash_Search_Types$DvmAdminFrontendFr.Fields.detentionMin) : undefined
            };
    })
};

var validators_exitDateParcEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      if (orderType === "VE" || orderType === "RR" || orderType === "RC" || orderType === "") {
        return Utils_Form$DvmAdminFrontendFr.validateDate(param.exitDateParcEnd, undefined);
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_exitDateParcStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      if (orderType === "VE" || orderType === "RR" || orderType === "RC" || orderType === "") {
        return Utils_Form$DvmAdminFrontendFr.validateDate(param.exitDateParcStart, undefined);
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_entryDateParcEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      if (orderType === "VE" || orderType === "RR" || orderType === "RC" || orderType === "") {
        return Utils_Form$DvmAdminFrontendFr.validateDate(param.entryDateParcEnd, undefined);
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_entryDateParcStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderType = param$1.orderType;
      if (orderType === "VE" || orderType === "RR" || orderType === "RC" || orderType === "") {
        return Utils_Form$DvmAdminFrontendFr.validateDate(param.entryDateParcStart, undefined);
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_madcDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.madcDateEnd, undefined);
    })
};

var validators_madcDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.madcDateStart, undefined);
    })
};

var validators_madaDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.madaDateEnd, undefined);
    })
};

var validators_madaDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.madaDateStart, undefined);
    })
};

var validators_deliveryDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.deliveryDateEnd, undefined);
    })
};

var validators_deliveryDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.deliveryDateStart, undefined);
    })
};

var validators_registrationDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationDateEnd, undefined);
    })
};

var validators_registrationDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationDateStart, undefined);
    })
};

var validators = {
  detentionMaxTo: validators_detentionMaxTo,
  detentionMaxFrom: validators_detentionMaxFrom,
  detentionMinTo: validators_detentionMinTo,
  detentionMinFrom: validators_detentionMinFrom,
  exitDateParcEnd: validators_exitDateParcEnd,
  exitDateParcStart: validators_exitDateParcStart,
  entryDateParcEnd: validators_entryDateParcEnd,
  entryDateParcStart: validators_entryDateParcStart,
  madcDateEnd: validators_madcDateEnd,
  madcDateStart: validators_madcDateStart,
  madaDateEnd: validators_madaDateEnd,
  madaDateStart: validators_madaDateStart,
  deliveryDateEnd: validators_deliveryDateEnd,
  deliveryDateStart: validators_deliveryDateStart,
  registrationDateEnd: validators_registrationDateEnd,
  registrationDateStart: validators_registrationDateStart
};

function initialFieldsStatuses(_input) {
  return {
          detentionMaxTo: /* Pristine */0,
          detentionMaxFrom: /* Pristine */0,
          detentionMinTo: /* Pristine */0,
          detentionMinFrom: /* Pristine */0,
          exitDateParcEnd: /* Pristine */0,
          exitDateParcStart: /* Pristine */0,
          entryDateParcEnd: /* Pristine */0,
          entryDateParcStart: /* Pristine */0,
          madcDateEnd: /* Pristine */0,
          madcDateStart: /* Pristine */0,
          madaDateEnd: /* Pristine */0,
          madaDateStart: /* Pristine */0,
          deliveryDateEnd: /* Pristine */0,
          deliveryDateStart: /* Pristine */0,
          registrationDateEnd: /* Pristine */0,
          registrationDateStart: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.detentionMaxTo;
  var match_0 = match ? match._0 : Curry._2(validators.detentionMaxTo.validate, input, metadata);
  var match$1 = fieldsStatuses.detentionMaxFrom;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.detentionMaxFrom.validate, input, metadata);
  var match$2 = fieldsStatuses.detentionMinTo;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.detentionMinTo.validate, input, metadata);
  var match$3 = fieldsStatuses.detentionMinFrom;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.detentionMinFrom.validate, input, metadata);
  var match$4 = fieldsStatuses.exitDateParcEnd;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.exitDateParcEnd.validate, input, metadata);
  var match$5 = fieldsStatuses.exitDateParcStart;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.exitDateParcStart.validate, input, metadata);
  var match$6 = fieldsStatuses.entryDateParcEnd;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.entryDateParcEnd.validate, input, metadata);
  var match$7 = fieldsStatuses.entryDateParcStart;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.entryDateParcStart.validate, input, metadata);
  var match$8 = fieldsStatuses.madcDateEnd;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.madcDateEnd.validate, input, metadata);
  var match$9 = fieldsStatuses.madcDateStart;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.madcDateStart.validate, input, metadata);
  var match$10 = fieldsStatuses.madaDateEnd;
  var match_0$10 = match$10 ? match$10._0 : Curry._2(validators.madaDateEnd.validate, input, metadata);
  var match$11 = fieldsStatuses.madaDateStart;
  var match_0$11 = match$11 ? match$11._0 : Curry._2(validators.madaDateStart.validate, input, metadata);
  var match$12 = fieldsStatuses.deliveryDateEnd;
  var match_0$12 = match$12 ? match$12._0 : Curry._2(validators.deliveryDateEnd.validate, input, metadata);
  var match$13 = fieldsStatuses.deliveryDateStart;
  var match_0$13 = match$13 ? match$13._0 : Curry._2(validators.deliveryDateStart.validate, input, metadata);
  var match$14 = fieldsStatuses.registrationDateEnd;
  var match_0$14 = match$14 ? match$14._0 : Curry._2(validators.registrationDateEnd.validate, input, metadata);
  var match$15 = fieldsStatuses.registrationDateStart;
  var match_0$15 = match$15 ? match$15._0 : Curry._2(validators.registrationDateStart.validate, input, metadata);
  var detentionMaxToResult = match_0;
  var detentionMaxToResult$1;
  if (detentionMaxToResult.TAG === /* Ok */0) {
    var detentionMaxFromResult = match_0$1;
    if (detentionMaxFromResult.TAG === /* Ok */0) {
      var detentionMinToResult = match_0$2;
      if (detentionMinToResult.TAG === /* Ok */0) {
        var detentionMinFromResult = match_0$3;
        if (detentionMinFromResult.TAG === /* Ok */0) {
          var exitDateParcEndResult = match_0$4;
          if (exitDateParcEndResult.TAG === /* Ok */0) {
            var exitDateParcStartResult = match_0$5;
            if (exitDateParcStartResult.TAG === /* Ok */0) {
              var entryDateParcEndResult = match_0$6;
              if (entryDateParcEndResult.TAG === /* Ok */0) {
                var entryDateParcStartResult = match_0$7;
                if (entryDateParcStartResult.TAG === /* Ok */0) {
                  var madcDateEndResult = match_0$8;
                  if (madcDateEndResult.TAG === /* Ok */0) {
                    var madcDateStartResult = match_0$9;
                    if (madcDateStartResult.TAG === /* Ok */0) {
                      var madaDateEndResult = match_0$10;
                      if (madaDateEndResult.TAG === /* Ok */0) {
                        var madaDateStartResult = match_0$11;
                        if (madaDateStartResult.TAG === /* Ok */0) {
                          var deliveryDateEndResult = match_0$12;
                          if (deliveryDateEndResult.TAG === /* Ok */0) {
                            var deliveryDateStartResult = match_0$13;
                            if (deliveryDateStartResult.TAG === /* Ok */0) {
                              var registrationDateEndResult = match_0$14;
                              if (registrationDateEndResult.TAG === /* Ok */0) {
                                var registrationDateStartResult = match_0$15;
                                if (registrationDateStartResult.TAG === /* Ok */0) {
                                  return {
                                          TAG: /* Valid */0,
                                          output: {
                                            registrationDateStart: registrationDateStartResult._0,
                                            registrationDateEnd: registrationDateEndResult._0,
                                            deliveryDateStart: deliveryDateStartResult._0,
                                            deliveryDateEnd: deliveryDateEndResult._0,
                                            entryDateParcStart: entryDateParcStartResult._0,
                                            entryDateParcEnd: entryDateParcEndResult._0,
                                            madaDateStart: madaDateStartResult._0,
                                            madaDateEnd: madaDateEndResult._0,
                                            madcDateStart: madcDateStartResult._0,
                                            madcDateEnd: madcDateEndResult._0,
                                            exitDateParcStart: exitDateParcStartResult._0,
                                            exitDateParcEnd: exitDateParcEndResult._0,
                                            detentionMinFrom: detentionMinFromResult._0,
                                            detentionMinTo: detentionMinToResult._0,
                                            detentionMaxFrom: detentionMaxFromResult._0,
                                            detentionMaxTo: detentionMaxToResult._0
                                          },
                                          fieldsStatuses: {
                                            detentionMaxTo: /* Dirty */{
                                              _0: detentionMaxToResult,
                                              _1: /* Shown */0
                                            },
                                            detentionMaxFrom: /* Dirty */{
                                              _0: detentionMaxFromResult,
                                              _1: /* Shown */0
                                            },
                                            detentionMinTo: /* Dirty */{
                                              _0: detentionMinToResult,
                                              _1: /* Shown */0
                                            },
                                            detentionMinFrom: /* Dirty */{
                                              _0: detentionMinFromResult,
                                              _1: /* Shown */0
                                            },
                                            exitDateParcEnd: /* Dirty */{
                                              _0: exitDateParcEndResult,
                                              _1: /* Shown */0
                                            },
                                            exitDateParcStart: /* Dirty */{
                                              _0: exitDateParcStartResult,
                                              _1: /* Shown */0
                                            },
                                            entryDateParcEnd: /* Dirty */{
                                              _0: entryDateParcEndResult,
                                              _1: /* Shown */0
                                            },
                                            entryDateParcStart: /* Dirty */{
                                              _0: entryDateParcStartResult,
                                              _1: /* Shown */0
                                            },
                                            madcDateEnd: /* Dirty */{
                                              _0: madcDateEndResult,
                                              _1: /* Shown */0
                                            },
                                            madcDateStart: /* Dirty */{
                                              _0: madcDateStartResult,
                                              _1: /* Shown */0
                                            },
                                            madaDateEnd: /* Dirty */{
                                              _0: madaDateEndResult,
                                              _1: /* Shown */0
                                            },
                                            madaDateStart: /* Dirty */{
                                              _0: madaDateStartResult,
                                              _1: /* Shown */0
                                            },
                                            deliveryDateEnd: /* Dirty */{
                                              _0: deliveryDateEndResult,
                                              _1: /* Shown */0
                                            },
                                            deliveryDateStart: /* Dirty */{
                                              _0: deliveryDateStartResult,
                                              _1: /* Shown */0
                                            },
                                            registrationDateEnd: /* Dirty */{
                                              _0: registrationDateEndResult,
                                              _1: /* Shown */0
                                            },
                                            registrationDateStart: /* Dirty */{
                                              _0: registrationDateStartResult,
                                              _1: /* Shown */0
                                            }
                                          },
                                          collectionsStatuses: undefined
                                        };
                                }
                                detentionMaxToResult$1 = detentionMaxToResult;
                              } else {
                                detentionMaxToResult$1 = detentionMaxToResult;
                              }
                            } else {
                              detentionMaxToResult$1 = detentionMaxToResult;
                            }
                          } else {
                            detentionMaxToResult$1 = detentionMaxToResult;
                          }
                        } else {
                          detentionMaxToResult$1 = detentionMaxToResult;
                        }
                      } else {
                        detentionMaxToResult$1 = detentionMaxToResult;
                      }
                    } else {
                      detentionMaxToResult$1 = detentionMaxToResult;
                    }
                  } else {
                    detentionMaxToResult$1 = detentionMaxToResult;
                  }
                } else {
                  detentionMaxToResult$1 = detentionMaxToResult;
                }
              } else {
                detentionMaxToResult$1 = detentionMaxToResult;
              }
            } else {
              detentionMaxToResult$1 = detentionMaxToResult;
            }
          } else {
            detentionMaxToResult$1 = detentionMaxToResult;
          }
        } else {
          detentionMaxToResult$1 = detentionMaxToResult;
        }
      } else {
        detentionMaxToResult$1 = detentionMaxToResult;
      }
    } else {
      detentionMaxToResult$1 = detentionMaxToResult;
    }
  } else {
    detentionMaxToResult$1 = detentionMaxToResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            detentionMaxTo: /* Dirty */{
              _0: detentionMaxToResult$1,
              _1: /* Shown */0
            },
            detentionMaxFrom: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            detentionMinTo: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            detentionMinFrom: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            exitDateParcEnd: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            exitDateParcStart: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            entryDateParcEnd: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            entryDateParcStart: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            madcDateEnd: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            madcDateStart: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            madaDateEnd: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            madaDateStart: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            deliveryDateEnd: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            deliveryDateStart: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            registrationDateEnd: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            registrationDateStart: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurDetentionMaxToField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMaxTo, validators_detentionMaxTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: status,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDetentionMaxFromField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMaxFrom, validators_detentionMaxFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: status,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDetentionMinToField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMinTo, validators_detentionMinTo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: status,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDetentionMinFromField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMinFrom, validators_detentionMinFrom, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: status,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitDateParcEndField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitDateParcEnd, validators_exitDateParcEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: status,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitDateParcStartField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitDateParcStart, validators_exitDateParcStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: status,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryDateParcEndField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryDateParcEnd, validators_entryDateParcEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: status,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryDateParcStartField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryDateParcStart, validators_entryDateParcStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: status,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadcDateEndField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madcDateEnd, validators_madcDateEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: status,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadcDateStartField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madcDateStart, validators_madcDateStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: status,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadaDateEndField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madaDateEnd, validators_madaDateEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: status,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMadaDateStartField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.madaDateStart, validators_madaDateStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: status,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryDateEndField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryDateEnd, validators_deliveryDateEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: status,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryDateStartField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryDateStart, validators_deliveryDateStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: status,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateEndField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationDateEnd, validators_registrationDateEnd, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: status,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateStartField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationDateStart, validators_registrationDateStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: status
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */16 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */16,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */17,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */19);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */18);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */17 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */18 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */19 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateDetentionMaxToField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$16 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput, metadata, nextFieldsStatuses.contents.detentionMaxFrom, validators_detentionMaxFrom, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: status,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$16 !== undefined) {
                    nextFieldsStatuses.contents = result$16;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, nextFieldsStatuses.contents.detentionMaxTo, state.submissionStatus, validators_detentionMaxTo, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            detentionMaxTo: status,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDetentionMaxFromField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$17 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.detentionMaxTo, validators_detentionMaxTo, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  detentionMaxTo: status,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$17 !== undefined) {
                    nextFieldsStatuses$1.contents = result$17;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.detentionMaxFrom, state.submissionStatus, validators_detentionMaxFrom, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: status,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDetentionMinToField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$18 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.detentionMinFrom, validators_detentionMinFrom, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: init.detentionMinTo,
                                  detentionMinFrom: status,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$18 !== undefined) {
                    nextFieldsStatuses$2.contents = result$18;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.detentionMinTo, state.submissionStatus, validators_detentionMinTo, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: status,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDetentionMinFromField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$19 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.detentionMinTo, validators_detentionMinTo, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  detentionMaxTo: init.detentionMaxTo,
                                  detentionMaxFrom: init.detentionMaxFrom,
                                  detentionMinTo: status,
                                  detentionMinFrom: init.detentionMinFrom,
                                  exitDateParcEnd: init.exitDateParcEnd,
                                  exitDateParcStart: init.exitDateParcStart,
                                  entryDateParcEnd: init.entryDateParcEnd,
                                  entryDateParcStart: init.entryDateParcStart,
                                  madcDateEnd: init.madcDateEnd,
                                  madcDateStart: init.madcDateStart,
                                  madaDateEnd: init.madaDateEnd,
                                  madaDateStart: init.madaDateStart,
                                  deliveryDateEnd: init.deliveryDateEnd,
                                  deliveryDateStart: init.deliveryDateStart,
                                  registrationDateEnd: init.registrationDateEnd,
                                  registrationDateStart: init.registrationDateStart
                                };
                        }));
                  if (result$19 !== undefined) {
                    nextFieldsStatuses$3.contents = result$19;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.detentionMinFrom, state.submissionStatus, validators_detentionMinFrom, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: status,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitDateParcEndField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.exitDateParcEnd, state.submissionStatus, validators_exitDateParcEnd, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: status,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitDateParcStartField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, state.fieldsStatuses.exitDateParcStart, state.submissionStatus, validators_exitDateParcStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: status,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryDateParcEndField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.entryDateParcEnd, state.submissionStatus, validators_entryDateParcEnd, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: status,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryDateParcStartField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.entryDateParcStart, state.submissionStatus, validators_entryDateParcStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: status,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadcDateEndField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.madcDateEnd, state.submissionStatus, validators_madcDateEnd, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: status,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadcDateStartField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, state.fieldsStatuses.madcDateStart, state.submissionStatus, validators_madcDateStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: status,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadaDateEndField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, state.fieldsStatuses.madaDateEnd, state.submissionStatus, validators_madaDateEnd, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: status,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMadaDateStartField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, state.fieldsStatuses.madaDateStart, state.submissionStatus, validators_madaDateStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: status,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryDateEndField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, state.fieldsStatuses.deliveryDateEnd, state.submissionStatus, validators_deliveryDateEnd, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: status,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryDateStartField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$13, metadata, state.fieldsStatuses.deliveryDateStart, state.submissionStatus, validators_deliveryDateStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: status,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateEndField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, state.fieldsStatuses.registrationDateEnd, state.submissionStatus, validators_registrationDateEnd, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: status,
                                            registrationDateStart: init.registrationDateStart
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateStartField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$15, metadata, state.fieldsStatuses.registrationDateStart, state.submissionStatus, validators_registrationDateStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            detentionMaxTo: init.detentionMaxTo,
                                            detentionMaxFrom: init.detentionMaxFrom,
                                            detentionMinTo: init.detentionMinTo,
                                            detentionMinFrom: init.detentionMinFrom,
                                            exitDateParcEnd: init.exitDateParcEnd,
                                            exitDateParcStart: init.exitDateParcStart,
                                            entryDateParcEnd: init.entryDateParcEnd,
                                            entryDateParcStart: init.entryDateParcStart,
                                            madcDateEnd: init.madcDateEnd,
                                            madcDateStart: init.madcDateStart,
                                            madaDateEnd: init.madaDateEnd,
                                            madaDateStart: init.madaDateStart,
                                            deliveryDateEnd: init.deliveryDateEnd,
                                            deliveryDateStart: init.deliveryDateStart,
                                            registrationDateEnd: init.registrationDateEnd,
                                            registrationDateStart: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */16 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */17 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */18 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateDetentionMaxTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMaxToField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDetentionMaxFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMaxFromField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDetentionMinTo: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMinToField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDetentionMinFrom: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMinFromField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitDateParcEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitDateParcEndField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitDateParcStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitDateParcStartField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryDateParcEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryDateParcEndField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryDateParcStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryDateParcStartField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadcDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadcDateEndField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadcDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadcDateStartField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadaDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadaDateEndField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMadaDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMadaDateStartField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryDateEndField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryDateStartField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateEndField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateStartField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurDetentionMaxTo: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMaxToField */0);
            }),
          blurDetentionMaxFrom: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMaxFromField */1);
            }),
          blurDetentionMinTo: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMinToField */2);
            }),
          blurDetentionMinFrom: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMinFromField */3);
            }),
          blurExitDateParcEnd: (function (param) {
              Curry._1(dispatch, /* BlurExitDateParcEndField */4);
            }),
          blurExitDateParcStart: (function (param) {
              Curry._1(dispatch, /* BlurExitDateParcStartField */5);
            }),
          blurEntryDateParcEnd: (function (param) {
              Curry._1(dispatch, /* BlurEntryDateParcEndField */6);
            }),
          blurEntryDateParcStart: (function (param) {
              Curry._1(dispatch, /* BlurEntryDateParcStartField */7);
            }),
          blurMadcDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurMadcDateEndField */8);
            }),
          blurMadcDateStart: (function (param) {
              Curry._1(dispatch, /* BlurMadcDateStartField */9);
            }),
          blurMadaDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurMadaDateEndField */10);
            }),
          blurMadaDateStart: (function (param) {
              Curry._1(dispatch, /* BlurMadaDateStartField */11);
            }),
          blurDeliveryDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryDateEndField */12);
            }),
          blurDeliveryDateStart: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryDateStartField */13);
            }),
          blurRegistrationDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateEndField */14);
            }),
          blurRegistrationDateStart: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateStartField */15);
            }),
          detentionMaxToResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMaxTo),
          detentionMaxFromResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMaxFrom),
          detentionMinToResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMinTo),
          detentionMinFromResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMinFrom),
          exitDateParcEndResult: Formality.exposeFieldResult(state.fieldsStatuses.exitDateParcEnd),
          exitDateParcStartResult: Formality.exposeFieldResult(state.fieldsStatuses.exitDateParcStart),
          entryDateParcEndResult: Formality.exposeFieldResult(state.fieldsStatuses.entryDateParcEnd),
          entryDateParcStartResult: Formality.exposeFieldResult(state.fieldsStatuses.entryDateParcStart),
          madcDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.madcDateEnd),
          madcDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.madcDateStart),
          madaDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.madaDateEnd),
          madaDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.madaDateStart),
          deliveryDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryDateEnd),
          deliveryDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryDateStart),
          registrationDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDateEnd),
          registrationDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDateStart),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.detentionMaxTo || match.detentionMaxFrom || match.detentionMinTo || match.detentionMinFrom || match.exitDateParcEnd || match.exitDateParcStart || match.entryDateParcEnd || match.entryDateParcStart || match.madcDateEnd || match.madcDateStart || match.madaDateEnd || match.madaDateStart || match.deliveryDateEnd || match.deliveryDateStart || match.registrationDateEnd) {
                exit = 1;
              } else {
                if (!match.registrationDateStart) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        return true;
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */16);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */17);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */18);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */18,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */19);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  registrationDateStart: undefined,
  registrationDateEnd: undefined,
  deliveryDateStart: undefined,
  deliveryDateEnd: undefined,
  madaDateStart: undefined,
  madaDateEnd: undefined,
  madcDateStart: undefined,
  madcDateEnd: undefined,
  entryDateParcStart: undefined,
  entryDateParcEnd: undefined,
  exitDateParcStart: undefined,
  exitDateParcEnd: undefined,
  detentionMinFrom: "",
  detentionMinTo: "",
  detentionMaxFrom: "",
  detentionMaxTo: ""
};

export {
  todayStr ,
  boolFieldToEmpty ,
  datePairToQuery ,
  TableFilterForm ,
  initialState$1 as initialState,
}
/* todayStr Not a pure module */
