// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as Flash_Detail_Styles$DvmAdminFrontendFr from "./Flash_Detail_Styles.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Flash_Detail_Info_Row$DvmAdminFrontendFr from "./Flash_Detail_Info_Row.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";

function Flash_Detail_Main_Info(Props) {
  var flash = Props.flash;
  var intl = ReactIntl.useIntl();
  var tmp;
  if (Belt_Option.mapWithDefault(flash.orderType, false, (function (x) {
            return Belt_Array.some(x, (function (x) {
                          return x === "VE";
                        }));
          }))) {
    tmp = Belt_Option.mapWithDefault(flash.isParcEntry, null, (function (parcEntry) {
            if (parcEntry) {
              return React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
                          label: Messages_Flash_Edit$DvmAdminFrontendFr.parcEntryPeriod,
                          values: [
                            {
                              label: Messages_Common$DvmAdminFrontendFr.startDate,
                              value: Belt_Option.mapWithDefault(flash.entryParcDatePeriodStart, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            },
                            {
                              label: Messages_Common$DvmAdminFrontendFr.endDate,
                              value: Belt_Option.mapWithDefault(flash.entryParcDatePeriodEnd, "-", (function (param) {
                                      return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                    }))
                            }
                          ]
                        });
            } else {
              return React.createElement(React.Fragment, undefined, React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Edit$DvmAdminFrontendFr.parcExitPeriod,
                              values: [
                                {
                                  label: Messages_Common$DvmAdminFrontendFr.startDate,
                                  value: Belt_Option.mapWithDefault(flash.exitParcDatePeriodStart, "-", (function (param) {
                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                        }))
                                },
                                {
                                  label: Messages_Common$DvmAdminFrontendFr.endDate,
                                  value: Belt_Option.mapWithDefault(flash.exitParcDatePeriodEnd, "-", (function (param) {
                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                        }))
                                }
                              ]
                            }), React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Edit$DvmAdminFrontendFr.detentionDuration,
                              values: [
                                {
                                  label: Messages_Flash_Edit$DvmAdminFrontendFr.dateMin,
                                  value: Belt_Option.mapWithDefault(flash.detentionMin, "-", (function (prim) {
                                          return String(prim);
                                        }))
                                },
                                {
                                  label: Messages_Flash_Edit$DvmAdminFrontendFr.dateMax,
                                  value: Belt_Option.mapWithDefault(flash.detentionMax, "-", (function (prim) {
                                          return String(prim);
                                        }))
                                }
                              ]
                            }));
            }
          }));
  } else {
    var match = flash.actionType;
    var tmp$1;
    var exit = 0;
    switch (match) {
      case " NOTSET" :
      case "PERF" :
      case "REPR" :
          tmp$1 = null;
          break;
      case "STOC" :
          exit = 1;
          break;
      default:
        exit = 1;
    }
    if (exit === 1) {
      var mada = flash.madaPeriod;
      var madc = flash.madcPeriod;
      tmp$1 = React.createElement(React.Fragment, undefined, mada !== undefined ? React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
                  label: Messages_Flash_Detail$DvmAdminFrontendFr.madaPeriod,
                  values: [
                    {
                      label: Messages_Common$DvmAdminFrontendFr.startDate,
                      value: Belt_Option.mapWithDefault(mada.startDate, "-", (function (param) {
                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                            }))
                    },
                    {
                      label: Messages_Common$DvmAdminFrontendFr.endDate,
                      value: Belt_Option.mapWithDefault(mada.endDate, "-", (function (param) {
                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                            }))
                    }
                  ]
                }) : null, madc !== undefined ? React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
                  label: Messages_Flash_Detail$DvmAdminFrontendFr.madcPeriod,
                  values: [
                    {
                      label: Messages_Common$DvmAdminFrontendFr.startDate,
                      value: Belt_Option.mapWithDefault(madc.startDate, "-", (function (param) {
                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                            }))
                    },
                    {
                      label: Messages_Common$DvmAdminFrontendFr.endDate,
                      value: Belt_Option.mapWithDefault(madc.endDate, "-", (function (param) {
                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                            }))
                    }
                  ]
                }) : null);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
              label: Messages_Flash_Detail$DvmAdminFrontendFr.orderPeriod,
              values: [
                {
                  label: Messages_Common$DvmAdminFrontendFr.startDate,
                  value: Belt_Option.mapWithDefault(flash.orderPeriod, "-", (function (x) {
                          return Belt_Option.mapWithDefault(x.startDate, "-", (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      }));
                        }))
                },
                {
                  label: Messages_Common$DvmAdminFrontendFr.endDate,
                  value: Belt_Option.mapWithDefault(flash.orderPeriod, "-", (function (x) {
                          return Belt_Option.mapWithDefault(x.endDate, "-", (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      }));
                        }))
                }
              ]
            }), React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
              label: Messages_Flash_Detail$DvmAdminFrontendFr.deliveryPeriod,
              values: [
                {
                  label: Messages_Common$DvmAdminFrontendFr.startDate,
                  value: Belt_Option.mapWithDefault(flash.deliveryPeriod, "-", (function (x) {
                          return Belt_Option.mapWithDefault(x.startDate, "-", (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      }));
                        }))
                },
                {
                  label: Messages_Common$DvmAdminFrontendFr.endDate,
                  value: Belt_Option.mapWithDefault(flash.deliveryPeriod, "-", (function (x) {
                          return Belt_Option.mapWithDefault(x.endDate, "-", (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      }));
                        }))
                }
              ]
            }), React.createElement(Flash_Detail_Info_Row$DvmAdminFrontendFr.make, {
              label: Messages_Flash_Detail$DvmAdminFrontendFr.registrationPeriod,
              values: [
                {
                  label: Messages_Common$DvmAdminFrontendFr.startDate,
                  value: Belt_Option.mapWithDefault(flash.registrationPeriod, "-", (function (x) {
                          return Belt_Option.mapWithDefault(x.startDate, "-", (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      }));
                        }))
                },
                {
                  label: Messages_Common$DvmAdminFrontendFr.endDate,
                  value: Belt_Option.mapWithDefault(flash.registrationPeriod, "-", (function (x) {
                          return Belt_Option.mapWithDefault(x.endDate, "-", (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      }));
                        }))
                }
              ]
            }), tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  color: "primary",
                  children: "" + intl.formatMessage(Messages_Flash_Detail$DvmAdminFrontendFr.title) + " " + flash.actionId + "",
                  variant: "h3"
                }), React.createElement(Core.Grid, {
                  children: tmp,
                  className: Flash_Detail_Styles$DvmAdminFrontendFr.detailBox,
                  container: true,
                  direction: "column"
                }));
}

var make = Flash_Detail_Main_Info;

export {
  make ,
}
/* react Not a pure module */
