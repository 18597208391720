// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../../../intl/messages/topMenu/Messages_TopMenu.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Flash_Edit_Router$DvmAdminFrontendFr from "../edit/Flash_Edit_Router.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "./Flash_Search_Types.bs.js";
import * as Flash_Search_Utils$DvmAdminFrontendFr from "./Flash_Search_Utils.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Table_Export_Menu$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Export_Menu.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../intl/messages/flash/Messages_Flash_Detail.bs.js";
import * as Messages_Flash_Search$DvmAdminFrontendFr from "../../../intl/messages/flash/Messages_Flash_Search.bs.js";
import * as App_Table_Create_Button$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Create_Button.bs.js";
import * as Flash_Search_Delete_Modal$DvmAdminFrontendFr from "./Flash_Search_Delete_Modal.bs.js";

function getEnglish(param) {
  return param[0];
}

function headersConfiguration(tableType, operation, selectedRole) {
  return Belt_List.concat(Belt_List.keepMap({
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.actionType), undefined, undefined, undefined, undefined, undefined, Messages_Flash_Detail$DvmAdminFrontendFr.flashType),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.actionID), undefined, undefined, undefined, undefined, undefined, Messages_Flash_Search$DvmAdminFrontendFr.flashMarketingActionID),
                    tl: {
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.actionLabel), undefined, undefined, undefined, undefined, undefined, Messages_Flash_Search$DvmAdminFrontendFr.bonusLabel),
                      tl: {
                        hd: tableType ? undefined : App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.budgetType), undefined, false, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget),
                        tl: {
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.bonusAmountEuro), undefined, false, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.amountHTPercentage),
                          tl: {
                            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.bonusAmountPercent), undefined, false, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.amountPercent),
                            tl: {
                              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.startDate), undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
                              tl: {
                                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.endDate), undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, (function (x) {
                    return x;
                  })), operation === /* Manage */1 && User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? ({
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.creationDate), undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.creationDate),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Flash_Search_Types$DvmAdminFrontendFr.Fields.modificationDate), undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.lastModificationDate),
                    tl: /* [] */0
                  }
                }) : /* [] */0);
}

function Flash_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var tableType = Props.tableType;
  var operation = Props.operation;
  var formList = Props.formList;
  var formModalList = Props.formModalList;
  var formModalAdditionalList = Props.formModalAdditionalList;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var match$1 = React.useState(function () {
        
      });
  var setModal = match$1[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(Belt_Option.mapWithDefault(row.actionType, undefined, (function (actionType) {
                                                    if (actionType === "DERO") {
                                                      return ;
                                                    } else {
                                                      return (function (param) {
                                                                RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rFlash + "/" + (
                                                                      tableType ? "nonfleet/" : "fleet/"
                                                                    ) + row.actionID);
                                                              });
                                                    }
                                                  })), Caml_option.some(operation === /* Manage */1 && Belt_Option.getWithDefault(row.actionType, "") !== "DERO" && (User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) || selectedRole === /* Advisor */0 || selectedRole === /* CompanyManager */2) ? React.createElement(Core.Grid, {
                                                        children: null,
                                                        container: true,
                                                        item: true,
                                                        wrap: "nowrap"
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Button, {
                                                                  onClick: (function (param) {
                                                                      Flash_Edit_Router$DvmAdminFrontendFr.routeToPage(row.actionID, tableType ? "nonfleet" : "fleet", undefined);
                                                                    }),
                                                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                        size: /* Large */3,
                                                                        type_: /* Edit */60
                                                                      }),
                                                                  key: "edit"
                                                                }),
                                                            item: true
                                                          }), User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? React.createElement(Core.Grid, {
                                                              children: React.createElement(Core.Button, {
                                                                    onClick: (function (param) {
                                                                        Curry._1(setModal, (function (param) {
                                                                                return [
                                                                                        row.actionID,
                                                                                        row.actionLabel
                                                                                      ];
                                                                              }));
                                                                      }),
                                                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                          size: /* Large */3,
                                                                          type_: /* Trash */38,
                                                                          color: "red"
                                                                        }),
                                                                    key: "delete"
                                                                  }),
                                                              item: true
                                                            }) : null) : null), undefined, Belt_List.mapWithIndex(Belt_List.concat(Belt_List.keepMap({
                                                          hd: Belt_Option.getWithDefault(row.actionType, ""),
                                                          tl: {
                                                            hd: row.actionID,
                                                            tl: {
                                                              hd: row.actionLabel,
                                                              tl: {
                                                                hd: tableType ? undefined : Belt_Option.getWithDefault(row.budgetType, ""),
                                                                tl: {
                                                                  hd: Belt_Option.mapWithDefault(tableType ? (
                                                                          Belt_Option.getWithDefault(row.calcMethod, "") === "AMOUNT" ? row.bonusValue : undefined
                                                                        ) : row.bonusAmountEuro, "", (function (eta) {
                                                                          return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                                                        })),
                                                                  tl: {
                                                                    hd: Belt_Option.mapWithDefault(tableType ? (
                                                                            Belt_Option.getWithDefault(row.calcMethod, "") === "PERCENTAGE" ? row.bonusValue : undefined
                                                                          ) : row.bonusAmountPercent, "", (function (param) {
                                                                            return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                                          })),
                                                                    tl: {
                                                                      hd: Belt_Option.mapWithDefault(row.startDate, "", (function (param) {
                                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                            })),
                                                                      tl: {
                                                                        hd: Belt_Option.mapWithDefault(row.endDate, "", (function (param) {
                                                                                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                              })),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }, (function (x) {
                                                            return x;
                                                          })), operation === /* Manage */1 && User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? ({
                                                          hd: Belt_Option.mapWithDefault(row.creationDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.modificationDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }) : /* [] */0), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(tableType, operation, selectedRole), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._5(fetchData, undefined, undefined, undefined, tableType, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._5(fetchData, undefined, undefined, undefined, tableType, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._5(fetchData, undefined, undefined, undefined, tableType, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var tmp = {
    headers: fetchRequest.headers,
    handleSorting: handleSorting,
    tablePage: convertTable(tablePage),
    paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
    exportMenu: React.createElement(Core.Grid, {
          children: null,
          container: true,
          item: true,
          justifyContent: "flex-end"
        }, operation === /* Manage */1 ? React.createElement(Core.Grid, {
                children: React.createElement(App_Table_Create_Button$DvmAdminFrontendFr.make, {
                      onClick: (function (param) {
                          RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rManage + Route_Paths$DvmAdminFrontendFr.rFlashEdit);
                        }),
                      message: Messages_TopMenu$DvmAdminFrontendFr.createFlash
                    }),
                item: true
              }) : null, React.createElement(Core.Grid, {
              children: React.createElement(App_Table_Export_Menu$DvmAdminFrontendFr.make, {
                    query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                          page: 0,
                          rowsPerPage: 150000,
                          headers: fetchRequest.headers,
                          filterQueryParams: Flash_Search_Utils$DvmAdminFrontendFr.getQueryParams(formList, formModalList, formModalAdditionalList, tableType, "fr"),
                          additionalQueryParams: fetchRequest.additionalQueryParams
                        }),
                    endpoint: tableType ? "flash/modular" : "flash"
                  }),
              item: true
            }))
  };
  var tmp$1 = User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) || selectedRole === /* Advisor */0 || selectedRole === /* CompanyManager */2 ? ({
        TAG: /* Left */0,
        _0: /* EmptyCell */0
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.rowButton = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, tmp), Belt_Option.mapWithDefault(match$1[0], null, (function (action) {
                    return React.createElement(Flash_Search_Delete_Modal$DvmAdminFrontendFr.make, {
                                action: action,
                                onClose: (function (param) {
                                    Curry._1(setModal, (function (param) {
                                            
                                          }));
                                  }),
                                fetchRequest: fetchRequest,
                                fetchData: fetchData,
                                tableType: tableType
                              });
                  })));
}

var Api;

var make = Flash_Search_Table;

export {
  Api ,
  getEnglish ,
  headersConfiguration ,
  make ,
}
/* react Not a pure module */
