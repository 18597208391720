// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../../context/user/User_Types_Utils.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Flash_Detail_Table_Types$DvmAdminFrontendFr from "../Flash_Detail_Table_Types.bs.js";

var headersConfiguration_0 = App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model);

var headersConfiguration_1 = {
  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
  tl: {
    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.versionLabel, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial),
    tl: {
      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
      tl: {
        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
        tl: /* [] */0
      }
    }
  }
};

var headersConfiguration = {
  hd: headersConfiguration_0,
  tl: headersConfiguration_1
};

function headersConfigurationPerf(selectedRole) {
  return Belt_List.keepMap({
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.model, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
                tl: {
                  hd: selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? undefined : App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.maxBonus, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.amount),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
                    tl: {
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Detail_Table_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }, (function (x) {
                return x;
              }));
}

function getDateString(date, flash, variant, intl) {
  return Belt_Option.mapWithDefault(date, Belt_Option.mapWithDefault(flash.orderPeriod, "", (function (x) {
                    return Belt_Option.mapWithDefault(variant === "start" ? x.startDate : x.endDate, "", (function (param) {
                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                }));
                  })), (function (param) {
                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
              }));
}

function Flash_Detail_Models_Table_Fleet(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var flash = Props.flash;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var intl = ReactIntl.useIntl();
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (param) {
                                  var match = flash.actionType;
                                  var tmp = match === "PERF" && !(selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) ? Belt_Option.mapWithDefault(param.maxBonus, "", (function (eta) {
                                            return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                          })) : undefined;
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, undefined, undefined, Belt_List.mapWithIndex(Belt_List.keepMap({
                                                      hd: param.model,
                                                      tl: {
                                                        hd: param.version,
                                                        tl: {
                                                          hd: param.versionLabel,
                                                          tl: {
                                                            hd: tmp,
                                                            tl: {
                                                              hd: getDateString(param.startDate, flash, "start", intl),
                                                              tl: {
                                                                hd: getDateString(param.endDate, flash, "end", intl),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }, (function (x) {
                                                        return x;
                                                      })), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration, index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined)
                }));
}

var Api;

var make = Flash_Detail_Models_Table_Fleet;

export {
  Api ,
  headersConfiguration ,
  headersConfigurationPerf ,
  getDateString ,
  make ,
}
/* headersConfiguration Not a pure module */
