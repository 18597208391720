// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../common/components/icons/App_Icon.bs.js";
import * as App_Table$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Head.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../../../common/components/table/App_Table_Pagination.bs.js";
import * as Flash_Edit_Versions_Types$DvmAdminFrontendFr from "../Flash_Edit_Versions_Types.bs.js";
import * as Flash_Edit_Version_Perf_Single_Modal$DvmAdminFrontendFr from "./modalPerf/Flash_Edit_Version_Perf_Single_Modal.bs.js";
import * as Flash_Edit_Fleet_Version_Single_Modal$DvmAdminFrontendFr from "./modal/Flash_Edit_Fleet_Version_Single_Modal.bs.js";

function headersConfiguration(actionType) {
  return Belt_List.keepMap({
              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.version, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.modelVersion),
              tl: {
                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.versionName, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.versionLabelCommercial),
                tl: {
                  hd: actionType === /* PERF */1 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.maxBonus, undefined, undefined, undefined, undefined, undefined, Messages_Flash_Edit$DvmAdminFrontendFr.maxBonus) : undefined,
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.startDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.startDate),
                    tl: {
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(Flash_Edit_Versions_Types$DvmAdminFrontendFr.Fields.endDate, undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.endDate),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }, (function (x) {
                return x;
              }));
}

var expandTextStyle = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
      color: "black",
      display: "none",
      position: "absolute",
      width: "max-content"
    });

var containerStyle = Css.css(Object.assign({}, {
          height: "100%",
          position: "relative",
          top: "-20px"
        }, {
          "&:hover > div": {
            display: "initial"
          }
        }));

function getDateString(date, flashDate, intl) {
  return Belt_Option.mapWithDefault(date, Belt_Option.mapWithDefault(flashDate, "", (function (param) {
                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                  })), (function (param) {
                return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
              }));
}

function Flash_Edit_Fleet_Version_Table(Props) {
  var flash = Props.flash;
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var refreshVersions = Props.refreshVersions;
  var fetchUpdatedModelsForDropdownAndCumulables = Props.fetchUpdatedModelsForDropdownAndCumulables;
  var actionType = Props.actionType;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow(undefined, Caml_option.some(React.createElement(Core.Button, {
                                                      onClick: (function (param) {
                                                          Curry._1(setModal, (function (param) {
                                                                  if (actionType === /* PERF */1) {
                                                                    return React.createElement(Flash_Edit_Version_Perf_Single_Modal$DvmAdminFrontendFr.make, {
                                                                                flash: flash,
                                                                                onClose: (function (param) {
                                                                                    Curry._1(setModal, (function (param) {
                                                                                            return null;
                                                                                          }));
                                                                                  }),
                                                                                onOk: (function (param) {
                                                                                    Curry._1(setModal, (function (param) {
                                                                                            return null;
                                                                                          }));
                                                                                    Curry._1(refreshVersions, undefined);
                                                                                    Curry._2(fetchData, undefined, fetchRequest);
                                                                                  }),
                                                                                fetchUpdatedModelsForDropdownAndCumulables: fetchUpdatedModelsForDropdownAndCumulables,
                                                                                row: row
                                                                              });
                                                                  } else {
                                                                    return React.createElement(Flash_Edit_Fleet_Version_Single_Modal$DvmAdminFrontendFr.make, {
                                                                                flash: flash,
                                                                                modelVersionID: row.modelVersionID,
                                                                                version: row.version,
                                                                                versionName: Belt_Option.getWithDefault(row.versionName, ""),
                                                                                startDate: row.startDate,
                                                                                endDate: row.endDate,
                                                                                onClose: (function (param) {
                                                                                    Curry._1(setModal, (function (param) {
                                                                                            return null;
                                                                                          }));
                                                                                  }),
                                                                                onOk: (function (param) {
                                                                                    Curry._1(setModal, (function (param) {
                                                                                            return null;
                                                                                          }));
                                                                                    Curry._1(refreshVersions, undefined);
                                                                                    Curry._2(fetchData, undefined, fetchRequest);
                                                                                  }),
                                                                                fetchUpdatedModelsForDropdownAndCumulables: fetchUpdatedModelsForDropdownAndCumulables
                                                                              });
                                                                  }
                                                                }));
                                                        }),
                                                      children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                            size: /* Large */3,
                                                            type_: /* Edit */60
                                                          })
                                                    })), undefined, Belt_List.mapWithIndex(Belt_List.keepMap({
                                                      hd: Caml_option.some(row.model + " " + row.version),
                                                      tl: {
                                                        hd: Caml_option.some(React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                                      noWrap: true,
                                                                      style: {
                                                                        maxWidth: "200px"
                                                                      },
                                                                      children: Belt_Option.getWithDefault(row.versionName, "")
                                                                    }), React.createElement("div", {
                                                                      className: containerStyle
                                                                    }, React.createElement("div", {
                                                                          className: expandTextStyle
                                                                        }, Belt_Option.getWithDefault(row.versionName, ""))))),
                                                        tl: {
                                                          hd: actionType === /* PERF */1 ? Caml_option.some(Belt_Option.mapWithDefault(row.maxBonus, "", (function (eta) {
                                                                        return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                                                      }))) : undefined,
                                                          tl: {
                                                            hd: Caml_option.some(getDateString(row.startDate, flash.startDate, intl)),
                                                            tl: {
                                                              hd: Caml_option.some(getDateString(row.endDate, flash.endDate, intl)),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }, (function (x) {
                                                        return x;
                                                      })), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(actionType), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._2(fetchData, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._2(fetchData, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._2(fetchData, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(App_Table$DvmAdminFrontendFr.make, {
                  headers: fetchRequest.headers,
                  handleSorting: handleSorting,
                  tablePage: convertTable(tablePage),
                  paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(/* BOTTOM */1, 10, handleChangePage, handleRowsPerPageChange, undefined),
                  rowButton: {
                    TAG: /* Right */1,
                    _0: /* EmptyCell */0
                  }
                }), match[0]);
}

var Types;

var make = Flash_Edit_Fleet_Version_Table;

export {
  Types ,
  headersConfiguration ,
  expandTextStyle ,
  containerStyle ,
  getDateString ,
  make ,
}
/* expandTextStyle Not a pure module */
