// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";

var validators_vehicleType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.vehicleType
            };
    })
};

var validators_brandsNewCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateListRequired(param.brandsNewCar);
    })
};

var validators_versions = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if (param.mappingDirection === "ALL") {
        return {
                TAG: /* Ok */0,
                _0: /* [] */0
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateListRequired(param.versions);
      }
    })
};

var validators_engineTypes = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.engineTypes
            };
    })
};

var validators_mappingDirection = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.mappingDirection
            };
    })
};

var validators_modelPhases = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateListMaxLength(14, param.modelPhases);
    })
};

var validators_models = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateListRequired(param.models);
    })
};

var validators = {
  vehicleType: validators_vehicleType,
  brandsNewCar: validators_brandsNewCar,
  versions: validators_versions,
  engineTypes: validators_engineTypes,
  mappingDirection: validators_mappingDirection,
  modelPhases: validators_modelPhases,
  models: validators_models
};

function initialFieldsStatuses(_input) {
  return {
          vehicleType: /* Pristine */0,
          brandsNewCar: /* Pristine */0,
          versions: /* Pristine */0,
          engineTypes: /* Pristine */0,
          mappingDirection: /* Pristine */0,
          modelPhases: /* Pristine */0,
          models: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.vehicleType;
  var match_0 = match ? match._0 : Curry._1(validators.vehicleType.validate, input);
  var match$1 = fieldsStatuses.brandsNewCar;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.brandsNewCar.validate, input);
  var match$2 = fieldsStatuses.versions;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.versions.validate, input);
  var match$3 = fieldsStatuses.engineTypes;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.engineTypes.validate, input);
  var match$4 = fieldsStatuses.mappingDirection;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.mappingDirection.validate, input);
  var match$5 = fieldsStatuses.modelPhases;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.modelPhases.validate, input);
  var match$6 = fieldsStatuses.models;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.models.validate, input);
  var vehicleTypeResult = match_0;
  var vehicleTypeResult$1;
  if (vehicleTypeResult.TAG === /* Ok */0) {
    var brandsNewCarResult = match_0$1;
    if (brandsNewCarResult.TAG === /* Ok */0) {
      var versionsResult = match_0$2;
      if (versionsResult.TAG === /* Ok */0) {
        var engineTypesResult = match_0$3;
        if (engineTypesResult.TAG === /* Ok */0) {
          var mappingDirectionResult = match_0$4;
          if (mappingDirectionResult.TAG === /* Ok */0) {
            var modelPhasesResult = match_0$5;
            if (modelPhasesResult.TAG === /* Ok */0) {
              var modelsResult = match_0$6;
              if (modelsResult.TAG === /* Ok */0) {
                return {
                        TAG: /* Valid */0,
                        output: {
                          models: modelsResult._0,
                          modelPhases: modelPhasesResult._0,
                          mappingDirection: mappingDirectionResult._0,
                          engineTypes: engineTypesResult._0,
                          versions: versionsResult._0,
                          brandsNewCar: brandsNewCarResult._0,
                          vehicleType: vehicleTypeResult._0
                        },
                        fieldsStatuses: {
                          vehicleType: /* Dirty */{
                            _0: vehicleTypeResult,
                            _1: /* Shown */0
                          },
                          brandsNewCar: /* Dirty */{
                            _0: brandsNewCarResult,
                            _1: /* Shown */0
                          },
                          versions: /* Dirty */{
                            _0: versionsResult,
                            _1: /* Shown */0
                          },
                          engineTypes: /* Dirty */{
                            _0: engineTypesResult,
                            _1: /* Shown */0
                          },
                          mappingDirection: /* Dirty */{
                            _0: mappingDirectionResult,
                            _1: /* Shown */0
                          },
                          modelPhases: /* Dirty */{
                            _0: modelPhasesResult,
                            _1: /* Shown */0
                          },
                          models: /* Dirty */{
                            _0: modelsResult,
                            _1: /* Shown */0
                          }
                        },
                        collectionsStatuses: undefined
                      };
              }
              vehicleTypeResult$1 = vehicleTypeResult;
            } else {
              vehicleTypeResult$1 = vehicleTypeResult;
            }
          } else {
            vehicleTypeResult$1 = vehicleTypeResult;
          }
        } else {
          vehicleTypeResult$1 = vehicleTypeResult;
        }
      } else {
        vehicleTypeResult$1 = vehicleTypeResult;
      }
    } else {
      vehicleTypeResult$1 = vehicleTypeResult;
    }
  } else {
    vehicleTypeResult$1 = vehicleTypeResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            vehicleType: /* Dirty */{
              _0: vehicleTypeResult$1,
              _1: /* Shown */0
            },
            brandsNewCar: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            versions: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            engineTypes: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            mappingDirection: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            modelPhases: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            models: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurVehicleTypeField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.vehicleType, validators_vehicleType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  vehicleType: status,
                                  brandsNewCar: init.brandsNewCar,
                                  versions: init.versions,
                                  engineTypes: init.engineTypes,
                                  mappingDirection: init.mappingDirection,
                                  modelPhases: init.modelPhases,
                                  models: init.models
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandsNewCarField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.brandsNewCar, validators_brandsNewCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  vehicleType: init.vehicleType,
                                  brandsNewCar: status,
                                  versions: init.versions,
                                  engineTypes: init.engineTypes,
                                  mappingDirection: init.mappingDirection,
                                  modelPhases: init.modelPhases,
                                  models: init.models
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionsField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.versions, validators_versions, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  vehicleType: init.vehicleType,
                                  brandsNewCar: init.brandsNewCar,
                                  versions: status,
                                  engineTypes: init.engineTypes,
                                  mappingDirection: init.mappingDirection,
                                  modelPhases: init.modelPhases,
                                  models: init.models
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEngineTypesField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.engineTypes, validators_engineTypes, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  vehicleType: init.vehicleType,
                                  brandsNewCar: init.brandsNewCar,
                                  versions: init.versions,
                                  engineTypes: status,
                                  mappingDirection: init.mappingDirection,
                                  modelPhases: init.modelPhases,
                                  models: init.models
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurMappingDirectionField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.mappingDirection, validators_mappingDirection, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  vehicleType: init.vehicleType,
                                  brandsNewCar: init.brandsNewCar,
                                  versions: init.versions,
                                  engineTypes: init.engineTypes,
                                  mappingDirection: status,
                                  modelPhases: init.modelPhases,
                                  models: init.models
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelPhasesField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.modelPhases, validators_modelPhases, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  vehicleType: init.vehicleType,
                                  brandsNewCar: init.brandsNewCar,
                                  versions: init.versions,
                                  engineTypes: init.engineTypes,
                                  mappingDirection: init.mappingDirection,
                                  modelPhases: status,
                                  models: init.models
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelsField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.models, validators_models, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  vehicleType: init.vehicleType,
                                  brandsNewCar: init.brandsNewCar,
                                  versions: init.versions,
                                  engineTypes: init.engineTypes,
                                  mappingDirection: init.mappingDirection,
                                  modelPhases: init.modelPhases,
                                  models: status
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */7 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */7,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */8,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */10);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */9);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */8 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */9 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */10 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateVehicleTypeField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.vehicleType, state.submissionStatus, validators_vehicleType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            vehicleType: status,
                                            brandsNewCar: init.brandsNewCar,
                                            versions: init.versions,
                                            engineTypes: init.engineTypes,
                                            mappingDirection: init.mappingDirection,
                                            modelPhases: init.modelPhases,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandsNewCarField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.brandsNewCar, state.submissionStatus, validators_brandsNewCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            vehicleType: init.vehicleType,
                                            brandsNewCar: status,
                                            versions: init.versions,
                                            engineTypes: init.engineTypes,
                                            mappingDirection: init.mappingDirection,
                                            modelPhases: init.modelPhases,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionsField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$7 = Formality.validateDependentFieldOnChange(nextInput$2, nextFieldsStatuses.contents.mappingDirection, validators_mappingDirection, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  vehicleType: init.vehicleType,
                                  brandsNewCar: init.brandsNewCar,
                                  versions: init.versions,
                                  engineTypes: init.engineTypes,
                                  mappingDirection: status,
                                  modelPhases: init.modelPhases,
                                  models: init.models
                                };
                        }));
                  if (result$7 !== undefined) {
                    nextFieldsStatuses.contents = result$7;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, nextFieldsStatuses.contents.versions, state.submissionStatus, validators_versions, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            vehicleType: init.vehicleType,
                                            brandsNewCar: init.brandsNewCar,
                                            versions: status,
                                            engineTypes: init.engineTypes,
                                            mappingDirection: init.mappingDirection,
                                            modelPhases: init.modelPhases,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEngineTypesField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.engineTypes, state.submissionStatus, validators_engineTypes, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            vehicleType: init.vehicleType,
                                            brandsNewCar: init.brandsNewCar,
                                            versions: init.versions,
                                            engineTypes: status,
                                            mappingDirection: init.mappingDirection,
                                            modelPhases: init.modelPhases,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateMappingDirectionField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$8 = Formality.validateDependentFieldOnChange(nextInput$4, nextFieldsStatuses$1.contents.versions, validators_versions, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  vehicleType: init.vehicleType,
                                  brandsNewCar: init.brandsNewCar,
                                  versions: status,
                                  engineTypes: init.engineTypes,
                                  mappingDirection: init.mappingDirection,
                                  modelPhases: init.modelPhases,
                                  models: init.models
                                };
                        }));
                  if (result$8 !== undefined) {
                    nextFieldsStatuses$1.contents = result$8;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, nextFieldsStatuses$1.contents.mappingDirection, state.submissionStatus, validators_mappingDirection, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            vehicleType: init.vehicleType,
                                            brandsNewCar: init.brandsNewCar,
                                            versions: init.versions,
                                            engineTypes: init.engineTypes,
                                            mappingDirection: status,
                                            modelPhases: init.modelPhases,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelPhasesField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.modelPhases, state.submissionStatus, validators_modelPhases, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            vehicleType: init.vehicleType,
                                            brandsNewCar: init.brandsNewCar,
                                            versions: init.versions,
                                            engineTypes: init.engineTypes,
                                            mappingDirection: init.mappingDirection,
                                            modelPhases: status,
                                            models: init.models
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelsField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.models, state.submissionStatus, validators_models, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            vehicleType: init.vehicleType,
                                            brandsNewCar: init.brandsNewCar,
                                            versions: init.versions,
                                            engineTypes: init.engineTypes,
                                            mappingDirection: init.mappingDirection,
                                            modelPhases: init.modelPhases,
                                            models: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */7 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */8 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */9 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateVehicleType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVehicleTypeField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrandsNewCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandsNewCarField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersions: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionsField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEngineTypes: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEngineTypesField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateMappingDirection: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateMappingDirectionField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModelPhases: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelPhasesField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModels: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelsField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurVehicleType: (function (param) {
              Curry._1(dispatch, /* BlurVehicleTypeField */0);
            }),
          blurBrandsNewCar: (function (param) {
              Curry._1(dispatch, /* BlurBrandsNewCarField */1);
            }),
          blurVersions: (function (param) {
              Curry._1(dispatch, /* BlurVersionsField */2);
            }),
          blurEngineTypes: (function (param) {
              Curry._1(dispatch, /* BlurEngineTypesField */3);
            }),
          blurMappingDirection: (function (param) {
              Curry._1(dispatch, /* BlurMappingDirectionField */4);
            }),
          blurModelPhases: (function (param) {
              Curry._1(dispatch, /* BlurModelPhasesField */5);
            }),
          blurModels: (function (param) {
              Curry._1(dispatch, /* BlurModelsField */6);
            }),
          vehicleTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.vehicleType),
          brandsNewCarResult: Formality.exposeFieldResult(state.fieldsStatuses.brandsNewCar),
          versionsResult: Formality.exposeFieldResult(state.fieldsStatuses.versions),
          engineTypesResult: Formality.exposeFieldResult(state.fieldsStatuses.engineTypes),
          mappingDirectionResult: Formality.exposeFieldResult(state.fieldsStatuses.mappingDirection),
          modelPhasesResult: Formality.exposeFieldResult(state.fieldsStatuses.modelPhases),
          modelsResult: Formality.exposeFieldResult(state.fieldsStatuses.models),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.vehicleType || match.brandsNewCar || match.versions || match.engineTypes || match.mappingDirection || match.modelPhases || match.models) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */7);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */8);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */9);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */9,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */10);
            })
        };
}

var NewVehicleForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  models: /* [] */0,
  modelPhases: /* [] */0,
  mappingDirection: "ALL",
  engineTypes: /* [] */0,
  versions: /* [] */0,
  brandsNewCar: {
    hd: "RENAULT",
    tl: /* [] */0
  },
  vehicleType: /* NOTSET */3
};

export {
  Types ,
  NewVehicleForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
