// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Gemo$DvmAdminFrontendFr from "../../../intl/messages/gemo/Messages_Gemo.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Gemo_AddEdit_Api$DvmAdminFrontendFr from "./Gemo_AddEdit_Api.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Submarket$DvmAdminFrontendFr from "../../../intl/messages/gemo/Messages_Submarket.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";

function Gemo_AddEdit_Inputs(Props) {
  var operation = Props.operation;
  var form = Props.form;
  var onClose = Props.onClose;
  var setBudgetOrAction = Props.setBudgetOrAction;
  var saveResult = Props.saveResult;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return /* NotStarted */0;
      });
  var setBudgets = match[1];
  var budgets = match[0];
  var match$1 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionTypesFleet = match$1[1];
  var actionTypesFleet = match$1[0];
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setActionTypesNonFleet = match$2[1];
  var actionTypesNonFleet = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAllSubmarkets = match$3[1];
  var allSubmarkets = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAllModels = match$4[1];
  var allModels = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setBonusActionOptions = match$5[1];
  var bonusActionOptions = match$5[0];
  var match$6 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$7 = match$6[0];
  var userManager = match$7.userManager;
  var selectedRole = match$7.selectedRole;
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.fetchBudgets(userManager, setBudgets, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchActionTypes(userManager, setActionTypesFleet, alert, selectedRole, "fleet");
          App_Api$DvmAdminFrontendFr.fetchActionTypes(userManager, setActionTypesNonFleet, alert, selectedRole, "nonfleet");
          Gemo_AddEdit_Api$DvmAdminFrontendFr.fetchSubmarkets(userManager, setAllSubmarkets, selectedRole);
          App_Api$DvmAdminFrontendFr.fetchModelVersions(setAllModels, userManager, selectedRole, undefined, undefined, undefined);
        }), []);
  React.useEffect((function () {
          var isBudget = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(budgets, undefined, (function (x) {
                  return Belt_List.getBy(x, (function (x) {
                                return x === form.input.budgetActionType;
                              }));
                }));
          var isAction = Belt_List.getBy(Belt_List.concat(App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesFleet, /* [] */0), App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0)), (function (x) {
                  return x === form.input.budgetActionType;
                }));
          if (isBudget !== undefined) {
            Curry._1(setBudgetOrAction, (function (param) {
                    return {
                            TAG: /* Budget */0,
                            _0: isBudget
                          };
                  }));
          } else if (isAction !== undefined) {
            Curry._1(setBudgetOrAction, (function (param) {
                    return {
                            TAG: /* Action */1,
                            _0: isAction
                          };
                  }));
          } else {
            Curry._1(setBudgetOrAction, (function (param) {
                    return /* Empty */0;
                  }));
          }
        }), [
        form.input.budgetActionType,
        actionTypesFleet,
        actionTypesNonFleet
      ]);
  React.useEffect((function () {
          var actionsList = Belt_List.map(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keep(Belt_List.concat(App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesFleet, /* [] */0), App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0)), (function (x) {
                                  return x.length > 0;
                                }))))), (function (x) {
                  return {
                          label: {
                            TAG: /* String */3,
                            _0: "Action " + x + ""
                          },
                          value: x
                        };
                }));
          var budgetList = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(budgets, /* [] */0, (function (x) {
                  return Belt_List.map(x, (function (x) {
                                return {
                                        label: {
                                          TAG: /* String */3,
                                          _0: "Budget " + x + ""
                                        },
                                        value: x
                                      };
                              }));
                }));
          Curry._1(setBonusActionOptions, (function (param) {
                  return Belt_List.concatMany([
                              actionsList,
                              budgetList
                            ]);
                }));
        }), [
        budgets,
        actionTypesFleet,
        actionTypesNonFleet
      ]);
  React.useEffect((function () {
          if (Belt_List.length(bonusActionOptions) > 0 && App_Types_Result$DvmAdminFrontendFr.isSuccess(actionTypesFleet) && App_Types_Result$DvmAdminFrontendFr.isSuccess(actionTypesNonFleet) && !Belt_List.has(bonusActionOptions, form.input.budgetActionType, (function (a, b) {
                    return a.value === b;
                  }))) {
            Curry._2(form.updateBudgetActionType, (function (input, budgetActionType) {
                    return {
                            gemoCode: input.gemoCode,
                            gemoLabel: input.gemoLabel,
                            budgetActionType: budgetActionType,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            submarketCode: input.submarketCode,
                            models: input.models
                          };
                  }), "");
          }
          
        }), [bonusActionOptions]);
  React.useEffect((function () {
          if (App_Types_Result$DvmAdminFrontendFr.isSuccess(allSubmarkets) && !Belt_List.has(App_Types_Result$DvmAdminFrontendFr.mapWithDefault(allSubmarkets, /* [] */0, (function (submarketCodes) {
                        return Belt_List.keepMap(submarketCodes, (function (x) {
                                      return x;
                                    }));
                      })), form.input.submarketCode, (function (a, b) {
                    return a === b;
                  }))) {
            Curry._2(form.updateSubmarketCode, (function (input, submarketCode) {
                    return {
                            gemoCode: input.gemoCode,
                            gemoLabel: input.gemoLabel,
                            budgetActionType: input.budgetActionType,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            submarketCode: submarketCode,
                            models: input.models
                          };
                  }), "");
          }
          
        }), [allSubmarkets]);
  var tmp = {
    onChange: (function (val) {
        Curry._2(form.updateGemoCode, (function (input, gemoCode) {
                return {
                        gemoCode: gemoCode,
                        gemoLabel: input.gemoLabel,
                        budgetActionType: input.budgetActionType,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        submarketCode: input.submarketCode,
                        models: input.models
                      };
              }), val.toUpperCase());
      }),
    value: form.input.gemoCode,
    disabled: operation !== /* Create */0,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Gemo$DvmAdminFrontendFr.gemo,
    required: /* OnlyLabel */1
  };
  if (form.gemoCodeResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.gemoCodeResult);
  }
  var tmp$1 = {
    onChange: Curry._1(form.updateGemoLabel, (function (input, gemoLabel) {
            return {
                    gemoCode: input.gemoCode,
                    gemoLabel: gemoLabel,
                    budgetActionType: input.budgetActionType,
                    startDate: input.startDate,
                    endDate: input.endDate,
                    submarketCode: input.submarketCode,
                    models: input.models
                  };
          })),
    value: form.input.gemoLabel,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    label: Messages_Gemo$DvmAdminFrontendFr.gemoLabel,
    required: /* OnlyLabel */1
  };
  if (form.gemoLabelResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.gemoLabelResult);
  }
  var tmp$2 = {
    label: Messages_Common$DvmAdminFrontendFr.startDate,
    value: form.input.startDate,
    onChange: Curry._1(form.updateStartDate, (function (input, startDate) {
            return {
                    gemoCode: input.gemoCode,
                    gemoLabel: input.gemoLabel,
                    budgetActionType: input.budgetActionType,
                    startDate: startDate,
                    endDate: input.endDate,
                    submarketCode: input.submarketCode,
                    models: input.models
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    required: /* OnlyLabel */1
  };
  if (form.startDateResult !== undefined) {
    tmp$2.validationResult = Caml_option.valFromOption(form.startDateResult);
  }
  var tmp$3 = {
    label: Messages_Common$DvmAdminFrontendFr.endDate,
    value: form.input.endDate,
    onChange: Curry._1(form.updateEndDate, (function (input, endDate) {
            return {
                    gemoCode: input.gemoCode,
                    gemoLabel: input.gemoLabel,
                    budgetActionType: input.budgetActionType,
                    startDate: input.startDate,
                    endDate: endDate,
                    submarketCode: input.submarketCode,
                    models: input.models
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    required: /* OnlyLabel */1
  };
  if (form.endDateResult !== undefined) {
    tmp$3.validationResult = Caml_option.valFromOption(form.endDateResult);
  }
  var tmp$4 = {
    label: Messages_Submarket$DvmAdminFrontendFr.budgetActionType,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.budgetActionType,
        onChange: Curry._1(form.updateBudgetActionType, (function (input, budgetActionType) {
                return {
                        gemoCode: input.gemoCode,
                        gemoLabel: input.gemoLabel,
                        budgetActionType: budgetActionType,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        submarketCode: input.submarketCode,
                        models: input.models
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: bonusActionOptions
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.budgetActionTypeResult !== undefined) {
    tmp$4.validationResult = Caml_option.valFromOption(form.budgetActionTypeResult);
  }
  var tmp$5 = {
    label: Messages_Gemo$DvmAdminFrontendFr.submarketLabel,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.submarketCode,
        onChange: Curry._1(form.updateSubmarketCode, (function (input, submarketCode) {
                return {
                        gemoCode: input.gemoCode,
                        gemoLabel: input.gemoLabel,
                        budgetActionType: input.budgetActionType,
                        startDate: input.startDate,
                        endDate: input.endDate,
                        submarketCode: submarketCode,
                        models: input.models
                      };
              }))
      }
    },
    options: {
      TAG: /* Unlabeled */0,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(allSubmarkets, /* [] */0, (function (submarketCodes) {
              return Belt_List.keepMap(submarketCodes, (function (x) {
                            return x;
                          }));
            }))
    },
    required: /* OnlyLabel */1,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
  };
  if (form.submarketCodeResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.submarketCodeResult);
  }
  var tmp$6 = {
    label: operation ? Messages_Common$DvmAdminFrontendFr.model : Messages_Common$DvmAdminFrontendFr.models,
    value: operation ? ({
          TAG: /* Single */0,
          _0: {
            value: Belt_Option.getWithDefault(Belt_List.get(form.input.models, 0), ""),
            onChange: (function (val) {
                Curry._2(form.updateModels, (function (input, models) {
                        return {
                                gemoCode: input.gemoCode,
                                gemoLabel: input.gemoLabel,
                                budgetActionType: input.budgetActionType,
                                startDate: input.startDate,
                                endDate: input.endDate,
                                submarketCode: input.submarketCode,
                                models: models
                              };
                      }), {
                      hd: val,
                      tl: /* [] */0
                    });
              })
          }
        }) : ({
          TAG: /* Multiple */1,
          _0: {
            value: form.input.models,
            onChange: Curry._1(form.updateModels, (function (input, models) {
                    return {
                            gemoCode: input.gemoCode,
                            gemoLabel: input.gemoLabel,
                            budgetActionType: input.budgetActionType,
                            startDate: input.startDate,
                            endDate: input.endDate,
                            submarketCode: input.submarketCode,
                            models: models
                          };
                  }))
          }
        }),
    options: {
      TAG: /* Unlabeled */0,
      _0: Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(allModels, /* [] */0), (function (x) {
              return x.parent;
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
    limitTags: 3,
    smallChips: true
  };
  if (form.modelsResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.modelsResult);
  }
  return React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
              show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(budgets) || App_Types_Result$DvmAdminFrontendFr.isPending(actionTypesFleet) || App_Types_Result$DvmAdminFrontendFr.isPending(actionTypesNonFleet) || App_Types_Result$DvmAdminFrontendFr.isPending(allSubmarkets) || App_Types_Result$DvmAdminFrontendFr.isPending(allModels),
              children: React.createElement("form", {
                    onSubmit: (function ($$event) {
                        $$event.preventDefault();
                        Curry._1(form.submit, undefined);
                      })
                  }, React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "row",
                        justifyContent: "center",
                        spacing: 2
                      }, React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            spacing: 3
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                item: true,
                                sm: Grid$Mui.Sm[6]
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                                item: true,
                                sm: Grid$Mui.Sm[6]
                              })), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            spacing: 3
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$2),
                                item: true,
                                sm: Grid$Mui.Sm[3]
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$3),
                                item: true,
                                sm: Grid$Mui.Sm[3]
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$4),
                                item: true,
                                sm: Grid$Mui.Sm[6]
                              })), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            spacing: 3
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$5),
                                item: true,
                                sm: Grid$Mui.Sm[6]
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$6),
                                item: true,
                                sm: Grid$Mui.Sm[6]
                              })), React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            item: true,
                            justifyContent: "space-between"
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      onClick: (function (param) {
                                          Curry._1(onClose, undefined);
                                        }),
                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                      color: "primaryBorder",
                                      size: "medium"
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                      label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                      size: "medium",
                                      type_: "submit"
                                    }),
                                item: true
                              }))))
            });
}

var Api;

var Form;

var Router;

var InputStyles;

var Types;

var make = Gemo_AddEdit_Inputs;

export {
  Api ,
  Form ,
  Router ,
  InputStyles ,
  Types ,
  make ,
}
/* react Not a pure module */
