// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Order_Search_Types$DvmAdminFrontendFr from "./Order_Search_Types.bs.js";
import * as App_Types_VN_VO_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_VN_VO_Status.bs.js";

var validators_usedCarStatus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarStatus, "normal", param.usedCarStatus)
            };
    })
};

var validators_orderStatus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var orderStatus = param.orderStatus;
      var tmp;
      if (Belt_List.length(orderStatus) > 0) {
        tmp = Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.orderStatus, "normal", orderStatus);
      } else {
        var match = Order_Search_Types$DvmAdminFrontendFr.Fields.orderStatus;
        tmp = {
          en: match[0] + "<>" + App_Types_VN_VO_Status$DvmAdminFrontendFr.toString(/* CANCELLED */2),
          fr: match[1] + "<>" + App_Types_VN_VO_Status$DvmAdminFrontendFr.toString(/* CANCELLED */2)
        };
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.orderType, param.orderType, false)
            };
    })
};

var validators_showArchived = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.boolFieldToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.archived, param.showArchived)
            };
    })
};

var validators_showHistoryDealer = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if (param.showHistoryDealer) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var match = Order_Search_Types$DvmAdminFrontendFr.Fields.showHistoryDealer;
      return {
              TAG: /* Ok */0,
              _0: {
                en: match[0] + "=oui",
                fr: match[1] + "=oui"
              }
            };
    })
};

var validators_budgetStatus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var tmp;
      switch (param.orderType) {
        case "CL" :
        case "PG" :
        case "RC" :
        case "RR" :
        case "VD" :
        case "VE" :
            tmp = undefined;
            break;
        default:
          tmp = Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.budgetStatus, "normal", param.budgetStatus);
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_budgetType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var tmp;
      switch (param.orderType) {
        case "CL" :
        case "PG" :
        case "RC" :
        case "RR" :
        case "VD" :
        case "VE" :
            tmp = undefined;
            break;
        default:
          tmp = Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.budgetType, param.budgetType, false);
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_newCarRegistrationID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.newCarRegistrationID, param.newCarRegistrationID, false)
            };
    })
};

var validators_vin = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.vin, param.vin, false)
            };
    })
};

var validators_orderNumberCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.orderNumberCar, param.orderNumberCar, false)
            };
    })
};

var validators_customerOrderNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.customerOrderNumber, param.customerOrderNumber, false)
            };
    })
};

var validators_dealerId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.dealerID, "normal", param.dealerId)
            };
    })
};

var validators_dr = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.dr, "normal", param.dr)
            };
    })
};

var validators = {
  usedCarStatus: validators_usedCarStatus,
  orderStatus: validators_orderStatus,
  orderType: validators_orderType,
  showArchived: validators_showArchived,
  showHistoryDealer: validators_showHistoryDealer,
  budgetStatus: validators_budgetStatus,
  budgetType: validators_budgetType,
  newCarRegistrationID: validators_newCarRegistrationID,
  vin: validators_vin,
  orderNumberCar: validators_orderNumberCar,
  customerOrderNumber: validators_customerOrderNumber,
  dealerId: validators_dealerId,
  dr: validators_dr
};

function initialFieldsStatuses(_input) {
  return {
          usedCarStatus: /* Pristine */0,
          orderStatus: /* Pristine */0,
          orderType: /* Pristine */0,
          showArchived: /* Pristine */0,
          showHistoryDealer: /* Pristine */0,
          budgetStatus: /* Pristine */0,
          budgetType: /* Pristine */0,
          newCarRegistrationID: /* Pristine */0,
          vin: /* Pristine */0,
          orderNumberCar: /* Pristine */0,
          customerOrderNumber: /* Pristine */0,
          dealerId: /* Pristine */0,
          dr: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.usedCarStatus;
  var match_0 = match ? match._0 : Curry._1(validators.usedCarStatus.validate, input);
  var match$1 = fieldsStatuses.orderStatus;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.orderStatus.validate, input);
  var match$2 = fieldsStatuses.orderType;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.orderType.validate, input);
  var match$3 = fieldsStatuses.showArchived;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.showArchived.validate, input);
  var match$4 = fieldsStatuses.showHistoryDealer;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.showHistoryDealer.validate, input);
  var match$5 = fieldsStatuses.budgetStatus;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.budgetStatus.validate, input);
  var match$6 = fieldsStatuses.budgetType;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.budgetType.validate, input);
  var match$7 = fieldsStatuses.newCarRegistrationID;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.newCarRegistrationID.validate, input);
  var match$8 = fieldsStatuses.vin;
  var match_0$8 = match$8 ? match$8._0 : Curry._1(validators.vin.validate, input);
  var match$9 = fieldsStatuses.orderNumberCar;
  var match_0$9 = match$9 ? match$9._0 : Curry._1(validators.orderNumberCar.validate, input);
  var match$10 = fieldsStatuses.customerOrderNumber;
  var match_0$10 = match$10 ? match$10._0 : Curry._1(validators.customerOrderNumber.validate, input);
  var match$11 = fieldsStatuses.dealerId;
  var match_0$11 = match$11 ? match$11._0 : Curry._1(validators.dealerId.validate, input);
  var match$12 = fieldsStatuses.dr;
  var match_0$12 = match$12 ? match$12._0 : Curry._1(validators.dr.validate, input);
  var usedCarStatusResult = match_0;
  var usedCarStatusResult$1;
  if (usedCarStatusResult.TAG === /* Ok */0) {
    var orderStatusResult = match_0$1;
    if (orderStatusResult.TAG === /* Ok */0) {
      var orderTypeResult = match_0$2;
      if (orderTypeResult.TAG === /* Ok */0) {
        var showArchivedResult = match_0$3;
        if (showArchivedResult.TAG === /* Ok */0) {
          var showHistoryDealerResult = match_0$4;
          if (showHistoryDealerResult.TAG === /* Ok */0) {
            var budgetStatusResult = match_0$5;
            if (budgetStatusResult.TAG === /* Ok */0) {
              var budgetTypeResult = match_0$6;
              if (budgetTypeResult.TAG === /* Ok */0) {
                var newCarRegistrationIDResult = match_0$7;
                if (newCarRegistrationIDResult.TAG === /* Ok */0) {
                  var vinResult = match_0$8;
                  if (vinResult.TAG === /* Ok */0) {
                    var orderNumberCarResult = match_0$9;
                    if (orderNumberCarResult.TAG === /* Ok */0) {
                      var customerOrderNumberResult = match_0$10;
                      if (customerOrderNumberResult.TAG === /* Ok */0) {
                        var dealerIdResult = match_0$11;
                        if (dealerIdResult.TAG === /* Ok */0) {
                          var drResult = match_0$12;
                          if (drResult.TAG === /* Ok */0) {
                            return {
                                    TAG: /* Valid */0,
                                    output: {
                                      dr: drResult._0,
                                      dealerId: dealerIdResult._0,
                                      customerOrderNumber: customerOrderNumberResult._0,
                                      orderNumberCar: orderNumberCarResult._0,
                                      vin: vinResult._0,
                                      newCarRegistrationID: newCarRegistrationIDResult._0,
                                      budgetType: budgetTypeResult._0,
                                      budgetStatus: budgetStatusResult._0,
                                      showHistoryDealer: showHistoryDealerResult._0,
                                      showArchived: showArchivedResult._0,
                                      orderType: orderTypeResult._0,
                                      orderStatus: orderStatusResult._0,
                                      usedCarStatus: usedCarStatusResult._0
                                    },
                                    fieldsStatuses: {
                                      usedCarStatus: /* Dirty */{
                                        _0: usedCarStatusResult,
                                        _1: /* Shown */0
                                      },
                                      orderStatus: /* Dirty */{
                                        _0: orderStatusResult,
                                        _1: /* Shown */0
                                      },
                                      orderType: /* Dirty */{
                                        _0: orderTypeResult,
                                        _1: /* Shown */0
                                      },
                                      showArchived: /* Dirty */{
                                        _0: showArchivedResult,
                                        _1: /* Shown */0
                                      },
                                      showHistoryDealer: /* Dirty */{
                                        _0: showHistoryDealerResult,
                                        _1: /* Shown */0
                                      },
                                      budgetStatus: /* Dirty */{
                                        _0: budgetStatusResult,
                                        _1: /* Shown */0
                                      },
                                      budgetType: /* Dirty */{
                                        _0: budgetTypeResult,
                                        _1: /* Shown */0
                                      },
                                      newCarRegistrationID: /* Dirty */{
                                        _0: newCarRegistrationIDResult,
                                        _1: /* Shown */0
                                      },
                                      vin: /* Dirty */{
                                        _0: vinResult,
                                        _1: /* Shown */0
                                      },
                                      orderNumberCar: /* Dirty */{
                                        _0: orderNumberCarResult,
                                        _1: /* Shown */0
                                      },
                                      customerOrderNumber: /* Dirty */{
                                        _0: customerOrderNumberResult,
                                        _1: /* Shown */0
                                      },
                                      dealerId: /* Dirty */{
                                        _0: dealerIdResult,
                                        _1: /* Shown */0
                                      },
                                      dr: /* Dirty */{
                                        _0: drResult,
                                        _1: /* Shown */0
                                      }
                                    },
                                    collectionsStatuses: undefined
                                  };
                          }
                          usedCarStatusResult$1 = usedCarStatusResult;
                        } else {
                          usedCarStatusResult$1 = usedCarStatusResult;
                        }
                      } else {
                        usedCarStatusResult$1 = usedCarStatusResult;
                      }
                    } else {
                      usedCarStatusResult$1 = usedCarStatusResult;
                    }
                  } else {
                    usedCarStatusResult$1 = usedCarStatusResult;
                  }
                } else {
                  usedCarStatusResult$1 = usedCarStatusResult;
                }
              } else {
                usedCarStatusResult$1 = usedCarStatusResult;
              }
            } else {
              usedCarStatusResult$1 = usedCarStatusResult;
            }
          } else {
            usedCarStatusResult$1 = usedCarStatusResult;
          }
        } else {
          usedCarStatusResult$1 = usedCarStatusResult;
        }
      } else {
        usedCarStatusResult$1 = usedCarStatusResult;
      }
    } else {
      usedCarStatusResult$1 = usedCarStatusResult;
    }
  } else {
    usedCarStatusResult$1 = usedCarStatusResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            usedCarStatus: /* Dirty */{
              _0: usedCarStatusResult$1,
              _1: /* Shown */0
            },
            orderStatus: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            showArchived: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            showHistoryDealer: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            budgetStatus: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            budgetType: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            newCarRegistrationID: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            vin: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            orderNumberCar: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            customerOrderNumber: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            dealerId: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            dr: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurUsedCarStatusField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarStatus, validators_usedCarStatus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: status,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderStatusField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderStatus, validators_orderStatus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: status,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: status,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShowArchivedField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.showArchived, validators_showArchived, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: status,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShowHistoryDealerField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.showHistoryDealer, validators_showHistoryDealer, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: status,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetStatusField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budgetStatus, validators_budgetStatus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: status,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetTypeField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.budgetType, validators_budgetType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: status,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurNewCarRegistrationIDField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.newCarRegistrationID, validators_newCarRegistrationID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: status,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVinField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.vin, validators_vin, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: status,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderNumberCarField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.orderNumberCar, validators_orderNumberCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: status,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerOrderNumberField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.customerOrderNumber, validators_customerOrderNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: status,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dealerId, validators_dealerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDrField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.dr, validators_dr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */13 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */13,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */14,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */16);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */15);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */14 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */15 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */16 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateUsedCarStatusField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.usedCarStatus, state.submissionStatus, validators_usedCarStatus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: status,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderStatusField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.orderStatus, state.submissionStatus, validators_orderStatus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: status,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: status,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShowArchivedField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.showArchived, state.submissionStatus, validators_showArchived, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: status,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShowHistoryDealerField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.showHistoryDealer, state.submissionStatus, validators_showHistoryDealer, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: status,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetStatusField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.budgetStatus, state.submissionStatus, validators_budgetStatus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: status,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetTypeField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.budgetType, state.submissionStatus, validators_budgetType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: status,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateNewCarRegistrationIDField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.newCarRegistrationID, state.submissionStatus, validators_newCarRegistrationID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: status,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVinField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.vin, state.submissionStatus, validators_vin, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: status,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderNumberCarField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, state.fieldsStatuses.orderNumberCar, state.submissionStatus, validators_orderNumberCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: status,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerOrderNumberField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, state.fieldsStatuses.customerOrderNumber, state.submissionStatus, validators_customerOrderNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: status,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$13 = Formality.validateDependentFieldOnChange(nextInput$11, nextFieldsStatuses.contents.dr, validators_dr, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$13 !== undefined) {
                    nextFieldsStatuses.contents = result$13;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$11, nextFieldsStatuses.contents.dealerId, state.submissionStatus, validators_dealerId, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: status,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDrField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$14 = Formality.validateDependentFieldOnChange(nextInput$12, nextFieldsStatuses$1.contents.dealerId, validators_dealerId, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$14 !== undefined) {
                    nextFieldsStatuses$1.contents = result$14;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$12, nextFieldsStatuses$1.contents.dr, state.submissionStatus, validators_dr, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */13 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */14 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */15 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateUsedCarStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarStatusField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderStatusField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateShowArchived: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShowArchivedField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateShowHistoryDealer: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShowHistoryDealerField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetStatusField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetTypeField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateNewCarRegistrationID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateNewCarRegistrationIDField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVin: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVinField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderNumberCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderNumberCarField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerOrderNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerOrderNumberField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDrField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurUsedCarStatus: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarStatusField */0);
            }),
          blurOrderStatus: (function (param) {
              Curry._1(dispatch, /* BlurOrderStatusField */1);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */2);
            }),
          blurShowArchived: (function (param) {
              Curry._1(dispatch, /* BlurShowArchivedField */3);
            }),
          blurShowHistoryDealer: (function (param) {
              Curry._1(dispatch, /* BlurShowHistoryDealerField */4);
            }),
          blurBudgetStatus: (function (param) {
              Curry._1(dispatch, /* BlurBudgetStatusField */5);
            }),
          blurBudgetType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetTypeField */6);
            }),
          blurNewCarRegistrationID: (function (param) {
              Curry._1(dispatch, /* BlurNewCarRegistrationIDField */7);
            }),
          blurVin: (function (param) {
              Curry._1(dispatch, /* BlurVinField */8);
            }),
          blurOrderNumberCar: (function (param) {
              Curry._1(dispatch, /* BlurOrderNumberCarField */9);
            }),
          blurCustomerOrderNumber: (function (param) {
              Curry._1(dispatch, /* BlurCustomerOrderNumberField */10);
            }),
          blurDealerId: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdField */11);
            }),
          blurDr: (function (param) {
              Curry._1(dispatch, /* BlurDrField */12);
            }),
          usedCarStatusResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarStatus),
          orderStatusResult: Formality.exposeFieldResult(state.fieldsStatuses.orderStatus),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          showArchivedResult: Formality.exposeFieldResult(state.fieldsStatuses.showArchived),
          showHistoryDealerResult: Formality.exposeFieldResult(state.fieldsStatuses.showHistoryDealer),
          budgetStatusResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetStatus),
          budgetTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetType),
          newCarRegistrationIDResult: Formality.exposeFieldResult(state.fieldsStatuses.newCarRegistrationID),
          vinResult: Formality.exposeFieldResult(state.fieldsStatuses.vin),
          orderNumberCarResult: Formality.exposeFieldResult(state.fieldsStatuses.orderNumberCar),
          customerOrderNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.customerOrderNumber),
          dealerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerId),
          drResult: Formality.exposeFieldResult(state.fieldsStatuses.dr),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.usedCarStatus || match.orderStatus || match.orderType || match.showArchived || match.showHistoryDealer || match.budgetStatus || match.budgetType || match.newCarRegistrationID || match.vin || match.orderNumberCar || match.customerOrderNumber || match.dealerId) {
                exit = 1;
              } else {
                if (!match.dr) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  return true;
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */13);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */14);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */15);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */15,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */16);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  dr: /* [] */0,
  dealerId: /* [] */0,
  customerOrderNumber: "",
  orderNumberCar: "",
  vin: "",
  newCarRegistrationID: "",
  budgetType: "",
  budgetStatus: /* [] */0,
  showHistoryDealer: false,
  showArchived: false,
  orderType: "",
  orderStatus: /* [] */0,
  usedCarStatus: /* [] */0
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
