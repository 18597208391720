// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../api/RestApi.bs.js";
import * as Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr from "./Flash_Edit_NonFleet_Version_Types.bs.js";

function isAmountCustom(customBonus) {
  return Belt_Option.mapWithDefault(customBonus, false, (function (x) {
                return x > 0.0;
              }));
}

function fetchNonFleetValidVersions(userManager, setResult, flashId, selectedRole) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", Flash_Edit_NonFleet_Version_Types$DvmAdminFrontendFr.actionVersionsList_decode, undefined, userManager, selectedRole, "/flash/" + flashId + "/modelversions/mapped").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var versions = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_List.map(versions, (function (x) {
                                        return {
                                                model: x.model,
                                                version: x.version,
                                                versionLabel: x.versionLabel,
                                                customBonus: x.customBonus,
                                                isCustomFEValue: isAmountCustom(x.customBonus),
                                                engineType: x.engineType,
                                                modelPhase: x.modelPhase
                                              };
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  isAmountCustom ,
  fetchNonFleetValidVersions ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
